import React, { useState, useMemo } from "react";
import { BsArrowRight } from "react-icons/bs";

import {
  PRECISION,
  USD_DECIMALS,
  SWAP,
  DECREASE,
  INCREASE,
  isTriggerRatioInverted,
  getNextToAmount,
  getExchangeRate,
  getExchangeRateDisplay,
  calculatePositionDelta,
  getLiquidationPrice,
  importImage,
  getDeltaStr,
} from "lib/legacy";
import { updateSwapOrder, updateIncreaseOrder, updateDecreaseOrder } from "domain/legacy";
import Modal from "../Modal/Modal";
import { getContract } from "config/contracts";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import {
  bigNumberify,
  formatAmount,
  formatAmountFree,
  formatNumber,
  getDisplayDecimalByAmount,
  parseValue,
} from "lib/numbers";
import { useChainId } from "lib/chains";
import { t, Trans } from "@lingui/macro";
import TakeProfitLongTriggerButton from "components/ExchangeV2/TakeProfitLongTriggerButton";
import TakeProfitShortTriggerButton from "components/ExchangeV2/TakeProfitShortTriggerButton";
import { getNextAveragePrice } from "./SwapBox";
import { getTokenByAddress } from "utils/tokenHelper";
import styled from "styled-components";
import TokenTopInfo from "./TokenTopInfo";
import RowsDropdown, { RowsDropdownHandler } from "components/ExchangeV2/RowsDropdown";
import GreenArraowIcon from "components/Icon/GreenArraowIcon";
import ExchangeInfoRow from "components/ExchangeV2/ExchangeInfoRow";
export default function OrderEditor(props) {
  const {
    account,
    order,
    setEditingOrder,
    infoTokens,
    pendingTxns,
    setPendingTxns,
    library,
    totalTokenWeights,
    usdgSupply,
    getPositionForOrder,
    positionsMap,
    savedShouldDisableValidationForTesting,
    selectedPosition,
  } = props;
  // console.log("????", selectedPosition);

  const { chainId } = useChainId();

  const position = order.type !== SWAP ? getPositionForOrder(account, order, positionsMap) : null;
  const liquidationPrice = order.type === DECREASE && position ? getLiquidationPrice(position) : null;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const fromTokenInfo = order.type === SWAP ? getTokenInfo(infoTokens, order.path[0], true, nativeTokenAddress) : null;
  const toTokenInfo =
    order.type === SWAP
      ? getTokenInfo(infoTokens, order.path[order.path.length - 1], order.shouldUnwrap, nativeTokenAddress)
      : null;

  const triggerRatioInverted = useMemo(() => {
    if (order.type !== SWAP) {
      return null;
    }

    return isTriggerRatioInverted(fromTokenInfo, toTokenInfo);
  }, [toTokenInfo, fromTokenInfo, order.type]);

  let initialRatio = 0;
  if (order.triggerRatio) {
    initialRatio = triggerRatioInverted ? PRECISION.mul(PRECISION).div(order.triggerRatio) : order.triggerRatio;
  }
  const [triggerRatioValue, setTriggerRatioValue] = useState(formatAmountFree(initialRatio, USD_DECIMALS, 6));
  const [triggerPriceValue, setTriggerPriceValue] = useState(
    formatAmountFree(order.triggerPrice, USD_DECIMALS, getDisplayDecimalByAmount(order.triggerPrice, USD_DECIMALS))
  );
  const triggerPrice = useMemo(() => {
    return triggerPriceValue ? parseValue(triggerPriceValue, USD_DECIMALS) : 0;
  }, [triggerPriceValue]);

  const triggerRatio = useMemo(() => {
    if (!triggerRatioValue) {
      return bigNumberify(0);
    }
    let ratio = parseValue(triggerRatioValue, USD_DECIMALS);
    if (triggerRatioInverted) {
      ratio = PRECISION.mul(PRECISION).div(ratio);
    }
    return ratio;
  }, [triggerRatioValue, triggerRatioInverted]);

  const indexTokenMarkPrice = useMemo(() => {
    if (order.type === SWAP) {
      return;
    }
    const toTokenInfo = getTokenInfo(infoTokens, order.indexToken);
    return order.isLong ? toTokenInfo.maxPrice : toTokenInfo.minPrice;
  }, [infoTokens, order]);

  let toAmount;
  if (order.type === SWAP) {
    const { amount } = getNextToAmount(
      chainId,
      order.amountIn,
      order.path[0],
      order.path[order.path.length - 1],
      infoTokens,
      undefined,
      triggerRatio,
      usdgSupply,
      totalTokenWeights
    );
    toAmount = amount;
  }

  const onClickPrimary = () => {
    setIsSubmitting(true);

    let func;
    let params;

    if (order.type === SWAP) {
      func = updateSwapOrder;
      params = [chainId, library, order.index, toAmount, triggerRatio, order.triggerAboveThreshold];
    } else if (order.type === DECREASE) {
      func = updateDecreaseOrder;
      params = [
        chainId,
        library,
        order.index,
        order.collateralDelta,
        order.sizeDelta,
        triggerPrice,
        order.triggerAboveThreshold,
      ];
    } else if (order.type === INCREASE) {
      func = updateIncreaseOrder;
      params = [chainId, library, order.index, order.sizeDelta, triggerPrice, order.triggerAboveThreshold];
    }

    params.push({
      successMsg: `Order updated!`,
      failMsg: `Order update failed.`,
      sentMsg: `Order update submitted!`,
      pendingTxns,
      setPendingTxns,
    });

    return func(...params)
      .then(() => {
        setEditingOrder(null);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onTriggerRatioChange = (evt) => {
    setTriggerRatioValue(evt.target.value || "");
  };

  const onTriggerPriceChange = (evt) => {
    setTriggerPriceValue(evt.target.value || "");
  };

  const getError = () => {
    if ((!triggerRatio || triggerRatio.eq(0)) && (!triggerPrice || triggerPrice.eq(0))) {
      return `Enter Price`;
    }
    if (order.type === SWAP && triggerRatio.eq(order.triggerRatio)) {
      return `Enter new Price`;
    }
    if (order.type !== SWAP && triggerPrice.eq(order.triggerPrice)) {
      return `Enter new Price`;
    }
    if (position) {
      if (order.type === DECREASE) {
        if (order.isLong && triggerPrice.lte(liquidationPrice)) {
          return `Price below Liq. Price`;
        }
        if (!order.isLong && triggerPrice.gte(liquidationPrice)) {
          return `Price above Liq. Price`;
        }
      }

      const { delta, hasProfit } = calculatePositionDelta(triggerPrice, position);
      if (hasProfit && delta.eq(0)) {
        return `Invalid price, see warning`;
      }
    }

    if (order.type !== SWAP && indexTokenMarkPrice && !savedShouldDisableValidationForTesting) {
      if (order.triggerAboveThreshold && indexTokenMarkPrice.gt(triggerPrice)) {
        return `Price below Mark Price`;
      }
      if (!order.triggerAboveThreshold && indexTokenMarkPrice.lt(triggerPrice)) {
        return `Price above Mark Price`;
      }
    }

    if (order.type === SWAP) {
      const currentRate = getExchangeRate(fromTokenInfo, toTokenInfo);
      if (currentRate && !currentRate.gte(triggerRatio)) {
        return triggerRatioInverted ? `Price is below Mark Price` : `Price is above Mark Price`;
      }
    }
  };

  const isPrimaryEnabled = () => {
    if (isSubmitting) {
      return false;
    }
    const error = getError();
    if (error) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }

    if (isSubmitting) {
      return `Editing...`;
    }
    return `Edit ${type}`;
  };
  const type = !position
    ? "Limit Order"
    : ["Swap", "Increase"].includes(order.type)
    ? "Increase Position"
    : order.isLong
    ? order.triggerAboveThreshold
      ? "Take Profit"
      : "Stop Loss"
    : order.triggerAboveThreshold
    ? "Stop Loss"
    : "Take Profit";

  const isIncrease = type === "Increase Position" || type === "Limit Order";
  const isTakeProfit = type === "Take Profit";
  const isStoploss = type === "Stop Loss";
  let nextAveragePrice;
  let nextLiquidationPrice;
  if (isIncrease) {
    // console.log("????", order);
  }

  if (isIncrease && triggerPrice && triggerPrice.gt(0) && position) {
    const data = calculatePositionDelta(triggerPrice || bigNumberify(0), position);
    const nextHasProfit = data.hasProfit;
    const nextDelta = data.delta;
    nextAveragePrice = getNextAveragePrice({
      size: position.size,
      sizeDelta: order.sizeDelta,
      hasProfit: nextHasProfit,
      delta: nextDelta,
      nextPrice: triggerPrice,
      isLong: order.isLong,
    });
    const fromUsdMin = getUsd(order.purchaseTokenAmount, order.purchaseToken, false, infoTokens);
    nextLiquidationPrice = getLiquidationPrice({
      isLong: order.isLong,
      size: position.size,
      collateral: position.collateral,
      averagePrice: nextAveragePrice,
      entryFundingRate: position.entryFundingRate,
      cumulativeFundingRate: position.cumulativeFundingRate,
      sizeDelta: order.sizeDelta,
      collateralDelta: fromUsdMin,
      increaseCollateral: true,
      increaseSize: true,
    });
  }
  const increaseToken =
    order.type === INCREASE
      ? getTokenInfo(infoTokens, order?.purchaseToken, true, nativeTokenAddress)
      : { maxPrice: parseValue(1, USD_DECIMALS) };
  const indexToken =
    order.type === INCREASE
      ? getTokenInfo(infoTokens, order?.indexToken, true, nativeTokenAddress)
      : { maxPrice: parseValue(1, USD_DECIMALS) };
  const collateral =
    order.type === INCREASE && !position
      ? order.purchaseTokenAmount
          .mul(increaseToken?.maxPrice || parseValue(1, USD_DECIMALS))
          .div(parseValue(1, increaseToken.decimals))
      : position.collateral;
  const leverage =
    order.type === INCREASE && !position ? order.sizeDelta.mul(parseValue(1, 30)).div(collateral) : position.leverage;
  const handleTakeProfitLongPercent = (percentage) => {
    const priceTrigger = position?.averagePrice || order?.triggerPrice;
    const value = priceTrigger.add(parseValue(percentage, 30).mul(priceTrigger).div(parseValue(1, 30)));
    setTriggerPriceValue(formatAmount(value, 30, 6, false));
  };
  const handleTakeProfitShortPercent = (percentage) => {
    const priceTrigger = position?.averagePrice || order?.triggerPrice;
    const value = priceTrigger.sub(parseValue(percentage, 30).mul(priceTrigger).div(parseValue(1, 30)));
    setTriggerPriceValue(formatAmount(value, 30, 6, false));
  };
  const [deltaStrTP, deltaPercentageStrTP] = useMemo(() => {
    if (!position || !position.markPrice || position.collateral.eq(0)) {
      return ["-", "-"];
    }
    if (!triggerPrice || triggerPrice.eq(0)) {
      return ["-", "-"];
    }

    const { pendingDelta, pendingDeltaPercentage, hasProfit } = calculatePositionDelta(
      triggerPrice,
      position,
      order.sizeDelta
    );

    const { deltaStr, deltaPercentageStr } = getDeltaStr({
      delta: pendingDelta,
      deltaPercentage: pendingDeltaPercentage,
      hasProfit,
    });
    return [deltaStr, deltaPercentageStr];
  }, [position, triggerPrice]);
  const [deltaStrTPOld] = useMemo(() => {
    if (!position || !position.markPrice || position.collateral.eq(0)) {
      return ["-", "-"];
    }
    if (!order.triggerPrice || order.triggerPrice.eq(0)) {
      return ["-", "-"];
    }

    const { pendingDelta, pendingDeltaPercentage, hasProfit } = calculatePositionDelta(
      order.triggerPrice,
      position,
      order.sizeDelta
    );

    const { deltaStr, deltaPercentageStr } = getDeltaStr({
      delta: pendingDelta,
      deltaPercentage: pendingDeltaPercentage,
      hasProfit,
    });
    return [deltaStr, deltaPercentageStr];
  }, [position, triggerPrice]);
  // if (isIncrease) console.log("?????", order);

  if (order.type !== SWAP) {
    const triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
    return (
      <Modal
        isVisible={true}
        className="PositionSeller-modal confirmation-modal add-to-position-modal add-to-position-modal1"
        setIsVisible={() => setEditingOrder(null)}
        label={`Edit ${type}`}
      >
        {/* <div className="order-title-line order-title-line-details">
          <img src={importImage("ic_" + position?.indexToken?.symbol?.toLowerCase() + "_24.svg")} alt="" />
          <div className="position-info-container">
            <div className="position-info">
              <div className="title">{position?.indexToken?.symbol}</div>
              <div className={`side ${order.isLong ? "side-long" : "side-short"}`}>
                {order.isLong ? "LONG" : "SHORT"}
              </div>
              <div className="side">MARKET</div>
              <div className="leverage-box">X</div>
            </div>

          </div>
        </div> */}

        <div className="content-container">
          {" "}
          <TokenTopInfo
            type="small"
            isLong={order.isLong}
            symbol={position?.indexToken?.symbol || indexToken?.symbol}
            orderOption={!position ? "LIMIT ORDER" : "MARKET"}
            leverageOption={formatAmount(position ? position.leverage : leverage, position ? 4 : 30, 2)}
            posId={selectedPosition?.posId}
          />
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <span>{isIncrease ? "Limit Price" : "Trigger Price"}</span>
              </div>
              <div
                className="muted align-right clickable"
                onClick={() => {
                  setTriggerPriceValue(
                    formatAmountFree(
                      indexTokenMarkPrice,
                      USD_DECIMALS,
                      getDisplayDecimalByAmount(indexTokenMarkPrice, USD_DECIMALS)
                    )
                  );
                }}
              >
                <span>
                  Mark: {indexTokenMarkPrice && "$"}
                  {formatAmount(
                    indexTokenMarkPrice,
                    USD_DECIMALS,
                    getDisplayDecimalByAmount(indexTokenMarkPrice, USD_DECIMALS),
                    true
                  )}
                </span>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={triggerPriceValue}
                  onChange={onTriggerPriceChange}
                />
              </div>
              <div className="PositionEditor-token-symbol">USD</div>
            </div>
          </div>
          {isTakeProfit ? (
            order.isLong ? (
              <TakeProfitLongTriggerButton
                onChangePercentage={handleTakeProfitLongPercent}
                balance={Number(formatAmount(position?.averagePrice || order.triggerPrice, 30, 30, false))}
                value={triggerPriceValue}
                percentArray={[0.01, 0.02, 0.03, 0.05]}
              />
            ) : (
              <TakeProfitShortTriggerButton
                onChangePercentage={handleTakeProfitShortPercent}
                balance={Number(formatAmount(position?.averagePrice || order.triggerPrice, 30, 30, false))}
                value={triggerPriceValue}
                percentArray={[0.01, 0.02, 0.03, 0.05]}
              />
            )
          ) : null}
          {isStoploss || isIncrease ? (
            order.isLong ? (
              <TakeProfitShortTriggerButton
                onChangePercentage={handleTakeProfitShortPercent}
                balance={Number(formatAmount(position?.averagePrice || order.triggerPrice, 30, 30, false))}
                value={triggerPriceValue}
                percentArray={[0.01, 0.02, 0.03, 0.05]}
              />
            ) : (
              <TakeProfitLongTriggerButton
                onChangePercentage={handleTakeProfitLongPercent}
                balance={Number(formatAmount(position?.averagePrice || order.triggerPrice, 30, 30, false))}
                value={triggerPriceValue}
                percentArray={[0.01, 0.02, 0.03, 0.05]}
              />
            )
          ) : null}
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <span>{isIncrease ? "Add amount" : "Close Quantity"}</span>
              </div>
              {/* {isTakeProfit ||
                (isStoploss && (
                  <div className="muted align-right clickable">
                    <span>
                      Max:{" "}
                      {formatAmount(
                        position.size.mul(parseValue(1, USD_DECIMALS)).div(position?.averagePrice),
                        USD_DECIMALS,
                        4,
                        true
                      )}
                    </span>
                  </div>
                ))} */}
            </div>
            <div className="Exchange-swap-section-bottom">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  disabled={true}
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={
                    isIncrease
                      ? Number(
                          formatAmount(
                            order.purchaseTokenAmount,
                            getTokenByAddress(chainId, order.purchaseToken)?.decimals,
                            getTokenByAddress(chainId, order.purchaseToken)?.decimals,
                            true
                          )
                        )
                      : Number(
                          formatAmount(
                            order.sizeDelta.mul(parseValue(1, USD_DECIMALS)).div(position?.averagePrice),
                            USD_DECIMALS,
                            position.indexToken.decimals,
                            true
                          )
                        )
                  }
                  // onChange={onTriggerPriceChange}
                />
              </div>
              <div className="PositionEditor-token-symbol">
                {isIncrease ? getTokenByAddress(chainId, order.purchaseToken)?.symbol : position.indexToken.symbol}
              </div>
            </div>
          </div>
          <div className="PositionEditor-info-box" style={{ marginTop: "16px" }}>
            <div className="square-container square-fee-container">
              <RowsDropdown show={true} handler={<RowsDropdownHandler> {type}</RowsDropdownHandler>}>
                <ExchangeInfoRow label={isIncrease ? `Limit Price` : `Trigger Price`}>
                  {triggerPrice && !triggerPrice.eq(order.triggerPrice) ? (
                    <>
                      <span style={{ textDecoration: "line-through", color: "#919499" }}>
                        {triggerPrice && isTakeProfit ? (order.isLong ? "≥ " : "≤") : ""}
                        {triggerPrice && isStoploss ? (order.isLong ? "≤" : "≥ ") : ""}
                        {triggerPrice && isIncrease ? (order.isLong ? "≤" : "≥ ") : ""}
                        {!triggerPrice || (triggerPrice && triggerPrice.eq(0)) ? "-" : ""}$
                        {formatAmount(order.triggerPrice, USD_DECIMALS, 2, true)}
                      </span>
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#919499"} />
                      </span>{" "}
                      <div>
                        <span style={{ marginLeft: "4px" }}>
                          {triggerPrice && isTakeProfit ? (order.isLong ? "≥ " : "≤") : ""}
                          {triggerPrice && isStoploss ? (order.isLong ? "≤" : "≥ ") : ""}
                          {triggerPrice && isIncrease ? (order.isLong ? "≤" : "≥ ") : ""}
                          {!triggerPrice || (triggerPrice && triggerPrice.eq(0)) ? "-" : ""}$
                          {formatAmount(triggerPrice, USD_DECIMALS, 2, true)}
                        </span>
                      </div>
                    </>
                  ) : (
                    <span style={{ marginLeft: "4px" }}>
                      {triggerPrice && isTakeProfit ? (order.isLong ? "≥ " : "≤") : ""}
                      {triggerPrice && isStoploss ? (order.isLong ? "≤" : "≥ ") : ""}
                      {triggerPrice && isIncrease ? (order.isLong ? "≤" : "≥ ") : ""}
                      {!triggerPrice || (triggerPrice && triggerPrice.eq(0)) ? "-" : ""}$
                      {formatAmount(order.triggerPrice, USD_DECIMALS, 2, true)}
                    </span>
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow
                  label={`${
                    isTakeProfit ? "TP" : isStoploss ? "SL" : type === "Limit Order" ? "Limit" : "Increase"
                  } Qty`}
                >
                  {" "}
                  {order.sizeDelta
                    ? formatNumber(
                        formatAmount(
                          order.sizeDelta.mul(parseValue(1, 30)).div(position?.averagePrice || order.triggerPrice),
                          30,
                          4,
                          false
                        ),
                        4
                      )
                    : "--"}{" "}
                  {position?.indexToken?.symbol || indexToken.symbol || increaseToken.symbol}
                </ExchangeInfoRow>
                {isIncrease && position ? (
                  <ExchangeInfoRow label={`Entry Price`}>
                    {nextAveragePrice && (
                      <div className="align-right">{`$${formatAmount(nextAveragePrice, USD_DECIMALS, 2, true)}`}</div>
                    )}
                  </ExchangeInfoRow>
                ) : null}
                {isIncrease && position ? (
                  <ExchangeInfoRow label={`Liq. Price`}>
                    {nextLiquidationPrice && (
                      <div className="align-right">{`$${formatAmount(
                        nextLiquidationPrice,
                        USD_DECIMALS,
                        2,
                        true
                      )}`}</div>
                    )}
                  </ExchangeInfoRow>
                ) : null}
                {(isTakeProfit || isStoploss) && position ? (
                  <ExchangeInfoRow label={`Liq. Price`}>
                    {liquidationPrice && (
                      <div className="align-right">{`$${formatAmount(liquidationPrice, USD_DECIMALS, 2, true)}`}</div>
                    )}
                  </ExchangeInfoRow>
                ) : null}

                {isTakeProfit || isStoploss ? (
                  <ExchangeInfoRow label={`Est. PnL`}>
                    {deltaStrTP && deltaStrTPOld !== deltaStrTP ? (
                      <>
                        <span style={{ textDecoration: "line-through", color: "#919499" }}>{deltaStrTPOld}</span>{" "}
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#919499"} />
                        </span>{" "}
                        <div
                          className={`bold align-right ${
                            deltaStrTP.includes("-") && deltaStrTP.includes("$") ? "negative" : "positive"
                          }`}
                        >
                          {deltaStrTP}
                        </div>
                      </>
                    ) : (
                      <span
                        className={`bold align-right ${
                          deltaStrTPOld.includes("-") && deltaStrTPOld.includes("$") ? "negative" : "positive"
                        }`}
                      >
                        {deltaStrTPOld}
                      </span>
                    )}
                  </ExchangeInfoRow>
                ) : null}

                <Divider />
                <div style={{ margin: 4 }} />
                <ExchangeInfoRow label={`Entry Price`}>
                  ${formatAmount(position?.averagePrice || order.triggerPrice, 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Size`}>
                  ${formatAmount(position?.size || order.sizeDelta, 30, 2, true)}
                </ExchangeInfoRow>
                {position && (
                  <ExchangeInfoRow label={`Pos. Qty`}>
                    {formatAmount(position.size.mul(parseValue(1, 30)).div(position?.averagePrice), 30, 4, true)}{" "}
                    {position?.indexToken?.symbol || indexToken.symbol || increaseToken.symbol}
                  </ExchangeInfoRow>
                )}
              </RowsDropdown>
            </div>
          </div>
          {/* <ExchangeInfoRow label={`Price`}>
            {triggerPrice && !triggerPrice.eq(order.triggerPrice) ? (
              <>
                <span className="muted">
                  {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, 3, true)}
                </span>
                &nbsp;
                <BsArrowRight />
                &nbsp;
                {triggerPricePrefix} {formatAmount(triggerPrice, USD_DECIMALS, 3, true)}
              </>
            ) : (
              <span>
                {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, 3, true)}
              </span>
            )}
          </ExchangeInfoRow> */}
          {/* {liquidationPrice && (
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>Liq. Price</span>
              </div>
              <div className="align-right">{`$${formatAmount(liquidationPrice, USD_DECIMALS, 3, true)}`}</div>
            </div>
          )} */}
        </div>
        <div className="Exchange-swap-button-container">
          <button
            className="App-cta Exchange-swap-button Exchange-list-modal-button"
            onClick={onClickPrimary}
            disabled={!isPrimaryEnabled()}
          >
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isVisible={true}
      className="Exchange-list-modal"
      setIsVisible={() => setEditingOrder(null)}
      label={`Edit order`}
    >
      <div className="Exchange-swap-section">
        <div className="Exchange-swap-section-top">
          <div className="muted">
            <span>Price</span>
          </div>
          {fromTokenInfo && toTokenInfo && (
            <div
              className="muted align-right clickable"
              onClick={() => {
                setTriggerRatioValue(
                  formatAmountFree(getExchangeRate(fromTokenInfo, toTokenInfo, triggerRatioInverted), USD_DECIMALS, 10)
                );
              }}
            >
              <span>Mark Price: </span>
              {formatAmount(getExchangeRate(fromTokenInfo, toTokenInfo, triggerRatioInverted), USD_DECIMALS, 2)}
            </div>
          )}
        </div>
        <div className="Exchange-swap-section-bottom">
          <div className="Exchange-swap-input-container">
            <input
              type="number"
              min="0"
              placeholder="0.0"
              className="Exchange-swap-input"
              value={triggerRatioValue}
              onChange={onTriggerRatioChange}
            />
          </div>
          {(() => {
            if (!toTokenInfo) return;
            if (!fromTokenInfo) return;
            const [tokenA, tokenB] = triggerRatioInverted ? [toTokenInfo, fromTokenInfo] : [fromTokenInfo, toTokenInfo];
            return (
              <div className="PositionEditor-token-symbol">
                {tokenA.symbol}&nbsp;/&nbsp;{tokenB.symbol}
              </div>
            );
          })()}
        </div>
      </div>
      <ExchangeInfoRow label={`Minimum received`}>
        {triggerRatio && !triggerRatio.eq(order.triggerRatio) ? (
          <>
            <span className="muted">{formatAmount(order.minOut, toTokenInfo.decimals, 4, true)}</span>
            &nbsp;
            <BsArrowRight />
            &nbsp;
            {formatAmount(toAmount, toTokenInfo.decimals, 4, true)}
          </>
        ) : (
          formatAmount(order.minOut, toTokenInfo.decimals, 4, true)
        )}
        &nbsp;{toTokenInfo.symbol}
      </ExchangeInfoRow>
      <ExchangeInfoRow label={`Price`}>
        {triggerRatio && !triggerRatio.eq(0) && !triggerRatio.eq(order.triggerRatio) ? (
          <>
            <span className="muted">
              {getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo, {
                omitSymbols: !triggerRatio || !triggerRatio.eq(order.triggerRatio),
              })}
            </span>
            &nbsp;
            <BsArrowRight />
            &nbsp;
            {getExchangeRateDisplay(triggerRatio, fromTokenInfo, toTokenInfo)}
          </>
        ) : (
          getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo, {
            omitSymbols: !triggerRatio || !triggerRatio.eq(order.triggerRatio),
          })
        )}
      </ExchangeInfoRow>
      {fromTokenInfo && (
        <div className="Exchange-info-row">
          <div className="Exchange-info-label">
            <span>{fromTokenInfo.symbol} price</span>
          </div>
          <div className="align-right">{formatAmount(fromTokenInfo.minPrice, USD_DECIMALS, 2, true)} USD</div>
        </div>
      )}
      {toTokenInfo && (
        <div className="Exchange-info-row">
          <div className="Exchange-info-label">
            <span>{toTokenInfo.symbol} price</span>
          </div>
          <div className="align-right">{formatAmount(toTokenInfo.maxPrice, USD_DECIMALS, 2, true)} USD</div>
        </div>
      )}
      <div className="Exchange-swap-button-container">
        <button
          className="App-cta Exchange-swap-button Exchange-list-modal-button"
          onClick={onClickPrimary}
          disabled={!isPrimaryEnabled()}
        >
          {getPrimaryText()}
        </button>
      </div>
    </Modal>
  );
}

const Divider = styled.div`
  background: var(--Border, rgba(239, 236, 235, 0.08));
  height: 1px;
  width: 100%;
  margin: 8px 0 16px 0;
`;
