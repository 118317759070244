import React from "react";

import cx from "classnames";

import "./Checkbox.css";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
import { useThemeContext } from "contexts/ThemeProvider";

import icCheck from "img/icons/checkbox-ic.svg";
import icChecked from "img/icons/checkbox-ic1.svg";
import icCheckLight from "img/icons/checkbox-icLight.svg";
import icCheckedLight from "img/icons/checkbox-ic1Light.svg";

export default function Checkbox(props) {
  const { isChecked, setIsChecked, disabled, className } = props;
  const { isLightTheme } = useThemeContext();

  return (
    <div
      className={cx("Checkbox", { disabled, selected: isChecked }, className)}
      onClick={() => {
        setIsChecked && setIsChecked(!isChecked);
      }}
    >
      <span className="Checkbox-icon-wrapper">
        {isChecked && (
          <img
            src={isLightTheme ? icCheckedLight : icChecked}
            alt="icon-check"
            className="App-icon Checkbox-icon active"
          />
        )}
        {!isChecked && (
          <img
            src={isLightTheme ? icCheckLight : icCheck}
            alt="icon-check"
            className="App-icon Checkbox-icon inactive"
          />
        )}
      </span>
      <span className="Checkbox-label">{props.children}</span>
    </div>
  );
}
