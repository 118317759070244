import {
  Bar,
  BarChart,
  Brush,
  CartesianAxis,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { uniq } from "lodash";
import ChartWrapper from "./ChartWrapper";

import { useChartViewState } from "hooks/useChartViewState";
import { convertToPercents } from "lib/helper";
import { memo } from "react";
import { formatNumberWithNegative } from "lib/numbers";
import SpinningLoader from "components/Common/SpinningLoader";
import { formatTimestamp2LocalDate } from "utils/formatDate";
import NoDataIcon from "img/analytics/no-data-icon.svg";
import { THEME_LIGHT } from "lib/legacy";
import { useThemeContext } from "contexts/ThemeProvider";

const formatAxis = (tickItem, isSwapToUsd) => {
  return `${Number(tickItem) >= 0 ? "" : "-"}${isSwapToUsd ? "$" : ""}${formatNumberWithNegative(
    Math.abs(tickItem),
    1
  )}`;
};

const convertToPercentsHandler = (data) =>
  convertToPercents(data, { ignoreKeys: ["cumulative", "movingAverageAll"], totalKey: "all" });

type BarChartType = {
  title: string;
  data: any;
  loading: boolean;
  chartHeight: number;
  yaxisWidth: [number, number];
  barSize?: number;
  barKeys?: string[];
  lineKeys?: string[];
  barColors?: string[];
  lineColors?: string[];
  barYAxisId?: string[];
  lineYAxisId?: string[];
  isSwapToUsd?: boolean;
  tooltipKeys?: string[];
};

const LABEL_MAPPED_BY_KEY = {
  dailyPnl: "Daily PnL",
  liquidation: "Liquidation",
  realizedPnl: "Realized PnL",
  volume: "Volume",
  volumeCumulative: "Cumulative",
  longOpenInterest: "Long",
  shortOpenInterest: "Short",
  returningUsers: "Returning Users",
  newUsers: "New Users",
  userCumulative: "Cumulative User",
  tradePerUser: "Trades Per User",
  fees: "Amount",
  feesCumulative: "Cumulative",
  gmPrice: "GM Price",
  gmTvl: "GM Tvl",
};

const NO_DATA_RECORD = [
  {
    name: "0",
    uv: 0,
  },
  {
    name: "Auto",
    uv: 0,
  },
];

const NUMBER_SPACE_OF_DOTS = 9;

const CustomizedDot = (props) => {
  const { cx, cy, index, stroke, payload, value } = props;
  if ((index + 1) % NUMBER_SPACE_OF_DOTS !== 0) return null;
  return (
    <svg x={cx - 4} y={cy - 4} width={8} height={8} fill="white">
      <g transform="translate(4 4)">
        <circle r="4" fill={stroke} />
        <circle r="2" fill="white" />
      </g>
    </svg>
  );
};

const VolumeChart = (props: BarChartType) => {
  const {
    title,
    data,
    loading,
    chartHeight,
    yaxisWidth,
    barSize,
    barKeys = [],
    lineKeys = [],
    barColors,
    lineColors,
    barYAxisId,
    lineYAxisId,
    isSwapToUsd,
  } = props;
  const { isLightTheme } = useThemeContext() as any;

  const controls = {
    convertToPercents: convertToPercentsHandler,
  };

  const { viewState, togglePercentView, formattedData, ...viewSettings } = useChartViewState({
    controls,
    data,
  });

  const csvFields = [...barKeys, ...lineKeys].map((key) => ({
    key,
    name: LABEL_MAPPED_BY_KEY[key],
  }));

  const CustomTooltip = (props) => {
    const { active, payload, label, isSwapToUsd } = props;
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          <div className="data-group">
            <div className="value">{payload[0].payload.timestamp || ""}</div>
            {payload.map((p) => {
              const { payload: data } = p;
              const textColor = p.fill || p.stroke || "red";

              return (
                <div className="data-row" key={p.dataKey}>
                  <div
                    className="dot"
                    style={{
                      width: 16,
                      height: 16,
                      backgroundColor: p.fill || p.stroke || "red",
                    }}
                  />
                  <div className="date" style={{ color: textColor }}>{`${LABEL_MAPPED_BY_KEY[p.dataKey] || "..."}: ${
                    Number(data[p.dataKey]) >= 0 ? "" : "-"
                  }${isSwapToUsd ? "$" : ""}${formatNumberWithNegative(Math.abs(data[p.dataKey]), 2)}`}</div>
                </div>
              );
            })}
            {payload[0].payload["total"] !== undefined && (
              <div className="data-row">
                <div className="date">
                  Total: {Number(payload[0].payload?.total) >= 0 ? "" : "-"}
                  {isSwapToUsd ? "$" : ""}
                  {formatNumberWithNegative(payload[0].payload?.total, 2)}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <ChartWrapper
      title={title}
      loading={loading}
      data={formattedData}
      viewState={viewState}
      csvFields={csvFields}
      togglePercentView={togglePercentView}
    >
      <div className="bar-chart-wrapper">
        {data && data.length !== 0 ? (
          <ResponsiveContainer width="100%" height={chartHeight}>
            <ComposedChart width={150} height={40} data={data} stackOffset="sign">
              <Tooltip content={<CustomTooltip isSwapToUsd={isSwapToUsd} />} />
              <CartesianGrid strokeDasharray="4" stroke={isLightTheme ? "#0000001A" : "#FFFFFF1A"} />
              <XAxis
                dataKey="timestamp"
                height={12}
                minTickGap={15}
                fontSize={12}
                stroke={isLightTheme ? "#0D1A16B2" : "#FFFFFF66"}
              />
              <YAxis
                yAxisId="left"
                axisLine={false}
                tickLine={false}
                // width={45}
                width={yaxisWidth[0]}
                tickFormatter={(e) => formatAxis(e, isSwapToUsd)}
                stroke={isLightTheme ? "#0D1A16B2" : "#FFFFFF66"}
                fontSize={12}
              />
              <YAxis
                yAxisId="right"
                axisLine={false}
                tickLine={false}
                // width={lineYAxisId?.some((l) => l === "right") ?  40 : 10}
                width={yaxisWidth[1]}
                tickFormatter={(e) => formatAxis(e, isSwapToUsd)}
                orientation="right"
                fontSize={12}
                stroke={isLightTheme ? "#0D1A16B2" : "#FFFFFF66"}
              />
              {!!barKeys?.length &&
                barKeys.map((barkey, index) => {
                  return (
                    <>
                      <Bar
                        yAxisId={barYAxisId ? barYAxisId[index] : "left"}
                        stackId="pmClass"
                        maxBarSize={18}
                        dataKey={barkey}
                        width={20}
                        fill={barColors?.[index] || "red"}
                      />
                      {/* {typeBarChart && typeBarChart === "PositiveAndNegativeBarChart" && <ReferenceLine y={0} stroke="#000" />} */}
                    </>
                  );
                })}
              {lineKeys &&
                !!lineKeys?.length &&
                lineKeys.map((lineKey, index) => {
                  return (
                    <Line
                      yAxisId={lineYAxisId ? lineYAxisId[index] : "right"}
                      type="monotone"
                      dataKey={lineKey}
                      // dot={<CustomizedDot />}
                      dot={false}
                      strokeWidth={2}
                      stroke={lineColors?.[index] || "#F3FF74"}
                      fill={lineColors?.[index] || "#F3FF74"}
                    />
                  );
                })}
            </ComposedChart>
          </ResponsiveContainer>
        ) : (
          <div className="no-record-chart">
            <img src={NoDataIcon} className="no-record-icon-styled" />
            <span>No Data</span>
          </div>
        )}
        {/* {!data && (
          <div className="no-record-chart">
            <SpinningLoader size={"40"} />
          </div>
        )} */}
      </div>
    </ChartWrapper>
  );
};

export default memo(VolumeChart);
