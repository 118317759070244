import React from "react";
import { Menu } from "@headlessui/react";
import { FaChevronDown } from "react-icons/fa";
import cx from "classnames";
import "./ChartTokenSelector.css";
import { importImage, LONG, SHORT, SWAP } from "lib/legacy";
import { getTokens, getWhitelistedTokens } from "config/tokens";
import triangleIcon from "img/triangle.svg";
import { MAX_LERVERAGES } from "pages/BuyGMY/constants";
import SelectTokenChart from "components/ExchangeV2/SelectTokenChart";
import { useHistory } from "react-router-dom";
export default function ChartTokenSelector(props) {
  const {
    chainId,
    onSelectSymbolToken,
    selectedToken,
    onSelectToken,
    swapOption,
    marketAsssetList,
    setDefaultChartTokenV2,
  } = props;

  const isLong = swapOption === LONG;
  const isShort = swapOption === SHORT;
  const isSwap = swapOption === SWAP;
  let options = getTokens(chainId);
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const indexTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isWrapped);
  const shortableTokens = indexTokens.filter((token) => token.isShortable);
  const history = useHistory();
  if (isLong) {
    options = indexTokens;
  }
  if (isShort) {
    options = shortableTokens;
  }

  const onSelect = async (token) => {
    // console.log("???", {token, options, marketAsssetList});
    if (token.isV1) {
      const v1Token = options.find((x) => x.symbol === token.tokenSymbol);
      onSelectToken(v1Token);
    }
    if (token.isV2) {
      setDefaultChartTokenV2(token.symbol);
    }
    onSelectSymbolToken(token?.symbol || token?.tokenSymbol);
    // if(!token.isV1 && token.isV2) {
    //   history.push("/trade?switch=true")
    // }
  };

  var value = selectedToken;
  const tokenImage = importImage(`ic_${selectedToken?.symbol?.toLowerCase()}_24.svg`);
  return (
    // <Menu>
    //   <Menu.Button as="div" disabled={isSwap}>
    //     <button className={cx("App-cta small transparent chart-token-selector", { "default-cursor": isSwap })}>
    //       <div className="max-leverage-container">
    //         <img alt="" src={tokenImage} className="token-selected" />
    //         <span className="chart-token-selector--current">{value.symbol}/USD</span>
    //         {/* {!isSwap && <div className="max-lerveage">{MAX_LERVERAGES[chainId][value.symbol]}x</div>} */}
    //         {!isSwap && <img alt="" src={triangleIcon} className="arrow-chart" /> }
    //       </div>
    //     </button>
    //   </Menu.Button>
    //   <div className="chart-token-menu">
    //     <Menu.Items as="div" className="menu-items chart-token-menu-items">
    //       {options.map((option, index) => (
    //         <Menu.Item key={index}>
    //           <div
    //             className="menu-item"
    //             onClick={() => {
    //               onSelect(option);
    //             }}
    //           >
    //             <span style={{ marginLeft: 5 }} className="token-label">
    //               {option.symbol}/USD
    //             </span>
    //           </div>
    //         </Menu.Item>
    //       ))}
    //     </Menu.Items>
    //   </div>
    // </Menu>
    <Menu>
      <Menu.Button as="div" disabled={isSwap}>
        <button
          className={cx("App-cta small transparent chart-token-selector", {
            "default-cursor": isSwap,
          })}
        >
          <div className="max-leverage-container">
            <img alt="" src={tokenImage} className="token-selected" />
            <span className="chart-token-selector--current">{value.symbol}/USD</span>

            <img alt="" src={triangleIcon} className="arrow-chart" />
          </div>
        </button>
      </Menu.Button>
      <div className="chart-token-menu">
        <Menu.Items as="div" className="menu-items chart-token-menu-items">
          <SelectTokenChart tokens={marketAsssetList} onSelect={onSelect} current={value.symbol} />
        </Menu.Items>
      </div>
    </Menu>
  );
}
