import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useCallback, useEffect, useRef, useState } from "react";
import ReaderMsp from "abis/ReaderMsp.json";
import SettingsManager from "abis/SettingsManager.json";

import { getContract } from "config/contracts";
import { useChainId } from "lib/chains";

import { convertPositionData, DEFAULT_SLIPPAGE_AMOUNT } from "lib/legacy";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { SLIPPAGE_BPS_KEY } from "config/localStorage";

const useUserTradeInfo = () => {
  const { chainId } = useChainId();
  const { account, library } = useWeb3React();

  const [listPosition, setListPosition] = useState([]);
  const [loading, setLoading] = useState(true);
  const readerAddress = getContract(chainId, "ReaderMsp");
  const settingsManagerAddress = getContract(chainId, "SettingsManager");

  var timeout = useRef(null);
  var oldAccount = useRef(null);
  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const getTradeData = useCallback(async () => {
    const readerContract = new ethers.Contract(readerAddress, ReaderMsp.abi, library?.getSigner());
    const settingsManagerContract = new ethers.Contract(settingsManagerAddress, SettingsManager.abi, library?.getSigner());
    if (readerContract && account) {
      const tradeData = await readerContract.getUserAlivePositions(account);
      const listLiquidateThreshold = await Promise.all(
        tradeData[1].map(element => settingsManagerContract.liquidateThreshold(element.tokenId))
      );
      const list = convertPositionData(tradeData, listLiquidateThreshold, savedSlippageAmount);
      setListPosition(list);
      setLoading(false);
    }
  }, [account, library, readerAddress, savedSlippageAmount, settingsManagerAddress]);

  useEffect(() => {
    if (account) {
      if (oldAccount.current !== account) oldAccount.current = account;
      if (timeout.current) {
        clearTimeout(timeout.current);
        getTradeData();
        const clear = setInterval(() => {
          getTradeData().catch((err) => console.error(err.stack));
        }, 4000);

        return () => clearInterval(clear);
      }
    } else {
      if (!oldAccount.current)
        timeout.current = setTimeout(() => {
          setLoading(false);
          setListPosition(false);
        }, 3500);
      else {
        setLoading(false);
        setListPosition(false);
      }
    }
  }, [account, chainId]);
  return { positions: listPosition, loading };
};

export default useUserTradeInfo;
