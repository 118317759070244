import { Menu } from "@headlessui/react";
import { Trans } from "@lingui/macro";
import { HiDotsVertical } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import { BiSelectMultiple } from "react-icons/bi";
import { RiShareBoxFill } from "react-icons/ri";
import "./PositionDropdown.css";
import threeDotsIcon from "img/trade/three-dot.svg";
import shareIcon from "img/trade/share.svg";
import addPositionIcon from "img/trade/add_positon.svg";
import addTpIcon from "img/trade/add_tp.svg";
import { useMediaQuery } from "react-responsive";
function PositionDropdown({
  handleAddTPSL,
  handleEditCollateral,
  handleShare,
  handleMarketSelect,
  handleAddToPosition,
  handleaAddTPSL,
}) {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <Menu>
      <Menu.Button as="div">
        <button className="PositionDropdown-dots-icon">
          <img src={threeDotsIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "15px" }} />
          {/* {isSmallScreen ? "Edit Order" : ""} */}
        </button>
      </Menu.Button>
      <div className="PositionDropdown-extra-options">
        <Menu.Items as="div" className="menu-items">
          {/* <Menu.Item>
            <div className="menu-item" onClick={handleEditCollateral}>
              <AiOutlineEdit fontSize={16} />
              <p>
                <span>Edit Collateral</span>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div className="menu-item" onClick={handleMarketSelect}>
              <BiSelectMultiple fontSize={16} />
              <p>
                <span>Select Market</span>
              </p>
            </div>
          </Menu.Item> */}
          <Menu.Item>
            <div
              className="menu-item"
              onClick={(e) => {
                e.stopPropagation();
                handleShare && handleShare();
              }}
            >
              <img src={shareIcon} alt="icon" />
              <p>
                <span>Share Position</span>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div
              className="menu-item"
              onClick={(e) => {
                e.stopPropagation();
                handleAddToPosition && handleAddToPosition();
              }}
            >
              <img src={addPositionIcon} alt="icon" />
              <p>
                <span>Increase Position</span>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div
              className="menu-item"
              onClick={(e) => {
                e.stopPropagation();
                handleAddTPSL && handleAddTPSL();
              }}
            >
              <img src={addTpIcon} alt="icon" />
              <p>
                <span>Add TP/SL</span>
              </p>
            </div>
          </Menu.Item>
        </Menu.Items>
      </div>
    </Menu>
  );
}

export default PositionDropdown;
