import { useMemo, useState } from "react";
import PositionItemV1 from "./PositionItemV1";
import PositionItemV2 from "./PositionItemV2";
import PositionShare from "components/ExchangeV2/PositionShare";
import PositionShareV1 from "components/Exchange/PositionShare";
import AddTPSL from "components/ExchangeV2/AddTPSL";
import AddTrailingStop from "components/ExchangeV2/AddTrailingStop";
import EditLeverage from "components/ExchangeV2/EditLeverage";
import ClosePosition from "components/ExchangeV2/ClosePosition";
import PositionDetails from "components/ExchangeV2/PositionDetails";
import AddToPosition from "components/ExchangeV2/AddToPosition";
import AddCollateral from "components/ExchangeV2/AddCollateral";
import EditTakeProfit from "components/ExchangeV2/EditTakeProfit";
import EditStopLoss from "components/ExchangeV2/EditStopLoss";
import { BigNumber, ethers } from "ethers";
import { parseValue } from "lib/numbers";
import { addOrRemoveCollateral, addPosition, addTPSL, addTrailingStop, decreasePosition } from "domain/legacy";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import { getContract } from "config/contracts";
import SettingsManager from "abis/SettingsManager.json";
import PositionEditor from "components/Exchange/PositionEditor";
import { getTokenInfo, getUsd } from "domain/tokens";
import { getLeverage } from "lib/legacy";
import useAccountType, { AccountType } from "lib/wallets/useAccountType";
import PositionSeller from "components/Exchange/PositionSeller";
// import AddTPSLV1 from "components/ExchangeV2/AddTPSLV1";
import EditCollateral from "components/ExchangeV2/EditCollateral";
import PaginationV2 from "../../pages/AnalyticsV3/components/PaginationV2";
import { DEFAULT_PAGE_SIZE_POSITIONS } from "../../hooks/useUserPosition";
import AddTPSLV1 from "components/Exchange/AddTPSLV1";
import PositionItemMobileV1 from "./PositionItemMobileV1";
import PositionItemMobileV2 from "./PositionItemMobileV2";
import AddToPositionV1 from "./AddToPositionV1";
import PositionDetailsV1 from "components/ExchangeV2/PositionDetailsV1";
import OrdersToa from "components/Exchange/OrdersToa";
const { AddressZero } = ethers.constants;
const PositionListMerged = ({
  /// v2
  userTokenBalances,
  closePositionTempt,
  positions,
  setPendingTxns,
  loading,
  chainId,
  library,
  account,
  active,
  marketTokensInfo,
  marketAsssets,
  /// v1
  nativeTokenAddress,
  orders,
  pendingPositions,
  setPendingPositions,
  positionsMap,
  pendingTxns,
  infoTokens,
  savedIsPnlInLeverage,
  positionRouterApproved,
  isPositionRouterApproving,
  isWaitingForPositionRouterApproval,
  approvePositionRouter,
  minExecutionFee,
  minExecutionFeeUSD,
  minExecutionFeeErrorMessage,
  savedSlippageAmount,
  setIsWaitingForPluginApproval,
  approveOrderBook,
  isPluginApproving,
  isWaitingForPluginApproval,
  orderBookApproved,
  flagOrdersEnabled,
  totalTokenWeights,
  usdgSupply,
  v1Orders,
  v2Orders,
  setPosOrders,
  setListSection,
}) => {
  // console.log("?????", positions);
  const nativeTokenInfo = getTokenInfo(infoTokens, AddressZero);
  const [selectedPosition, setSelectedPosition] = useState(undefined);
  const [positionToShare, setPositionToShare] = useState(null);
  const [positionToSellKey, setPositionToSellKey] = useState(undefined);

  const [isPositionShareModalOpen, setIsPositionShareModalOpen] = useState(false);
  const [isTrailingStopModalOpen, setIsTrailingStopModalOpen] = useState(false);
  const [isEditTakeProfitModalOpen, setIsEditTakeProfitModalOpen] = useState(false);
  const [isEditStopLossModalOpen, setIsEditStopLossModalOpen] = useState(false);
  const [isAddToPosition, setIsAddToPosition] = useState(false);
  const [isTPSLModalOPen, setIsTPSLModalOPen] = useState(false);
  const [isAddCollateralModalOpen, setIsAddCollateralModalOpen] = useState(false);
  const [isEditLeverageModalOPen, setIsEditLeverageModalOPen] = useState(false);
  const [isPositionSellerVisible, setIsPositionSellerVisible] = useState(undefined);
  const [isPositionDetailsVisible, setIsPositionDetailsVisible] = useState(undefined);
  const [isSectionConfirming, setIsSectionConfirming] = useState(false);
  const [page, setPage] = useState(1);

  /// v1 -----------------------------------
  const [isPositionSellerVisibleV1, setIsPositionSellerVisibleV1] = useState(undefined);
  const [isAddTPSLVisibleV1, setIsAddTPSLVisibleV1] = useState(undefined);
  const [collateralTokenAddress, setCollateralTokenAddress] = useState(undefined);
  const [isPositionShareModalOpenV1, setIsPositionShareModalOpenV1] = useState(false);
  const [isAddToPositionV1, setIsAddToPositionV1] = useState(false);
  const [isDetailsPositionV1, setIsDetailsPositionV1] = useState(false);
  const [positionToAddKey, setPositionToAddKey] = useState(undefined);
  const [positionSelectedDetails, setPositionSelectedDetails] = useState(undefined);
  const [positionToEditKey, setPositionToEditKey] = useState(undefined);
  const [positionToSellKeyV1, setPositionToSellKeyV1] = useState(undefined);
  const [isPositionEditorVisible, setIsPositionEditorVisible] = useState(undefined);
  const [isTPSLModalV1, setIsTPSLModalV1] = useState(undefined);
  const [selectedPositionV1, setSelectedPositionV1] = useState(undefined);
  const [ordersToaOpen, setOrdersToaOpen] = useState(false);
  const accountType = useAccountType();
  const isContractAccount = accountType === AccountType.CONTRACT;
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false);
  /// -----------------------------------

  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const { data: marketOrderGasFee } = useSWR(
    ["ExChange:marketOrderGasFee", chainId, settingsManagerAddress, "marketOrderGasFee"],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const { data: triggerGasFee } = useSWR(["ExChange:triggerGasFee", chainId, settingsManagerAddress, "triggerGasFee"], {
    fetcher: contractFetcher(library, SettingsManager),
  });
  const handleSelectedPosition = (position) => {
    setSelectedPosition(position);
    setIsPositionDetailsVisible(true);
  };
  const sellPosition = (position) => {
    setSelectedPosition(position);
    setIsPositionSellerVisible(true);
    // setIsHigherSlippageAllowed(false);
  };
  /// -----------------------------------
  const handleClosePosition = (position, size) => {
    const sizeAmount = size;
    const isLong = position.isLong;
    const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
    const markPrice = BigNumber.from((isLong ? currentMarket?.askPrice : currentMarket?.bidPrice) || 0);

    const slippageStorage = window.localStorage.getItem(`[${chainId},"Exchange-swap-slippage-basis-points-v3"]`);
    const allowPrice = position.isLong
      ? markPrice.sub(markPrice.mul(parseValue(slippageStorage || 50, 30)).div(parseValue(10000, 30)))
      : markPrice.add(markPrice.mul(parseValue(slippageStorage || 50, 30)).div(parseValue(10000, 30)));
    const posId = position.posId;
    setIsSectionConfirming(true);
    decreasePosition(chainId, library, sizeAmount, allowPrice, posId, {
      value: marketOrderGasFee,
      setPendingTxns,
      sentMsg: `Close position submitted!`,
      failMsg: `Closed position failed.`,
      successMsg: "Closed position successfully!",
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      hideSuccessMsg: true,
    })
      .then(async (res) => {
        // setIsConfirming(false);
        setIsPositionSellerVisible(false);
        setIsPositionDetailsVisible(false);
        await res.wait();
        if (sizeAmount.eq(parseValue(position.size, 30))) {
          closePositionTempt.push({ ...position });
        }
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };
  const handleEditCollateral = (position, collateral) => {
    const posId = position.posId;
    setIsSectionConfirming(true);
    addOrRemoveCollateral(chainId, library, false, posId, collateral, {
      value: marketOrderGasFee,
      setPendingTxns,
      sentMsg: `Edit leverage submitted.`,
      failMsg: `Edit leverage failed.`,
      successMsg: "Edited leverage successfully",
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      hideSuccessMsg: false,
    })
      .then(async () => {
        // setIsConfirming(false);
        setIsEditLeverageModalOPen(false);
        setIsAddCollateralModalOpen(false);
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };
  const handleAddToPostion = (position, collateralDelta, sizeDelta) => {
    const posId = position.posId;
    const isLong = position.isLong;
    const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
    const markPrice = BigNumber.from((isLong ? currentMarket?.askPrice : currentMarket.bidPrice) || 0);
    const slippageStorage = window.localStorage.getItem(`[${chainId},"Exchange-swap-slippage-basis-points-v3"]`);
    const allowPrice = position.isLong
      ? markPrice.add(markPrice.mul(parseValue(slippageStorage || 50, 30)).div(parseValue(10000, 30)))
      : markPrice.sub(markPrice.mul(parseValue(slippageStorage || 50, 30)).div(parseValue(10000, 30)));
    // console.log("??????",{chainId, library, posId, collateralDelta, sizeDelta, allowPrice});
    setIsSectionConfirming(true);
    addPosition(chainId, library, posId, collateralDelta, sizeDelta, allowPrice, {
      value: marketOrderGasFee,
      setPendingTxns,
      sentMsg: `Add to position submitted!`,
      failMsg: `Add to positon failed.`,
      successMsg: "Added to position successfully!",
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      hideSuccessMsg: false,
    })
      .then(async () => {
        // setIsConfirming(false);
        setIsAddToPosition(false);
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };
  const handleEditPosition = (position, amount) => {
    const sizeAmount = parseValue(amount, 30);
    const posId = position.posId;
    setIsSectionConfirming(true);
    addOrRemoveCollateral(chainId, library, true, posId, sizeAmount, {
      value: marketOrderGasFee,
      setPendingTxns,
      sentMsg: `Edit collateral submitted.`,
      failMsg: `Edit collateral failed.`,
      successMsg: "Edited collateral successfully",
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      hideSuccessMsg: false,
    })
      .then(async () => {
        // setIsConfirming(false);
        setIsAddCollateralModalOpen(false);
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };
  const handleAddTPSLPosition = (position, isTPs, prices, amountPercents) => {
    const posId = position.posId;
    setIsSectionConfirming(true);
    addTPSL(chainId, library, posId, isTPs, prices, amountPercents, {
      value: BigNumber.from(isTPs.length).mul(triggerGasFee),
      setPendingTxns,
      sentMsg: `Add TP/SL submitted.`,
      failMsg: `Add TP/SL failed`,
      successMsg: "Added TP/SL successfully",
      hideSuccessMsg: false,
    })
      .then(async () => {
        // setIsConfirming(false);
        setIsTPSLModalOPen(false);
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };
  const handleAddTPSLPositionV1 = (position, isTPs, prices, amountPercents) => {};
  const handleAddTrailingStop = (posId, params) => {
    // console.log("?????", { posId, params });
    setIsSectionConfirming(true);
    addTrailingStop(chainId, library, posId, params, {
      value: triggerGasFee,
      setPendingTxns,
      sentMsg: `Add trailing stop submitted.`,
      failMsg: `Add trailing stop failed`,
      successMsg: "Added trailing stop successfully",
      hideSuccessMsg: false,
    })
      .then(async () => {
        // setIsConfirming(false);
        setIsTrailingStopModalOpen(false);
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };

  const filterPositions = useMemo(() => {
    const startIndex = (page - 1) * DEFAULT_PAGE_SIZE_POSITIONS;
    const endIndex = page * DEFAULT_PAGE_SIZE_POSITIONS;
    return positions?.slice(startIndex, endIndex) || [];
  }, [positions, page]);

  ///-----------------------------------

  /// V1 Logic
  const onPositionV1Click = (position) => {
    setPositionToAddKey(position.key);
    setPositionSelectedDetails(position);
    setIsDetailsPositionV1(true);
  };
  const editPositionV1 = (position) => {
    setCollateralTokenAddress(position.collateralToken.address);
    setPositionToEditKey(position.key);
    setSelectedPosition(position);
    setIsPositionEditorVisible(true);
  };
  const handleAddToPositionV1 = (position) => {
    setPositionToAddKey(position.key);
    setSelectedPosition(position);
    setIsAddToPositionV1(true);
  };

  const sellPositionV1 = (position) => {
    setPositionToSellKeyV1(position.key);
    setSelectedPosition(position);
    setIsPositionSellerVisibleV1(true);
    setIsHigherSlippageAllowed(false);
  };
  const handleAddTPSLV1 = (position) => {
    setPositionToSellKeyV1(position.key);
    setSelectedPosition(position);
    setIsAddTPSLVisibleV1(true);
    // setIsHigherSlippageAllowed(false);
  };
  ///-----------------------------------

  return (
    <div className="PositionsList">
      {ordersToaOpen && (
        <OrdersToa
          setIsVisible={setOrdersToaOpen}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
        />
      )}
      {isPositionShareModalOpenV1 && (
        <PositionShareV1
          setIsPositionShareModalOpen={setIsPositionShareModalOpenV1}
          isPositionShareModalOpen={isPositionShareModalOpenV1}
          positionToShare={positionToShare}
          chainId={chainId}
          account={account}
        />
      )}
      {isAddToPositionV1 && (
        <AddToPositionV1
          setIsVisible={setIsAddToPositionV1}
          isVisible={isAddToPositionV1}
          // position={selectedPositionV1}
          selectedPosition={selectedPosition}
          chainId={chainId}
          library={library}
          // onConfirm={handleAddTPSLPositionV1}
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          account={account}
          infoTokens={infoTokens}
          isConfirm={isSectionConfirming}
          positionsMap={positionsMap}
          positionKey={positionToAddKey}
          usdgSupply={usdgSupply}
          totalTokenWeights={totalTokenWeights}
          savedSlippageAmount={savedSlippageAmount}
          setPendingTxns={setPendingTxns}
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
          minExecutionFee={minExecutionFee}
          minExecutionFeeUSD={minExecutionFeeUSD}
          nativeTokenInfo={nativeTokenInfo}
        />
      )}
      {isDetailsPositionV1 && (
        <PositionDetailsV1
          account={account}
          infoTokens={infoTokens}
          positionsMap={positionsMap}
          positionKey={positionToAddKey}
          isVisible={isDetailsPositionV1}
          setIsVisible={setIsDetailsPositionV1}
          orders={v1Orders}
          selectedPosition={positionSelectedDetails}
          nativeTokenAddress={nativeTokenAddress}
          savedSlippageAmount={savedSlippageAmount}
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          isContractAccount={isContractAccount}
          minExecutionFee={minExecutionFee}
          setPendingTxns={setPendingTxns}
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
          chainId={chainId}
        />
      )}
      {isPositionSellerVisibleV1 && (
        <PositionSeller
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
          setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
          isWaitingForPluginApproval={isWaitingForPluginApproval}
          orderBookApproved={orderBookApproved}
          positionsMap={positionsMap}
          positionKey={positionToSellKeyV1}
          isVisible={isPositionSellerVisibleV1}
          selectedPosition={selectedPosition}
          setIsVisible={setIsPositionSellerVisibleV1}
          infoTokens={infoTokens}
          active={active}
          account={account}
          orders={orders}
          library={library}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          flagOrdersEnabled={flagOrdersEnabled}
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          chainId={chainId}
          nativeTokenAddress={nativeTokenAddress}
          setOrdersToaOpen={setOrdersToaOpen}
          positionRouterApproved={positionRouterApproved}
          isPositionRouterApproving={isPositionRouterApproving}
          isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
          approvePositionRouter={approvePositionRouter}
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          minExecutionFee={minExecutionFee}
          minExecutionFeeUSD={minExecutionFeeUSD}
          minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
          usdgSupply={usdgSupply}
          totalTokenWeights={totalTokenWeights}
          isContractAccount={isContractAccount}
        />
      )}
      {isAddTPSLVisibleV1 && (
        <AddTPSLV1
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
          setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
          isWaitingForPluginApproval={isWaitingForPluginApproval}
          orderBookApproved={orderBookApproved}
          positionsMap={positionsMap}
          positionKey={positionToSellKeyV1}
          isVisible={isAddTPSLVisibleV1}
          setIsVisible={setIsAddTPSLVisibleV1}
          selectedPosition={selectedPosition}
          infoTokens={infoTokens}
          active={active}
          account={account}
          orders={orders}
          library={library}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          flagOrdersEnabled={flagOrdersEnabled}
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          chainId={chainId}
          nativeTokenAddress={nativeTokenAddress}
          setOrdersToaOpen={setOrdersToaOpen}
          positionRouterApproved={positionRouterApproved}
          isPositionRouterApproving={isPositionRouterApproving}
          isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
          approvePositionRouter={approvePositionRouter}
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          minExecutionFee={minExecutionFee}
          minExecutionFeeUSD={minExecutionFeeUSD}
          minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
          usdgSupply={usdgSupply}
          totalTokenWeights={totalTokenWeights}
          isContractAccount={isContractAccount}
        />
      )}
      <PositionEditor
        pendingPositions={pendingPositions}
        setPendingPositions={setPendingPositions}
        positionsMap={positionsMap}
        positionKey={positionToEditKey}
        isVisible={isPositionEditorVisible}
        setIsVisible={setIsPositionEditorVisible}
        infoTokens={infoTokens}
        active={active}
        account={account}
        selectedPosition={selectedPosition}
        library={library}
        collateralTokenAddress={collateralTokenAddress}
        pendingTxns={pendingTxns}
        setPendingTxns={setPendingTxns}
        getUsd={getUsd}
        getLeverage={getLeverage}
        savedIsPnlInLeverage={savedIsPnlInLeverage}
        positionRouterApproved={positionRouterApproved}
        isPositionRouterApproving={isPositionRouterApproving}
        isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
        approvePositionRouter={approvePositionRouter}
        chainId={chainId}
        minExecutionFee={minExecutionFee}
        minExecutionFeeUSD={minExecutionFeeUSD}
        minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
        isContractAccount={isContractAccount}
      />
      {isPositionShareModalOpen && (
        <PositionShare
          setIsPositionShareModalOpen={setIsPositionShareModalOpen}
          isPositionShareModalOpen={isPositionShareModalOpen}
          selectedPosition={selectedPosition}
          chainId={chainId}
          account={account}
          marketTokensInfo={marketTokensInfo}
          positions={positions}
        />
      )}
      {isTPSLModalOPen && (
        <AddTPSL
          setIsVisible={setIsTPSLModalOPen}
          isVisible={isTPSLModalOPen}
          position={selectedPosition}
          chainId={chainId}
          library={library}
          onConfirm={handleAddTPSLPosition}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}
      {isTrailingStopModalOpen && (
        <AddTrailingStop
          setIsVisible={setIsTrailingStopModalOpen}
          isVisible={isTrailingStopModalOpen}
          position={selectedPosition}
          chainId={chainId}
          marketTokensInfo={marketTokensInfo}
          onConfirm={handleAddTrailingStop}
          isConfirm={isSectionConfirming}
        />
      )}
      {/* {isEditLeverageModalOPen && (
        <EditLeverage
          setIsVisible={setIsEditLeverageModalOPen}
          isVisible={isEditLeverageModalOPen}
          position={selectedPosition}
          chainId={chainId}
          library={library}
          onConfirm={handleEditCollateral}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )} */}
      {isAddCollateralModalOpen && (
        <EditCollateral
          isVisible={isAddCollateralModalOpen}
          setIsVisible={setIsAddCollateralModalOpen}
          position={selectedPosition}
          chainId={chainId}
          userTokenBalances={userTokenBalances}
          handleEditPosition={handleEditPosition}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
          handleEditLeverage={handleEditCollateral}
        />
      )}
      {isPositionSellerVisible && (
        <ClosePosition
          isVisible={isPositionSellerVisible}
          setIsVisible={setIsPositionSellerVisible}
          selectedPosition={selectedPosition}
          chainId={chainId}
          library={library}
          handleClosePosition={handleClosePosition}
          marketTokensInfo={marketTokensInfo}
          positions={positions}
          isConfirm={isSectionConfirming}
        />
      )}
      {isPositionDetailsVisible && (
        <PositionDetails
          isVisible={isPositionDetailsVisible}
          setIsVisible={setIsPositionDetailsVisible}
          selectedPosition={selectedPosition}
          positions={positions}
          chainId={chainId}
          onConfirm={handleClosePosition}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}
      {isAddToPosition && (
        <AddToPosition
          isVisible={isAddToPosition}
          setIsVisible={setIsAddToPosition}
          position={selectedPosition}
          chainId={chainId}
          userTokenBalances={userTokenBalances}
          onConfirm={handleAddToPostion}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}

      {isEditTakeProfitModalOpen && (
        <EditTakeProfit
          isVisible={isEditTakeProfitModalOpen}
          position={selectedPosition}
          setIsVisible={setIsEditTakeProfitModalOpen}
          chainId={chainId}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}
      {isEditStopLossModalOpen && (
        <EditStopLoss
          isVisible={isEditStopLossModalOpen}
          position={selectedPosition}
          setIsVisible={setIsEditStopLossModalOpen}
          chainId={chainId}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}
      {loading && (
        <>
          <div className="skeleton-box" style={{ height: "56.19px" }} />
          <div className="skeleton-box" style={{ height: "56.19px", marginTop: 8 }} />
          <div className="skeleton-box" style={{ height: "56.19px", marginTop: 8 }} />
        </>
      )}
      {!loading && positions && (
        <table className={`Exchange-list large App-box ${positions.length === 0 ? "no-array-length" : ""}`}>
          <tbody>
            {positions.length > 0 && (
              <tr className="Exchange-list-header">
                <th>
                  <span>Position</span>
                </th>
                <th>
                  <span>Net/PnL</span>
                </th>
                <th>
                  <span>Pos.Size</span>
                </th>
                <th>
                  <span>Collateral</span>
                </th>
                <th>
                  <span>Liq.Risk</span>
                </th>
                <th>
                  <span>Liq./Mark Price</span>
                </th>
                <th>
                  <span>Avg. Entry Price</span>
                </th>
                <th></th>
              </tr>
            )}

            {positions.length === 0 && !loading && (
              <tr className="no-position">
                <td colSpan="15">
                  <div className="no-data-record">No open positions</div>
                </td>
              </tr>
            )}

            {filterPositions.length > 0 &&
              filterPositions.map((position, index) => {
                if (position.isV1)
                  return (
                    <PositionItemV1
                      position={position}
                      key={index}
                      account={account}
                      showPnlAfterFees={false}
                      nativeTokenAddress={nativeTokenAddress}
                      sellPosition={sellPositionV1}
                      setPositionToShare={setPositionToShare}
                      setIsPositionShareModalOpen={setIsPositionShareModalOpenV1}
                      handleAddTPSL={handleAddTPSLV1}
                      orders={orders}
                      onPositionClick={onPositionV1Click}
                      editPosition={editPositionV1}
                      handleAddToPosition={handleAddToPositionV1}
                      myOrders={v1Orders}
                      setPosOrders={setPosOrders}
                      setListSection={setListSection}
                    />
                  );
                else
                  return (
                    <PositionItemV2
                      position={position}
                      key={index}
                      handleSelectedPosition={handleSelectedPosition}
                      marketAsssets={marketAsssets}
                      marketTokensInfo={marketTokensInfo}
                      setIsEditStopLossModalOpen={setIsEditStopLossModalOpen}
                      setIsEditTakeProfitModalOpen={setIsEditTakeProfitModalOpen}
                      setIsTrailingStopModalOpen={setIsTrailingStopModalOpen}
                      setIsAddCollateralModalOpen={setIsAddCollateralModalOpen}
                      setIsAddToPosition={setIsAddToPosition}
                      setIsTPSLModalOPen={setIsTPSLModalOPen}
                      setIsPositionShareModalOpen={setIsPositionShareModalOpen}
                      setIsEditLeverageModalOPen={setIsEditLeverageModalOPen}
                      setSelectedPosition={setSelectedPosition}
                      sellPosition={sellPosition}
                      myOrders={v2Orders}
                      setPosOrders={setPosOrders}
                      setListSection={setListSection}
                    />
                  );
              })}
          </tbody>
        </table>
      )}
      {positions && !loading && (
        <div className="Exchange-list small">
          <div>
            {positions.length === 0 && loading && (
              <div className="Exchange-empty-positions-list-note App-card">
                <div className="skeleton-box" style={{ height: "25px" }} />
              </div>
            )}
            {positions.length === 0 && <div className="no-data-record">No open positions</div>}
            {filterPositions.length > 0 &&
              filterPositions.map((position, index) => {
                if (position.isV1)
                  return (
                    <PositionItemMobileV1
                      position={position}
                      key={index}
                      account={account}
                      showPnlAfterFees={false}
                      nativeTokenAddress={nativeTokenAddress}
                      sellPosition={sellPositionV1}
                      setPositionToShare={setPositionToShare}
                      setIsPositionShareModalOpen={setIsPositionShareModalOpenV1}
                      handleAddTPSL={handleAddTPSLV1}
                      orders={orders}
                      onPositionClick={onPositionV1Click}
                      editPosition={editPositionV1}
                      handleAddToPosition={handleAddToPositionV1}
                      myOrders={v1Orders}
                      setPosOrders={setPosOrders}
                      setListSection={setListSection}
                    />
                  );
                else
                  return (
                    <PositionItemMobileV2
                      position={position}
                      key={index}
                      handleSelectedPosition={handleSelectedPosition}
                      marketAsssets={marketAsssets}
                      marketTokensInfo={marketTokensInfo}
                      setIsEditStopLossModalOpen={setIsEditStopLossModalOpen}
                      setIsEditTakeProfitModalOpen={setIsEditTakeProfitModalOpen}
                      setIsTrailingStopModalOpen={setIsTrailingStopModalOpen}
                      setIsAddCollateralModalOpen={setIsAddCollateralModalOpen}
                      setIsAddToPosition={setIsAddToPosition}
                      setIsTPSLModalOPen={setIsTPSLModalOPen}
                      setIsPositionShareModalOpen={setIsPositionShareModalOpen}
                      setIsEditLeverageModalOPen={setIsEditLeverageModalOPen}
                      setSelectedPosition={setSelectedPosition}
                      sellPosition={sellPosition}
                      myOrders={v2Orders}
                      setPosOrders={setPosOrders}
                      setListSection={setListSection}
                    />
                  );
              })}
          </div>
        </div>
      )}
      {positions && positions.length > DEFAULT_PAGE_SIZE_POSITIONS && (
        <PaginationV2
          rowsPerPage={DEFAULT_PAGE_SIZE_POSITIONS}
          rows={positions.length}
          currentPage={page}
          setCurrentPage={setPage}
        />
      )}
    </div>
  );
};
export default PositionListMerged;
