import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, MAINNET, OP, FANTOM, FANTOM_LEGACY, BASE, BERACHAIN_BARTIO } from "./chains";

export const GMY_STATS_API_URL = "https://api.yummy.fi/bartio/api";

const BACKEND_URLS = {
  default: "https://api.yummy.fi/bartio/api",
  [FANTOM]: "https://api.yummy.fi/fantom/api",
  [BERACHAIN_BARTIO]: "https://api.yummy.fi/bartio/api",
  [FANTOM_LEGACY]: "https://api.yummy.fi/api",
  [OP]: "https://api.yummy.fi/optimism/api",
  [MAINNET]: "https://gambit-server-staging.uc.r.appspot.com",
  [ARBITRUM_TESTNET]: "https://gambit-server-devnet.uc.r.appspot.com",
  [BASE]: "https://api.yummy.fi/base/api",
  [ARBITRUM]: "https://api.yummy.fi/arbitrum/api",
  [AVALANCHE]: "https://gmy-avax-server.uc.r.appspot.com",
};

const KYBER_API = {
  default: "https://aggregator-api.kyberswap.com/fantom/api/v1/",
  [FANTOM]: "https://aggregator-api.kyberswap.com/fantom/api/v1/",
  [OP]: "https://aggregator-api.kyberswap.com/optimism/api/v1/",
  [BASE]: "https://aggregator-api.kyberswap.com/base/api/v1/",
  [ARBITRUM]: "https://aggregator-api.kyberswap.com/arbitrum/api/v1/",
};

const BACKEND_URLS_V2 = {
  default: "https://api.yummy.fi/v2/fantom",
  [FANTOM]: "https://api.yummy.fi/v2/fantom",
  [BERACHAIN_BARTIO]: "https://api.yummy.fi/v2/bartio",
  [OP]: "https://api.yummy.fi/v2/optimism",
};

export function getServerBaseUrl(chainId: number, isV2 = false) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }
  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  return isV2 ? BACKEND_URLS_V2[chainId] || BACKEND_URLS_V2.default : BACKEND_URLS[chainId] || BACKEND_URLS.default;
}

export function getServerBaseUrlV2(chainId: number) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }
  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  return BACKEND_URLS_V2[chainId] || BACKEND_URLS_V2.default;
}
export function getServerKyberUrl(chainId: number) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }
  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("KYBER_SERVER_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  return KYBER_API[chainId] || KYBER_API.default;
}
export function getServerUrl(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}`;
}

export function getServerUrlV2(chainId: number, path: string) {
  return `${getServerBaseUrlV2(chainId)}${path}`;
}
