import { importImage, USD_DECIMALS } from "lib/legacy";
import cx from "classnames";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import moment from "moment";
import { secondsToHMS } from "./ClosedPositionItemV2";
import shareIcon from "img/trade/share.svg";
const ClosedPositionItemV1 = (props) => {
  const { position, setIsPositionShareModalOpenV1, setPositionToShareV1, handleSelectedPosition } = props;
  const leverage = parseValue(position.size, 30)
    .mul(parseValue(1, USD_DECIMALS))
    .div(parseValue(position.collateral, 30));
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const duration = secondsToHMS(position.closedAt - position.openedAt);

  const roiPrefix = position.roi < 0 ? "-" : "+";
  const rPnLPrifix = position.realisedPnl.includes("-") ? "-" : "+";
  const isLoss = position.realisedPnl.includes("-");
  const pnlPrefix = position.realisedPnl.includes("-") ? "-" : "+";
  const posQty = parseValue(position.size, 30).mul(parseValue(1, 30)).div(parseValue(position.averagePrice, 30));

  return (
    <tr className="table-content clickable" onClick={() => handleSelectedPosition(position)}>
      <td className="clickable">
        <div>
          <img src={importImage("ic_" + position.indexToken.symbol?.toLowerCase() + "_24.svg")} alt="tk" />{" "}
          <div className="tk_info">
            <div className="Exchange-list-title">
              {position.indexToken.symbol}/USD <span className="muted">#{position.posId}</span>
            </div>
            <div className="Exchange-list-info-label">
              <span
                className={cx({ bold: true, positive: position.isLong, negative: !position.isLong })}
                style={{ color: position.isLong ? "#C1FF99" : "#F6475D" }}
              >
                {position.isLong ? `LONG` : `SHORT`}
              </span>
              {leverage && (
                <span
                  className="leverage-box"
                  style={{
                    color: "#FFC700",
                    backgroundColor: "rgba(255, 199, 0, 0.20)",
                    height: 16,
                    display: "inline-block",
                    opacity: 1,
                    borderRadius: "4px",
                    padding: "0 4px",
                    lineHeight: "15.8px",
                    fontWeight: 700,
                    marginLeft: "4px",
                  }}
                >
                  {formatAmount(leverage, 30, 2, true)}X
                </span>
              )}
            </div>
          </div>
        </div>
        {/* <div className={`flag ${position.isLong ? "isLong" : "isShort"}`}></div> */}
      </td>
      <td>
        <div>{moment(new Date(position.openedAt * 1000)).format(dateFormat)}</div>
        <div className="muted fz-12 bold">{moment(new Date(position.openedAt * 1000)).format(timeFormat)}</div>
      </td>
      <td>
        <div>{moment(new Date(position.closedAt * 1000)).format(dateFormat)}</div>
        <div className="muted fz-12 bold">{moment(new Date(position.closedAt * 1000)).format(timeFormat)}</div>
      </td>
      <td>
        <div className="">{duration}</div>
      </td>
      <td className="clickable">
        <div
          className={`${!isLoss ? "isProfit" : "isLoss"} ${!isLoss ? "positive" : "negative"}`}
          style={{
            color: position.realisedPnl.includes("-") ? "#F6475D" : "#C1FF99 ",
          }}
        >
          {pnlPrefix}${formatAmount(parseValue(position.realisedPnl, 30), 30, 2, true).replace("-", "")}
        </div>
        <div className="muted fz-12 bold">
          {roiPrefix}
          {limitDecimals(Number(position.roi) < -100 ? -100 : Number(position.roi), 2).replace("-", "")}%
        </div>
      </td>
      <td className="clickable">${formatAmount(parseValue(position.averagePrice, 30), 30, 2, true)}</td>
      <td className="clickable">${formatAmount(parseValue(position.exitPrice, 30), 30, 2, true)}</td>

      <td>
        <div> ${formatAmount(parseValue(position.size, 30), 30, 2, true)}</div>
        <div className="muted muted fz-12 bold">
          {" "}
          {formatAmount(posQty, 30, 4, true)} {position.indexToken.symbol}
        </div>
      </td>
      <td style={{ width: "30px" }}>
        <button
          className="PositionDropdown-dots-icon"
          onClick={(e) => {
            e.stopPropagation();
            setPositionToShareV1(position);
            setIsPositionShareModalOpenV1(true);
          }}
        >
          <img src={shareIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
        </button>
      </td>
    </tr>
  );
};
export default ClosedPositionItemV1;
