import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { createContext, useMemo } from "react";
import useSWR from "swr";

import { getServerUrl } from "config/backendV2";
import { useChainId } from "lib/chains";
import { ACTIVE_CHAIN_IDS, ACTIVE_CHAIN_IDS_V2, arrayURLFetcher, getStatsInfoV2, getStatsInfoV3 } from "lib/legacy";
import { BASE, BERACHAIN_BARTIO, FANTOM, FANTOM_TESTNET, OP, OP_TESTNET } from "config/chains";

const { AddressZero } = ethers.constants;

export const StatsContext = createContext();

const StatsProvider = ({ children }) => {
  const { chainId } = useChainId();
  const { active, library } = useWeb3React();

  // const { data: dataStatsAll } = useSWR(
  //   ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/app-stats")),
  //   {
  //     fetcher: arrayURLFetcher,
  //     refreshInterval: 10000,
  //   },
  // );
  const { data: dataStatsAll } = useSWR(
    ACTIVE_CHAIN_IDS_V2.map((chainId) => getServerUrl(chainId, "/public/app-stats")),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 10000,
    }
  );

  // const { gmyPrice } = useGmyPrice(chainId, {}, active);
  // const { gmyPriceFromFantom, gmyPriceFromOP, gmyPriceFromArbitrum } = useGmyPrice(
  //   chainId,
  //   { arbitrum: chainId === ARBITRUM ? library : undefined, op: chainId === OP ? library : undefined },
  //   active
  // );
  const currentStats = getStatsInfoV3(dataStatsAll);
  // console.log("?????", currentStats);
  // const whitelistedTokens = getWhitelistedTokens(chainId);
  // const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  // const glpManagerAddress = getContract(chainId, "GlpManager");
  // const glpManagerAddressFANTOM = getContract(FANTOM, "GlpManager");
  // const glpManagerAddressOP = getContract(OP, "GlpManager");
  // const glpManagerAddressARB = getContract(ARBITRUM, "GlpManager");

  // const readerAddress = getContract(chainId, "Reader");
  // const gmyAddress = getContract(chainId, "GMY");
  // const glpAddress = getContract(chainId, "GLP");
  // const usdgAddress = getContract(chainId, "USDG");
  // const glpVesterAddress = getContract(chainId, "GlpVester");
  // const gmyVesterAddress = getContract(chainId, "GmyVester");
  // const tokensForSupplyQuery = [gmyAddress, glpAddress, usdgAddress];

  // const { data: aums } = useSWR([`Dashboard:getAums`, chainId, glpManagerAddress, "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  // });

  // const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  // let aum;
  // if (aums && aums.length > 0) {
  //   aum = aums[0].add(aums[1]).div(2);
  // }

  // let adjustedUsdgSupply = bigNumberify(0);

  // for (let i = 0; i < tokenList.length; i++) {
  //   const token = tokenList[i];
  //   const tokenInfo = infoTokens[token.address];
  //   if (tokenInfo && tokenInfo.usdgAmount) {
  //     adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
  //   }
  // }

  // const { data: totalSupplies } = useSWR(
  //   [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
  //   {
  //     fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
  //     refreshInterval: 5000,
  //   }
  // );

  // const { data: gmySupplyOfGmyVester } = useSWR(
  //   [`Dashboard:gmySupplyOfGmyVester:${active}`, chainId, gmyAddress, "balanceOf", gmyVesterAddress],
  //   {
  //     fetcher: contractFetcher(library, Token),
  //     refreshInterval: 5000,
  //   }
  // );

  // const { data: gmySupplyOfGlpVester } = useSWR(
  //   [`Dashboard:gmySupplyOfGlpVester:${active}`, chainId, gmyAddress, "balanceOf", glpVesterAddress],
  //   {
  //     fetcher: contractFetcher(library, Token),
  //     refreshInterval: 5000,
  //   }
  // );

  // const {
  //   total: totalGmyStaked,
  //   fantom: fantomGmyStaked,
  //   fantomlegacy: fantomGmyStakedLegacy,
  //   op: opGmyStaked,
  //   oplegacy: opGmyStakedLegacy,
  //   arbitrum: arbitrumStakedGmy,
  // } = useTotalGmyStaked();
  // let currentChainGmyStaked = fantomGmyStaked;
  // if (chainId === OP) currentChainGmyStaked = opGmyStaked;
  // if (chainId === ARBITRUM) currentChainGmyStaked = arbitrumStakedGmy;

  // const { data: aumsOP } = useSWR([`Dashboard:getAumsOP`, OP, glpManagerAddressOP, "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  //   refreshInterval: 5000,
  // });

  // const { data: aumsFANTOM } = useSWR([`Dashboard:getAumsFANTOM`, FANTOM, glpManagerAddressFANTOM, "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  //   refreshInterval: 5000,
  // });
  // const { data: aumsFANTOMlegacy } = useSWR([`Dashboard:getAumsFANTOMlegacy`, FANTOM, "0x304951d7172bCAdA54ccAC1E4674862b3d5b3d5b", "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  //   refreshInterval: 5000,
  // });

  // const { data: aumsArb } = useSWR([`Dashboard:getAumsARB`, ARBITRUM, glpManagerAddressARB, "getAums"], {
  //   fetcher: contractFetcher(library, GlpManager),
  //   refreshInterval: 5000,
  // });

  // let aumOP;
  // if (aumsOP && aumsOP.length > 0) {
  //   aumOP = aumsOP[0].add(aumsOP[1]).div(2);
  // }

  // let aumFANTOM;
  // if (aumsFANTOM && aumsFANTOM.length > 0) {
  //   aumFANTOM = aumsFANTOM[0].add(aumsFANTOM[1]).div(2);
  // }

  // let aumFANTOMlegacy;
  // if (aumsFANTOMlegacy && aumsFANTOMlegacy.length > 0) {
  //   aumFANTOMlegacy = aumsFANTOMlegacy[0].add(aumsFANTOMlegacy[1]).div(2);
  // }

  // let aumArb;
  // if (aumsArb && aumsArb.length > 0) {
  //   aumArb = aumsArb[0].add(aumsArb[1]).div(2);
  // }

  // let glpPrice;
  // let glpSupply;
  // let glpMarketCap;
  // if (aum && totalSupplies && totalSupplies[3]) {
  //   glpSupply = totalSupplies[3];
  //   if (gmySupplyOfGmyVester && gmySupplyOfGlpVester) {
  //     glpSupply = glpSupply.sub(gmySupplyOfGmyVester).sub(gmySupplyOfGlpVester);
  //   }

  //   glpPrice =
  //     aum && aum.gt(0) && glpSupply.gt(0)
  //       ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply)
  //       : expandDecimals(1, USD_DECIMALS);
  //   glpMarketCap = glpPrice.mul(glpSupply).div(expandDecimals(1, GLP_DECIMALS));
  // }

  // let tvl;
  // if (currentChainGmyStaked && aum && gmyPrice) {
  //   tvl = gmyPrice.mul(currentChainGmyStaked).div(expandDecimals(1, GMY_DECIMALS)).add(aum);
  // }

  // let fantomTvl;
  // if (fantomGmyStaked && aumFANTOM && gmyPriceFromFantom) {
  //   fantomTvl = gmyPriceFromFantom.mul(fantomGmyStaked).div(expandDecimals(1, GMY_DECIMALS)).add(aumFANTOM);
  // }

  // let fantomLegacyTvl;
  // if (fantomGmyStakedLegacy && aumFANTOMlegacy && gmyPriceFromFantom) {
  //   fantomLegacyTvl = gmyPriceFromFantom.mul(fantomGmyStakedLegacy).div(expandDecimals(1, GMY_DECIMALS)).add(aumFANTOMlegacy);
  // }
  // let opTvl;
  // if (opGmyStaked && opGmyStakedLegacy && aumOP && gmyPriceFromOP) {
  //   opTvl = gmyPriceFromOP.mul(opGmyStaked.add(opGmyStakedLegacy)).div(expandDecimals(1, GMY_DECIMALS)).add(aumOP);
  // }

  // let arbTvl;
  // if (arbitrumStakedGmy && aumArb && gmyPriceFromArbitrum) {
  //   arbTvl = gmyPriceFromArbitrum.mul(arbitrumStakedGmy).div(expandDecimals(1, GMY_DECIMALS)).add(aumArb);
  // }
  // let totalTvl;
  // if (glpMarketCap && gmyPrice && totalGmyStaked && aumOP && aumFANTOM && aumArb && fantomTvl && opTvl && arbTvl && fantomLegacyTvl) {
  //   totalTvl = fantomTvl.add(opTvl).add(arbTvl).add(fantomLegacyTvl)
  // }

  const valueProvider = useMemo(() => {
    return {
      // totalVolume: {
      //   total: currentStats.totalVolume,
      //   [FANTOM_TESTNET]: currentStats[FANTOM_TESTNET]?.totalVolume,
      //   [OP_TESTNET]: currentStats[OP_TESTNET]?.totalVolume,
      // },
      // total24hVolume: {
      //   total: currentStats.totalFees,
      //   [FANTOM_TESTNET]: currentStats[FANTOM_TESTNET]?.totalFees,
      //   [OP_TESTNET]: currentStats[OP_TESTNET]?.totalFees,
      // },
      // openInterest: {
      //   total: currentStats.longOpenInterest,
      //   [FANTOM_TESTNET]: currentStats[FANTOM_TESTNET]?.longOpenInterest,
      //   [OP_TESTNET]: currentStats[OP_TESTNET]?.longOpenInterest,
      // },
      trades: {
        total: currentStats?.trades,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.trades,
      },
      trades24H: {
        total: currentStats.trades24H,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.trades24H,
      },
      volume: {
        total: currentStats?.volume,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.volume,
      },
      volume24H: {
        total: currentStats.volume24H,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.volume24H,
      },
      fees: {
        total: currentStats.fees,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.fees,
      },
      fees24H: {
        total: currentStats.fees24H,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.fees24H,
      },
      realizedPnl24H: {
        total: currentStats.realizedPnl24H,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.realizedPnl24H,
      },
      openInterest: {
        total: currentStats?.openInterest,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.openInterest,
      },
      gmPrice: {
        total: currentStats?.gmPrice,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.gmPrice,
      },
      gmTvl: {
        total: currentStats?.gmTvl,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.gmTvl,
      },
      gmStaked: {
        total: currentStats?.gmStaked,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.gmStaked,
      },
      totalUsers: {
        total: currentStats?.users,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.users,
      },
    };
  }, [currentStats]);
  return <StatsContext.Provider value={valueProvider}>{children}</StatsContext.Provider>;
};

export default StatsProvider;
