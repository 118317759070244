import React, { useState } from "react";
import styled, { css } from "styled-components";

import arrow from "img/trade/arrow-down-green.svg";
import arrowLight from "img/trade/arrow-downLight.svg";
import { useThemeContext } from "contexts/ThemeProvider";

const RowsDropdown = ({ handler, children, show = false }) => {
  const [isExpand, setIsExpand] = useState(show);
  return (
    <div style={{ width: "100%" }} className="row-dropdown-wrap">
      {React.cloneElement(handler, {
        onClick: () => setIsExpand((prev) => !prev),
        isHandler: true,
        isExpand: isExpand,
      })}
      <ExpandSection $isExpand={isExpand}>{children}</ExpandSection>
    </div>
  );
};

export const RowsDropdownHandler = ({ children, isExpand, ...props }) => {
  // const { isLightTheme } = useThemeContext();
  return (
    <RowsDropdownHandlerWrapper $isExpand={isExpand} {...props} className="row-dropdown">
      <div className="children">{children}</div>

      <img src={arrow} className={isExpand ? "rotated" : ""} alt="arrow" />
    </RowsDropdownHandlerWrapper>
  );
};

const RowsDropdownHandlerWrapper = styled.div`
  .children {
    font-weight: 700;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: var(--white-100, #fff);
  font-size: 14px;
  font-weight: 700;
  line-height: 120%; /* 16.8px */

  img {
    width: 20px;
    height: 20px;
    rotate: 180deg;
  }
  .rotated {
    rotate: 0deg;
  }

  ${({ $isExpand }) => {
    $isExpand &&
      css`
        img {
          rotate: 180deg;
        }
      `;
  }}
`;

const ExpandSection = styled.div`
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-self: stretch;

  .handler {
    grid-template-columns: 1fr auto auto;
    cursor: pointer;
  }

  .isExpand img {
    rotate: 0deg !important;
  }

  ${({ $isExpand }) =>
    $isExpand &&
    css`
      height: unset;
      /* padding-bottom: 16px; */
      margin: 8px 0;
    `}
`;

export default RowsDropdown;
