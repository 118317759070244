import { ARBITRUM, AVALANCHE, BASE, BERACHAIN_BARTIO, FANTOM, OP } from "./chains";

export const SUBGRAPH_URLS = {
  [ARBITRUM]: {
    stats: "https://api.studio.thegraph.com/query/74668/gummy-arb-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/gummy-arb-referrals/version/latest",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/gmy-vault",
  },
  [AVALANCHE]: {
    stats: "https://api.thegraph.com/subgraphs/name/gmy-io/gmy-avalanche-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/gmy-io/gmy-avalanche-referrals",
  },
  [OP]: {
    stats: "https://api.studio.thegraph.com/query/74668/gummy-op-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/gummy-op-referrals/version/latest",
  },
  [BASE]: {
    stats: "https://api.studio.thegraph.com/query/74668/gummy-base-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/gummy-base-referrals/version/latest",
  },
  [FANTOM]: {
    stats: "https://api.studio.thegraph.com/query/74668/gummy-fantom-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/gummy-fantom-referrals/version/latest",
  },
  [BERACHAIN_BARTIO]: {
    stats: "https://api.goldsky.com/api/public/project_cm1of30dksjme01wjgpyj8gph/subgraphs/gummy-bartio-stats/0.0.1/gn",
    referrals:
      "https://api.goldsky.com/api/public/project_cm1a2sph4zobs01wcbtpkdc2p/subgraphs/gummy-bartio-referrals/0.0.1/gn",
    positions:
      "https://api.goldsky.com/api/public/project_cm0q554x1ezgf01utgsfw28ta/subgraphs/gummy-bartio-middleware/0.0.1/gn",
  },

  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};
