import { getTokenSymbol } from "config/tokens";
import { importImage } from "lib/legacy";
import cx from "classnames";
import moment from "moment";
import { formatAmount, parseValue } from "lib/numbers";
import ExternalLink from "components/ExternalLink/ExternalLink";
import ic_new_tab from "img/trade/new_tab.svg";
import { getExplorerUrl } from "config/chains";

const TradeActivityItemMobileV1 = ({ position, chainId, handleSelectedPosition }) => {
  const indexToken = position.indexToken.symbol;
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const txUrl = getExplorerUrl(chainId) + "tx/" + position.txhash;
  const posQty = parseValue(position.size, 30).mul(parseValue(1, 30)).div(parseValue(position.entryPrice, 30));
  return (
    <div key={position.index} className="App-card">
      <div className="App-card-title">
        <span className="Exchange-list-title">
          <img src={importImage("ic_" + indexToken?.toLowerCase() + "_24.svg")} alt="tk" /> {indexToken}/USD{" "}
          <span className="muted">#{position.posId}</span>
        </span>
      </div>
      <div className="App-card-divider" />
      <div className="App-card-content app-card-position">
        <div className="leverage-pad">
          <div
            className={cx("Exchange-list-side", {
              bold: true,
              positive: position.isLong,
              negative: !position.isLong,
            })}
          >
            {position.isLong ? "LONG" : "SHORT"}
          </div>
          {position.leverage ? (
            <span
              className=" leverage-box"
              style={{
                color: "#FFC700",
                backgroundColor: "rgba(255, 199, 0, 0.20)",
                height: 16,
                display: "inline-block",
                opacity: 1,
                borderRadius: "4px",
                padding: "0 4px",
                fontSize: "12px",
                lineHeight: "15.6px",
                fontWeight: 700,
                marginLeft: "4px",
              }}
            >
              {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X
            </span>
          ) : null}
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Date/Time</span>
          </div>
          <div>
            <div>{moment(new Date(position.timestamp * 1000)).format(dateFormat)}</div>
            <div className="muted fz-12 bold">{moment(new Date(position.timestamp * 1000)).format(timeFormat)}</div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Action</span>
          </div>
          <div className="position-list-collateral">
            <span>
              {position.action === "CLOSE" || position.action === "DECREASE"
                ? "Decreased position"
                : position.action === "OPEN" || position.action === "INCREASE"
                ? "Increased position"
                : position.action === "DEPOSIT"
                ? "Deposited"
                : position.action === "WITHDRAW"
                ? "Withdrew"
                : "Liquidated"}{" "}
              <span className="muted">
                (Price ${formatAmount(parseValue(position.price || position.markPrice, 30), 30, 2, true)})
              </span>
            </span>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Type</span>
          </div>
          <div>
            <span>{position.positionType}</span>
          </div>
        </div>
        {position.action !== "DEPOSIT" && position.action !== "WITHDRAW" ? (
          <div className="App-card-row">
            <div className="label">
              <span>Size Delta</span>
            </div>
            <div>
              <>
                <div> ${formatAmount(parseValue(position.size, 30), 30, 2, true)}</div>
                <div className="muted fz-12 bold">
                  {formatAmount(posQty, 30, 4, true)} {indexToken}
                </div>
              </>
            </div>
          </div>
        ) : null}

        {/* <div className="App-card-row">
      <div className="label">
        <span>Pos.Size</span>
      </div>
      <div>
        <div> ${formatAmount(position.tradeVolume, 30, 2, true)}</div>
        <div className="muted fz-12">
          {formatAmount(posQty, 30, 4, true)} {getTokenSymbolFromString(symbol)}
        </div>
      </div>
    </div> */}
      </div>
      <div style={{ marginTop: "1.3rem" }} />
      <div className="App-card-options">
        <button
          className="App-button-option App-card-option"
          onClick={() => handleSelectedPosition(position)}
          // disabled={position.size == 0}
        >
          <span>View</span>
        </button>
        <button
          style={{ width: 40, display: "flex", justifyContent: "center", alignItems: "center" }}
          className="PositionDropdown-dots-icon"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ExternalLink href={txUrl} className="tx">
            <img style={{ width: 16 }} src={ic_new_tab} alt="ic_new_tab" />
          </ExternalLink>
        </button>
      </div>
    </div>
  );
};
export default TradeActivityItemMobileV1;
