import { getNormalizedTokenSymbol, getTokenLowerCase } from "config/tokens";
import { DECREASE, getOrderError, importImage, INCREASE, USD_DECIMALS } from "lib/legacy";
import { formatAmount, getDisplayDecimalByAmount, parseValue } from "lib/numbers";
import cx from "classnames";
import editIcon from "img/trade/edit.svg";
import { getTokenInfo } from "domain/tokens";
import { invalid } from "moment";
import { getTokenByAddress } from "utils/tokenHelper";

const OrderItemV1 = ({
  chainId,
  setOrderPos,
  setListSection,
  order,
  onCancelClick,
  onEditClick,
  nativeTokenAddress,
  infoTokens,
  position,
  positionsMap,
  account,
  setSelectedPosition,
}) => {
  const { type, indexToken, triggerAboveThreshold, triggerPrice, sizeDelta, isLong } = order;
  // console.log("????", infoTokens);

  const symbol = getNormalizedTokenSymbol(getTokenLowerCase(chainId, indexToken).symbol);

  const indexTokenMi = getTokenInfo(infoTokens, indexToken, true, nativeTokenAddress);
  const triggerPricePrefix = triggerAboveThreshold ? "≥" : "≤";
  const maximisePrice = (type === INCREASE && isLong) || (type === DECREASE && !isLong);
  const markPriceMaximise = maximisePrice ? indexTokenMi?.maxPrice : indexTokenMi?.minPrice;

  const increaseToken =
    order.type === INCREASE
      ? getTokenInfo(infoTokens, order?.purchaseToken, true, nativeTokenAddress)
      : { maxPrice: parseValue(1, USD_DECIMALS), decimals: 6 };

  const collateral =
    order.type === INCREASE && !position
      ? order.purchaseTokenAmount
          .mul(increaseToken?.maxPrice || parseValue(1, USD_DECIMALS))
          .div(parseValue(1, increaseToken.decimals))
      : position.collateral;

  const collateralOrder =
    order.type === INCREASE
      ? order.purchaseTokenAmount
          .mul(increaseToken?.maxPrice || parseValue(1, USD_DECIMALS))
          .div(parseValue(1, increaseToken.decimals))
      : 0;
  // console.log("????", { collateralOrder, increaseToken, order, infoTokens });
  const orderError = getOrderError(account, order, positionsMap, position);

  const isValid =
    order.type === INCREASE ? (collateralOrder.gte(parseValue(10, 30)) || position ? true : false) : !orderError;
  const isLimitError =
    order.type === INCREASE ? (collateralOrder.lt(parseValue(10, 30)) && !position ? true : false) : false;
  const leverage =
    order.type === INCREASE && !position ? order.sizeDelta.mul(parseValue(1, 30)).div(collateral) : position.leverage;

  const posQty = position
    ? position.size.mul(parseValue(1, 30)).div(position.averagePrice)
    : order.sizeDelta
        .mul(parseValue(1, 30))
        .div(order?.triggerPrice || indexTokenMi?.maxPrice || parseValue(1, USD_DECIMALS));
  return (
    <tr
      className={`table-content ${!isValid ? "table-content-invalid" : ""}`}
      // onClick={() => handleSelectedPosition(completePosition)}
    >
      <td className="order-info">
        <div>
          <img
            src={importImage(
              "ic_" + getNormalizedTokenSymbol(getTokenLowerCase(chainId, indexToken).symbol)?.toLowerCase() + "_24.svg"
            )}
            alt="tk"
          />
          <div className="tk_info">
            <div className="Exchange-list-title">
              {symbol}/USD {position && <span className="muted">#{position.posId}</span>}
            </div>
            <div className="Exchange-list-info-label">
              <span
                className={cx({
                  positive: order.isLong,
                  negative: !order.isLong,
                })}
                style={{ bold: true, color: order.isLong ? "#7EE55C" : "#F6475D" }}
              >
                {order.isLong ? `LONG` : `SHORT`}
              </span>
              {position ? (
                position.leverage && (
                  <span
                    className=" leverage-box"
                    style={{
                      color: "#FFC700",
                      backgroundColor: "rgba(255, 199, 0, 0.20)",
                      height: 16,
                      display: "inline-block",
                      opacity: 1,
                      borderRadius: "100px",
                      padding: "0 4px",
                      fontSize: "12px",
                      lineHeight: "15.6px",
                      fontWeight: 700,
                      marginLeft: "4px",
                    }}
                  >
                    {formatAmount(position.leverage, 4, 2, true)}X
                  </span>
                )
              ) : (
                <span
                  className=" leverage-box"
                  style={{
                    color: "#FFC700",
                    backgroundColor: "rgba(255, 199, 0, 0.20)",
                    height: 16,
                    display: "inline-block",
                    opacity: 1,
                    borderRadius: "100px",
                    padding: "0 4px",
                    fontSize: "12px",
                    lineHeight: "15.6px",
                    fontWeight: 700,
                    marginLeft: "4px",
                  }}
                >
                  {formatAmount(leverage, 30, 2, true)}X
                </span>
              )}
            </div>
          </div>
        </div>
      </td>
      <td className="order-info">
        {["Swap", "Increase"].includes(order.type)
          ? "Limit Order"
          : isLong
          ? triggerAboveThreshold
            ? "Take Profit"
            : "Stop Loss"
          : triggerAboveThreshold
          ? "Stop Loss"
          : "Take Profit"}
      </td>
      <td className="order-info">
        <span>
          {type === "Decrease" ? `${"Decrease"} $${formatAmount(sizeDelta, 30, 2, true)}` : ""}
          {type === "Increase" ? `${"Increase position"}` : ""}
        </span>
      </td>
      <td className="order-info">
        Mark Price {triggerPricePrefix} $
        {formatAmount(triggerPrice, 30, getDisplayDecimalByAmount(triggerPrice, 30), true)}
      </td>
      <td className="order-info">
        <div>
          {position ? "$" + formatAmount(position.size, 30, 2, true) : "$" + formatAmount(order.sizeDelta, 30, 2, true)}
        </div>
        <div className="muted fz-12 bold">
          {formatAmount(posQty, 30, 4, true)} {symbol}
        </div>
      </td>
      <td className="order-info">
        {markPriceMaximise ? (
          "$" + formatAmount(markPriceMaximise, 30, getDisplayDecimalByAmount(markPriceMaximise, 30), true)
        ) : (
          <div className="skeleton-box" style={{ width: "60px" }} />
        )}
      </td>
      <td style={{ width: "30px" }} className="close-container-td">
        <div className="close-container">
          <button
            className="Exchange-list-action"
            onClick={(e) => {
              e.stopPropagation();
              onCancelClick(order);
            }}
            style={{ marginLeft: 0 }}
            // disabled={position.size.eq(0)}
          >
            Cancel
          </button>
          {isValid && (
            <button
              className="PositionDropdown-dots-icon"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedPosition(position);
                onEditClick(order);
              }}
            >
              <img src={editIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
            </button>
          )}
          {!isValid && !isLimitError && (
            <button
              className="Exchange-list-action"
              style={{ margin: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                setOrderPos([{ ...position, isV1: true }]);
                setListSection("Positions");
              }}
            >
              Increase Position
            </button>
          )}
        </div>
      </td>
      {/* <td>
      <div className="child action" data-name="Action" style={{}}>
        <div className="action-child" style={{ marginLeft: "15px" }} onClick={() => onCancelClick(order)}>
          Cancel
        </div>
        <div className="action-child" onClick={() => onEditClick(order)}>
          <img src={editIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
        </div>
      </div>
    </td> */}
      {!isValid && (
        <div className="negative error-order">
          {isLimitError
            ? "Order collateral is smaller than min collateral ($10), will only be executable to increase an existing position."
            : orderError}
        </div>
      )}
    </tr>
  );
};
export default OrderItemV1;
