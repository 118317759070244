import styled from "styled-components";

export default styled.div`
  flex: 1;
  padding-left: 0;
  padding-right: 0;
  @media (max-width: 600px) {
    padding: 30.5px 16px 0;
    padding-top: 0;
    padding-bottom: 0 !important;
    min-height: unset !important;
  }
  .vault-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-primary, #176ce5);
    font-size: 14px;
    font-style: normal;
    margin-bottom: 24px;
    font-weight: 400;
    line-height: 140%;
    svg {
      width: 20px;
      margin-left: 4px;
    }
  }

  .title {
    font-weight: 700;
    font-size: 24px;

    color: #ffffff;
  }

  .description {
    font-weight: 400;
    font-size: 14px;

    color: rgba(255, 255, 255, 0.6);
  }

  .content {
    display: flex;
    gap: 24px;

    margin-top: 24px;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .left,
    .right {
      position: relative;

      flex: 1;

      display: flex;
      flex-direction: column;

      background-image: linear-gradient(138.67deg, #191b2e 8.79%, #191b2e 100%),
        linear-gradient(138.67deg, rgba(3, 245, 174, 0.15) 8.79%, rgba(3, 245, 174, 0) 100%);

      border: 2px solid;

      border: 2px solid transparent;
      background-origin: border-box;
      background-clip: content-box, border-box;

      border-radius: 16px;

      .inside {
        padding: 24px;
        gap: 24px;

        @media (max-width: 700px) {
          padding: 16px;
        }

        .title {
          font-weight: 700;
          font-size: 16px;

          color: #ffffff;

          margin-bottom: 24px;
        }

        .claim-area {
          padding: 12px;
          gap: 8px;

          background: #20233b;
          border-radius: 12px;

          .top {
            display: flex;
            justify-content: space-between;

            margin-bottom: 8px;

            .top-left {
              font-weight: 400;
              font-size: 12px;

              color: rgba(255, 255, 255, 0.6);
            }

            .top-right {
              cursor: pointer;

              font-weight: 400;
              font-size: 14px;
              a {
                color: #176ce5;
                text-decoration: none;

                &:hover {
                  color: #4787e3;
                }
              }
            }
          }

          .bottom {
            font-weight: 500;
            font-size: 16px;

            color: #ffffff;
          }
        }

        .info {
          display: flex;
          flex-direction: column;

          margin-top: 12px;
          gap: 12px;

          .child {
            display: flex;
            justify-content: space-between;

            .title {
              font-weight: 400;
              font-size: 14px;

              color: rgba(255, 255, 255, 0.6);

              margin: 0;
            }

            .value {
              font-weight: 400;
              font-size: 14px;

              color: #ffffff;
            }
          }
        }

        .group-btn {
          margin-top: 24px;
          display: flex;
          gap: 12px;

          a,
          button {
            cursor: pointer;

            flex: 1;

            margin: 0;
            height: 40px;

            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;

            font-weight: 500;
            font-size: 14px;

            &.deposit {
              background: #176ce5;

              color: #080715;
            }

            &.withdraw {
              border: 1px solid rgba(255, 255, 255, 0.1);

              color: #ffffff;
            }
          }
        }
      }

      img {
        position: absolute;

        top: 16px;
        right: 16px;

        animation: rotation 2s infinite linear;
      }

      @keyframes rotation {
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &.VestPage {
    min-height: unset;
    padding-bottom: 0;
    @media (max-width: 1023px) {
      min-height: unset !important;
      padding-bottom: 0 !important;
    }
  }
`;
