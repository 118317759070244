import { useWeb3React } from "@web3-react/core";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import Tooltip from "components/Tooltip/Tooltip";
import { getContract } from "config/contracts";
import gum_ic from "img/gum.svg";
import mglp_ic from "img/mglp.svg";
import gslp_ic from "img/gslp.svg";
import { useChainId } from "lib/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { bigNumberify, formatAmount, formatAmountFree, formatKeyAmount, formatNumber, parseValue } from "lib/numbers";
import { useEffect, useState } from "react";
import useSWR from "swr";

import MigrateVester from "abis/MigrateVester.json";
import Vester from "abis/Vester.json";
import { FANTOM, OP } from "config/chains";
import { helperToast } from "lib/helperToast";
import Modal from "components/Modal/Modal";
import { ethers } from "ethers";
import { getPageTitle } from "lib/legacy";
import SEO from "components/Common/SEO";
import ExternalLink from "components/ExternalLink/ExternalLink";

const VestSection = ({ vestingData, totalRewardTokens, processedData, setPendingTxns }) => {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();
  const [isVesterDepositModalVisible, setIsVesterDepositModalVisible] = useState(false);

  const [vesterDepositTitle, setVesterDepositTitle] = useState("");
  const [vesterDepositStakeTokenLabel, setVesterDepositStakeTokenLabel] = useState("");
  const [vesterDepositMaxAmount, setVesterDepositMaxAmount] = useState("");

  const [vesterDepositBalance, setVesterDepositBalance] = useState("");
  const [vesterDepositEscrowedBalance, setVesterDepositEscrowedBalance] = useState("");
  const [vesterDepositVestedAmount, setVesterDepositVestedAmount] = useState("");
  const [vesterDepositAverageStakedAmount, setVesterDepositAverageStakedAmount] = useState("");
  const [vesterDepositMaxVestableAmount, setVesterDepositMaxVestableAmount] = useState("");
  const [vesterDepositValue, setVesterDepositValue] = useState("");
  const [vesterDepositReserveAmount, setVesterDepositReserveAmount] = useState("");
  const [vesterDepositMaxReserveAmount, setVesterDepositMaxReserveAmount] = useState("");
  const [vesterDepositAddress, setVesterDepositAddress] = useState("");

  const [isVesterWithdrawModalVisible, setIsVesterWithdrawModalVisible] = useState(false);
  const [vesterWithdrawTitle, setVesterWithdrawTitle] = useState(false);
  const [vesterWithdrawAddress, setVesterWithdrawAddress] = useState("");
  const [fromVesterAddresssGLP, setFromVesterAddresssGLP] = useState("");
  const [fromVesterAddresssGMY, setFromVesterAddresssGMY] = useState("");
  const [isMigrateGMYEnabled, setIsMigrateGMYEnabled] = useState(false);
  const [isMigrateGLPEnabled, setIsMigrateGLPEnabled] = useState(false);

  const gmyVesterAddress = getContract(chainId, "GmyVester");
  const glpVesterAddress = getContract(chainId, "GlpVester");
  const gmVesterAddress = getContract(chainId, "GmVester");

  // const gmyVesterAddressV1 = getContract(chainId, "GmyVesterV1");
  // const glpVesterAddressV1 = getContract(chainId, "GlpVesterV1");

  // const migrateVesterAddress = getContract(chainId, "MigrateVester");

  useEffect(() => {
    switch (gmyVesterAddress) {
      case "0x8eF5634F5C6Ef625302Ab6e4C424Dbe6B59496C0":
        setFromVesterAddresssGMY("0xa1a65D3639A1EFbFB18C82003330a4b1FB620C5a");
        break;
      case "0x4F697DeDF2b3862CA43493c33589F42CFEF66026":
        setFromVesterAddresssGMY("0x2A3E489F713ab6F652aF930555b5bb3422711ac1");
        break;
      default:
        break;
    }
    switch (glpVesterAddress) {
      case "0xDdeaC5Dc3A5352cCa9dD13C3067Cbbdbe2A43700":
        setFromVesterAddresssGLP("0x2A3E489F713ab6F652aF930555b5bb3422711ac1");
        break;
      case "0xA25d432ae720e57e85778905935A9A0B9284114A":
        setFromVesterAddresssGLP("0xb54AbE3FEC8e1aE64620185A1E111Aa0c3a81542");
        break;
      default:
        break;
    }
  }, [glpVesterAddress, gmyVesterAddress, setFromVesterAddresssGLP, setFromVesterAddresssGMY]);

  // const { data: hasCalledMigrateGLP } = useSWR(
  //   [
  //     `StakeV2:hasCalledMigrateGLP:${[active, fromVesterAddresssGLP, account]}`,
  //     chainId,
  //     migrateVesterAddress,
  //     "hasCalledMigrate",
  //     account,
  //     fromVesterAddresssGLP,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, MigrateVester),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: hasCalledMigrateGMY } = useSWR(
  //   [
  //     `StakeV2:hasCalledMigrateYUM:${[active, fromVesterAddresssGMY, account]}`,
  //     chainId,
  //     migrateVesterAddress,
  //     "hasCalledMigrate",
  //     account,
  //     fromVesterAddresssGMY,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, MigrateVester),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: vestedGMYAmountV1 } = useSWR(
  //   [`StakeV2:vestedGMYAmountV1:${[active, account]}`, chainId, gmyVesterAddressV1, "getVestedAmount", account],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: vestedGLPAmountV1 } = useSWR(
  //   [`StakeV2:vestedGLPAmountV1:${[active, account]}`, chainId, glpVesterAddressV1, "getVestedAmount", account],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: vestedGMYAmountV1Fantom } = useSWR(
  //   [
  //     `StakeV2:vestedGMYAmountV1Fantom:${[active, account]}`,
  //     chainId,
  //     gmyVesterAddressV1,
  //     "getMaxVestableAmount",
  //     account,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: vestedGLPAmountV1Fantom } = useSWR(
  //   [
  //     `StakeV2:vestedGLPAmountV1Fantom:${[active, account]}`,
  //     chainId,
  //     glpVesterAddressV1,
  //     "getMaxVestableAmount",
  //     account,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: vestedGMYAmountV1OP } = useSWR(
  //   [`StakeV2:vestedGMYAmountV1OP:${[active, account]}`, chainId, gmyVesterAddressV1, "bonusRewards", account],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: vestedGLPAmountV1OP } = useSWR(
  //   [`StakeV2:vestedGLPAmountV1OP:${[active, account]}`, chainId, glpVesterAddressV1, "bonusRewards", account],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );

  // useEffect(() => {
  //   if (chainId === FANTOM) {
  //     if (hasCalledMigrateGLP === false && vestedGLPAmountV1Fantom && vestedGLPAmountV1Fantom.gt(0))
  //       setIsMigrateGLPEnabled(true);
  //   }
  //   if (chainId === OP) {
  //     if (hasCalledMigrateGLP === false && vestedGLPAmountV1OP && vestedGLPAmountV1OP.gt(0))
  //       setIsMigrateGLPEnabled(true);
  //   }
  //   if (hasCalledMigrateGLP && hasCalledMigrateGLP === true) {
  //     setIsMigrateGLPEnabled(false);
  //   }
  // }, [
  //   chainId,
  //   hasCalledMigrateGLP,
  //   vestedGLPAmountV1,
  //   vestedGLPAmountV1Fantom,
  //   vestedGLPAmountV1OP,
  //   vestingData?.glpVester?.vestedAmount,
  // ]);

  // useEffect(() => {
  //   if (chainId === FANTOM) {
  //     if (hasCalledMigrateGMY === false && vestedGMYAmountV1Fantom && vestedGMYAmountV1Fantom.gt(0))
  //       setIsMigrateGMYEnabled(true);
  //   }
  //   if (chainId === OP) {
  //     if (hasCalledMigrateGMY === false && vestedGMYAmountV1OP && vestedGMYAmountV1OP.gt(0))
  //       setIsMigrateGMYEnabled(true);
  //   }
  //   if (hasCalledMigrateGMY === true) {
  //     setIsMigrateGMYEnabled(false);
  //   }
  // }, [
  //   chainId,
  //   hasCalledMigrateGMY,
  //   vestedGMYAmountV1,
  //   vestedGMYAmountV1Fantom,
  //   vestedGMYAmountV1OP,
  //   vestingData?.gmyVester?.vestedAmount,
  // ]);

  const showGmyVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.gmyVester.maxVestableAmount.sub(vestingData.gmyVester.vestedAmount);
    if (processedData.esGmyBalance && processedData.esGmyBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esGmyBalance;
    }
    setVesterDepositAddress(gmyVesterAddress);
    setVesterDepositTitle(`YUM Vault`);
    //TODO off setIsMigrateGLPEnabled
    if (Date.now() < 1692180000000 && isMigrateGMYEnabled) {
      setVesterDepositTitle(`Migrate YUM Vault`);
    } else {
      setIsVesterDepositModalVisible(true);
    }

    setVesterDepositStakeTokenLabel("staked YUM + esYUM + Multiplier Points");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esGmyBalance);
    setVesterDepositEscrowedBalance(vestingData.gmyVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.gmyVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.gmyVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.gmyVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.gmyVester.pairAmount);
    setVesterDepositMaxReserveAmount(totalRewardTokens);
    setVesterDepositValue("");
  };
  const showGlpVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.glpVester.maxVestableAmount.sub(vestingData.glpVester.vestedAmount);
    if (processedData.esGmyBalance && processedData.esGmyBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esGmyBalance;
    }
    setVesterDepositTitle(`MGLP Vault`);
    setVesterDepositAddress(glpVesterAddress);
    if (Date.now() < 1692180000000 && isMigrateGLPEnabled) {
      setVesterDepositTitle(`Migrate MGLP Vault`);
    } else {
      setIsVesterDepositModalVisible(true);
    }
    setVesterDepositStakeTokenLabel("staked MGLP");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esGmyBalance);
    setVesterDepositEscrowedBalance(vestingData.glpVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.glpVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.glpVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.glpVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.glpVester.pairAmount);
    setVesterDepositMaxReserveAmount(processedData.glpBalance);
    setVesterDepositValue("");
  };

  const showGmVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.gmVester.maxVestableAmount.sub(vestingData.gmVester.vestedAmount);
    if (processedData.esGmyBalance && processedData.esGmyBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esGmyBalance;
    }
    setVesterDepositTitle(`YUM Vault`);
    setVesterDepositAddress(gmVesterAddress);
    if (Date.now() < 1692180000000 && isMigrateGLPEnabled) {
      setVesterDepositTitle(`Migrate YUM Vault`);
    } else {
      setIsVesterDepositModalVisible(true);
    }
    setVesterDepositStakeTokenLabel("staked YUM");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esGmyBalance);
    setVesterDepositEscrowedBalance(vestingData.gmVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.gmVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.gmVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.gmVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.gmVester.pairAmount);
    setVesterDepositMaxReserveAmount(processedData.gmBalance);
    setVesterDepositValue("");
  };
  const showGmyVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.gmyVesterVestedAmount || vestingData.gmyVesterVestedAmount.eq(0)) {
      helperToast.error(`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(`Withdraw from YUM Vault`);
    setVesterWithdrawAddress(gmyVesterAddress);
  };
  const showGlpVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.glpVesterVestedAmount || vestingData.glpVesterVestedAmount.eq(0)) {
      helperToast.error(`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(`Withdraw from MGLP Vault`);
    setVesterWithdrawAddress(glpVesterAddress);
  };

  const showGmVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.gmVesterVestedAmount || vestingData.gmVesterVestedAmount.eq(0)) {
      helperToast.error(`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(`Withdraw from YUM Vault`);
    setVesterWithdrawAddress(gmVesterAddress);
  };

  return (
    <section className="Earn-Section">
      <h1 className="Section-Title">Vest</h1>
      <p className="Section-Desc">
        Convert esYUM tokens to YUM tokens. Please read the vesting{" "}
        <ExternalLink href="https://docs.yummy.fi/rewards#vesting">details</ExternalLink> before using the vaults.
      </p>

      <div className="Section-TriplePaper">
        <div className="Section-SmallPaper">
          <div className="Section-SmallPaperHead">
            <img src={gum_ic} alt="gum_ic" />
            YUM Vault
          </div>
          <div className="Section-SmallPaperBody">
            <div className="Section-SmallPaperBodyBottom">
              <div className="Section-SmallPaperBodyBottomItemRows">
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Staked tokens
                  {totalRewardTokens ? (
                    <Tooltip
                      handle={<h5>{formatAmount(totalRewardTokens, 18, 2, true)}</h5>}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <>
                            <StatsTooltipRow
                              showDollar={false}
                              label="YUM"
                              value={formatAmount(processedData.gmyInStakedGmy, 18, 2, true)}
                            />
                            <StatsTooltipRow
                              showDollar={false}
                              label="esYUM"
                              value={formatAmount(processedData.esGmyInStakedGmy, 18, 2, true)}
                            />
                            <StatsTooltipRow
                              showDollar={false}
                              label="Multiplier Points"
                              value={formatAmount(processedData.bnGmyInFeeGmy, 18, 2, true)}
                            />
                          </>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Reserved for vesting
                  {vestingData?.gmyVesterPairAmount ? (
                    <h5>
                      {formatKeyAmount(vestingData, "gmyVesterPairAmount", 18, 2, true)} /{" "}
                      {formatAmount(totalRewardTokens, 18, 2, true)}
                    </h5>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Vesting status
                  {vestingData?.gmyVesterClaimSum ? (
                    <Tooltip
                      handle={
                        <h5>
                          {formatKeyAmount(vestingData, "gmyVesterClaimSum", 18, 4, true)} /{" "}
                          {formatKeyAmount(vestingData, "gmyVesterVestedAmount", 18, 4, true)}
                        </h5>
                      }
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            <span
                              style={{
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: "140%",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "gmyVesterClaimSum", 18, 4, true)}
                              </span>{" "}
                              tokens have been converted to YUM from the{" "}
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "gmyVesterVestedAmount", 18, 4, true)} esYUM
                              </span>{" "}
                              deposited for vesting.
                            </span>
                          </div>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Claimable
                  {vestingData?.gmyVesterClaimable ? (
                    <Tooltip
                      position="right-bottom"
                      handle={<h5>{formatKeyAmount(vestingData, "gmyVesterClaimable", 18, 4, true)} YUM</h5>}
                      renderContent={() => (
                        <span
                          style={{
                            fontWeight: 400,
                            fontSize: 14,
                            lineHeight: "140%",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 700,
                              fontSize: 14,
                              lineHeight: "140%",
                            }}
                          >
                            {formatKeyAmount(vestingData, "gmyVesterClaimable", 18, 4, true)} YUM
                          </span>{" "}
                          tokens can be claimed, use the options under the Total Rewards section to claim them.
                        </span>
                      )}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="Section-SmallPaperBodyBottomActions">
                <button className="default-btn" onClick={showGmyVesterDepositModal}>
                  Desposit
                </button>
                <button className="border-btn" onClick={showGmyVesterWithdrawModal}>
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="Section-SmallPaper">
          <div className="Section-SmallPaperHead">
            <img src={mglp_ic} alt="mglp_ic" />
            MGLP Vault
          </div>
          <div className="Section-SmallPaperBody">
            <div className="Section-SmallPaperBodyBottom">
              <div className="Section-SmallPaperBodyBottomItemRows">
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Staked tokens
                  {processedData.glpBalance ? (
                    <h5>{formatAmount(processedData.glpBalance, 18, 2, true)} MGLP</h5>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Reserved for vesting
                  {vestingData?.glpVesterPairAmount ? (
                    <h5>
                      {formatKeyAmount(vestingData, "glpVesterPairAmount", 18, 2, true)} /{" "}
                      {formatAmount(processedData.glpBalance, 18, 2, true)}
                    </h5>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Vesting status
                  {vestingData?.glpVesterClaimSum ? (
                    <Tooltip
                      handle={
                        <h5>
                          {formatKeyAmount(vestingData, "glpVesterClaimSum", 18, 4, true)} /{" "}
                          {formatKeyAmount(vestingData, "glpVesterVestedAmount", 18, 4, true)}
                        </h5>
                      }
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            <span
                              style={{
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: "140%",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "glpVesterClaimSum", 18, 4, true)}
                              </span>{" "}
                              tokens have been converted to YUM from the{" "}
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "glpVesterVestedAmount", 18, 4, true)} esYUM
                              </span>{" "}
                              deposited for vesting.
                            </span>
                          </div>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Claimable
                  {vestingData?.glpVesterClaimable ? (
                    <Tooltip
                      position="right-bottom"
                      handle={<h5>{formatKeyAmount(vestingData, "glpVesterClaimable", 18, 4, true)} YUM</h5>}
                      renderContent={() => (
                        <span
                          style={{
                            fontWeight: 400,
                            fontSize: 14,
                            lineHeight: "140%",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 700,
                              fontSize: 14,
                              lineHeight: "140%",
                            }}
                          >
                            {formatKeyAmount(vestingData, "glpVesterClaimable", 18, 4, true)} YUM
                          </span>{" "}
                          tokens can be claimed, use the options under the Total Rewards section to claim them.
                        </span>
                      )}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="Section-SmallPaperBodyBottomActions">
                <button className="default-btn" onClick={showGlpVesterDepositModal}>
                  Desposit
                </button>
                <button className="border-btn" onClick={showGlpVesterWithdrawModal}>
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="Section-SmallPaper">
          <div className="Section-SmallPaperHead">
            <img src={gslp_ic} alt="gslp_ic" />
            GSLP Vault
          </div>
          <div className="Section-SmallPaperBody">
            <div className="Section-SmallPaperBodyBottom">
              <div className="Section-SmallPaperBodyBottomItemRows">
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Staked tokens
                  <h5>
                    {processedData.gmBalance ? (
                      <h5>{formatAmount(processedData.gmBalance, 18, 2, true)} GSLP</h5>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    )}
                  </h5>
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Reserved for vesting
                  <h5>
                    {vestingData?.gmVesterPairAmount ? (
                      <h5>
                        {formatKeyAmount(vestingData, "gmVesterPairAmount", 18, 2, true)} /{" "}
                        {formatAmount(processedData.gmBalance, 18, 2, true)}
                      </h5>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    )}
                  </h5>
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Vesting status
                  {vestingData?.gmVesterClaimSum ? (
                    <Tooltip
                      handle={
                        <h5>
                          {formatKeyAmount(vestingData, "gmVesterClaimSum", 18, 4, true)} /{" "}
                          {formatKeyAmount(vestingData, "gmVesterVestedAmount", 18, 4, true)}
                        </h5>
                      }
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            <span
                              style={{
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: "140%",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "gmVesterClaimSum", 18, 4, true)}
                              </span>{" "}
                              tokens have been converted to YUM from the{" "}
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "gmVesterVestedAmount", 18, 4, true)} esYUM
                              </span>{" "}
                              deposited for vesting.
                            </span>
                          </div>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Claimable
                  {vestingData?.glpVesterClaimable ? (
                    <Tooltip
                      position="right-bottom"
                      handle={<h5>0.0000 YUM</h5>}
                      renderContent={() => (
                        <span
                          style={{
                            fontWeight: 400,
                            fontSize: 14,
                            lineHeight: "140%",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 700,
                              fontSize: 14,
                              lineHeight: "140%",
                            }}
                          >
                            0.0000 YUM
                          </span>{" "}
                          tokens can be claimed, use the options under the Total Rewards section to claim them.
                        </span>
                      )}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="Section-SmallPaperBodyBottomActions">
                <button className="default-btn" onClick={showGmVesterDepositModal}>
                  Desposit
                </button>
                <button className="border-btn" onClick={showGmVesterWithdrawModal}>
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VesterDepositModal
        isVisible={isVesterDepositModalVisible}
        setIsVisible={setIsVesterDepositModalVisible}
        chainId={chainId}
        title={vesterDepositTitle}
        stakeTokenLabel={vesterDepositStakeTokenLabel}
        maxAmount={vesterDepositMaxAmount}
        balance={vesterDepositBalance}
        escrowedBalance={vesterDepositEscrowedBalance}
        vestedAmount={vesterDepositVestedAmount}
        averageStakedAmount={vesterDepositAverageStakedAmount}
        maxVestableAmount={vesterDepositMaxVestableAmount}
        reserveAmount={vesterDepositReserveAmount}
        maxReserveAmount={vesterDepositMaxReserveAmount}
        value={vesterDepositValue}
        setValue={setVesterDepositValue}
        library={library}
        vesterAddress={vesterDepositAddress}
        setPendingTxns={setPendingTxns}
      />
      <VesterWithdrawModal
        isVisible={isVesterWithdrawModalVisible}
        setIsVisible={setIsVesterWithdrawModalVisible}
        vesterAddress={vesterWithdrawAddress}
        chainId={chainId}
        title={vesterWithdrawTitle}
        library={library}
        setPendingTxns={setPendingTxns}
      />
    </section>
  );
};

function VesterWithdrawModal(props) {
  const { isVisible, setIsVisible, chainId, title, library, vesterAddress, setPendingTxns } = props;
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const onClickPrimary = () => {
    setIsWithdrawing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "withdraw", [], {
      sentMsg: `Withdraw submitted.`,
      failMsg: `Withdraw failed.`,
      successMsg: `Withdrawn!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsWithdrawing(false);
      });
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <span>
          <div>
            This will withdraw and unreserve all tokens as well as pause vesting.
            <br />
            <br />
            esYUM tokens that have been converted to YUM will remain as YUM tokens.
            <br />
            <br />
            To claim YUM tokens without withdrawing, use the "Claim" button under the General Rewards section.
            <br />
            <br />
          </div>
        </span>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={isWithdrawing}>
            {!isWithdrawing && "Confirm Withdraw"}
            {isWithdrawing && "Confirming..."}
          </button>
        </div>
      </Modal>
    </div>
  );
}
function VesterDepositModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    balance,
    vestedAmount,
    averageStakedAmount,
    maxVestableAmount,
    library,
    stakeTokenLabel,
    reserveAmount,
    maxReserveAmount,
    vesterAddress,
    setPendingTxns,
  } = props;
  const [isDepositing, setIsDepositing] = useState(false);

  let amount = parseValue(value, 18);

  let nextReserveAmount = reserveAmount;

  let nextDepositAmount = vestedAmount;
  if (amount) {
    nextDepositAmount = vestedAmount.add(amount);
  }

  let additionalReserveAmount = bigNumberify(0);
  if (amount && averageStakedAmount && maxVestableAmount && maxVestableAmount.gt(0)) {
    nextReserveAmount = nextDepositAmount.mul(averageStakedAmount).div(maxVestableAmount);
    if (nextReserveAmount.gt(reserveAmount)) {
      additionalReserveAmount = nextReserveAmount.sub(reserveAmount);
    }
  }

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
    if (nextReserveAmount.gt(maxReserveAmount)) {
      return `Insufficient staked tokens`;
    }
  };

  const onClickPrimary = () => {
    setIsDepositing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "deposit", [amount], {
      sentMsg: `Deposit submitted!`,
      failMsg: `Deposit failed!`,
      successMsg: `Deposited!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsDepositing(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isDepositing) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isDepositing) {
      return `Depositing...`;
    }
    return `Deposit`;
  };

  return (
    <SEO title={getPageTitle("Earn")}>
      <div className="StakeModal">
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title} className="non-scrollable">
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <div className="Exchange-swap-usd">
                  <span>Deposit</span>
                </div>
              </div>
              <div
                className="muted align-right clickable"
                onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}
              >
                <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div>
                <input
                  type="number"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="PositionEditor-token-symbol">esYUM</div>
            </div>
          </div>
          <div className="VesterDepositModal-info-rows">
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>Wallet</span>
              </div>
              <div className="align-right">{formatAmount(balance, 18, 2, true)} esYUM</div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>Vault Capacity</span>
              </div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(nextDepositAmount, 18, 2, true)} / ${formatAmount(
                    maxVestableAmount,
                    18,
                    2,
                    true
                  )}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div>
                        <p className="text-white">
                          <span>Vault Capacity for your Account:</span>
                        </p>
                        <StatsTooltipRow
                          showDollar={false}
                          label={`Deposited`}
                          value={`${formatAmount(vestedAmount, 18, 2, true)} esYUM`}
                        />
                        <StatsTooltipRow
                          showDollar={false}
                          label={`Max Capacity`}
                          value={`${formatAmount(maxVestableAmount, 18, 2, true)} esYUM`}
                        />
                      </div>
                    );
                  }}
                  className="excep"
                />
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>Reserve Amount</span>
              </div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(
                    reserveAmount && reserveAmount.gte(additionalReserveAmount)
                      ? reserveAmount
                      : additionalReserveAmount,
                    18,
                    2,
                    true
                  )} / ${formatAmount(maxReserveAmount, 18, 2, true)}`}
                  position={amount && nextReserveAmount.gt(maxReserveAmount) ? "right-top" : "right-bottom"}
                  renderContent={() => {
                    return (
                      <>
                        <StatsTooltipRow
                          label={`Current Reserved`}
                          value={formatAmount(reserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        <StatsTooltipRow
                          label={`Additional Reserve Required`}
                          value={formatAmount(additionalReserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        {amount && nextReserveAmount.gt(maxReserveAmount) && (
                          <>
                            <br />
                            <span>
                              You need a total of at least {formatAmount(nextReserveAmount, 18, 2, true)}{" "}
                              {stakeTokenLabel} to vest {formatAmount(amount, 18, 2, true)} esYUM.
                            </span>
                          </>
                        )}
                      </>
                    );
                  }}
                  className="excep"
                />
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      </div>
    </SEO>
  );
}

export default VestSection;
