import React, { useEffect, useState, useMemo, useCallback, forwardRef, useImperativeHandle, cloneElement } from "react";
import { Trans, t, Plural } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { ethers } from "ethers";
import styled from "styled-components";
import cx from "classnames";
import ArrowUp from "img/icons/icon-up.svg";
import ChartPad from "img/icons/chart-pad.svg";
import ChartPadLight from "img/chart-pad-light.svg";
import {
  FUNDING_RATE_PRECISION,
  BASIS_POINTS_DIVISOR,
  MARGIN_FEE_BASIS_POINTS,
  SWAP,
  LONG,
  SHORT,
  USD_DECIMALS,
  getPositionKey,
  getPositionContractKey,
  getLeverage,
  getDeltaStr,
  useAccountOrders,
  getPageTitle,
  getMarketTokensInfo,
  getCompletePosition,
  CLOSED_POSITION,
  getMergedPositionV1,
  INCREASE,
  getMergedOrderV1,
} from "lib/legacy";
import { getConstant, getExplorerUrl } from "config/chains";
import { approvePlugin, useMinExecutionFee, cancelMultipleOrders, useTrades } from "domain/legacy";

import { getContract } from "config/contracts";

import Reader from "abis/ReaderV2.json";
import VaultV2 from "abis/VaultV2.json";
import Router from "abis/Router.json";
import Token from "abis/Token.json";
import ReaderMsp from "abis/ReaderMsp.json";

import Checkbox from "components/Checkbox/Checkbox";
import SwapBox from "components/Exchange/SwapBox";
import ExchangeTVChart, { getChartToken } from "components/Exchange/ExchangeTVChart";
import PositionsList from "components/Exchange/PositionsList";
import OrdersList from "components/Exchange/OrdersList";
import TradeHistory from "components/Exchange/TradeHistory";
import ExchangeWalletTokens from "components/Exchange/ExchangeWalletTokens";
import ExchangeBanner from "components/Exchange/ExchangeBanner";
import Tab from "components/Tab/Tab";
import Footer from "components/Footer/Footer";

import "./Exchange.css";
import "./Exchange.scss";
import { contractFetcher } from "lib/contracts";
import { useInfoTokens } from "domain/tokens";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { getTokenInfo } from "domain/tokens/utils";
import { bigNumberify, formatAmount, parseValue } from "lib/numbers";
import { getToken, getTokenBySymbol, getTokens, getWhitelistedTokens } from "config/tokens";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import TradingView from "../../components/Exchange/TradingView";
import TradeHistoryV2 from "components/Exchange/TradeHistoryV2";
import OrdersListV2 from "components/Exchange/OrdersListV2";
import ExchangeTVChartPad from "components/Exchange/ExchangeTVChartPad";
import { useMediaQuery } from "react-responsive";
import useMarketAssets from "hooks/useMarketAssets";
import useAssetsPrices from "hooks/useAssetsPrices";
import { useThemeContext } from "contexts/ThemeProvider";

import ExchangeTVChartV2 from "components/ExchangeV2/ExchangeTVChart";
import useUserPosition from "hooks/useUserPosition";
import useUserOrder from "hooks/useUserOrder";
import useUserTradeInfo from "hooks/useUserTradeInfo";
import SwapBoxV2 from "components/ExchangeV2/SwapBox";
import PositionListMerged from "components/TradeMerged/PositionList";
import OrderList from "components/TradeMerged/OrderList";
import TradeActivityList from "components/TradeMerged/TradeActivityList";
import useUserTradeActivity from "hooks/useUserTradeActivity";
import useUserClosedPositions from "hooks/useUserClosedPositions";
import ClosedPositionList from "components/TradeMerged/ClosedPositionList";
import useUserPositionV1 from "hooks/useUserPositionV1";
import useUserClosedPositionsV1 from "hooks/useUserClosedPositionsV1";
import useUserTradeActivityV1 from "hooks/useUserTradeActivityV1";
import useUserOpenOdersV1 from "hooks/useUserOpenOdersV1";

const { AddressZero } = ethers.constants;

const PENDING_POSITION_VALID_DURATION = 600 * 1000;
const UPDATED_POSITION_VALID_DURATION = 60 * 1000;
const TRADE_V1 = true;
const TRADE_V2 = false;
const notifications = {};

function pushSuccessNotification(chainId, message, e) {
  const { transactionHash } = e;
  const id = ethers.utils.id(message + transactionHash);
  if (notifications[id]) {
    return;
  }

  notifications[id] = true;

  const txUrl = getExplorerUrl(chainId) + "tx/" + transactionHash;
  helperToast.success(
    <div>
      {message}{" "}
      <ExternalLink href={txUrl}>
        <span>View</span>
      </ExternalLink>
    </div>
  );
}

function pushErrorNotification(chainId, message, e) {
  const { transactionHash } = e;
  const id = ethers.utils.id(message + transactionHash);
  if (notifications[id]) {
    return;
  }

  notifications[id] = true;

  const txUrl = getExplorerUrl(chainId) + "tx/" + transactionHash;
  helperToast.error(
    <div>
      {message}{" "}
      <ExternalLink href={txUrl}>
        <span>View</span>
      </ExternalLink>
    </div>
  );
}

function getFundingFee(data) {
  let { entryFundingRate, cumulativeFundingRate, size } = data;
  if (entryFundingRate && cumulativeFundingRate) {
    return size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION);
  }

  return;
}

const getTokenAddress = (token, nativeTokenAddress) => {
  if (token.address === AddressZero) {
    return nativeTokenAddress;
  }
  return token.address;
};

function applyPendingChanges(position, pendingPositions) {
  if (!pendingPositions) {
    return;
  }
  const { key } = position;

  if (
    pendingPositions[key] &&
    pendingPositions[key].updatedAt &&
    pendingPositions[key].pendingChanges &&
    pendingPositions[key].updatedAt + PENDING_POSITION_VALID_DURATION > Date.now()
  ) {
    const { pendingChanges } = pendingPositions[key];
    if (pendingChanges.size && position.size.eq(pendingChanges.size)) {
      return;
    }

    if (pendingChanges.expectingCollateralChange && !position.collateral.eq(pendingChanges.collateralSnapshot)) {
      return;
    }

    position.hasPendingChanges = true;
    position.pendingChanges = pendingChanges;
  }
}

export function getPositions(
  chainId,
  positionQuery,
  positionData,
  infoTokens,
  includeDelta,
  showPnlAfterFees,
  account,
  pendingPositions,
  updatedPositions
) {
  const propsLength = getConstant(chainId, "positionReaderPropsLength");
  const positions = [];
  const positionsMap = {};
  if (!positionData) {
    return { positions, positionsMap };
  }
  const { collateralTokens, indexTokens, isLong } = positionQuery;
  for (let i = 0; i < collateralTokens.length; i++) {
    const collateralToken = getTokenInfo(infoTokens, collateralTokens[i], true, getContract(chainId, "NATIVE_TOKEN"));
    const indexToken = getTokenInfo(infoTokens, indexTokens[i], true, getContract(chainId, "NATIVE_TOKEN"));
    const key = getPositionKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
    let contractKey;
    if (account) {
      contractKey = getPositionContractKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
    }

    const position = {
      key,
      contractKey,
      collateralToken,
      indexToken,
      isLong: isLong[i],
      size: positionData[i * propsLength],
      collateral: positionData[i * propsLength + 1],
      averagePrice: positionData[i * propsLength + 2],
      entryFundingRate: positionData[i * propsLength + 3],
      cumulativeFundingRate: collateralToken.cumulativeFundingRate,
      hasRealisedProfit: positionData[i * propsLength + 4].eq(1),
      realisedPnl: positionData[i * propsLength + 5],
      lastIncreasedTime: positionData[i * propsLength + 6].toNumber(),
      hasProfit: positionData[i * propsLength + 7].eq(1),
      delta: positionData[i * propsLength + 8],
      markPrice: isLong[i] ? indexToken.minPrice : indexToken.maxPrice,
    };

    if (
      updatedPositions &&
      updatedPositions[key] &&
      updatedPositions[key].updatedAt &&
      updatedPositions[key].updatedAt + UPDATED_POSITION_VALID_DURATION > Date.now()
    ) {
      const updatedPosition = updatedPositions[key];
      position.size = updatedPosition.size;
      position.collateral = updatedPosition.collateral;
      position.averagePrice = updatedPosition.averagePrice;
      position.entryFundingRate = updatedPosition.entryFundingRate;
    }
    // console.log("?????",1 );

    let fundingFee = getFundingFee(position);

    position.fundingFee = fundingFee ? fundingFee : bigNumberify(0);
    position.collateralAfterFee = position.collateral.sub(position.fundingFee);

    position.closingFee = position.size.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
    position.positionFee = position.size.mul(MARGIN_FEE_BASIS_POINTS).mul(2).div(BASIS_POINTS_DIVISOR);
    //TODO
    // position.totalFees = position.positionFee.add(position.fundingFee);
    position.totalFees = position.closingFee.add(position.fundingFee);

    position.pendingDelta = position.delta;

    if (position.collateral.gt(0)) {
      position.hasLowCollateral =
        position.collateralAfterFee.lt(0) || position.size.div(position.collateralAfterFee.abs()).gt(50);

      if (position.averagePrice && position.markPrice) {
        const priceDelta = position.averagePrice.gt(position.markPrice)
          ? position.averagePrice.sub(position.markPrice)
          : position.markPrice.sub(position.averagePrice);
        position.pendingDelta = position.size.mul(priceDelta).div(position.averagePrice);

        position.delta = position.pendingDelta;

        if (position.isLong) {
          position.hasProfit = position.markPrice.gte(position.averagePrice);
        } else {
          position.hasProfit = position.markPrice.lte(position.averagePrice);
        }
      }

      position.deltaPercentage = position.pendingDelta.mul(BASIS_POINTS_DIVISOR).div(position.collateral);

      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: position.pendingDelta,
        deltaPercentage: position.deltaPercentage,
        hasProfit: position.hasProfit,
      });

      position.deltaStr = deltaStr;
      position.deltaPercentageStr = deltaPercentageStr;
      position.deltaBeforeFeesStr = deltaStr;

      let hasProfitAfterFees;
      let pendingDeltaAfterFees;

      if (position.hasProfit) {
        if (position.pendingDelta.gt(position.totalFees)) {
          hasProfitAfterFees = true;
          pendingDeltaAfterFees = position.pendingDelta.sub(position.totalFees);
        } else {
          hasProfitAfterFees = false;
          pendingDeltaAfterFees = position.totalFees.sub(position.pendingDelta);
        }
      } else {
        hasProfitAfterFees = false;
        pendingDeltaAfterFees = position.pendingDelta.add(position.totalFees);
      }

      position.hasProfitAfterFees = hasProfitAfterFees;
      position.pendingDeltaAfterFees = pendingDeltaAfterFees;
      position.deltaPercentageAfterFees = position.pendingDeltaAfterFees
        .mul(BASIS_POINTS_DIVISOR)
        .div(position.collateral);

      const { deltaStr: deltaAfterFeesStr, deltaPercentageStr: deltaAfterFeesPercentageStr } = getDeltaStr({
        delta: position.pendingDeltaAfterFees,
        deltaPercentage: position.deltaPercentageAfterFees,
        hasProfit: hasProfitAfterFees,
      });

      position.deltaAfterFeesStr = deltaAfterFeesStr;
      position.deltaAfterFeesPercentageStr = deltaAfterFeesPercentageStr;

      if (showPnlAfterFees) {
        position.deltaStr = position.deltaAfterFeesStr;
        position.deltaPercentageStr = position.deltaAfterFeesPercentageStr;
      }

      let netValue = position.hasProfit
        ? position.collateral.add(position.pendingDelta)
        : position.collateral.sub(position.pendingDelta);

      netValue = netValue.sub(position.fundingFee);

      if (showPnlAfterFees) {
        netValue = netValue.sub(position.closingFee);
      }

      position.netValue = netValue;
    }

    position.leverage = getLeverage({
      size: position.size,
      collateral: position.collateral,
      entryFundingRate: position.entryFundingRate,
      cumulativeFundingRate: position.cumulativeFundingRate,
      hasProfit: position.hasProfit,
      delta: position.delta,
      includeDelta,
    });

    positionsMap[key] = position;

    applyPendingChanges(position, pendingPositions);

    if (position.size.gt(0) || position.hasPendingChanges) {
      positions.push(position);
    }
  }

  return { positions, positionsMap };
}

export function getPositionQuery(tokens, nativeTokenAddress) {
  const collateralTokens = [];
  const indexTokens = [];
  const isLong = [];

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    if (token.isStable) {
      continue;
    }
    if (token.isWrapped) {
      continue;
    }
    collateralTokens.push(getTokenAddress(token, nativeTokenAddress));
    indexTokens.push(getTokenAddress(token, nativeTokenAddress));
    isLong.push(true);
  }

  for (let i = 0; i < tokens.length; i++) {
    const stableToken = tokens[i];
    if (!stableToken.isStable) {
      continue;
    }

    for (let j = 0; j < tokens.length; j++) {
      const token = tokens[j];
      if (token.isStable) {
        continue;
      }
      if (token.isWrapped) {
        continue;
      }
      collateralTokens.push(stableToken.address);
      indexTokens.push(getTokenAddress(token, nativeTokenAddress));
      isLong.push(false);
    }
  }

  return { collateralTokens, indexTokens, isLong };
}
let justOpenPositions = [];
let closePositionTempt = [];
export const ExchangeMergePortfolio = forwardRef((props, ref) => {
  const {
    savedIsPnlInLeverage,
    setSavedIsPnlInLeverage,
    savedShowPnlAfterFees,
    savedSlippageAmount,
    pendingTxns,
    setPendingTxns,
    savedShouldShowPositionLines,
    setSavedShouldShowPositionLines,
    connectWallet,
    savedShouldDisableValidationForTesting,
    setTradeVersionState,
    setSavedSlippageAmount,
    savedSelectedDexes,
  } = props;
  const theme = useThemeContext();
  const [showBanner, setShowBanner] = useLocalStorageSerializeKey("showBanner", true);
  const [bannerHidden, setBannerHidden] = useLocalStorageSerializeKey("bannerHidden", null);

  const [pendingPositions, setPendingPositions] = useState({});
  const [updatedPositions, setUpdatedPositions] = useState({});
  const [showChart, setShowChart] = useState(false);
  const [isTradeV1, setIsTradeV1] = useState(TRADE_V2);
  const { active, library, account } = useWeb3React();
  // const account = "0x1B99b649e7b154C6F0efDCaf280F87269F9D4D04"; //FIX ME
  const { chainId } = useChainId();
  const currentAccount = account;
  /// V2 Data

  // console.log("????", parseValue(1, 30).sub(parseValue(0.5, 30)));
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const [defaultChartToken, setDefaultChartToken] = useLocalStorageSerializeKey(
    [chainId, "default-chart-token-v2"],
    "FTM/USD"
  );
  const [shortCollateralAddress, setShortCollateralAddress] = useLocalStorageByChainId(
    chainId,
    "Short-Collateral-Address-V2",
    getTokenBySymbol(chainId, defaultCollateralSymbol).address
  );
  useEffect(() => {
    if (defaultChartToken !== "BTC/USD" && defaultChartToken !== "ETH/USD") {
      if (isTradeV1) setIsTradeV1(TRADE_V2);
    } else {
      if (!isTradeV1) setIsTradeV1(TRADE_V1);
    }
  }, [defaultChartToken]);
  const readerMspAddress = getContract(chainId, "ReaderMsp");
  const { data: userTokenBalances } = useSWR(
    active && [`Exchange:getUserBalances:${active}:${account}`, chainId, readerMspAddress, "getUserBalances", account],
    {
      fetcher: contractFetcher(library, ReaderMsp),
    }
  );
  /// ------------------------------

  const hideBanner = () => {
    const hiddenLimit = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000);
    setBannerHidden(hiddenLimit);
    setShowBanner(false);
  };
  const isPadScreen = useMediaQuery({ query: "(max-width: 1110px)" });
  const isMobileScreen = useMediaQuery({ query: "(max-width: 700px)" });
  useEffect(() => {
    if (new Date() > new Date("2021-11-30")) {
      setShowBanner(false);
    } else {
      if (bannerHidden && new Date(bannerHidden) > new Date()) {
        setShowBanner(false);
      } else {
        setBannerHidden(null);
        setShowBanner(true);
      }
    }
  }, [showBanner, bannerHidden, setBannerHidden, setShowBanner]);

  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  const vaultAddress = getContract(chainId, "Vault");
  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const readerAddress = getContract(chainId, "Reader");
  const usdgAddress = getContract(chainId, "USDG");
  const { isLightTheme, lightThemeClassName } = useThemeContext();
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address);

  const positionQuery = getPositionQuery(whitelistedTokens, nativeTokenAddress);

  // const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const defaultTokenSelection = useMemo(
    () => ({
      [SWAP]: {
        from: AddressZero,
        to: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
      },
      [LONG]: {
        from: AddressZero,
        to: AddressZero,
      },
      [SHORT]: {
        from: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
        to: AddressZero,
      },
    }),
    [chainId, defaultCollateralSymbol]
  );

  const [tokenSelection, setTokenSelection] = useLocalStorageByChainId(
    chainId,
    "Exchange-token-selection-v3",
    defaultTokenSelection
  );
  const [swapOption, setSwapOption] = useLocalStorageByChainId(chainId, "Trade-v1-Swap-option", LONG);

  const fromTokenAddress = tokenSelection[swapOption].from;
  const toTokenAddress = tokenSelection[swapOption].to;

  const setFromTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[selectedSwapOption].from = address;
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection]
  );
  const marketAsssets = useMarketAssets();

  const marketAsssetList = Object.keys(marketAsssets).map((key) => marketAsssets[key]);

  const marketPrices = useAssetsPrices();
  const marketTokensInfo = getMarketTokensInfo(marketAsssetList, marketPrices);
  const setToTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[selectedSwapOption].to = address;
      if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
        newTokenSelection[LONG].to = address;
        newTokenSelection[SHORT].to = address;
      }
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection]
  );

  const setMarket = (selectedSwapOption, toTokenAddress) => {
    setSwapOption(selectedSwapOption);
    const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
    newTokenSelection[selectedSwapOption].to = toTokenAddress;
    if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
      newTokenSelection[LONG].to = toTokenAddress;
      newTokenSelection[SHORT].to = toTokenAddress;
    }
    setTokenSelection(newTokenSelection);
  };

  const [isConfirming, setIsConfirming] = useState(false);
  const [isPendingConfirmation, setIsPendingConfirmation] = useState(false);

  const tokens = getTokens(chainId);

  const tokenAddresses = tokens.map((token) => token.address);
  const { data: tokenBalances } = useSWR(active && [active, chainId, readerAddress, "getTokenBalances", account], {
    fetcher: contractFetcher(library, Reader, [tokenAddresses]),
  });

  const { data: positionData, error: positionDataError } = useSWR(
    active && [active, chainId, readerAddress, "getPositions", vaultAddress, account],
    {
      fetcher: contractFetcher(library, Reader, [
        positionQuery.collateralTokens,
        positionQuery.indexTokens,
        positionQuery.isLong,
      ]),
    }
  );
  // console.log("indexTokens", {
  //   indexTokens: positionQuery.indexTokens,
  //   isLong: positionQuery.isLong,
  //   collateralTokens: positionQuery.collateralTokens,
  //   positionData,
  // });

  const positionsDataIsLoading = active && !positionData && !positionDataError;

  const { data: fundingRateInfo } = useSWR([active, chainId, readerAddress, "getFundingRates"], {
    fetcher: contractFetcher(library, Reader, [vaultAddress, nativeTokenAddress, whitelistedTokenAddresses]),
  });

  const { data: totalTokenWeights } = useSWR(
    [`Exchange:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(library, VaultV2),
    }
  );

  const { data: usdgSupply } = useSWR([`Exchange:usdgSupply:${active}`, chainId, usdgAddress, "totalSupply"], {
    fetcher: contractFetcher(library, Token),
  });

  const orderBookAddress = getContract(chainId, "OrderBook");
  const routerAddress = getContract(chainId, "Router");
  const { data: orderBookApproved } = useSWR(
    active && [active, chainId, routerAddress, "approvedPlugins", account, orderBookAddress],
    {
      fetcher: contractFetcher(library, Router),
    }
  );

  const { data: positionRouterApproved } = useSWR(
    active && [active, chainId, routerAddress, "approvedPlugins", account, positionRouterAddress],
    {
      fetcher: contractFetcher(library, Router),
    }
  );

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, fundingRateInfo);

  const { minExecutionFee, minExecutionFeeUSD, minExecutionFeeErrorMessage } = useMinExecutionFee(
    library,
    active,
    chainId,
    infoTokens
  );

  useEffect(() => {
    const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
    const toToken = getTokenInfo(infoTokens, toTokenAddress);
    let selectedToken = getChartToken(swapOption, fromToken, toToken, chainId);
    let currentTokenPriceStr = formatAmount(selectedToken.maxPrice, USD_DECIMALS, 2, true);
    let title = getPageTitle(currentTokenPriceStr + ` | ${selectedToken.symbol}${selectedToken.isStable ? "" : "USD"}`);
    document.title = title;
  }, [tokenSelection, swapOption, infoTokens, chainId, fromTokenAddress, toTokenAddress]);
  const flagOrdersEnabled = true;
  const [orders] = useAccountOrders(flagOrdersEnabled);

  const { positions, positionsMap } = getPositions(
    chainId,
    positionQuery,
    positionData,
    infoTokens,
    savedIsPnlInLeverage,
    savedShowPnlAfterFees,
    account,
    pendingPositions,
    updatedPositions
  );
  // const { trades: tradev1 } = useTrades(chainId, account, true, 0);
  // const tradesV1 = useMemo(() => {
  //   return tradev1 ? tradev1.filter((x) => x.action !== SWAP && x.action !== "BuyUSDG" && x.action !== "SellUSDG") : [];
  // }, [tradev1]);
  const { positions: tradeV1, isLoading: loadingTradeHistoryV1 } = useUserTradeActivityV1(account, {
    pageSize: 1000,
    page: 1,
    type: "V1",
  });
  const { positions: ordersV1, isLoading: loadingOdersV1 } = useUserOpenOdersV1(account, {
    pageSize: 1000,
    page: 1,
    type: "V1",
  });
  const { positions: myTradesV2, isLoading: loadingTradeHistory } = useUserTradeActivity(account, {
    pageSize: 1000,
    page: 1,
  });
  const { positions: ordersV2, isLoading: loadingOrders } = useUserOrder(account);
  const { positions: closedPositionV2, isLoading: loadingCLosePositionV2 } = useUserClosedPositions(account, {
    pageSize: 1000,
    page: 1,
  });
  const { positions: closedPositionV1, isLoading: loadingCLosePositionV1 } = useUserClosedPositionsV1(account, {
    pageSize: 1000,
    page: 1,
    type: "V1",
  });
  let filteredOrders = orders
    .filter((order) => order.type !== "Swap")
    .filter(
      (x) =>
        x.type === INCREASE ||
        (x.isLong && positions.find((y) => y.isLong && y.indexToken.address === x.indexToken)) ||
        (!x.isLong &&
          positions.find(
            (y) => !y.isLong && y.indexToken.address === x.indexToken && y.collateralToken.address === x.collateralToken
          ))
    );
  const { positions: positionsOnChain } = useUserTradeInfo(account);
  const { positions: positionsApi, positionsDataIsLoading: loadingV2 } = useUserPosition(account);
  let positionsV2 = getCompletePosition(positionsOnChain, positionsApi);
  // let positionsV2 = getCompletePosition(positionsOnChain, []);
  const { positions: positionSubgraphV1 } = useUserPositionV1(account);

  if (justOpenPositions && justOpenPositions.length > 0) {
    const listNew = justOpenPositions.filter((x) => !positionsV2.find((y) => y.posId === x.posId));
    if (listNew.length > 0) {
      positionsV2 = [...listNew.reverse(), ...positionsV2];
    }
  }
  if (closePositionTempt && closePositionTempt.length > 0) {
    positionsV2 = positionsV2.filter((x) => !closePositionTempt.find((y) => y.posId === x.posId));
  }
  const tradeActivityMerged = useMemo(() => {
    return [
      ...tradeV1.map((i) => ({ ...i, isV1: true, createdAt: i.timestamp })),
      ...myTradesV2.map((i) => ({ ...i, isV1: false })),
    ].sort((a, b) => b.createdAt - a.createdAt);
  }, [tradeV1, myTradesV2]);
  const positionsV1Merged = getMergedPositionV1(positions, positionSubgraphV1);
  const mergedPosition = useMemo(() => {
    return [
      ...positionsV1Merged.map((i) => ({ ...i, isV1: true })),
      ...positionsV2.map((i) => ({ ...i, isV1: false })),
    ].sort((a, b) => b.createdAt - a.createdAt);
  }, [positionsV1Merged, positionsV2]);
  const orderV1Merged = getMergedOrderV1(filteredOrders, ordersV1);
  const mergedOrders = useMemo(() => {
    return [...orderV1Merged.map((i) => ({ ...i, isV1: true })), ...ordersV2.map((i) => ({ ...i, isV1: false }))].sort(
      (a, b) => b.createdAt - a.createdAt
    );
  }, [orderV1Merged, ordersV2]);

  const mergedClosePositions = useMemo(() => {
    return [
      ...closedPositionV1.map((i) => ({ ...i, closedAt: i.closedTime, openedAt: i.openTime, isV1: true })),
      ...closedPositionV2.map((i) => ({ ...i, isV1: false })),
    ].sort((a, b) => b.closedAt - a.closedAt);
  }, [closedPositionV2, closedPositionV1]);
  // console.log("???", { mergedOrders });

  useImperativeHandle(ref, () => ({
    onUpdatePosition(key, size, collateral, averagePrice, entryFundingRate, reserveAmount, realisedPnl) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i];
        if (position.contractKey === key) {
          updatedPositions[position.key] = {
            size,
            collateral,
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          };
          setUpdatedPositions({ ...updatedPositions });
          break;
        }
      }
    },
    onClosePosition(key, size, collateral, averagePrice, entryFundingRate, reserveAmount, realisedPnl, e) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i];
        if (position.contractKey === key) {
          updatedPositions[position.key] = {
            size: bigNumberify(0),
            collateral: bigNumberify(0),
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          };
          setUpdatedPositions({ ...updatedPositions });
          break;
        }
      }
    },

    onIncreasePosition(key, account, collateralToken, indexToken, collateralDelta, sizeDelta, isLong, price, fee, e) {
      if (account !== currentAccount) {
        return;
      }

      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? `Long` : `Short`;
      let message;
      if (sizeDelta.eq(0)) {
        message = `Deposited ${formatAmount(
          collateralDelta,
          USD_DECIMALS,
          2,
          true
        )} USD into ${tokenSymbol} ${longOrShortText}`;
      } else {
        message = `Increased ${tokenSymbol} ${longOrShortText}, +${formatAmount(
          sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD.`;
      }

      pushSuccessNotification(chainId, message, e);
    },

    onDecreasePosition(key, account, collateralToken, indexToken, collateralDelta, sizeDelta, isLong, price, fee, e) {
      if (account !== currentAccount) {
        return;
      }

      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? `Long` : `Short`;

      let message;
      if (sizeDelta.eq(0)) {
        message = `Withdrew ${formatAmount(
          collateralDelta,
          USD_DECIMALS,
          2,
          true
        )} USD from ${tokenSymbol} ${longOrShortText}.`;
      } else {
        message = `Decreased ${tokenSymbol} ${longOrShortText}, -${formatAmount(
          sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD.`;
      }

      pushSuccessNotification(chainId, message, e);
    },

    onCancelIncreasePosition(
      account,
      path,
      indexToken,
      amountIn,
      minOut,
      sizeDelta,
      isLong,
      acceptablePrice,
      executionFee,
      blockGap,
      timeGap,
      e
    ) {
      if (account !== currentAccount) {
        return;
      }
      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? `Long` : `Short`;

      const message = `Could not increase ${tokenSymbol} ${longOrShortText} within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;
      pushErrorNotification(chainId, message, e);

      const key = getPositionKey(account, path[path.length - 1], indexToken, isLong);
      pendingPositions[key] = {};
      setPendingPositions({ ...pendingPositions });
    },

    onCancelDecreasePosition(
      account,
      path,
      indexToken,
      collateralDelta,
      sizeDelta,
      isLong,
      receiver,
      acceptablePrice,
      minOut,
      executionFee,
      blockGap,
      timeGap,
      e
    ) {
      if (account !== currentAccount) {
        return;
      }
      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? `Long` : `Short`;

      const message = `Could not decrease ${tokenSymbol} ${longOrShortText} within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;

      pushErrorNotification(chainId, message, e);

      const key = getPositionKey(account, path[path.length - 1], indexToken, isLong);
      pendingPositions[key] = {};
      setPendingPositions({ ...pendingPositions });
    },
  }));

  const [isWaitingForPluginApproval, setIsWaitingForPluginApproval] = useState(false);
  const [isWaitingForPositionRouterApproval, setIsWaitingForPositionRouterApproval] = useState(false);
  const [isPluginApproving, setIsPluginApproving] = useState(false);
  const [isPositionRouterApproving, setIsPositionRouterApproving] = useState(false);

  const approveOrderBook = () => {
    setIsPluginApproving(true);
    return approvePlugin(chainId, orderBookAddress, {
      library,
      pendingTxns,
      setPendingTxns,
      sentMsg: `Enable orders sent.`,
      failMsg: `Enable orders failed.`,
    })
      .then(() => {
        setIsWaitingForPluginApproval(true);
      })
      .finally(() => {
        setIsPluginApproving(false);
      });
  };

  const approvePositionRouter = ({ sentMsg, failMsg }) => {
    setIsPositionRouterApproving(true);
    return approvePlugin(chainId, positionRouterAddress, {
      library,
      pendingTxns,
      setPendingTxns,
      sentMsg,
      failMsg,
    })
      .then(() => {
        setIsWaitingForPositionRouterApproval(true);
      })
      .finally(() => {
        setIsPositionRouterApproving(false);
      });
  };
  const POSITIONS = "Positions";
  const ORDERS = "Orders";
  const TRADES = "Trade";

  const LIST_SECTIONS = [POSITIONS, flagOrdersEnabled && ORDERS, CLOSED_POSITION, TRADES].filter(Boolean);
  let [listSection, setListSection] = useLocalStorageByChainId(chainId, "List-section-trade", LIST_SECTIONS[0]);
  const [posOrders, setPosOrders] = useState();
  const [orderPos, setOrderPos] = useState();
  useEffect(() => {
    if (listSection !== ORDERS) {
      setPosOrders(undefined);
    }
  }, [listSection]);
  useEffect(() => {
    if (listSection !== POSITIONS) {
      setOrderPos(undefined);
    }
  }, [listSection]);
  const LIST_SECTIONS_LABELS = {
    [ORDERS]: mergedOrders.length ? `Orders <span class="number-tag">${mergedOrders.length}</span>` : `Orders`,
    [POSITIONS]: mergedPosition.length
      ? `Positions <span class="number-tag">${mergedPosition.length}</span>`
      : `Positions`,
    [CLOSED_POSITION]: `Closed Positions`,
    [TRADES]: `Trade Activities`,
  };
  const TABS = [
    {
      label: "Positions ",
      key: "openPositions",
      count: mergedPosition.length || 0,
    },
    {
      label: "Orders ",
      key: "orders",
      count: mergedOrders.length || 0,
    },
    {
      label: "Closed Positions ",
      key: "closedPositions",
      // count: dataUserStats?.closedCount || 0,
    },
    {
      label: "Trade Activities ",
      key: "tradeHistories",
      // count: dataUserStats?.trades || 0,
    },
  ];
  const [activeTab, setActiveTab] = useState(TABS[0]);
  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0];
  }

  if (!getToken(chainId, toTokenAddress)) {
    return null;
  }
  const renderTabSection = () => {
    return {
      openPositions: (
        <PositionListMerged
          userTokenBalances={userTokenBalances}
          closePositionTempt={closePositionTempt}
          positions={orderPos || mergedPosition}
          setPendingTxns={setPendingTxns}
          loading={positionsDataIsLoading}
          chainId={chainId}
          library={library}
          account={account}
          active={active}
          marketTokensInfo={marketTokensInfo}
          marketAsssets={marketAsssets}
          nativeTokenAddress={nativeTokenAddress}
          orders={orders}
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
          positionsMap={positionsMap}
          pendingTxns={pendingTxns}
          infoTokens={infoTokens}
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          positionRouterApproved={positionRouterApproved}
          isPositionRouterApproving={isPositionRouterApproving}
          isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
          approvePositionRouter={approvePositionRouter}
          minExecutionFee={minExecutionFee}
          minExecutionFeeUSD={minExecutionFeeUSD}
          minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
          setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
          isWaitingForPluginApproval={isWaitingForPluginApproval}
          orderBookApproved={orderBookApproved}
          flagOrdersEnabled={flagOrdersEnabled}
          totalTokenWeights={totalTokenWeights}
          usdgSupply={usdgSupply}
          savedSlippageAmount={savedSlippageAmount}
          v1Orders={filteredOrders}
          v2Orders={ordersV2}
          setPosOrders={setPosOrders}
          setListSection={() => setActiveTab(TABS[1])}
        />
      ),
      orders: (
        <OrderList
          account={account}
          orders={posOrders || mergedOrders}
          loading={loadingOrders}
          library={library}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          positionsMap={positionsMap}
          totalTokenWeights={totalTokenWeights}
          usdgSupply={usdgSupply}
          savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
          marketAsssets={marketAsssets}
          marketTokensInfo={marketTokensInfo}
          nativeTokenAddress={nativeTokenAddress}
          infoTokens={infoTokens}
          positions={positionsV1Merged}
          setOrderPos={setOrderPos}
          setListSection={() => setActiveTab(TABS[0])}
          positionsV2={positionsV2}
        />
      ),
      closedPositions: (
        <ClosedPositionList
          account={account}
          positions={mergedClosePositions}
          loading={loadingCLosePositionV2 || loadingCLosePositionV1}
          chainId={chainId}
          marketAsssets={marketAsssets}
          marketTokensInfo={marketTokensInfo}
        />
      ),
      tradeHistories: (
        <TradeActivityList
          positions={tradeActivityMerged}
          loading={loadingTradeHistory}
          chainId={chainId}
          marketAsssets={marketAsssets}
        />
      ),
    }[activeTab.key];
  };
  return (
    <div className={`Portfolio-positions Exchange-updated-ui exchange-v2 ${lightThemeClassName}`}>
      <div className="Portfolio-tabs">
        {TABS.map((tab) => {
          return (
            <div
              className={cx("tab", {
                active: activeTab.key === tab.key,
              })}
              onClick={() => setActiveTab(tab)}
              key={tab.key}
            >
              {tab.label}{" "}
              {tab.hasOwnProperty("count") ? (
                <Styledcount
                  className={cx("", {
                    styleActive: activeTab.key === tab.key,
                  })}
                >
                  {tab.count}
                </Styledcount>
              ) : null}
            </div>
          );
        })}
      </div>
      <div className="Portfolio-tab-content">
        {cloneElement(renderTabSection(), {
          marketAsssets,
          account,
          chainId,
          marketTokensInfo,
          library,
          active,
        })}
      </div>
    </div>
  );
});
const Styledcount = styled.div`
  border-radius: 8px;
  border: 1px solid #41454d;
  background: var(--Nature-2, #212224);
  color: #919499;
  height: 20px;
  padding: 0 6px;
  font-size: 10px;

  &.styleActive {
    color: #fff;
  }
`;
