import React from "react";

function Icon() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_22044_2153)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.92237 11V2C9.2459 2 8.57521 2.07623 7.92237 2.22503C6.85734 2.46778 5.83982 2.90366 4.92223 3.51677C3.44219 4.50571 2.28864 5.91131 1.60745 7.55585C0.926263 9.20038 0.748033 11.01 1.0953 12.7558C1.44257 14.5016 2.29973 16.1053 3.55841 17.364C4.81708 18.6226 6.42072 19.4798 8.16655 19.8271C9.91238 20.1743 11.722 19.9961 13.3665 19.3149C15.0111 18.6337 16.4167 17.4802 17.4056 16.0001C18.0187 15.0825 18.4546 14.065 18.6973 13C18.8461 12.3472 18.9224 11.6765 18.9224 11H9.92237ZM16.6306 13H7.92237V4.2918C7.25545 4.49063 6.61831 4.78887 6.03337 5.17971C4.88223 5.94888 3.98502 7.04213 3.45521 8.32121C2.9254 9.6003 2.78677 11.0078 3.05687 12.3656C3.32697 13.7235 3.99365 14.9708 4.97262 15.9497C5.95159 16.9287 7.19887 17.5954 8.55673 17.8655C9.9146 18.1356 11.3221 17.997 12.6011 17.4672C13.8802 16.9373 14.9735 16.0401 15.7427 14.889C16.1335 14.3041 16.4317 13.6669 16.6306 13Z"
          fill="#FF65D9"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.6011 2.53284C14.3788 2.44073 14.1522 2.36031 13.9224 2.2918V7L18.6306 7C18.562 6.77018 18.4816 6.54361 18.3895 6.32122C18.0377 5.47194 17.5221 4.70026 16.8721 4.05025C16.2221 3.40024 15.4504 2.88463 14.6011 2.53284ZM20.6973 7C20.5851 6.5077 20.4314 6.02455 20.2373 5.55585C19.785 4.46392 19.1221 3.47177 18.2863 2.63604C17.4506 1.80031 16.4584 1.13738 15.3665 0.685084C14.8978 0.490942 14.4147 0.337242 13.9224 0.225036C13.2677 0.0758356 12.5969 0 11.9224 0V9L20.9224 9C20.9224 8.32542 20.8465 7.65462 20.6973 7Z"
          fill="#FF65D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_22044_2153">
          <rect width="20" height="20" fill="white" transform="translate(0.922363)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
