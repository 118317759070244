import { getContract } from "config/contracts";
import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import Token from "abis/Token.json";

import { parseValue } from "lib/numbers";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import useVaultGmyContract from "./contracts/useVaultGmyContract";

function useInfoGm(chainId) {
  const [totalSupply, setTotalSupply] = useState<BigNumber>();
  const [priceGm, setPriceGm] = useState<BigNumber>();
  // const urlApi = getServerBaseUrl(chainId);
  const gmAddress = getContract(chainId, "GM");

  const { data: totalSupplyGm } = useSWR<BigNumber>([`Gm:getTotalSupply`, chainId, gmAddress, "totalSupply"], {
    fetcher: contractFetcher(undefined, Token),
    refreshInterval: 10000,
  });
  const { vaultUSDBalance} = useVaultGmyContract(chainId);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getGMInfo = async () => {
    try {
      if (totalSupplyGm && !totalSupplyGm.eq(0) && vaultUSDBalance) {
        const totalSupplyParsed = totalSupplyGm.mul(parseValue("1", 12)!);
        setPriceGm(vaultUSDBalance.mul(parseValue("1", 30)!).div(totalSupplyParsed));
        setTotalSupply(BigNumber.from(totalSupplyGm));
      } else {
        setPriceGm(BigNumber.from(0));
      }
    } catch (error) {
      console.error("Error fetching GM info:", error);
    }
  };

  useEffect(() => {
    if (totalSupplyGm && !totalSupplyGm.eq(0) && vaultUSDBalance) {
      getGMInfo();
    }

    const interval = setInterval(getGMInfo, 30000);

    return () => clearInterval(interval);
  }, [totalSupplyGm, vaultUSDBalance]);

  return {
    totalSupply,
    price: priceGm,
  };
}

export default useInfoGm;
