import { Spin } from "antd";
import { getPositionNetValue } from "components/ExchangeV2/PositionShare";
import { getTokenSymbolFromString } from "domain/tokens";
import { BigNumber } from "ethers";
import { getLiqRisk, getUnrealizedPnl, importImage, USD_DECIMALS } from "lib/legacy";
import { formatAmount, parseValue } from "lib/numbers";
import { LoadingOutlined } from "@ant-design/icons";
import cx from "classnames";
import Tooltip from "components/Tooltip/Tooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { renderRisk } from "lib/helper";
import moment from "moment";
import { useThemeContext } from "contexts/ThemeProvider";
import ic_chain from "img/trade/chains.svg";
import ic_chainLight from "img/trade/chains_light.svg";
import PositionDropdown from "components/ExchangeV2/PositionDropdown";
import editIcon from "img/trade/edit.svg";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "#FFA722" }} spin />;
const PositionItemV2 = (props) => {
  const {
    position,
    marketTokensInfo,
    marketAsssets,
    myOrders,
    handleSelectedPosition,
    setListSection,
    setPosOrders,
    setSelectedPosition,
    sellPosition,
    setIsEditLeverageModalOPen,
    setPositionToShare,
    setIsPositionShareModalOpen,
    setIsTPSLModalOPen,
    setIsAddToPosition,
    setIsAddCollateralModalOpen,
    setIsTrailingStopModalOpen,
    setIsEditTakeProfitModalOpen,
    setIsEditStopLossModalOpen,
  } = props;
  const theme = useThemeContext();
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);

  const accruedFees =
    parseValue(position?.accruedPositionFee || 0, 30)
      .add(parseValue(position?.accruedFundingFee || 0, 30))
      .add(parseValue(position?.accruedBorrowFee || 0, 30)) || BigNumber.from(0);
  const avgEntryPrice = parseValue(position.averagePrice, 30);
  const markPrice = BigNumber.from(position?.isLong ? currentMarket?.askPrice || 0 : currentMarket?.bidPrice || 0);
  const posOrders = myOrders?.filter((x) => x.posId === position.posId).map((i) => ({ ...i, isV1: false })) || [];
  const paidFees =
    parseValue(position?.paidPositionFee || 0, 30)
      .add(parseValue(position?.paidBorrowFee || 0, 30))
      .add(parseValue(position?.paidFundingFee || 0, 30)) || BigNumber.from(0);
  const posQty = parseValue(position.size, 30).mul(parseValue(1, 30)).div(parseValue(position.averagePrice, 30));
  const positionNetValue = getPositionNetValue(position, markPrice);
  const uPnL =
    getUnrealizedPnl(accruedFees, markPrice, avgEntryPrice, position.isLong, posQty)?.div(parseValue(1, 30)) ||
    BigNumber.from(0);
  const collateral = parseValue(position.collateral, 30);
  const LiqRisk = getLiqRisk(positionNetValue?.pnl, collateral);
  const rPnL = positionNetValue?.pnlWithoutFee.sub(paidFees || 0);
  const completePosition = {
    positionNetValue,
    markPrice: markPrice,
    ...position,
    symbol: getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol),
    quantity: posQty,
    currentMarket,
    accruedFees,
    uPnL,
    LiqRisk,
    paidFees,
    rPnL,
    posOrders,
  };
  // console.log("????", posOrders);

  return (
    <tr
      key={position.key}
      className="table-content clickable"
      onClick={() => position.liquidationPrice && handleSelectedPosition(completePosition)}
    >
      <td className="clickable" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
        <div>
          <img src={importImage("ic_" + completePosition?.symbol?.toLowerCase() + "_24.svg")} alt="tk" />{" "}
          <div className="tk_info">
            <div className="Exchange-list-title">
              {marketAsssets?.[position.tokenId]?.symbol} <span className="muted">#{position.posId}</span>
            </div>
            <div className="Exchange-list-info-label">
              <span
                className={cx({ positive: position.isLong, negative: !position.isLong })}
                style={{ color: position.isLong ? "#7EE55C" : "#F6475D", fontWeight: 700 }}
              >
                {position.isLong ? `LONG` : `SHORT`}
              </span>
              {position.leverage && (
                <span
                  className="leverage-box"
                  style={{
                    color: "#FFC700",
                    backgroundColor: "rgba(255, 199, 0, 0.20)",
                    height: 16,
                    display: "inline-block",
                    opacity: 1,
                    borderRadius: "100px",
                    padding: "0 4px",
                    fontSize: "12px",
                    lineHeight: "15.6px",
                    fontWeight: 700,
                    marginLeft: "4px",
                  }}
                >
                  {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X
                </span>
              )}
            </div>
          </div>
        </div>
        {/* <div className={`flag ${position.isLong ? "isLong" : "isShort"}`}></div> */}
      </td>
      {position.liquidationPrice ? (
        <td style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
          <div style={{ display: "flex", alignItems: "center", gap: "0px" }}>
            {positionNetValue.netValue.lt(0) ? "-" : ""}$
            {positionNetValue.netValue ? formatAmount(positionNetValue.netValue, 30, 2, true).replace("-", "") : "--"}
            <span style={{ position: "relative", top: "1px" }}>
              {" "}
              <Tooltip
                trigger="hover"
                handle={``}
                position={"left-top"}
                handleClassName={cx("plain", { negative: position.hasLowCollateral })}
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`PnL without fees`}
                        value={`${positionNetValue.prefixWithoutFee}$${formatAmount(
                          positionNetValue.pnlWithoutFee,
                          USD_DECIMALS,
                          2,
                          true
                        ).replace("-", "")}`}
                        showDollar={false}
                      />
                      <StatsTooltipRow
                        label={`Accrued fees`}
                        showDollar={false}
                        value={`${accruedFees.lt(0) ? "-" : ""}$${formatAmount(
                          accruedFees,
                          USD_DECIMALS,
                          2,
                          true
                        ).replace("-", "")}`}
                      />
                    </>
                  );
                }}
              />
            </span>
          </div>
          {positionNetValue?.pnlWithoutFee && (
            <div
              className={cx("Exchange-list-info-label profit-loss-label", {
                positive: positionNetValue?.pnl?.gt(0),
                negative: positionNetValue?.pnl.lt(0),
                muted: positionNetValue?.pnl.eq(0),
              })}
            >
              <span
                className={cx("", {
                  positive: positionNetValue?.pnl?.gt(0),
                  negative: positionNetValue?.pnl.lt(0),
                  muted: positionNetValue?.pnl.eq(0),
                })}
              >
                {/* {positionNetValue.prefix ? (
                  <img
                    src={positionNetValue.prefix === "-" ? ic_ArrowDown : ic_ArrowUp}
                    alt="tk"
                    style={{ width: "16px", height: "16px" }}
                  />
                ) : (
                  ""
                )} */}
                {`${positionNetValue?.pnl?.lt(0) ? "-" : ""}$${formatAmount(positionNetValue?.pnl, 30, 2, true).replace(
                  "-",
                  ""
                )}`}{" "}
                <span
                  className={cx("", {
                    positive: positionNetValue?.pnl?.gt(0),
                    negative: positionNetValue?.pnl.lt(0),
                    muted: positionNetValue?.pnl.eq(0),
                  })}
                >
                  ({positionNetValue?.pnl?.gt(0) && "+"}
                  {positionNetValue?.pnl.lt(0) && "-"}
                  {formatAmount(positionNetValue?.percentPnl, 30, 2, true).replace("-", "")}
                  %)
                </span>
              </span>
            </div>
          )}
        </td>
      ) : (
        <td style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
          <div>--</div>
        </td>
      )}

      <td style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
        <div>${formatAmount(parseValue(position?.size, 30), 30, 2, true)}</div>
        <div className="muted fz-12 bold">
          {formatAmount(posQty, 30, 4, true)} {getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol)}
        </div>
      </td>
      <td className="clickable" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
        ${formatAmount(parseValue(position.collateral, 30), 30, 2, true)}
        <button
          className="PositionDropdown-dots-icon"
          disabled={position && !position.liquidationPrice}
          style={{ position: "relative", top: "2px", marginLeft: "2px" }}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedPosition(completePosition);
            setIsAddCollateralModalOpen(true);
          }}
        >
          <img src={editIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
        </button>
      </td>
      <td style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
        <div>
          {renderRisk(LiqRisk, false)}

          {/* {formatAmount(LiqRisk, 30, LiqRisk.eq(0) ? 0 : 2, true).replace("-", "")}% */}
        </div>
      </td>
      <td className="clickable" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
        <div>${formatAmount(parseValue(position.liquidationPrice, 30), 30, currentMarket?.decimals || 2, true)}</div>
        <div className="muted fz-12 bold">${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}</div>
      </td>
      <td className="clickable" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
        ${formatAmount(parseValue(position.averagePrice, 30), 30, currentMarket?.decimals || 2, true)}
      </td>
      {/* <td className="clickable">${limitDecimals(position.liquidationPrice, 3)}</td> */}
      {/* <td className="clickable" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
        {moment(new Date(position.createdAt * 1000)).format(dateFormat)}
        <div className="muted fz-12 bold">{moment(new Date(position.createdAt * 1000)).format(timeFormat)}</div>
      </td> */}

      <td style={{ width: "30px" }}>
        {position.liquidationPrice ? (
          <div className="close-container">
            {posOrders.length > 0 && (
              <Tooltip
                position="right-top"
                hideInfoImg={true}
                handle={
                  <div
                    className={`chains-btn`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setListSection("Orders");
                      setPosOrders(posOrders);
                    }}
                  >
                    <span>{posOrders.length}</span>
                    <img src={theme?.isLightTheme ? ic_chainLight : ic_chain} alt="" style={{ marginLeft: "0px" }} />
                  </div>
                }
                renderContent={() => {
                  return (
                    <>
                      {posOrders && posOrders.length > 0 && (
                        <>
                          <strong>
                            <span style={{ fontWeight: 700, color: "white" }}>Active Orders</span>
                          </strong>
                          {posOrders.map((order) => {
                            if (order.type === "TRAILING") {
                              const sizeDelta = BigNumber.from(order.sizeDelta);

                              const price =
                                order.stepType == 0
                                  ? `$${formatAmount(
                                      BigNumber.from(order.stepAmount),
                                      30,
                                      currentMarket?.decimals || 2,
                                      true
                                    )} TA`
                                  : `${formatAmount(
                                      BigNumber.from(order.stepAmount),
                                      3,
                                      currentMarket?.decimals || 2,
                                      true
                                    )} T%`;
                              return (
                                <div
                                  key={`${order.isLong}-${order.type}-${order.index}`}
                                  className="Position-list-order"
                                >
                                  <div>
                                    <span style={{ color: "white" }}>
                                      Trailing stop: {price} | -$
                                      {sizeDelta.gte(parseValue(position.size, 30))
                                        ? formatAmount(
                                            parseValue(position.size, 30),
                                            30,
                                            currentMarket?.decimals || 2,
                                            true
                                          )
                                        : formatAmount(sizeDelta, 30, currentMarket?.decimals || 2, true)}
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                            const conditionQuantity = Number(order.trigger.amountPercent / 100000);
                            const sizeDelta = parseValue(position.size, 30)
                              .mul(parseValue(conditionQuantity, USD_DECIMALS))
                              .div(parseValue(1, USD_DECIMALS));
                            return (
                              <div key={`${order.isLong}-${order.type}-${order.index}`} className="Position-list-order">
                                <div>
                                  <span style={{ color: "white" }}>
                                    {order?.trigger.isTP ? "Take profit" : "Stop loss"}:{" "}
                                    {order?.trigger.isTP ? (position.isLong ? "≥" : "≤") : !position.isLong ? "≥" : "≤"}{" "}
                                    ${formatAmount(order.trigger.price, 30, currentMarket?.decimals || 2, true)} | -$
                                    {formatAmount(sizeDelta, 30, currentMarket?.decimals || 2, true)}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </>
                  );
                }}
              />
            )}
            <button
              className="Exchange-list-action"
              onClick={(e) => {
                e.stopPropagation();
                sellPosition(completePosition);
              }}
              disabled={position.size == 0 || (position && !position.liquidationPrice)}
            >
              Close
            </button>

            {/* <div className="order-count">5 Orders</div> */}
            <PositionDropdown
              position={position}
              handleEditLeverage={() => {
                setSelectedPosition(completePosition);
                setIsEditLeverageModalOPen(true);
              }}
              handleShare={() => {
                setSelectedPosition(completePosition);
                setIsPositionShareModalOpen(true);
              }}
              handleAddTPSL={() => {
                setSelectedPosition(completePosition);
                setIsTPSLModalOPen(true);
              }}
              handlAddToPosition={() => {
                setSelectedPosition(completePosition);
                setIsAddToPosition(true);
              }}
              handlAddCollateral={() => {
                setSelectedPosition(completePosition);
                setIsAddCollateralModalOpen(true);
              }}
              handlAddTrailingStop={() => {
                setSelectedPosition(completePosition);
                setIsTrailingStopModalOpen(true);
              }}
              handleMarketSelect={() => {
                // onPositionClick(position);
              }}
              handleEditTakeProfit={() => {
                setSelectedPosition(completePosition);
                setIsEditTakeProfitModalOpen(true);
              }}
              handleEditStopLoss={() => {
                setSelectedPosition(completePosition);
                setIsEditStopLossModalOpen(true);
              }}
            />
          </div>
        ) : (
          <div className="processing">
            Processing <Spin indicator={antIcon} />
          </div>
        )}
      </td>
    </tr>
  );
};
export default PositionItemV2;
