const LinkSquareIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.66667 2C6.29848 2 6 2.29848 6 2.66667C6 3.03486 6.29848 3.33333 6.66667 3.33333H11.7239L2.19526 12.8619C1.93491 13.1223 1.93491 13.5444 2.19526 13.8047C2.45561 14.0651 2.87772 14.0651 3.13807 13.8047L12.6667 4.27614V8.66667C12.6667 9.03486 12.9651 9.33333 13.3333 9.33333C13.7015 9.33333 14 9.03486 14 8.66667V2H6.66667Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkSquareIcon;
