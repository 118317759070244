import { useChainId } from "lib/chains";
import OrderItemV1 from "./OrderItemV1";
import OrderItemV2 from "./OrderItemV2";
import { useCallback, useMemo, useState } from "react";
import {
  addTrailingStop,
  cancelPendingOrder,
  cancelTriggerOrder,
  editTriggerOrder,
  handleCancelOrder,
} from "domain/legacy";
import OrderEditor from "components/Exchange/OrderEditor";
import { getPositionForOrder } from "lib/legacy";
import PaginationV2 from "../../pages/AnalyticsV3/components/PaginationV2";
import { DEFAULT_PAGE_SIZE_POSITIONS } from "../../hooks/useUserPosition";
import OrderItemMobileV1 from "./OrderItemMobileV1";
import OrderItemMobileV2 from "./OrderItemMobileV2";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import { getContract } from "config/contracts";
import SettingsManager from "abis/SettingsManager.json";
import EditTakeProfit from "components/ExchangeV2/EditTakeProfit";
import EditTralingStop from "components/ExchangeV2/EditTralingStop";
const OrderList = ({
  orders,
  loading,
  library,
  account,
  pendingTxns,
  positionsMap,
  totalTokenWeights,
  usdgSupply,
  savedShouldDisableValidationForTesting,
  setPendingTxns,
  marketAsssets,
  marketTokensInfo,
  infoTokens,
  nativeTokenAddress,
  positions,
  setOrderPos,
  setListSection,
  positionsV2,
}) => {
  // console.log("?????", { positions, orders });

  const [page, setPage] = useState(1);
  const { chainId } = useChainId();
  const [editingOrder, setEditingOrder] = useState(null);
  const [isEditTakeProfitModalOpen, setIsEditTakeProfitModalOpen] = useState(false);
  const [isEditTralingStopModalOpen, setIsEditTralingStopModalOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(undefined);
  const [isSectionConfirming, setIsSectionConfirming] = useState(false);
  const onEditClick = useCallback(
    (order) => {
      setEditingOrder(order);
    },
    [setEditingOrder]
  );
  const onCancelClick = useCallback(
    (order) => {
      handleCancelOrder(chainId, library, order, { pendingTxns, setPendingTxns });
    },
    [library, pendingTxns, setPendingTxns, chainId]
  );

  const filterOrders = useMemo(() => {
    const startIndex = (page - 1) * DEFAULT_PAGE_SIZE_POSITIONS;
    const endIndex = page * DEFAULT_PAGE_SIZE_POSITIONS;
    return orders?.slice(startIndex, endIndex) || [];
  }, [orders, page]);
  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const { data: triggerGasFee } = useSWR(["ExChange:triggerGasFee", chainId, settingsManagerAddress, "triggerGasFee"], {
    fetcher: contractFetcher(library, SettingsManager),
  });
  const handlePendingTrigger = (position) => {
    // debugger
    const posId = position.posId;
    cancelPendingOrder(chainId, library, posId, {
      value: triggerGasFee,
      setPendingTxns,
      sentMsg: `Cancel trigger submitted.`,
      failMsg: `Cancel trigger failed.`,
      successMsg: "Cancelled trigger successfully",
    })
      .then(async () => {})
      .finally(() => {});
  };
  const handleCancelTrigger = (position) => {
    // debugger
    const posId = position.posId;
    const orderId = position?.trigger?.id?.split("-")[1];
    cancelTriggerOrder(chainId, library, posId, orderId, {
      value: triggerGasFee,
      setPendingTxns,
      sentMsg: `Cancel trigger submitted.`,
      failMsg: `Cancel trigger failed.`,
      successMsg: "Cancelled trigger successfully",
    })
      .then(async () => {})
      .finally(() => {});
  };

  const handleAddTrailingStop = (posId, params) => {
    setIsSectionConfirming(true);
    addTrailingStop(chainId, library, posId, params, {
      value: triggerGasFee,
      setPendingTxns,
      sentMsg: `Edit trailing stop submitted.`,
      failMsg: `Edit trailing stop failed`,
      successMsg: "Edited trailing stop successfully",
    })
      .then(async () => {
        // setIsConfirming(false);
        setIsEditTralingStopModalOpen(false);
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };
  const handleEditTriggerOrder = (position, _orderId, _isTP, _price, _amountPercent) => {
    const _posId = position.posId;
    setIsSectionConfirming(true);
    editTriggerOrder(chainId, library, _posId, _orderId, _isTP, _price, _amountPercent, {
      // value: BigNumber.from(isTPs.length).mul(triggerGasFee),
      setPendingTxns,
      sentMsg: `Edit TP/SL submitted.`,
      failMsg: `Edit TP/SL`,
      successMsg: "Edited TP/SL successfully",
    })
      .then(async () => {
        // setIsConfirming(false);
        setIsEditTakeProfitModalOpen(false);
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };
  return (
    <div className="PositionsList">
      {isEditTralingStopModalOpen && (
        <EditTralingStop
          isVisible={isEditTralingStopModalOpen}
          position={selectedPosition}
          setIsVisible={setIsEditTralingStopModalOpen}
          chainId={chainId}
          onConfirm={handleAddTrailingStop}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}
      {isEditTakeProfitModalOpen && (
        <EditTakeProfit
          isVisible={isEditTakeProfitModalOpen}
          position={selectedPosition}
          setIsVisible={setIsEditTakeProfitModalOpen}
          chainId={chainId}
          onConfirm={handleEditTriggerOrder}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}
      {editingOrder && (
        <OrderEditor
          account={account}
          order={editingOrder}
          setEditingOrder={setEditingOrder}
          infoTokens={infoTokens}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          getPositionForOrder={getPositionForOrder}
          selectedPosition={selectedPosition}
          positionsMap={positionsMap}
          library={library}
          totalTokenWeights={totalTokenWeights}
          usdgSupply={usdgSupply}
          savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
        />
      )}
      <table className={`Exchange-list large App-box ${orders.length === 0 ? "no-array-length" : ""}`}>
        <tbody>
          <tr className="Exchange-list-header">
            <th>
              <span>Position</span>
            </th>
            <th>
              <span>Type</span>
            </th>
            <th>
              <span>Order</span>
            </th>
            <th>
              <span>Trigger Conditions</span>
            </th>
            <th>
              <span>Pos. Size</span>
            </th>
            <th>
              <span>Mark. Price</span>
            </th>
            <th></th>
          </tr>
        </tbody>
        {orders.length === 0 && !loading && (
          <tr className="no-position">
            <td colSpan="15">
              <div className="no-data-record">No open orders</div>
            </td>
          </tr>
        )}
        {filterOrders.length > 0 &&
          filterOrders.map((order, index) => {
            if (order.isV1) {
              let position = undefined;
              if (order.isLong) {
                position = positions.find((x) => x.isLong && x.indexToken.address === order.indexToken);
              } else {
                position = positions.find(
                  (x) =>
                    !x.isLong &&
                    x.indexToken.address === order.indexToken &&
                    x.collateralToken.address === order.collateralToken
                );
              }
              return (
                <OrderItemV1
                  key={index}
                  order={order}
                  chainId={chainId}
                  nativeTokenAddress={nativeTokenAddress}
                  infoTokens={infoTokens}
                  onCancelClick={onCancelClick}
                  onEditClick={onEditClick}
                  position={position}
                  setOrderPos={setOrderPos}
                  setListSection={setListSection}
                  account={account}
                  positionsMap={positionsMap}
                  setSelectedPosition={setSelectedPosition}
                />
              );

              // if (position !== undefined) {
              //   return (
              //     <OrderItemV1
              //       key={index}
              //       order={order}
              //       chainId={chainId}
              //       nativeTokenAddress={nativeTokenAddress}
              //       infoTokens={infoTokens}
              //       onCancelClick={onCancelClick}
              //       onEditClick={onEditClick}
              //       position={position}
              //       setOrderPos={setOrderPos}
              //       setListSection={setListSection}
              //     />
              //   );
              // } else return null;
            } else
              return (
                <OrderItemV2
                  key={index}
                  position={order}
                  marketAsssets={marketAsssets}
                  marketTokensInfo={marketTokensInfo}
                  handlePendingTrigger={handlePendingTrigger}
                  handleCancelTrigger={handleCancelTrigger}
                  setSelectedPosition={setSelectedPosition}
                  setIsEditTakeProfitModalOpen={setIsEditTakeProfitModalOpen}
                  isSectionConfirming={isSectionConfirming}
                  setIsEditTralingStopModalOpen={setIsEditTralingStopModalOpen}
                  positions={positionsV2}
                />
              );
          })}
      </table>
      <div className="Exchange-list small">
        {filterOrders.length > 0 &&
          filterOrders.map((order, index) => {
            if (order.isV1) {
              let position = undefined;
              if (order.isLong) {
                position = positions.find((x) => x.isLong && x.indexToken.address === order.indexToken);
              } else {
                position = positions.find(
                  (x) =>
                    !x.isLong &&
                    x.indexToken.address === order.indexToken &&
                    x.collateralToken.address === order.collateralToken
                );
              }
              return (
                <OrderItemMobileV1
                  key={index}
                  order={order}
                  chainId={chainId}
                  nativeTokenAddress={nativeTokenAddress}
                  infoTokens={infoTokens}
                  onCancelClick={onCancelClick}
                  onEditClick={onEditClick}
                  position={position}
                  setOrderPos={setOrderPos}
                  setListSection={setListSection}
                  account={account}
                  positionsMap={positionsMap}
                  setSelectedPosition={setSelectedPosition}
                />
              );
            } else
              return (
                <OrderItemMobileV2
                  key={index}
                  position={order}
                  marketAsssets={marketAsssets}
                  marketTokensInfo={marketTokensInfo}
                  handlePendingTrigger={handlePendingTrigger}
                  handleCancelTrigger={handleCancelTrigger}
                  setSelectedPosition={setSelectedPosition}
                  setIsEditTakeProfitModalOpen={setIsEditTakeProfitModalOpen}
                  isSectionConfirming={isSectionConfirming}
                  setIsEditTralingStopModalOpen={setIsEditTralingStopModalOpen}
                  positions={positionsV2}
                />
              );
          })}
      </div>
      {orders && orders.length > DEFAULT_PAGE_SIZE_POSITIONS && (
        <PaginationV2
          rowsPerPage={DEFAULT_PAGE_SIZE_POSITIONS}
          rows={orders.length}
          currentPage={page}
          setCurrentPage={setPage}
        />
      )}
    </div>
  );
};
export default OrderList;
