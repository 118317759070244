import React from "react";

import { formatAmount, parseValue } from "lib/numbers";
import { DataTable, DateAndTime, PositionHead, TableRow } from "../../helper/table";

import icView from "img/ic-view-arrow.svg";
import icViewLight from "img/ic-view-arrowLight.svg";
import { useThemeContext } from "contexts/ThemeProvider";
import { TYPE_MAP_TYPE } from "..";
import { getExplorerUrl } from "config/chains";
import { formatHistory } from "../../helper";
const TradeHistories = ({ histories, chainId, assets, isEmpty }) => {
  const { lightThemeClassName, isLightTheme } = useThemeContext();

  const formattedPositions = histories.map((history) => {
    return formatHistory(history, assets);
  });

  return (
    <DataTable $noIndex $autoHeight $noHeading={isEmpty} className={lightThemeClassName}>
      <div className="head">
        <div className="col-name">Position</div>
        <div className="col-name">Date/Time</div>
        <div className="col-name">Type</div>
        <div
          className="col-name"
          style={{
            minWidth: 400,
          }}
        >
          Action
        </div>
        <div className="col-name">Size Delta</div>
      </div>
      <div className="body">
        {formattedPositions.map((history, i) => {
          const asset = assets[history.indexToken.id] || {};
          const price = parseValue(history.price, 30);

          const isV2 = history.type === "V2";
          return (
            <TableRow noScaleOnHover key={history.key || i} href={`${getExplorerUrl(chainId)}tx/${history.txhash}`}>
              <div d-label="Position" className="col-body">
                <PositionHead noFlag position={history} isV2={isV2} asset={isV2 ? asset : undefined} hideLeverage />
              </div>
              <div d-label="Date/Time" className="col-body">
                <div className="col-body-content">
                  <DateAndTime unix={history.timestamp} />
                </div>
              </div>
              <div d-label="Type" className="col-body">
                {/* {isV2 ? (
                  <>{history.positionType}</>
                ) : (
                  <>
                    {history.action === "DECREASE"
                      ? "Decrease position"
                      : history.action === "INCREASE"
                      ? "Increase position "
                      : "Liquidation"}
                  </>
                )} */}
                {history.positionType}
              </div>
              <div
                d-label="Action"
                className="col-body col-min-width"
                style={{
                  minWidth: 400,
                }}
              >
                {isV2 ? (
                  <>
                    {history.action === "ADD_TRAILING_STOP" ? (
                      history.trailingStopAll ? (
                        "Close"
                      ) : (
                        "Decrease"
                      )
                    ) : (
                      <span>
                        {history.action === "EDIT_COLLATERAL"
                          ? history.isPlus
                            ? "Deposited"
                            : "Withdrew"
                          : TYPE_MAP_TYPE[history.action]}{" "}
                        <span className="muted">(Price ${formatAmount(price, 30, asset.decimals, true)})</span>
                      </span>
                    )}
                  </>
                ) : (
                  <div className="mobile-row">
                    {history.action === "CLOSE" || history.action === "DECREASE"
                      ? "Decreased position"
                      : history.action === "OPEN" || history.action === "INCREASE"
                      ? "Increased position"
                      : history.action === "DEPOSIT"
                      ? "Deposited"
                      : history.action === "WITHDRAW"
                      ? "Withdrew"
                      : "Liquidated"}{" "}
                    <span className="muted">(Price ${formatAmount(price, 30, 2, true)})</span>
                  </div>
                )}
              </div>
              <div d-label="Size Delta" className="col-body">
                {!["DEPOSIT", "WITHDRAW"].includes(history.action) && (
                  <div className="table-row">
                    <div> ${formatAmount(history.size, 30, 2, true)}</div>
                    <div className="muted fz-12">
                      {formatAmount(history.volume, 30, 4, true)} {asset.tokenSymbol || history.indexToken.symbol}
                    </div>
                  </div>
                )}
              </div>
              <img className="icon-view" src={isLightTheme ? icViewLight : icView} alt="view_ic" />
            </TableRow>
          );
        })}
      </div>
    </DataTable>
  );
};

export default TradeHistories;
