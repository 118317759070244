import {
  DECREASE,
  FUNDING_RATE_PRECISION,
  getLiqRisk,
  getLiquidationPrice,
  getOrderError,
  importImage,
  INCREASE,
  USD_DECIMALS,
} from "lib/legacy";
import { bigNumberify, formatAmount, parseValue } from "lib/numbers";
import { ImSpinner2 } from "react-icons/im";
import cx from "classnames";
import Tooltip from "components/Tooltip/Tooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { AiOutlineEdit } from "react-icons/ai";
import PositionDropdown from "components/Exchange/PositionDropdown";
import editIcon from "img/trade/edit.svg";
import { renderRisk, renderRiskV1 } from "lib/helper";
import ic_chain from "img/trade/chains.svg";
import ic_chain_error from "img/trade/chains_error.svg";
import ic_chainLight from "img/trade/chains_light.svg";
import { useThemeContext } from "contexts/ThemeProvider";
const getOrdersForPosition = (account, position, orders, nativeTokenAddress) => {
  if (!orders || orders.length === 0) {
    return [];
  }
  /* eslint-disable array-callback-return */
  return orders
    .filter((order) => {
      const hasMatchingIndexToken =
        order.indexToken === nativeTokenAddress
          ? position.indexToken.isNative
          : order.indexToken === position.indexToken.address;
      const hasMatchingCollateralToken =
        order.collateralToken === nativeTokenAddress
          ? position.collateralToken.isNative
          : order.collateralToken === position.collateralToken.address;
      if (order.isLong === position.isLong && hasMatchingIndexToken && hasMatchingCollateralToken) {
        return true;
      }
    })
    .map((order) => {
      order.error = getOrderError(account, order, undefined, position);
      if (order.type === DECREASE && order.sizeDelta.gt(position.size)) {
        order.error = `Order size is bigger than position, will only be executable if position increases`;
      }
      return order;
    });
};
const PositionItemV1 = (props) => {
  const {
    position,
    handleAddTPSL,
    setIsPositionShareModalOpen,
    setPositionToShare,
    sellPosition,
    editPosition,
    account,
    setListSection,
    onPositionClick,
    orders,
    nativeTokenAddress,
    showPnlAfterFees,
    handleAddToPosition,
    setPosOrders,
    myOrders,
  } = props;
  const liquidationPrice = getLiquidationPrice(position) || bigNumberify(0);
  const positionOrders = getOrdersForPosition(account, position, orders, nativeTokenAddress);
  const hasOrderError = !!positionOrders.find((order) => order.error);
  const hasPositionProfit = position[showPnlAfterFees ? "hasProfitAfterFees" : "hasProfit"];
  const positionDelta = position[showPnlAfterFees ? "pendingDeltaAfterFees" : "pendingDelta"] || bigNumberify(0);
  let borrowFeeUSD;
  if (position.collateralToken && position.collateralToken.fundingRate) {
    const borrowFeeRate = position.collateralToken.fundingRate.mul(position.size).mul(24).div(FUNDING_RATE_PRECISION);
    borrowFeeUSD = formatAmount(borrowFeeRate, USD_DECIMALS, 2);
  }
  // console.log("???", position);

  const posOrders =
    myOrders
      ?.filter(
        (x) =>
          (x.isLong && position.isLong && position.indexToken.address === x.indexToken) ||
          (!x.isLong &&
            !position.isLong &&
            position.indexToken.address === x.indexToken &&
            position.collateralToken.address === x.collateralToken)
      )
      .map((i) => ({ ...i, isV1: true })) || [];
  const isInvalid = posOrders.find((x) => getOrderError(account, x, undefined, position) && x.type !== "Increase");
  const myErrorOrders = posOrders.filter((x) => getOrderError(account, x, undefined, position));
  const myValidOrders = posOrders.filter((x) => !getOrderError(account, x, undefined, position));
  const posQty =
    position?.averagePrice && position?.averagePrice.gt(0)
      ? position.size.mul(parseValue(1, USD_DECIMALS)).div(position?.averagePrice)
      : 0;
  // const LiqRisk = getLiqRisk(pnl, collateral);
  const isLong = position.isLong;
  const pnlWithoutFee_LiqPrice =
    position.markPrice && position.averagePrice && position.averagePrice.gt(0) && position.markPrice.gt(0)
      ? isLong
        ? liquidationPrice.sub(position.averagePrice).mul(position.size).div(position.averagePrice)
        : position.averagePrice.sub(liquidationPrice).mul(position.size).div(position.averagePrice)
      : bigNumberify(0);

  const pnlWithoutFee_MarkPrice =
    position.markPrice && position.averagePrice && position.averagePrice.gt(0) && position.markPrice.gt(0)
      ? isLong
        ? position.markPrice.sub(position.averagePrice).mul(position.size).div(position.averagePrice)
        : position.averagePrice.sub(position.markPrice).mul(position.size).div(position.averagePrice)
      : bigNumberify(0);

  const LiqRisk =
    position.markPrice &&
    position.averagePrice &&
    pnlWithoutFee_LiqPrice.lt(0) &&
    position.averagePrice.gt(0) &&
    position.markPrice.gt(0)
      ? pnlWithoutFee_MarkPrice.mul(parseValue(100, USD_DECIMALS)).div(pnlWithoutFee_LiqPrice)
      : 0;
  // console.log("????", { position, pnlWithoutFee_LiqPrice, pnlWithoutFee_MarkPrice, LiqRisk });
  const theme = useThemeContext();
  return (
    <tr
      key={position.key}
      className="table-content clickable"
      onClick={() => {
        onPositionClick(position);
      }}
    >
      <td>
        <div>
          <img src={importImage("ic_" + position?.indexToken?.symbol?.toLowerCase() + "_24.svg")} alt="tk" />{" "}
          <div className="tk_info">
            <div className="Exchange-list-title">
              {position?.indexToken?.symbol}/USD <span className="muted">#{position.posId}</span>
            </div>
            <div className="Exchange-list-info-label">
              <span
                className={cx({ positive: position.isLong, negative: !position.isLong })}
                style={{ color: position.isLong ? "#7EE55C" : "#F6475D", fontWeight: 700 }}
              >
                {position.isLong ? `LONG` : `SHORT`}
              </span>
              {position.leverage && (
                <span
                  className="leverage-box"
                  style={{
                    color: "#FFC700",
                    backgroundColor: "rgba(255, 199, 0, 0.20)",
                    height: 16,
                    display: "inline-block",
                    opacity: 1,
                    borderRadius: "100px",
                    padding: "0 4px",
                    fontSize: "12px",
                    lineHeight: "15.6px",
                    fontWeight: 700,
                    marginLeft: "4px",
                  }}
                >
                  {formatAmount(position.leverage, 4, 2)}X
                </span>
              )}
            </div>
          </div>
        </div>
      </td>
      <td>
        <div>
          {!position.netValue && `Opening...`}
          {position.netValue && (
            <Tooltip
              handle={`$${formatAmount(position.netValue, USD_DECIMALS, 2, true)}`}
              position="left-top"
              handleClassName="plain"
              renderContent={() => {
                return (
                  <>
                    <StatsTooltipRow
                      label={`PnL without fees`}
                      value={`${position.deltaBeforeFeesStr}`}
                      showDollar={false}
                    />
                    <StatsTooltipRow
                      label={`Accrued fees`}
                      value={formatAmount(position.closingFee.add(position.fundingFee), USD_DECIMALS, 2, true)}
                    />
                    {/* {showPnlAfterFees
                      ? `Net Value: Initial Collateral - Fees + PnL`
                      : `Net Value: Initial Collateral - Borrow Fee + PnL`}
                    <br />
                    <br />
                    <StatsTooltipRow
                      label={`Initial Collateral`}
                      value={formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                    />
                    <StatsTooltipRow label={`PnL`} value={position.deltaBeforeFeesStr} showDollar={false} />
                    <StatsTooltipRow
                      label={`Borrow Fee`}
                      showDollar={false}
                      value={`-$${formatAmount(position.fundingFee, USD_DECIMALS, 2, true)}`}
                    />
                    <StatsTooltipRow
                      label={`Open + Close fee`}
                      showDollar={false}
                      value={`-$${formatAmount(position.positionFee, USD_DECIMALS, 2, true)}`}
                    />
                    <StatsTooltipRow
                      label={`PnL After Fees`}
                      value={`${position.deltaAfterFeesStr} (${position.deltaAfterFeesPercentageStr})`}
                      showDollar={false}
                    /> */}
                  </>
                );
              }}
            />
          )}
        </div>
        {position.deltaStr && (
          <div
            className={cx("Exchange-list-info-label bold", {
              positive: position.deltaAfterFeesStr.includes("+"),
              negative: position.deltaAfterFeesStr.includes("-"),
              muted: !position.deltaAfterFeesStr.includes("+") && !position.deltaAfterFeesStr.includes("-"),
            })}
            style={{ gap: 0 }}
          >
            {/* {positionDelta.eq(0) ? null : (
              <img
                src={!hasPositionProfit && positionDelta.gt(0) ? ic_ArrowDown : ic_ArrowUp}
                alt="tk"
                style={{ width: "16px", height: "16px" }}
              />
            )} */}
            {position.deltaAfterFeesStr.replace("+", "")} ({position.deltaAfterFeesPercentageStr})
          </div>
        )}
      </td>
      <td>
        <div>${formatAmount(position.size, USD_DECIMALS, 2, true)}</div>
        <div className="muted fz-12 bold">
          {formatAmount(posQty, 30, 4, true)} {position.indexToken.symbol}
        </div>
        {/* {positionOrders.length > 0 && (
          <div onClick={() => setListSection && setListSection("Orders")}>
            <Tooltip
              handle={`Orders (${positionOrders.length})`}
              position="left-bottom"
              handleClassName={cx(
                ["Exchange-list-info-label", "Exchange-position-list-orders", "plain", "clickable"],
                { muted: !hasOrderError, negative: hasOrderError }
              )}
              renderContent={() => {
                return (
                  <>
                    <strong>
                      <span>Active Orders</span>
                    </strong>
                    {positionOrders.map((order) => {
                      return (
                        <div
                          key={`${order.isLong}-${order.type}-${order.index}`}
                          className="Position-list-order"
                        >
                          {order.triggerAboveThreshold ? "≥" : "≤"}{" "}
                          {formatAmount(
                            order.triggerPrice,
                            30,
                            position?.indexToken?.displayDecimals || 2,
                            true
                          )}
                          :{order.type === INCREASE ? " +" : " -"}$
                          {formatAmount(order.sizeDelta, 30, 2, true)}
                          {order.error && (
                            <>
                              , <span className="negative">{order.error}</span>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </>
                );
              }}
            />
          </div>
        )} */}
      </td>
      <td>
        <div className="position-list-collateral">
          {`$${formatAmount(position.collateral, USD_DECIMALS, 2, true)}`}
          {/* <Tooltip
            handle={`$${formatAmount(position.collateralAfterFee, USD_DECIMALS, 2, true)}`}
            position="left-bottom"
            handleClassName={cx("plain", { negative: position.hasLowCollateral })}
            renderContent={() => {
              return (
                <>
                  {position.hasLowCollateral && (
                    <div>
                      <span>
                        WARNING: This position has a low amount of collateral after deducting borrowing
                        fees, deposit more collateral to reduce the position's liquidation risk.
                      </span>
                      <br />
                      <br />
                    </div>
                  )}

                  <StatsTooltipRow
                    label={`Initial Collateral`}
                    value={formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                  />
                  <StatsTooltipRow
                    label={`Borrow Fee`}
                    showDollar={false}
                    value={`-$${formatAmount(position.fundingFee, USD_DECIMALS, 2, true)}`}
                  />
                  <StatsTooltipRow
                    showDollar={false}
                    label={`Borrow Fee / Day`}
                    value={`-$${borrowFeeUSD}`}
                  />
                  <br />
                  <span>Use the Edit Collateral icon to deposit or withdraw collateral.</span>
                </>
              );
            }}
          /> */}
          {/* <span className="edit-icon" onClick={() => editPosition(position)}>
            <AiOutlineEdit fontSize={16} />
          </span> */}
          <button
            className="PositionDropdown-dots-icon"
            // disabled={position && !position.liquidationPrice}
            style={{ position: "relative", top: "4px", marginLeft: "2px", padding: "2px" }}
            onClick={(e) => {
              e.stopPropagation();
              editPosition(position);
            }}
          >
            <img src={editIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
          </button>
        </div>
      </td>
      <td className="clickable" onClick={() => onPositionClick(position)}>
        {renderRiskV1(LiqRisk, false)}
      </td>
      <td className="clickable" onClick={() => onPositionClick(position)}>
        {/* $
        {formatAmount(
          position.averagePrice,
          USD_DECIMALS,
          position?.indexToken?.displayDecimals || 2,
          true
        )} */}
        <div> ${formatAmount(liquidationPrice, USD_DECIMALS, position?.indexToken?.displayDecimals || 2, true)}</div>
        <div className="muted fz-12 bold">
          {position.markPrice ? (
            "$" + formatAmount(position.markPrice, USD_DECIMALS, position?.indexToken?.displayDecimals || 2, true)
          ) : (
            <div className="skeleton-box" style={{ width: "70px", margin: "auto" }} />
          )}
        </div>
      </td>
      <td className="clickable" onClick={() => onPositionClick(position)}>
        ${formatAmount(position.averagePrice, USD_DECIMALS, position?.indexToken?.displayDecimals || 2, true)}
      </td>

      <td style={{ width: "30px" }}>
        <div className="close-container">
          {/* {posOrders.length > 0 && !isInvalid && (
            <div
              className={`chains-btn ${isInvalid ? "chains-btn-invalid" : ""}`}
              onClick={(e) => {
                e.stopPropagation();
                setListSection("Orders");
                setPosOrders(posOrders);
              }}
            >
              <span>{posOrders.length}</span>
              <img src={theme?.isLightTheme ? ic_chainLight : ic_chain} alt="" />
            </div>
          )} */}
          {posOrders.length > 0 && (
            <Tooltip
              position="right-top"
              hideInfoImg={true}
              handle={
                <div
                  className={`chains-btn ${isInvalid ? "chains-btn-invalid" : ""}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setListSection("Orders");
                    setPosOrders(posOrders);
                  }}
                >
                  <span>{posOrders.length}</span>
                  <img
                    src={theme?.isLightTheme ? ic_chainLight : isInvalid ? ic_chain_error : ic_chain}
                    alt=""
                    style={{ marginLeft: "0px" }}
                  />
                </div>
              }
              renderContent={() => {
                return (
                  <>
                    {myValidOrders && myValidOrders.length > 0 && (
                      <>
                        <strong>
                          <span style={{ fontWeight: 700, color: "white" }}>Active Orders</span>
                        </strong>
                        {myValidOrders.map((order) => {
                          return (
                            <div key={`${order.isLong}-${order.type}-${order.index}`} className="Position-list-order">
                              <div>
                                <span style={{ color: "white" }}>
                                  {["Swap", "Increase"].includes(order.type)
                                    ? "Limit"
                                    : position.isLong
                                    ? order.triggerAboveThreshold
                                      ? "Take Profit"
                                      : "Stop Loss"
                                    : order.triggerAboveThreshold
                                    ? "Stop Loss"
                                    : "Take Profit"}
                                  : {order.triggerAboveThreshold ? "≥" : "≤"} $
                                  {formatAmount(
                                    order.triggerPrice,
                                    30,
                                    position?.indexToken?.displayDecimals || 2,
                                    true
                                  )}{" "}
                                  | {order.type === INCREASE ? " +" : " -"}${formatAmount(order.sizeDelta, 30, 2, true)}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                    {myErrorOrders && myErrorOrders.length > 0 && (
                      <>
                        {myValidOrders && myValidOrders.length > 1 && <div className="divider" />}

                        <strong>
                          <span style={{ fontWeight: 700, color: "white" }}>Inactive Orders</span>
                        </strong>
                        {myErrorOrders.map((order) => {
                          return (
                            <div key={`${order.isLong}-${order.type}-${order.index}`} className="Position-list-order">
                              <div>
                                <span>
                                  {["Swap", "Increase"].includes(order.type)
                                    ? "Limit"
                                    : position.isLong
                                    ? order.triggerPrice.gt(position.averagePrice)
                                      ? "Take profit"
                                      : "Stop loss"
                                    : order.triggerPrice.gt(position.averagePrice)
                                    ? "Stop loss"
                                    : "Take profit"}
                                  : {order.triggerAboveThreshold ? "≥" : "≤"} $
                                  {formatAmount(
                                    order.triggerPrice,
                                    30,
                                    position?.indexToken?.displayDecimals || 2,
                                    true
                                  )}{" "}
                                  | {order.type === INCREASE ? " +" : " -"}${formatAmount(order.sizeDelta, 30, 2, true)}
                                </span>
                              </div>
                              {order.error && (
                                <div>
                                  <span className="negative">{order.error}</span>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </>
                );
              }}
            />
          )}
          <button
            className="Exchange-list-action"
            onClick={(e) => {
              e.stopPropagation();
              sellPosition(position);
            }}
            disabled={position.size.eq(0)}
          >
            <span>Close</span>
          </button>
          <PositionDropdown
            handleEditCollateral={() => {
              editPosition(position);
            }}
            handleShare={() => {
              setPositionToShare(position);
              setIsPositionShareModalOpen(true);
            }}
            handleMarketSelect={() => {
              onPositionClick(position);
            }}
            handleAddTPSL={() => {
              handleAddTPSL(position);
            }}
            handleAddToPosition={() => {
              handleAddToPosition && handleAddToPosition(position);
            }}
          />
        </div>
      </td>
    </tr>
  );
};
export default PositionItemV1;
