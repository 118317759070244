import cx from "classnames";

import { formatAmount, formatAmountInDollar, parseValue } from "lib/numbers";

import icView from "img/ic-view.svg";
import { renderRisk } from "lib/helper";
import { useThemeContext } from "contexts/ThemeProvider";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import Tooltip from "components/Tooltip/Tooltip";
import { useMedia } from "react-use";
import { DataTable, PositionHead, TableRow } from "../../helper/table";
import { formatOpenPosition } from "../../helper";
import SmallTextNumber from "../../../../components/Tooltip/SmallTextNumber";
import { BigNumber } from "ethers";

const OpenPositions = ({ positions, assets, marketTokensInfo, isEmpty }) => {
  const { lightThemeClassName } = useThemeContext();
  const isTablet = useMedia("(max-width: 1023px)");

  const formattedPositions = positions.map((position) => {
    return formatOpenPosition(position, assets, marketTokensInfo);
  });

  return (
    <DataTable $noIndex $autoHeight $noHeading={isEmpty} className={lightThemeClassName}>
      <div className="head">
        <div className="col-name">Position</div>
        <div className="col-name">Net/PnL</div>
        <div className="col-name">Pos. Size</div>
        <div className="col-name">Collateral</div>
        <div className="col-name">Liq. Risk</div>
        <div className="col-name">Liq./Mark Price</div>
        <div className="col-name">Avg. Entry Price</div>
        {/* <div className="col-name">Created</div> */}
      </div>
      <div className="body">
        {formattedPositions.map((position, i) => {
          const asset = position.isV2 ? assets[position.indexToken.id] : undefined;
          return (
            <TableRow noScaleOnHover key={position.key || i} to={`/analytics/position/${position.id}`}>
              <div d-label="Position" className="col-body">
                <PositionHead position={position} isV2={position.isV2} asset={asset} noFlag />
              </div>
              <div d-label="Net/PnL" className="col-body">
                <div className="col-body-content">
                  <div>
                    <Tooltip
                      handle={
                        <>
                          {position?.netValue?.lt(0) ? "-" : ""}$
                          {position?.netValue ? formatAmount(position.netValue, 30, 2, true).replace("-", "") : "--"}
                        </>
                      }
                      position={isTablet ? "right-top" : "center-top"}
                      handleClassName={cx("plain", { negative: position.hasLowCollateral })}
                      renderContent={() => {
                        return (
                          <>
                            {position.isV2 ? (
                              <>
                                Unrealized PnL after accrued funding, borrow
                                <br />
                                and position fee
                              </>
                            ) : (
                              "Unrealized PnL after accrued borrow and position fee"
                            )}
                            <br />
                            <br />
                            <StatsTooltipRow
                              label={`PnL without fees`}
                              value={formatAmountInDollar(position?.pnlWithoutFee, 30, 2, true)}
                              showDollar={false}
                            />
                            {position.isV2 && (
                              <>
                                <StatsTooltipRow
                                  label={`Accrued funding fee`}
                                  value={formatAmountInDollar(position?.accruedFundingFee, 30, 2, true)}
                                  showDollar={false}
                                />
                                <StatsTooltipRow
                                  label={`Accrued borrow fee`}
                                  value={formatAmountInDollar(position?.accruedBorrowFee, 30, 2, true)}
                                  showDollar={false}
                                />
                                <StatsTooltipRow
                                  label={`Accrued position fee`}
                                  value={formatAmountInDollar(position?.accruedPositionFee, 30, 2, true)}
                                  showDollar={false}
                                />
                              </>
                            )}
                            {!position.isV2 && (
                              <>
                                <StatsTooltipRow
                                  label={`Accrued borrow fee`}
                                  value={formatAmountInDollar(position?.accruedFundingFee, 30, 2, true)}
                                  showDollar={false}
                                />
                                <StatsTooltipRow
                                  label={`Accrued position fee`}
                                  value={formatAmountInDollar(position?.accruedPositionFee, 30, 2, true)}
                                  showDollar={false}
                                />
                              </>
                            )}
                          </>
                        );
                      }}
                    />
                  </div>
                  {position?.pnlAfterFee && (
                    <div
                      className={cx("fz-12", {
                        positive: position?.pnlAfterFee?.gt(0),
                        negative: position?.pnlAfterFee.lt(0),
                        muted: position?.pnlAfterFee.eq(0),
                      })}
                    >
                      <span>{formatAmountInDollar(position?.pnlAfterFee, 30, 2, true)}</span>

                      <span
                        style={{ marginLeft: "4px" }}
                        className={cx("fz-12", {
                          positive: position?.pnlAfterFee?.gt(0),
                          negative: position?.pnlAfterFee.lt(0),
                          muted: position?.pnlAfterFee.eq(0),
                        })}
                      >
                        ({position.prefix}
                        {formatAmount(parseValue(position?.roi, 30), 30, 2, true).replace("-", "")}
                        %)
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div d-label="Pos. Size" className="col-body">
                <div className="col-body-content">
                  ${formatAmount(parseValue(position?.size, 30), 30, 2, true)}
                  <div className="muted fz-12">
                    {formatAmount(position.posQty, 30, 4, true)} {asset?.tokenSymbol || position.indexToken?.symbol}
                  </div>
                </div>
              </div>
              <div d-label="Collateral" className="col-body">
                {formatAmountInDollar(parseValue(position.collateral, 30), 30, 2, true)}
              </div>
              <div d-label="Liq. Risk" className="col-body">
                {position.isV2 && renderRisk(position.LiqRisk)}
                {!position.isV2 && renderRisk(position.LiqRisk.lte(0) ? BigNumber.from(0) : position.LiqRisk)}
              </div>
              <div d-label="Liq./Mark Price" className="col-body">
                <div className="col-body-content">
                  <div>
                    {/* ${formatAmount(parseValue(position.liquidPrice, 30), 30, asset?.decimals || 2, true)} */}

                    {parseValue(position.liquidPrice, 30)?.gt(parseValue(0.0001, 30)) ? (
                      formatAmountInDollar(parseValue(position.liquidPrice, 30), 30, asset?.decimals || 2, true, {
                        autoDisplayDecimals: true,
                      })
                    ) : (
                      <SmallTextNumber
                        prefix="$"
                        val={formatAmount(parseValue(position.liquidPrice, 30), 30, 30, false) || "--"}
                        decimal={4}
                      />
                    )}
                  </div>
                  <div className="muted fz-12">
                    {/* ${formatAmount(parseValue(position.marketPrice, 30), 30, asset?.decimals || 2, true)} */}

                    {parseValue(position.marketPrice, 30)?.gt(parseValue(0.0001, 30)) ? (
                      formatAmountInDollar(parseValue(position.marketPrice, 30), 30, asset?.decimals || 2, true, {
                        autoDisplayDecimals: true,
                      })
                    ) : (
                      <SmallTextNumber
                        prefix="$"
                        val={formatAmount(parseValue(position.marketPrice, 30), 30, 30, false) || "--"}
                        decimal={4}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div d-label="Avg. Entry Price" className="col-body">
                {/* ${formatAmount(parseValue(position.averagePrice, 30), 30, asset?.decimals || 2, true)} */}

                {parseValue(position.averagePrice, 30)?.gt(parseValue(0.0001, 30)) ? (
                  formatAmountInDollar(parseValue(position.averagePrice, 30), 30, asset?.decimals || 2, true, {
                    autoDisplayDecimals: true,
                  })
                ) : (
                  <SmallTextNumber
                    prefix="$"
                    val={formatAmount(parseValue(position.averagePrice, 30), 30, 30, false) || "--"}
                    decimal={4}
                  />
                )}
              </div>
              {/* <div d-label="Created" className="col-body">
                <div className="col-body-content">
                  <DateAndTime unix={position.openTime} />
                </div>
              </div> */}

              <img className="icon-view" src={icView} alt="view_ic" />
            </TableRow>
          );
        })}
      </div>
    </DataTable>
  );
};

export default OpenPositions;
