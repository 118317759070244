import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";

import {
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
} from "lib/legacy";

import Vault from "abis/Vault.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import GlpManager from "abis/GlpManager.json";
import useVaultGmyContract from "hooks/contracts/useVaultGmyContract";

import { useGmyPrice } from "domain/legacy";

import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";

const useProcessedData = (chainId) => {
  let { active } = useWeb3React();

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const gmyAddress = getContract(chainId, "GMY");
  const esGmyAddress = getContract(chainId, "ES_GMY");
  const bnGmyAddress = getContract(chainId, "BN_GMY");
  const glpAddress = getContract(chainId, "GLP");
  const gmAddress = getContract(chainId, "GM");
  const usdgAddress = getContract(chainId, "USDG");

  const stakedGmyTrackerAddress = getContract(chainId, "StakedGmyTracker");
  const bonusGmyTrackerAddress = getContract(chainId, "BonusGmyTracker");
  const feeGmyTrackerAddress = getContract(chainId, "FeeGmyTracker");

  const stakedGlpTrackerAddress = getContract(chainId, "StakedGlpTracker");
  const feeGlpTrackerAddress = getContract(chainId, "FeeGlpTracker");

  const stakedGmTrackerAddress = getContract(chainId, "StakedGmTracker");
  const feeGmTrackerAddress = getContract(chainId, "FeeGmTracker");

  const glpManagerAddress = getContract(chainId, "GlpManager");

  const gmyVesterAddress = getContract(chainId, "GmyVester");
  const glpVesterAddress = getContract(chainId, "GlpVester");
  const gmVesterAddress = getContract(chainId, "GmVester");

  const vesterAddresses = [gmyVesterAddress, glpVesterAddress, gmVesterAddress];

  const walletTokens = [gmyAddress, esGmyAddress, glpAddress, stakedGmyTrackerAddress, gmAddress];
  const depositTokens = [
    gmyAddress,
    esGmyAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    bnGmyAddress,
    glpAddress,
    gmAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedGmyTrackerAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGlpTrackerAddress,
    feeGmTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    stakedGlpTrackerAddress,
    feeGlpTrackerAddress,
    stakedGmTrackerAddress,
    feeGmTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [`StakeV2:walletBalances:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );

  const { data: depositBalances } = useSWR(
    [`StakeV2:depositBalances:${active}`, chainId, rewardReaderAddress, "getDepositBalances", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 10000,
    }
  );

  const { data: stakedGmySupply } = useSWR(
    [`StakeV2:stakedGmySupply:${active}`, chainId, gmyAddress, "balanceOf", stakedGmyTrackerAddress],
    {
      fetcher: contractFetcher(undefined, Token),
      refreshInterval: 10000,
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(undefined, GlpManager),
    refreshInterval: 10000,
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(undefined, Vault),
      refreshInterval: 10000,
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );
  const tokensForSupplyQuery = [gmyAddress, glpAddress, usdgAddress, gmAddress];

  const { data: totalSupplies } = useSWR(
    [`StakeV2:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 10000,
    }
  );
  const { gmyPrice } = useGmyPrice(chainId, {}, active);

  let gmySupply;
  if (totalSupplies && totalSupplies[1]) {
    gmySupply = totalSupplies[1];
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);
  const { gmPrice } = useVaultGmyContract(chainId);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedGmySupply,
    gmyPrice,
    gmySupply,
    gmPrice
  );

  return processedData;
};

export default useProcessedData;
