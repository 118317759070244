import { getTokenSymbolFromString } from "domain/tokens";
import { BigNumber } from "ethers";
import { importImage } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import cx from "classnames";
import shareIcon from "img/trade/share.svg";
import moment from "moment";
function secondsToHMS(secs) {
  function z(n) {
    return (n < 10 ? "0" : "") + n;
  }
  var sign = secs < 0 ? "-" : "";
  secs = Math.abs(secs);
  return sign + z((secs / 3600) | 0) + "h:" + z(((secs % 3600) / 60) | 0) + "m:" + z(secs % 60) + "s";
}
const ClosedPositionItemMobileV2 = (props) => {
  const {
    position,
    marketAsssets,
    marketTokensInfo,
    setPositionToShare,
    setIsPositionShareModalOpen,
    handleSelectedPosition,
  } = props;
  const symbol = marketAsssets?.[position.tokenId]?.symbol;
  const leverage = BigNumber.from(position?.totalSize || 0)
    .mul(parseValue("1", 30))
    .div(BigNumber.from(position?.totalCollateral || 0));
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const duration = secondsToHMS(position.closedAt - position.createdAt);
  const roiPrefix = position.totalROI.includes("-") ? "-" : "+";
  const rPnLPrifix = position.realisedPnl.includes("-") ? "-" : "+";
  const isLoss = position.realisedPnl.includes("-");
  const pnlPrefix = position.realisedPnl.includes("-") ? "-" : "+";
  const posQty = BigNumber.from(position?.totalSize || 0)
    .mul(parseValue("1", 30))
    .div(BigNumber.from(position?.averagePrice || 0));

  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
  const completePosition = {
    // positionNetValue,
    ...position,
    duration,
    paidFees: BigNumber.from(position.positionFee || 0)
      .add(BigNumber.from(position.fundingFee || 0))
      .add(BigNumber.from(position.borrowFee || 0)),
    positionNetValue: {
      prefix: roiPrefix,
      percentPnl: parseValue(limitDecimals(Number(position.totalROI) / 1000, 2).replace("-", ""), 30),
      pnl: position.realisedPnl,
    },
    rPnLPrifix,
    leverage: formatAmount(leverage, 30, 2, true),
    symbol: getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol),
    quantity: posQty,
    currentMarket,
  };
  return (
    <div key={position.index} className="App-card">
      <div className="App-card-title">
        <span className="Exchange-list-title">
          <img src={importImage("ic_" + getTokenSymbolFromString(symbol)?.toLowerCase() + "_24.svg")} alt="tk" />
          {symbol} <span className="muted">#{position.posId}</span>
        </span>
      </div>
      <div className="App-card-divider" />
      <div className="App-card-content app-card-position">
        <div className="leverage-pad">
          <div
            className={cx("Exchange-list-side", {
              positive: position.isLong,
              negative: !position.isLong,
            })}
          >
            {position.isLong ? "LONG" : "SHORT"}
          </div>
          {leverage && (
            <span
              className="leverage-box"
              style={{
                color: "#FFC700",
                backgroundColor: "rgba(255, 199, 0, 0.20)",
                height: 16,
                display: "inline-block",
                opacity: 1,
                borderRadius: "100px",
                padding: "0 4px",
                fontSize: "12px",
                lineHeight: "15.6px",
                fontWeight: 700,
                marginLeft: "4px",
              }}
            >
              {formatAmount(leverage, 30, 2, true)}X
            </span>
          )}
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Opened</span>
          </div>
          <div>
            <div>{moment(new Date(position.createdAt * 1000)).format(dateFormat)}</div>
            <div className="muted fz-12 bold">{moment(new Date(position.createdAt * 1000)).format(timeFormat)}</div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Closed</span>
          </div>
          <div>
            <div>{moment(new Date(position.closedAt * 1000)).format(dateFormat)}</div>
            <div className="muted fz-12 bold">{moment(new Date(position.closedAt * 1000)).format(timeFormat)}</div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Duration</span>
          </div>
          <div>{duration}</div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>PnL/ROI</span>
          </div>
          <div>
            <div
              className={`${!isLoss ? "isProfit" : "isLoss"} ${!isLoss ? "positive" : "negative"}`}
              style={{
                color: position.realisedPnl.includes("-") ? "#F6475D" : "#C1FF99 ",
              }}
            >
              {pnlPrefix}${formatAmount(position.realisedPnl, 30, 2, true).replace("-", "")}
            </div>
            <div className="muted fz-12 bold">
              {roiPrefix}
              {limitDecimals(Number(position.totalROI) / 1000, 2).replace("-", "")}%
            </div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Avg. Entry Price</span>
          </div>
          <div>${formatAmount(position.averagePrice, 30, currentMarket?.decimals, true)}</div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Close Price</span>
          </div>
          <div>${formatAmount(position.markPrice, 30, currentMarket?.decimals, true)}</div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Pos. Size</span>
          </div>
          <div>
            <div>${formatAmount(position.totalSize, 30, 2, true)}</div>
            <div className="muted muted fz-12 bold">
              {" "}
              {formatAmount(posQty, 30, 4, true)} {getTokenSymbolFromString(symbol)}
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "1.3rem" }} />
      <div className="App-card-options">
        <button
          className="App-button-option App-card-option"
          onClick={() => handleSelectedPosition(completePosition)}
          // disabled={position.size == 0}
        >
          <span>View</span>
        </button>
        <button
          className="PositionDropdown-dots-icon"
          onClick={(e) => {
            e.stopPropagation();
            setPositionToShare(completePosition);
            setIsPositionShareModalOpen(true);
          }}
        >
          <img src={shareIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
          Share
        </button>
      </div>
    </div>
  );
};
export default ClosedPositionItemMobileV2;
