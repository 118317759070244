import { useEffect, useRef, useState } from "react";
import {
  getClosedPositions,
  getOpenPositions,
  getOrders,
  getTradeHistories,
} from "../pages/AnalyticsYummy/helper/request";

const requestByKey = {
  openPositions: getOpenPositions,
  closedPositions: getClosedPositions,
  orders: getOrders,
  tradeHistories: getTradeHistories,
};

function useTraderActions(chainId, account, pagination, timestamp) {
  const [data, setData] = useState({
    openPositions: {},
    closedPositions: {},
    orders: {},
    tradeHistories: {},
  });
  const [isLoading, setIsLoading] = useState(true);
  const tabDispatched = useRef(null);

  //Fetch all tabs on first mount
  useEffect(() => {
    async function getActions() {
      //on first mount fetch all data
      if (pagination.lastDispatch === null && !tabDispatched.current) {
        const [openPositions, closedPositions, orders, tradeHistories] = await Promise.all([
          getOpenPositions(chainId, account, pagination["openPositions"]),
          getClosedPositions(chainId, account, pagination["closedPositions"]),
          getOrders(chainId, account, pagination["orders"]),
          getTradeHistories(chainId, account, pagination["tradeHistories"]),
        ]);

        setData({
          openPositions,
          closedPositions,
          orders,
          tradeHistories,
        });
        setIsLoading(false);
        tabDispatched.current = "openPositions";

        //on change page & pageSize fetch ONLY THAT TAB DATA
      } else {
        const dispatched = pagination.lastDispatch || tabDispatched.current;
        const data = await requestByKey[dispatched](chainId, account, pagination[dispatched], timestamp[dispatched]);
        setData((prev) => ({
          ...prev,
          [dispatched]: data,
        }));
        setIsLoading(false);
      }
    }

    getActions();
    const timerId = setInterval(getActions, 30000);

    return () => clearInterval(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId, pagination, timestamp.forceCall]);

  return { data, isLoading };
}

export default useTraderActions;
