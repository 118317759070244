import { getTokenSymbol } from "config/tokens";
import { importImage } from "lib/legacy";
import cx from "classnames";
import moment from "moment";
import { formatAmount, parseValue } from "lib/numbers";
import ExternalLink from "components/ExternalLink/ExternalLink";
import ic_new_tab from "img/trade/new_tab.svg";
import { getExplorerUrl } from "config/chains";
const TradeActivityItemV1 = ({ position, chainId, handleSelectedPosition }) => {
  const indexToken = position.indexToken.symbol;
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const txUrl = getExplorerUrl(chainId) + "tx/" + position.txhash;
  const posQty = parseValue(position.size, 30).mul(parseValue(1, 30)).div(parseValue(position.entryPrice, 30));
  // console.log("position", position);
  return (
    <tr
      className="table-content clickable"
      onClick={(e) => {
        handleSelectedPosition(position);
      }}
    >
      <td className="clickable">
        <div>
          <img src={importImage("ic_" + indexToken?.toLowerCase() + "_24.svg")} alt="tk" />{" "}
          <div className="tk_info">
            <div className="Exchange-list-title">{indexToken}/USD <span className="muted">#{position.posId}</span></div>
            <div className="Exchange-list-info-label">
              <span
                className={cx({ bold: true, positive: position.isLong, negative: !position.isLong })}
                style={{ color: position.isLong ? "#C1FF99" : "#F6475D" }}
              >
                {position.isLong ? `LONG` : `SHORT`}
              </span>
              {/* {position.leverage ? (
                <span
                  className=" leverage-box"
                  style={{
                    color: "#FFC700",
                    backgroundColor: "rgba(255, 199, 0, 0.20)",
                    height: 16,
                    display: "inline-block",
                    opacity: 1,
                    borderRadius: "4px",
                    padding: "0 4px",
                    fontSize: "12px",
                    lineHeight: "15.6px",
                    fontWeight: 700,
                    marginLeft: "4px",
                  }}
                >
                  {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X
                </span>
              ) : null} */}
            </div>
          </div>
        </div>
        {/* <div className={`flag ${position.isLong ? "isLong" : "isShort"}`}></div> */}
      </td>
      <td>
        <div>{moment(new Date(position.timestamp * 1000)).format(dateFormat)}</div>
        <div className="muted fz-12 bold">{moment(new Date(position.timestamp * 1000)).format(timeFormat)}</div>
      </td>
      <td>{position.positionType}</td>
      <td>
        <span>
          {position.action === "CLOSE" || position.action === "DECREASE"
            ? "Decreased position"
            : position.action === "OPEN" || position.action === "INCREASE"
            ? "Increased position"
            : position.action === "DEPOSIT"
            ? "Deposited"
            : position.action === "WITHDRAW"
            ? "Withdrew"
            : "Liquidated"}{" "}
          <span className="muted">
            (Price ${formatAmount(parseValue(position.price || position.markPrice, 30), 30, 2, true)})
          </span>
        </span>
      </td>
      <td>
        {position.action !== "DEPOSIT" && position.action !== "WITHDRAW" ? (
          <>
            <div> ${formatAmount(parseValue(position.size, 30), 30, 2, true)}</div>
            <div className="muted fz-12 bold">
              {formatAmount(posQty, 30, 4, true)} {indexToken}
            </div>
          </>
        ) : null}
      </td>
      <td>
        <ExternalLink
          href={txUrl}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img style={{ width: 20, height: 20 }} src={ic_new_tab} alt="ic_new_tab" />
        </ExternalLink>
      </td>
    </tr>
  );
};
export default TradeActivityItemV1;
