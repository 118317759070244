import { BERACHAIN_BARTIO, FANTOM, OP } from "config/chains";
import useMspPrice from "./useMspPrice";
import useInfoGm from "./useInfoGm";

function useMspPriceMultiChain() {
  return {
    [BERACHAIN_BARTIO]: useInfoGm(BERACHAIN_BARTIO).price,
    // [OP]: useInfoGm(OP).price,
  };
}

export default useMspPriceMultiChain;
