import React from "react";

function Icon() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5612 7.50016H4.89453C4.43429 7.50016 4.0612 7.87326 4.0612 8.3335V15.8335C4.0612 16.2937 4.43429 16.6668 4.89453 16.6668H16.5612C17.0214 16.6668 17.3945 16.2937 17.3945 15.8335V8.3335C17.3945 7.87326 17.0214 7.50016 16.5612 7.50016ZM4.89453 5.8335C3.51382 5.8335 2.39453 6.95278 2.39453 8.3335V15.8335C2.39453 17.2142 3.51382 18.3335 4.89453 18.3335H16.5612C17.9419 18.3335 19.0612 17.2142 19.0612 15.8335V8.3335C19.0612 6.95278 17.9419 5.8335 16.5612 5.8335H4.89453Z"
        fill="#FFA322"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.64518 5.83317C9.33554 5.83317 9.89518 5.27353 9.89518 4.58317C9.89518 3.89281 9.33554 3.33317 8.64518 3.33317C7.95483 3.33317 7.39518 3.89281 7.39518 4.58317C7.39518 5.27353 7.95483 5.83317 8.64518 5.83317ZM8.64518 7.49984C10.256 7.49984 11.5618 6.194 11.5618 4.58317C11.5618 2.97234 10.256 1.6665 8.64518 1.6665C7.03435 1.6665 5.72852 2.97234 5.72852 4.58317C5.72852 6.194 7.03435 7.49984 8.64518 7.49984Z"
        fill="#FFA322"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8112 5.00016C11.9377 5.00016 11.5612 5.53717 11.5612 5.8335H9.89453C9.89453 4.28887 11.3835 3.3335 12.8112 3.3335C14.2389 3.3335 15.7279 4.28887 15.7279 5.8335H14.0612C14.0612 5.53717 13.6847 5.00016 12.8112 5.00016Z"
        fill="#FFA322"
      />
      <circle cx="14.4785" cy="12.0835" r="1.25" fill="#FFA322" />
    </svg>
  );
}

export default Icon;
