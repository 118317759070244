import React, { useState } from "react";
import cx from "classnames";
import { USD_DECIMALS, SHORT, importImage } from "lib/legacy";
import { formatAmount, parseValue } from "lib/numbers";
import { BigNumber } from "ethers";
import moment from "moment";
import useUserTradeActivity, { DEFAULT_PAGE_SIZE_TRADES } from "hooks/useUserTradeActivity";
import TradeActivity from "./TradeActivity";
import PaginationV2 from "pages/Analytics/components/PaginationV2";
import ic_new_tab from "img/trade/new_tab.svg";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getExplorerUrl } from "config/chains";
import { getTokenSymbolFromString } from "domain/tokens";

const TYPE_MAP_TYPE = {
  EDIT_COLLATERAL: "Edit Collateral",
  CLOSE_POSITION: "Close",
  DECREASE_POSITION: "Decrease",
  ADD_POSITION: "Increase",
  OPEN_POSITION: "New",
  LIQUIDATE_POSITION: "Liquidated",
  ADD_TRAILING_STOP: "Decrease/Close",
};

function secondsToHMS(secs) {
  function z(n) {
    return (n < 10 ? "" : "") + n;
  }
  var sign = secs < 0 ? "-" : "";
  secs = Math.abs(secs);
  return sign + z((secs / 3600) | 0) + "h:" + z(((secs % 3600) / 60) | 0) + "m:" + z(secs % 60) + "s";
}

export function titleCase(str) {
  str = str.replace("_", " ");
  var convertToArray = str.toLowerCase().split(" ");
  var result = convertToArray.map(function (val) {
    return val.replace(val.charAt(0), val.charAt(0).toUpperCase());
  });

  return result.join(" ");
}
export default function TradeActivityList(props) {
  const { marketAsssets, account, chainId } = props;

  const { positions: myTrades, count, currentPage, setCurrenPage, isLoading } = useUserTradeActivity(account);

  const [isTradeActivityVisible, setIsTradeActivityVisible] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState();

  const handleSelectedPosition = (position) => {
    setSelectedPosition(position);
    setIsTradeActivityVisible(true);
  };

  return (
    <div className="PositionsList">
      {myTrades && (
        <div className="Exchange-list small">
          <div>
            {/* {isLoading && (
              <div className="Exchange-empty-positions-list-note App-card">
                <div className="skeleton-box" style={{ height: "25px" }} />
              </div>
            )} */}
            {!isLoading && myTrades.length === 0 && <div className="no-data-record">No trade activities</div>}
            {myTrades.map((position, index) => {
              const symbol = marketAsssets?.[position.tokenId]?.symbol;
              const leverage = BigNumber.from(position?.tradeVolume || 0)
                .mul(parseValue("1", 30))
                .div(BigNumber.from(position?.collateral || 0));
              const dateFormat = "YYYY-MM-DD";
              const timeFormat = "HH:mm:ss";
              const posQty = parseValue(position.tradeVolume, 30)
                .mul(parseValue(1, 30))
                .div(parseValue(position.averagePrice, 30));

              const completePosition = {
                ...position,
                leverage,
                symbol,
                quantity: posQty,
              };
              const txUrl = getExplorerUrl(chainId) + "tx/" + position.transactionHash;
              return (
                <div key={index} className="App-card">
                  <div className="App-card-title">
                    <span className="Exchange-list-title">
                      <img
                        src={importImage("ic_" + getTokenSymbolFromString(symbol)?.toLowerCase() + "_24.svg")}
                        alt=""
                      />{" "}
                      {symbol} <span className="muted">#{position.posId}</span>
                    </span>
                  </div>
                  <div className="App-card-divider" />
                  <div className="App-card-content app-card-position">
                    <div className="leverage-pad">
                      <div
                        className={cx("Exchange-list-side", {
                          positive: position.isLong,
                          negative: !position.isLong,
                        })}
                      >
                        {position.isLong ? "LONG" : "SHORT"}
                      </div>
                      {leverage && (
                        <span
                          className=" leverage-box"
                          style={{
                            color: "#FFC700",
                            backgroundColor: "rgba(255, 199, 0, 0.20)",
                            height: 16,
                            display: "inline-block",
                            opacity: 1,
                            borderRadius: "100px",
                            padding: "0 4px",
                            fontSize: "12px",
                            lineHeight: "15.6px",
                            fontWeight: 700,
                            marginLeft: "4px",
                          }}
                        >
                          {formatAmount(leverage, 30, 2, true)}X
                        </span>
                      )}
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <span>Date/Time</span>
                      </div>
                      <div>
                        <div>{moment(new Date(position.createdAt * 1000)).format(dateFormat)}</div>
                        <div className="muted fz-12">
                          {moment(new Date(position.createdAt * 1000)).format(timeFormat)}
                        </div>
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <span>Action</span>
                      </div>
                      <div className="position-list-collateral">
                        {/* {titleCase(position.actionType.replace("_", " "))} */}
                        {position.actionType === "ADD_TRAILING_STOP"
                          ? position.trailingStopAll
                            ? "Close"
                            : "Decrease"
                          : TYPE_MAP_TYPE[position.actionType]}
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <span>Type</span>
                      </div>
                      <div>
                        {/* {position.actionType === "CLOSE_POSITION" ? "Close" : "Open"} */}
                        {position.positionType}
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <span>Pos.Size</span>
                      </div>
                      <div>
                        <div> ${formatAmount(position.tradeVolume, 30, 2, true)}</div>
                        <div className="muted fz-12">
                          {formatAmount(posQty, 30, 4, true)} {getTokenSymbolFromString(symbol)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "1.3rem" }} />
                  <div className="App-card-options">
                    <button
                      className="App-button-option App-card-option"
                      onClick={() => handleSelectedPosition(completePosition)}
                      // disabled={position.size == 0}
                    >
                      <span>View</span>
                    </button>
                    <button
                      style={{ width: 40, display: "flex", justifyContent: "center", alignItems: "center" }}
                      className="PositionDropdown-dots-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <ExternalLink href={txUrl} className="tx">
                        <img style={{ width: 16 }} src={ic_new_tab} alt="ic_new_tab" />
                      </ExternalLink>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {isLoading && (
        <>
          <div className="skeleton-box" style={{ height: "56.19px" }} />
          <div className="skeleton-box" style={{ height: "56.19px", marginTop: 8 }} />
          <div className="skeleton-box" style={{ height: "56.19px", marginTop: 8 }} />
        </>
      )}
      {!isLoading && (
        <table className={`Exchange-list large App-box ${myTrades?.length === 0 ? "no-array-length" : ""}`}>
          <tbody>
            {myTrades && myTrades.length > 0 && (
              <tr className="Exchange-list-header">
                <th>
                  <span>Position</span>
                </th>
                <th>
                  <span>Date/Time</span>
                </th>
                <th>
                  <span>Type</span>
                </th>
                <th>
                  <span>Action</span>
                </th>
                <th>
                  <span>Pos.Size</span>
                </th>
                <th></th>
              </tr>
            )}

            {!isLoading && myTrades.length === 0 && (
              <tr className="no-position">
                <td colSpan="15">
                  <div className="no-data-record">No trade activities</div>
                </td>
              </tr>
            )}
            {myTrades &&
              myTrades.length > 0 &&
              myTrades.map((position, index) => {
                const symbol = marketAsssets?.[position.tokenId]?.symbol;
                const leverage = BigNumber.from(position?.tradeVolume || 0)
                  .mul(parseValue("1", 30))
                  .div(BigNumber.from(position?.collateral || 0));
                const dateFormat = "YYYY-MM-DD";
                const timeFormat = "HH:mm:ss";
                const posQty = parseValue(position.tradeVolume, 30)
                  .mul(parseValue(1, 30))
                  .div(parseValue(position.averagePrice, 30));

                const completePosition = {
                  ...position,
                  leverage,
                  symbol,
                  quantity: posQty,
                };

                const txUrl = getExplorerUrl(chainId) + "tx/" + position.transactionHash;
                return (
                  <tr
                    key={index}
                    className="table-content clickable"
                    onClick={() => handleSelectedPosition(completePosition)}
                  >
                    <td className="clickable">
                      <div>
                        <img
                          src={importImage("ic_" + getTokenSymbolFromString(symbol)?.toLowerCase() + "_24.svg")}
                          alt="tk"
                        />{" "}
                        <div className="tk_info">
                          <div className="Exchange-list-title">
                            {symbol} <span className="muted">#{position.posId}</span>
                          </div>
                          <div className="Exchange-list-info-label">
                            <span
                              className={cx({ bold: true, positive: position.isLong, negative: !position.isLong })}
                              style={{ color: position.isLong ? "#176CE5" : "#F6475D" }}
                            >
                              {position.isLong ? `LONG` : `SHORT`}
                            </span>
                            {/* {leverage && (
                              <span
                                className=" leverage-box"
                                style={{
                                  color: "#FFC700",
                                  backgroundColor: "rgba(255, 199, 0, 0.20)",
                                  height: 16,
                                  display: "inline-block",
                                  opacity: 1,
                                  borderRadius: "100px",
                                  padding: "0 4px",
                                  fontSize: "12px",
                                  lineHeight: "15.6px",
                                  fontWeight: 700,
                                  marginLeft: "4px",
                                }}
                              >
                                {formatAmount(leverage, 30, 2, true)}X
                              </span>
                            )} */}
                          </div>
                        </div>
                      </div>
                      {/* <div className={`flag ${position.isLong ? "isLong" : "isShort"}`}></div> */}
                    </td>
                    <td>
                      <div>{moment(new Date(position.createdAt * 1000)).format(dateFormat)}</div>
                      <div className="muted fz-12 bold">
                        {moment(new Date(position.createdAt * 1000)).format(timeFormat)}
                      </div>
                    </td>
                    <td>
                      {/* {position.isTrigger
                      ? BigNumber.from(position.profitLoss).gt(0)
                        ? "Take Profit"
                        : "Stop Loss"
                      : position.positionType} */}
                      {position.positionType}
                    </td>
                    <td>
                      <div className="">
                        {position.actionType === "ADD_TRAILING_STOP"
                          ? position.trailingStopAll
                            ? "Close"
                            : "Decrease"
                          : TYPE_MAP_TYPE[position.actionType]}
                      </div>
                    </td>
                    <td>
                      <div> ${formatAmount(position.tradeVolume, 30, 2, true)}</div>
                      <div className="muted fz-12 bold">
                        {formatAmount(posQty, 30, 4, true)} {getTokenSymbolFromString(symbol)}
                      </div>
                    </td>
                    <td>
                      <button
                        style={{ marginLeft: "auto", display: "block" }}
                        className="PositionDropdown-dots-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <ExternalLink href={txUrl}>
                          <img style={{ width: 16 }} src={ic_new_tab} alt="ic_new_tab" />
                        </ExternalLink>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {isTradeActivityVisible && (
        <TradeActivity
          isVisible={isTradeActivityVisible}
          setIsVisible={setIsTradeActivityVisible}
          position={selectedPosition}
          chainId={chainId}
        />
      )}
      {count > DEFAULT_PAGE_SIZE_TRADES && (
        <PaginationV2
          rowsPerPage={DEFAULT_PAGE_SIZE_TRADES}
          rows={count}
          currentPage={currentPage}
          setCurrentPage={setCurrenPage}
        />
      )}
    </div>
  );
}
