import cx from "classnames";
import {
  BASIS_POINTS_DIVISOR,
  DUST_BNB,
  LEVERAGE_MARKS,
  MARGIN_FEE_BASIS_POINTS,
  MARKET,
  MAX_ALLOWED_LEVERAGE,
  USDG_ADDRESS,
  USDG_DECIMALS,
  USD_DECIMALS,
  adjustForDecimals,
  calculatePositionDelta,
  getLeverage,
  getLiquidationPrice,
  getNextToAmount,
  getPositionKey,
  importImage,
} from "lib/legacy";

import Modal from "../Modal/Modal";

import PositionRouter from "abis/PositionRouter.json";
import "../ExchangeV2/PositionSeller.css";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  formatNumber,
  getDisplayDecimalByAmount,
  parseValue,
} from "lib/numbers";
import { preventSpecialCharacters } from "utils/helpers";
import Tooltip from "components/Tooltip/Tooltip";
import GreenArraowIcon from "components/Icon/GreenArraowIcon";
import { useThemeContext } from "contexts/ThemeProvider";
import styled from "styled-components";
import { useState } from "react";
import ExchangeInfoRow from "components/ExchangeV2/ExchangeInfoRow";
import PercentageButtons from "components/ExchangeV2/PercentageButtons";
import TokenSelector from "components/Exchange/TokenSelector";
import { getToken, getTokens } from "config/tokens";
import { getTokenInfo, getUsd, replaceNativeTokenAddress, shouldRaiseGasError } from "domain/tokens";
import { getNextAveragePrice } from "components/Exchange/SwapBox";
import { callContract } from "lib/contracts";
import { getContract } from "config/contracts";
import { BigNumber, ethers } from "ethers";
import { getConstant } from "config/chains";
import { helperToast } from "lib/helperToast";
import { useMinExecutionFee } from "domain/legacy";
import { useUserReferralCode } from "domain/referrals";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import Slider, { SliderTooltip } from "rc-slider";
import TokenTopInfo from "components/Exchange/TokenTopInfo";
const leverageSliderHandle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${parseFloat(value).toFixed(2)}x`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Slider.Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};
export default function AddToPositionV1(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    library,
    isConfirm,
    positionsMap,
    positionKey,
    infoTokens,
    usdgSupply,
    totalTokenWeights,
    savedSlippageAmount,
    account,
    nativeTokenAddress,
    setPendingTxns,
    pendingPositions,
    setPendingPositions,
    savedIsPnlInLeverage,
    nativeTokenInfo,
    selectedPosition,
  } = props;
  let swapFees;
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const INCREASE_ORDER_EXECUTION_GAS_FEE = getConstant(chainId, "INCREASE_ORDER_EXECUTION_GAS_FEE");
  const executionFee = INCREASE_ORDER_EXECUTION_GAS_FEE;
  const [fromValue, setFromValue] = useState("");
  const [limitPrice, setLimitPrice] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const position = positionsMap && positionKey ? positionsMap[positionKey] : undefined;
  const [fromTokenAddress, setFromTokenAddress] = useState(position?.indexToken?.address);
  const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);
  const toTokenInfo = getTokenInfo(infoTokens, position.indexToken.address);
  const fromToken = getToken(chainId, fromTokenAddress);
  const fromBalance = fromTokenInfo ? fromTokenInfo.balance : bigNumberify(0);
  const toToken = position?.indexToken;
  const theme = useThemeContext();
  const tokens = getTokens(chainId);
  const triggerPriceUsd = limitPrice ? parseValue(limitPrice, USD_DECIMALS) : BigNumber.from(0);
  const [leverageOption, setLeverageOption] = useState(Number(formatAmount(position.leverage, 4, 2, false)));
  const isMarketOrder = !limitPrice && !triggerPriceUsd.gt(0);

  const fromTokens = tokens;

  const posQty = position?.averagePrice
    ? position.size.mul(parseValue(1, USD_DECIMALS)).div(position?.averagePrice)
    : 0;
  let entryMarkPrice = position.isLong ? toTokenInfo.maxPrice : toTokenInfo.minPrice;
  const fromAmount = parseValue(fromValue, fromToken && fromToken.decimals);
  const onSelectFromToken = (token) => {
    setFromTokenAddress(token.address);
  };
  const { AddressZero } = ethers.constants;
  const handleSelectPercentage = (percentage) => {
    if (!fromBalance || fromBalance.eq(0)) {
      return;
    }
    const temptValue = fromBalance.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
    setFromValue(formatAmount(temptValue, fromTokenInfo.decimals, 6, false));
  };

  let toValue = 0;
  let leverage = bigNumberify(0);
  const fromUsdMin = getUsd(fromAmount, fromTokenAddress, false, infoTokens);
  if (toTokenInfo && toTokenInfo.maxPrice && fromUsdMin && fromUsdMin.gt(0)) {
    const leverageMultiplier = parseInt(Number(leverageOption) * BASIS_POINTS_DIVISOR);
    const toTokenPriceUsd =
      !isMarketOrder && triggerPriceUsd && triggerPriceUsd.gt(0) ? triggerPriceUsd : toTokenInfo.maxPrice;

    const { feeBasisPoints } = getNextToAmount(
      chainId,
      fromAmount,
      fromTokenAddress,
      position.collateralToken.address,
      infoTokens,
      undefined,
      undefined,
      usdgSupply,
      totalTokenWeights,
      false
    );

    let fromUsdMinAfterFee = fromUsdMin;
    if (feeBasisPoints) {
      swapFees = fromUsdMin.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR);
      fromUsdMinAfterFee = fromUsdMin.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR);
    }

    const toNumerator = fromUsdMinAfterFee.mul(leverageMultiplier).mul(BASIS_POINTS_DIVISOR);
    const toDenominator = bigNumberify(MARGIN_FEE_BASIS_POINTS)
      .mul(leverageMultiplier)
      .add(bigNumberify(BASIS_POINTS_DIVISOR).mul(BASIS_POINTS_DIVISOR));

    const nextToUsd = toNumerator.div(toDenominator);

    const nextToAmount = nextToUsd.mul(expandDecimals(1, toToken.decimals)).div(toTokenPriceUsd);

    const nextToValue = formatAmountFree(nextToAmount, toToken.decimals, toToken.decimals);

    toValue = nextToValue;
  }

  const toAmount = parseValue(toValue, toToken && toToken.decimals);
  const toUsdMax = getUsd(toAmount, position.indexToken.address, true, infoTokens, MARKET, triggerPriceUsd);
  let nextAveragePrice = isMarketOrder ? entryMarkPrice : triggerPriceUsd;

  let nextDelta, nextHasProfit;

  if (isMarketOrder) {
    nextDelta = position.delta;
    nextHasProfit = position.hasProfit;
  } else {
    const data = calculatePositionDelta(triggerPriceUsd || bigNumberify(0), position);
    nextDelta = data.delta;
    nextHasProfit = data.hasProfit;
  }
  if (fromUsdMin && toUsdMax && fromUsdMin.gt(0)) {
    const fees = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
    if (fromUsdMin.sub(fees).gt(0)) {
      leverage = toUsdMax.mul(BASIS_POINTS_DIVISOR).div(fromUsdMin.sub(fees));
    }
  }
  nextAveragePrice = getNextAveragePrice({
    size: position.size,
    sizeDelta: toUsdMax,
    hasProfit: nextHasProfit,
    delta: nextDelta,
    nextPrice: isMarketOrder ? entryMarkPrice : triggerPriceUsd,
    isLong: position.isLong,
  });

  let feesUsd = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
  let collateralAfterFees = fromUsdMin;
  if (feesUsd && fromUsdMin) {
    collateralAfterFees = fromUsdMin.sub(feesUsd);
  }

  const nextCollateral =
    fromValue && collateralAfterFees ? collateralAfterFees.add(position.collateral) : position.collateral;
  const nextSize = fromValue ? position.size.add(toUsdMax) : position.size;

  const nextQty = fromValue ? nextSize.mul(parseValue(1, USD_DECIMALS)).div(nextAveragePrice) : position.averagePrice;

  const { feeBasisPoints } = getNextToAmount(
    chainId,
    fromAmount,
    fromTokenAddress,
    position.collateralToken.address,
    infoTokens,
    undefined,
    undefined,
    usdgSupply,
    totalTokenWeights,
    false
  );
  leverage = getLeverage({
    size: position.size,
    sizeDelta: toUsdMax ? toUsdMax : bigNumberify(0),
    collateral: position.collateral,
    collateralDelta: fromUsdMin ? fromUsdMin : bigNumberify(0),
    increaseCollateral: true,
    entryFundingRate: position.entryFundingRate,
    cumulativeFundingRate: position.cumulativeFundingRate,
    increaseSize: true,
    hasProfit: position.hasProfit,
    delta: position.delta,
    includeDelta: savedIsPnlInLeverage,
  });
  const liquidationPrice = getLiquidationPrice({
    isLong: position.isLong,
    size: position.size,
    collateral: position.collateral,
    averagePrice: nextAveragePrice,
    entryFundingRate: position.entryFundingRate,
    cumulativeFundingRate: position.cumulativeFundingRate,
    sizeDelta: toUsdMax,
    collateralDelta: fromUsdMin,
    increaseCollateral: true,
    increaseSize: true,
  });
  const existingLiquidationPrice = position ? getLiquidationPrice(position) : undefined;
  const { attachedOnChain, userReferralCode } = useUserReferralCode(library, chainId, account);
  if (feeBasisPoints) {
    const swapFees = fromUsdMin.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR);
    feesUsd = feesUsd.add(swapFees);
  }
  const renderPrimaryText = () => {
    if (!Number(fromValue)) return "Enter an amount";
    if (Number(formatAmount(fromBalance, fromToken.decimals, fromToken.decimals, false)) < fromValue)
      return "Amount exceeds balance";
    const errorPool = getErrorPool();
    if (errorPool) return errorPool[0];
    return isAdding ? "Increasing..." : "Increase position";
  };
  const getErrorPool = () => {
    if (position.isLong) {
      let requiredAmount = toAmount;
      if (fromTokenAddress !== position.indexToken.address) {
        const { amount: swapAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          position.indexToken.address,
          infoTokens,
          undefined,
          undefined,
          usdgSupply,
          totalTokenWeights,
          false
        );
        if (toToken && position.indexToken.address !== USDG_ADDRESS) {
          if (!toTokenInfo.availableAmount) {
            return [`Liquidity data not loaded`];
          }
          if (toTokenInfo.availableAmount && requiredAmount.gt(toTokenInfo.availableAmount)) {
            return [`Insufficient liquidity`];
          }
        }
        if (
          toTokenInfo.poolAmount &&
          toTokenInfo.bufferAmount &&
          toTokenInfo.bufferAmount.gt(toTokenInfo.poolAmount.sub(swapAmount))
        ) {
          return [`Insufficient liquidity`, true, "BUFFER"];
        }
        if (
          fromUsdMin &&
          fromTokenInfo.maxUsdgAmount &&
          fromTokenInfo.maxUsdgAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdgAmount
        ) {
          const usdgFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDG_DECIMALS);
          const nextUsdgAmount = fromTokenInfo.usdgAmount.add(usdgFromAmount);
          if (nextUsdgAmount.gt(fromTokenInfo.maxUsdgAmount)) {
            return [`${fromTokenInfo.symbol} pool exceeded, try different token`, true, "MAX_USDG"];
          }
        }
      }
      if (toTokenInfo && toTokenInfo.maxPrice) {
        const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals));
        if (
          toTokenInfo.maxGlobalLongSize &&
          toTokenInfo.maxGlobalLongSize.gt(0) &&
          toTokenInfo.maxAvailableLong &&
          sizeUsd.gt(toTokenInfo.maxAvailableLong)
        ) {
          return [`Max ${toTokenInfo.symbol} long exceeded`];
        }
      }
    }
    if (!position.isLong) {
      let stableTokenAmount = bigNumberify(0);
      const shortCollateralToken = getTokenInfo(infoTokens, position?.collateralToken?.address);
      if (fromTokenAddress !== position?.collateralToken?.address && fromAmount && fromAmount.gt(0)) {
        const { amount: nextToAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          position?.collateralToken?.address,
          infoTokens,
          undefined,
          undefined,
          usdgSupply,
          totalTokenWeights,
          false
        );

        stableTokenAmount = nextToAmount;
        if (stableTokenAmount.gt(shortCollateralToken.availableAmount)) {
          return [`Insufficient liquidity`];
        }
        if (
          shortCollateralToken.bufferAmount &&
          shortCollateralToken.poolAmount &&
          shortCollateralToken.bufferAmount.gt(shortCollateralToken.poolAmount.sub(stableTokenAmount))
        ) {
          // suggest swapping to collateralToken
          return [`Insufficient liquidity`, true, "BUFFER"];
        }
        if (
          fromTokenInfo.maxUsdgAmount &&
          fromTokenInfo.maxUsdgAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdgAmount
        ) {
          const usdgFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDG_DECIMALS);
          const nextUsdgAmount = fromTokenInfo.usdgAmount.add(usdgFromAmount);
          if (nextUsdgAmount.gt(fromTokenInfo.maxUsdgAmount)) {
            return [`${fromTokenInfo.symbol} pool exceeded, try different token`, true, "MAX_USDG"];
          }
        }
      }
      if (
        !shortCollateralToken ||
        !fromTokenInfo ||
        !toTokenInfo ||
        !toTokenInfo.maxPrice ||
        !shortCollateralToken.availableAmount
      ) {
        return [`Fetching token info...`];
      }
      const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals));
      const sizeTokens = sizeUsd
        .mul(expandDecimals(1, shortCollateralToken.decimals))
        .div(shortCollateralToken.minPrice);

      if (!toTokenInfo.maxAvailableShort) {
        return [`Liquidity data not loaded`];
      }

      if (
        toTokenInfo.maxGlobalShortSize &&
        toTokenInfo.maxGlobalShortSize.gt(0) &&
        toTokenInfo.maxAvailableShort &&
        sizeUsd.gt(toTokenInfo.maxAvailableShort)
      ) {
        return [`Max ${toTokenInfo.symbol} short exceeded`];
      }

      stableTokenAmount = stableTokenAmount.add(sizeTokens);
      if (stableTokenAmount.gt(shortCollateralToken.availableAmount)) {
        return [`Insufficient liquidity`];
      }
    }
    return null;
  };
  const isDisableButton = () => {
    if (!Number(fromValue) || fromUsdMin.lt(parseValue(0, 30))) return true;
    if (Number(formatAmount(fromBalance, fromToken.decimals, fromToken.decimals, false)) < fromValue) return true;
    if (
      triggerPriceUsd &&
      triggerPriceUsd.gt(0) &&
      position.markPrice &&
      position.isLong &&
      triggerPriceUsd.gt(position.markPrice)
    )
      return true;
    if (
      triggerPriceUsd &&
      triggerPriceUsd.gt(0) &&
      position.markPrice &&
      !position.isLong &&
      triggerPriceUsd.lt(position.markPrice)
    )
      return true;
    return getErrorPool();
  };
  const { minExecutionFee, minExecutionFeeUSD, minExecutionFeeErrorMessage } = useMinExecutionFee(
    library,
    true,
    chainId,
    infoTokens
  );
  let referralCode = ethers.constants.HashZero;
  if (!attachedOnChain && userReferralCode) {
    referralCode = userReferralCode;
  }
  const increasePosition = async () => {
    // setIsSubmitting(true);
    setIsAdding(true);
    const tokenAddress0 = fromTokenAddress === AddressZero ? nativeTokenAddress : fromTokenAddress;
    const indexTokenAddress =
      position.indexToken.address === AddressZero ? nativeTokenAddress : position.indexToken.address;
    let path = [indexTokenAddress]; // assume long
    if (position.indexToken.address !== fromTokenAddress) {
      path = [tokenAddress0, indexTokenAddress];
    }

    if (fromTokenAddress === AddressZero && position.indexToken.address === nativeTokenAddress) {
      path = [nativeTokenAddress];
    }

    if (fromTokenAddress === nativeTokenAddress && position.indexToken.address === AddressZero) {
      path = [nativeTokenAddress];
    }

    if (!position.isLong) {
      path = [position.collateralToken.address];
      if (tokenAddress0 !== position.collateralToken.address) {
        path = [tokenAddress0, position.collateralToken.address];
      }
    }

    const refPrice = position.isLong ? toTokenInfo.maxPrice : toTokenInfo.minPrice;
    const priceBasisPoints = position.isLong
      ? BASIS_POINTS_DIVISOR + savedSlippageAmount
      : BASIS_POINTS_DIVISOR - savedSlippageAmount;
    const priceLimit = refPrice.mul(priceBasisPoints).div(BASIS_POINTS_DIVISOR);

    const boundedFromAmount = fromAmount ? fromAmount : bigNumberify(0);

    if (fromAmount && fromAmount.gt(0) && fromTokenAddress === USDG_ADDRESS && position.isLong) {
      const { amount: nextToAmount, path: multiPath } = getNextToAmount(
        chainId,
        fromAmount,
        fromTokenAddress,
        indexTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdgSupply,
        totalTokenWeights,
        false
      );
      if (nextToAmount.eq(0)) {
        helperToast.error(`Insufficient liquidity`);
        return;
      }
      if (multiPath) {
        path = replaceNativeTokenAddress(multiPath);
      }
    }

    let params = [
      path, // _path
      indexTokenAddress, // _indexToken
      boundedFromAmount, // _amountIn
      0, // _minOut
      toUsdMax, // _sizeDelta
      position.isLong, // _isLong
      priceLimit, // _acceptablePrice
      minExecutionFee, // _executionFee
      referralCode, // _referralCode
      AddressZero, // _callbackTarget
    ];

    let method = "createIncreasePosition";
    let value = minExecutionFee;
    if (fromTokenAddress === AddressZero) {
      method = "createIncreasePositionETH";
      value = boundedFromAmount.add(minExecutionFee);
      params = [
        path, // _path
        indexTokenAddress, // _indexToken
        0, // _minOut
        toUsdMax, // _sizeDelta
        position.isLong, // _isLong
        priceLimit, // _acceptablePrice
        minExecutionFee, // _executionFee
        referralCode, // _referralCode
        AddressZero, // _callbackTarget
      ];
    }

    if (shouldRaiseGasError(getTokenInfo(infoTokens, fromTokenAddress), fromAmount)) {
      // setIsSubmitting(false);
      // setIsPendingConfirmation(false);
      helperToast.error(
        `Leave at least ${formatAmount(DUST_BNB, 18, 3)} ${getConstant(chainId, "nativeTokenSymbol")} for gas`
      );
      return;
    }

    const contractAddress = getContract(chainId, "PositionRouter");
    const contract = new ethers.Contract(contractAddress, PositionRouter.abi, library.getSigner());
    const indexToken = getTokenInfo(infoTokens, indexTokenAddress);
    const tokenSymbol = indexToken.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexToken.symbol;
    const longOrShortText = position.isLong ? `Long` : `Short`;
    const successMsg = `Requested increase of ${tokenSymbol} ${longOrShortText} by ${formatAmount(
      toUsdMax,
      USD_DECIMALS,
      2
    )} USD.`;

    callContract(chainId, contract, method, params, {
      value,
      setPendingTxns,
      sentMsg: `${longOrShortText} submitted.`,
      failMsg: `${longOrShortText} failed.`,
      successMsg,
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      hideSuccessMsg: false,
    })
      .then(async () => {
        // setIsConfirming(false);
        setIsVisible(false);
        setFromValue("");

        const key = getPositionKey(account, path[path.length - 1], indexTokenAddress, position.isLong);
        let nextSize = toUsdMax;
        if (true) {
          nextSize = position.size.add(toUsdMax);
        }

        pendingPositions[key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            size: nextSize,
          },
        };

        setPendingPositions({ ...pendingPositions });
      })
      .finally(() => {
        // setIsSubmitting(false);
        setIsAdding(false);
        // setIsPendingConfirmation(false);
      });
  };
  const handleBlurLeverage = ({ target }) => {
    const val = target.value;
    if (!val || val < 1.1 || val > 100) {
      setLeverageOption(1.1);
      return;
    }
    setLeverageOption(target.value);
  };
  const onChangeLeverage = (value) => {
    if (value && value.includes(".")) {
      var arr = value.split(".");
      if (arr[1].length <= 1) setLeverageOption(value);
    } else {
      setLeverageOption(value);
    }
  };
  // console.log("??????", { fromBalance, fromToken });

  return (
    <div className="PositionEditor">
      <Modal
        className={`PositionSeller-modal confirmation-modal add-to-position-modal add-to-position-modal1`}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        label={"Increase Position"}
        allowContentTouchMove
        maxWidth="480px"
      >
        {/* <img src={importImage("ic_" + position?.indexToken?.symbol?.toLowerCase() + "_24.svg")} alt="" /> <div className="position-info-container">
            <div className="position-info">
              <div className="title">{position?.indexToken?.symbol}</div>
              <div className={`side ${position.isLong ? "side-long" : "side-short"}`}>
                {position.isLong ? "LONG" : "SHORT"}
              </div>
              <div className="side">MARKET</div>
              <div className="leverage-box">{formatAmount(position.leverage, 4, 2)}X</div>
            </div>
             <div className="position-id">#{position?.posId}</div> *
          </div> */}
        <div className="order-title-line order-title-line-details" style={{ paddingBottom: "0px" }}>
          {" "}
          <TokenTopInfo
            type="small"
            isLong={position.isLong}
            orderOption="MARKET"
            symbol={position?.indexToken?.symbol}
            leverageOption={formatAmount(position.leverage, 4, 2)}
            posId={selectedPosition?.posId}
          />
        </div>

        <div className="content-container">
          {" "}
          <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "0px" }}>
            <div className="Exchange-swap-section-top">
              <div className="">
                Add Amount
                {/* <Tooltip
                  handle={`Add Amount`}
                  position="left-bottom"
                  renderContent={() => {
                    return (
                      <span>
                        When you click on the 100% option, your max collateral amount will be estimated based on your
                        free collateral and Open pos. fees
                      </span>
                    );
                  }}
                /> */}
              </div>
              <div className="muted align-right clickable" onClick={() => handleSelectPercentage(1)}>
                <StyledBalance>Balance: {formatAmount(fromBalance, fromToken.decimals, 4, true)}</StyledBalance>
              </div>
              {/* <div className="muted align-right clickable">
                  <span>Max: {formatAmount(freeQuantity, 30, 4, true)}</span>
                </div> */}
            </div>
            <div className="Exchange-swap-section-bottom">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={fromValue}
                  onChange={(e) => setFromValue(e.target.value)}
                  onKeyDown={preventSpecialCharacters}
                />
              </div>
              <div>
                <TokenSelector
                  label={`Pay`}
                  chainId={chainId}
                  tokenAddress={fromTokenAddress}
                  onSelectToken={onSelectFromToken}
                  tokens={fromTokens}
                  infoTokens={infoTokens}
                  showMintingCap={false}
                  showSymbolImage={true}
                  showTokenImgInDropdown={true}
                />
              </div>
            </div>
          </div>
          {/* {closeQuantity && isDisableButton() && <div className="validate-error">{getErrorCollateral()}</div>} */}
          <PercentageButtons
            onChangePercentage={handleSelectPercentage}
            balance={Number(formatAmount(fromBalance, fromToken.decimals, 6, false)) || 0}
            value={Number(fromValue || 0)}
            decimals={6}
            fullBalance={fromBalance?.mul(parseValue(1, USD_DECIMALS - fromToken.decimals))}
            isUsd={true}
          />
          {/* <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "16px" }}>
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <span>Limit price</span>
              </div>
              <div className="muted align-right">
                <span>Mark: ${formatAmount(position.markPrice, 30, 2, true)}</span>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={limitPrice}
                  onChange={(e) => setLimitPrice(e.target.value)}
                  onKeyDown={preventSpecialCharacters}
                />
              </div>
              <div className="PositionEditor-token-symbol">USD</div>
            </div>
          </div> */}
          <div style={{ fontWeight: 700, marginTop: "16px" }}>Leverage</div>
          <div className="Exchange-leverage-slider-container" style={{ marginTop: "8px" }}>
            <div className="percentahe-value">
              <input
                className="percentahe-input"
                value={leverageOption}
                type="number"
                onChange={({ target }) => onChangeLeverage(target.value)}
                onBlur={handleBlurLeverage}
                onKeyDown={preventSpecialCharacters}
              />
              <span>x</span>
            </div>
            <div
              className={cx("Exchange-leverage-slider", "App-slider", {
                normalSlider: true,
              })}
            >
              <Slider
                min={1.0}
                max={MAX_ALLOWED_LEVERAGE(chainId) / BASIS_POINTS_DIVISOR}
                step={0.5}
                marks={LEVERAGE_MARKS(chainId)}
                handle={leverageSliderHandle}
                onChange={(value) => setLeverageOption(value)}
                defaultValue={leverageOption}
                value={leverageOption}
              />
            </div>
          </div>
          <div className="PositionEditor-info-box" style={{ marginTop: "16px" }}>
            <div className="square-container square-fee-container">
              <ExchangeInfoRow label={`Mark Price`}>
                {position.markPrice ? formatAmount(position.markPrice, 30, 2, true) : "--"}
              </ExchangeInfoRow>
              <ExchangeInfoRow label={`Collateral In`}>{position.collateralToken.symbol}</ExchangeInfoRow>
              <ExchangeInfoRow label={`Fees`}>
                <Tooltip
                  handle={`${feesUsd && "$"}${formatAmount(
                    feesUsd.add(
                      isMarketOrder
                        ? minExecutionFeeUSD
                        : executionFee.mul(nativeTokenInfo?.minPrice || 1).div(parseValue(1, 6))
                    ),
                    USD_DECIMALS,
                    getDisplayDecimalByAmount(feesUsd, USD_DECIMALS),
                    true
                  )}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div>
                        {swapFees && (
                          <div>
                            <span>{position.collateralToken.symbol} is required for collateral.</span> <br />
                            <br />
                            <StatsTooltipRow
                              label={`Swap ${fromToken.symbol} to ${position.collateralToken.symbol} Fee`}
                              value={formatAmount(
                                swapFees,
                                USD_DECIMALS,
                                getDisplayDecimalByAmount(swapFees, USD_DECIMALS),
                                true
                              )}
                            />
                            <br />
                          </div>
                        )}
                        <div>
                          <StatsTooltipRow
                            label={`Network Fee`}
                            showDollar={false}
                            value={`${formatAmount(
                              isMarketOrder ? minExecutionFee : executionFee,
                              18,
                              getDisplayDecimalByAmount(isMarketOrder ? minExecutionFee : executionFee, USD_DECIMALS),
                              true
                            )} ${nativeTokenSymbol} ($${formatAmount(
                              isMarketOrder
                                ? minExecutionFeeUSD
                                : executionFee.mul(nativeTokenInfo?.minPrice || 1).div(parseValue(1, 6)),
                              USD_DECIMALS,
                              getDisplayDecimalByAmount(minExecutionFee, USD_DECIMALS),
                              true
                            )})`}
                          />
                        </div>
                        <br />
                        <div>
                          <StatsTooltipRow
                            label={`Position Fee (0.1% of position size)`}
                            value={formatAmount(
                              feesUsd,
                              USD_DECIMALS,
                              getDisplayDecimalByAmount(feesUsd, USD_DECIMALS),
                              true
                            )}
                          />
                        </div>
                      </div>
                    );
                  }}
                />
              </ExchangeInfoRow>

              <Divider />
              <div className="position-change-line">
                <div className="position-change-info">Leverage</div>
                <div className="position-change-info change-value">
                  {fromValue && leverage && !leverage.eq(position.leverage) ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#919499" }}>
                        {formatAmount(position.leverage, 4, 2, true)}x
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#919499"} />
                      </span>{" "}
                      {formatAmount(leverage, 4, 2, true)}x
                      {/*( <span
                        className={cx("Exchange-list-info-label", {
                          positive: leverage.sub(position.leverage).gt(0),
                          negative: leverage.sub(position.leverage).lt(0),
                        })}
                        style={{ marginLeft: "4px", fontSize: "14px" }}
                      >
                        {leverage.sub(position.leverage).gt(0) ? "+" : leverage.sub(position.leverage).lt(0) ? "-" : ""}
                        {formatAmount(leverage.sub(position.leverage), 4, 2, true).replace("-", "")}x
                      </span>   )*/}
                    </span>
                  ) : (
                    `${formatAmount(position.leverage, 4, 2, true)}x`
                  )}
                </div>
              </div>
              <div className="position-change-line">
                <div className="position-change-info">Collateral</div>
                <div className="position-change-info change-value">
                  {fromValue && nextAveragePrice && !nextAveragePrice.eq(position.averagePrice) ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#919499" }}>
                        ${formatAmount(position.collateral, 30, 2, true)}
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#919499"} />
                      </span>{" "}
                      ${formatAmount(nextCollateral, 30, 2, true)}
                      {/* (
                      <span
                        className={cx("Exchange-list-info-label", {
                          positive: true,
                        })}
                        style={{ marginLeft: "4px", fontSize: "14px" }}
                      >
                        +${formatAmount(nextCollateral.sub(position.collateral), 30, 2, true)}
                      </span>
                      ) */}
                    </span>
                  ) : (
                    `$${formatAmount(position.collateral, 30, 2, true)}`
                  )}
                </div>
              </div>
              <div className="position-change-line">
                <div className="position-change-info">Pos. Size</div>
                <div className="position-change-info change-value">
                  {fromValue && nextAveragePrice && !nextAveragePrice.eq(position.averagePrice) ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#919499" }}>
                        ${formatAmount(position.size, 30, 2, true)}
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#919499"} />
                      </span>{" "}
                      ${formatAmount(position.size.add(toUsdMax), 30, 2, true)}
                      {/* (
                      <span
                        className={cx("Exchange-list-info-label", {
                          positive: true,

                        })}
                        style={{ marginLeft: "4px", fontSize: "14px" }}
                      >
                        +${formatAmount(toUsdMax, 30, 2, true)}
                      </span>
                      ) */}
                    </span>
                  ) : (
                    `$${formatAmount(position.size, 30, 2, true)}`
                  )}
                </div>
              </div>

              <div className="position-change-line">
                <div className="position-change-info">
                  <span>Pos. Qty ({position.indexToken.symbol})</span>
                </div>
                <div className="position-change-info change-value">
                  {fromValue && nextAveragePrice && !nextAveragePrice.eq(position.averagePrice) ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#919499" }}>
                        {formatAmount(posQty, 30, 4, true)}
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#919499"} />
                      </span>{" "}
                      {formatAmount(nextQty, 30, 4, true)}
                      {/* (
                      <span
                        className={cx("Exchange-list-info-label", {
                          positive: true,
                        })}
                        style={{ marginLeft: "4px", fontSize: "14px" }}
                      >
                        +{formatAmount(nextQty.sub(posQty), 30, 4, true)}
                      </span>
                      ) */}
                    </span>
                  ) : (
                    `${formatAmount(posQty, 30, 4, true)}`
                  )}
                </div>
              </div>
              <div className="position-change-line">
                <div className="position-change-info">Entry Price</div>
                <div className="position-change-info change-value">
                  {fromValue && nextAveragePrice && !nextAveragePrice.eq(position?.averagePrice) ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#919499" }}>
                        ${formatAmount(position.averagePrice || 0, 30, 2, true)}
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#919499"} />
                      </span>{" "}
                      ${formatAmount(nextAveragePrice || 0, 30, 2, true)}
                      {/* (
                      <span
                        className={cx("Exchange-list-info-label", {
                          positive: nextAveragePrice?.sub(position?.averagePrice || 0).gt(0),
                          negative: nextAveragePrice?.sub(position?.averagePrice || 0).lt(0),
                        })}
                        style={{ marginLeft: "4px", fontSize: "14px" }}
                      >
                        {nextAveragePrice?.sub(position.averagePrice || 0).gt(0)
                          ? "+"
                          : nextAveragePrice?.sub(position.averagePrice || 0).lt(0)
                          ? "-"
                          : ""}
                        $
                        {formatAmount(nextAveragePrice?.sub(position?.averagePrice || 0), 30, 2, true).replace("-", "")}
                      </span>
                      ) */}
                    </span>
                  ) : (
                    `$${formatAmount(position.averagePrice, 30, 2, true)}`
                  )}
                </div>
              </div>
              <div className="position-change-line">
                <div className="position-change-info">Liq. Price</div>
                <div className="position-change-info change-value">
                  {fromValue && liquidationPrice && !liquidationPrice.eq(existingLiquidationPrice) ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#919499" }}>
                        ${formatAmount(existingLiquidationPrice || 0, 30, 2, true)}
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#919499"} />
                      </span>{" "}
                      ${formatAmount(liquidationPrice || 0, 30, 2, true)}
                      {/* (
                      <span
                        className={cx("Exchange-list-info-label", {
                          positive: liquidationPrice?.sub(existingLiquidationPrice || 0).gt(0),
                          negative: liquidationPrice?.sub(existingLiquidationPrice || 0).lt(0),
                        })}
                        style={{ marginLeft: "4px", fontSize: "14px" }}
                      >
                        {liquidationPrice?.sub(existingLiquidationPrice || 0).gt(0)
                          ? "+"
                          : liquidationPrice?.sub(existingLiquidationPrice || 0).lt(0)
                          ? "-"
                          : ""}
                        $
                        {formatAmount(liquidationPrice?.sub(existingLiquidationPrice || 0), 30, 2, true).replace(
                          "-",
                          ""
                        )}
                      </span>
                      ) */}
                    </span>
                  ) : (
                    `$${formatAmount(existingLiquidationPrice, 30, 2, true)}`
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button
            className="default-btn1 Exchange-swap-button"
            onClick={() => {
              increasePosition();
            }}
            disabled={isDisableButton() || isAdding}
          >
            {renderPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}
const Wrapper = styled.div`
  &.order-title-line {
    @media screen and (max-width: 700px) {
      padding-top: 16px;
    }
  }
`;
const StyledTag = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: var(--Leverage-Text, #ffc700);
  line-height: 140%;
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Leverage-Background, rgba(255, 199, 0, 0.2));
`;
const Divider = styled.div`
  background: var(--Border, rgba(239, 236, 235, 0.08));
  height: 1px;
  width: 100%;
  margin: 8px 0 12px 0;
`;
const StyledBalance = styled.div`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
