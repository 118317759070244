import { getNormalizedTokenSymbol, getTokenLowerCase } from "config/tokens";
import { DECREASE, getOrderError, importImage, INCREASE, USD_DECIMALS } from "lib/legacy";
import { formatAmount, getDisplayDecimalByAmount, parseValue } from "lib/numbers";
import cx from "classnames";
import editIcon from "img/trade/edit.svg";
import { getTokenInfo } from "domain/tokens";
import moment from "moment";

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm:ss";

const OrderItemMobileV1 = ({
  chainId,
  order,
  onCancelClick,
  onEditClick,
  nativeTokenAddress,
  infoTokens,
  position,
  account,
  positionsMap,
  setSelectedPosition,
}) => {
  const { type, indexToken, triggerAboveThreshold, triggerPrice, sizeDelta, isLong } = order;
  const symbol = getNormalizedTokenSymbol(getTokenLowerCase(chainId, indexToken).symbol);
  // const posQty = sizeDelta.mul(parseValue(1, 30)).div(triggerPrice);
  const indexTokenMi = getTokenInfo(infoTokens, indexToken, true, nativeTokenAddress);
  const triggerPricePrefix = triggerAboveThreshold ? "≥" : "≤";
  const maximisePrice = (type === INCREASE && isLong) || (type === DECREASE && !isLong);
  const markPriceMaximise = maximisePrice ? indexTokenMi?.maxPrice : indexTokenMi?.minPrice;
  const increaseToken =
    order.type === INCREASE
      ? getTokenInfo(infoTokens, order?.purchaseToken, true, nativeTokenAddress)
      : { maxPrice: parseValue(1, USD_DECIMALS) };

  const collateral =
    order.type === INCREASE && !position
      ? order.purchaseTokenAmount
          .mul(increaseToken?.maxPrice || parseValue(1, USD_DECIMALS))
          .div(parseValue(1, increaseToken.decimals))
      : position.collateral;
  const collateralOrder =
    order.type === INCREASE
      ? order.purchaseTokenAmount
          .mul(increaseToken?.maxPrice || parseValue(1, USD_DECIMALS))
          .div(parseValue(1, increaseToken.decimals))
      : 0;
  const orderError = getOrderError(account, order, positionsMap, position);

  const isValid =
    order.type === INCREASE ? (collateralOrder.gte(parseValue(10, 30)) || position ? true : false) : !orderError;
  const isLimitError =
    order.type === INCREASE ? (collateral.lt(parseValue(10, 30)) && !position ? true : false) : false;
  const leverage =
    order.type === INCREASE && !position ? order.sizeDelta.mul(parseValue(1, 30)).div(collateral) : position.leverage;
  const posQty = position
    ? position.size.mul(parseValue(1, 30)).div(position.averagePrice)
    : order.sizeDelta
        .mul(parseValue(1, 30))
        .div(order?.triggerPrice || indexTokenMi?.maxPrice || parseValue(1, USD_DECIMALS));
  return (
    <div key={order.index} className="App-card">
      <div className="App-card-title">
        <span className="Exchange-list-title">
          <img
            src={importImage(
              "ic_" + getNormalizedTokenSymbol(getTokenLowerCase(chainId, indexToken).symbol)?.toLowerCase() + "_24.svg"
            )}
            alt="tk"
          />{" "}
          {symbol}/USD {position && <span className="muted">#{position.posId}</span>}
        </span>
      </div>
      <div className="App-card-divider" />
      <div className="App-card-content app-card-position" style={{ opacity: isValid ? 1 : 0.5 }}>
        <div className="leverage-pad">
          <div>
            <span
              className={cx({
                positive: order.isLong,
                negative: !order.isLong,
              })}
              style={{ bold: true, color: order.isLong ? "#7EE55C" : "#F6475D" }}
            >
              {order.isLong ? `LONG` : `SHORT`}
            </span>
          </div>
          <div
            className=" "
            style={{
              color: "#FFC700",
              backgroundColor: "rgba(255, 199, 0, 0.20)",
              height: 16,
              display: "inline-block",
              opacity: 1,
              borderRadius: "100px",
              padding: "0 4px ",
              fontSize: "12px",
              lineHeight: "16px ",
              fontWeight: 700,
              marginLeft: "4px",
            }}
          >
            {formatAmount(position ? position.leverage : leverage, position ? 4 : 30, 2, true)}X
          </div>
        </div>
        {/* <div className="App-card-row">
          <div className="label">
            <span>Created</span>
          </div>
          <div>
            {moment(new Date(order.createdAt * 1000)).format(dateFormat)}
            <div className="muted fz-12 bold">{moment(new Date(order.createdAt * 1000)).format(timeFormat)}</div>
          </div>
        </div> */}
        <div className="App-card-row">
          <div className="label">
            <span>Type</span>
          </div>
          <div className="position-list-collateral">
            {["Swap", "Increase"].includes(order.type)
              ? "Limit Order"
              : isLong
              ? triggerAboveThreshold
                ? "Take Profit"
                : "Stop Loss"
              : triggerAboveThreshold
              ? "Stop Loss"
              : "Take Profit"}
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Order</span>
          </div>
          <div>
            {type === "Decrease" || type === "Increase"
              ? `${type === "Decrease" ? "Decrease" : "Increase position"} $${formatAmount(sizeDelta, 30, 2, true)}`
              : ""}
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Trigger Conditions</span>
          </div>
          <div>
            Mark Price {triggerPricePrefix} $
            {formatAmount(triggerPrice, 30, getDisplayDecimalByAmount(triggerPrice, 30), true)}
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Pos. Size</span>
          </div>
          <div>
            <div>
              {position
                ? "$" + formatAmount(position.size, 30, 2, true)
                : "$" + formatAmount(order.sizeDelta, 30, 2, true)}
            </div>
            <div className="muted fz-12 bold">
              {formatAmount(posQty, 30, 4, true)} {symbol}
            </div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Mark. Price</span>
          </div>
          <div>${formatAmount(markPriceMaximise, 30, getDisplayDecimalByAmount(markPriceMaximise, 30), true)}</div>
        </div>

        {/* <div className="App-card-row">
                        <div className="label">
                          <span>Collateral</span>
                        </div>
                        <div>${renderCollateral(position)}</div>
                      </div> */}
        {/* <div className="App-card-row">
                        <div className="label">
                          <span>Parameters</span>
                        </div>
                        <div>
                          <div>
                            {renderParamaters(position, currentMarket).param1}{" "}
                            <span className="muted">{renderParamaters(position, currentMarket).param1Sub}</span>
                          </div>
                          {renderParamaters(position, currentMarket).param2 && (
                            <div>
                              {renderParamaters(position, currentMarket).param2}{" "}
                              <span className="muted">{renderParamaters(position, currentMarket).param2Sub}</span>
                            </div>
                          )}
                        </div>
                      </div> */}
      </div>
      {!isValid && <div style={{ marginTop: "1.3rem" }} />}
      {!isValid && (
        <div className="negative error-order">
          {isLimitError
            ? "Order collateral is smaller than min collateral ($10), will only be executable to increase an existing position."
            : orderError}
        </div>
      )}
      <div style={{ marginTop: "24px" }} />
      <div className="App-card-options">
        <button
          className="App-button-option App-card-option"
          onClick={(e) => {
            e.stopPropagation();
            onCancelClick(order);
          }}
          // disabled={position.size == 0}
        >
          <span>Cancel</span>
        </button>
        {isValid && (
          <button
            className="PositionDropdown-dots-icon"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedPosition(position);
              onEditClick(order);
            }}
          >
            <img src={editIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
          </button>
        )}
      </div>
    </div>
  );
};
export default OrderItemMobileV1;
