import { getTokenSymbolFromString } from "domain/tokens";
import { BigNumber } from "ethers";
import { importImage, PRECISION } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import cx from "classnames";
import moment from "moment";
import editIcon from "img/trade/edit.svg";
const OrderItemV2 = ({
  position,
  setIsEditTralingStopModalOpen,
  marketAsssets,
  marketTokensInfo,
  handlePendingTrigger,
  setSelectedPosition,
  setIsEditTakeProfitModalOpen,
  handleCancelTrigger,
  positions,
}) => {
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const symbol = getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol);
  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
  const markPrice = BigNumber.from(position?.isLong ? currentMarket?.askPrice || 0 : currentMarket?.bidPrice || 0);
  const currentPosition = positions.find((x) => x.posId === position.posId);
  if (position.type === "TRIGGER") {
    const posQty = position.size.gt(0) ? position.size.mul(parseValue(1, 30)).div(position.averagePrice) : 0;
    const conditionPrice = `${formatAmount(position?.trigger?.price, 30, currentMarket?.decimals || 2, true)}`;
    const conditionQuantity = Number(position.trigger.amountPercent / 1000);
    const triggerPrice = BigNumber.from(position.trigger.price);
    const liquidationPrice = position.liquidationPrice;

    const completePosition = {
      ...position,
      markPrice,
      symbol,
      posQty,
      triggerPrice,
      liquidationPrice,
    };
    return (
      <tr
        className="table-content"
        // onClick={() => handleSelectedPosition(completePosition)}
      >
        <td>
          <div>
            <img src={importImage("ic_" + symbol?.toLowerCase() + "_24.svg")} alt="tk" />
            <div className="tk_info">
              <div className="Exchange-list-title">
                {symbol}/USD <span className="muted">#{position.posId}</span>
              </div>
              <div className="Exchange-list-info-label">
                <span
                  className={cx({
                    positive: position.isLong,
                    negative: !position.isLong,
                  })}
                  style={{ bold: true, color: position.isLong ? "#7EE55C" : "#F6475D" }}
                >
                  {position.isLong ? `LONG` : `SHORT`}
                </span>
                {position.leverage && (
                  <span
                    className=" leverage-box"
                    style={{
                      color: "#FFC700",
                      backgroundColor: "rgba(255, 199, 0, 0.20)",
                      height: 16,
                      display: "inline-block",
                      opacity: 1,
                      borderRadius: "100px",
                      padding: "0 4px",
                      fontSize: "12px",
                      lineHeight: "15.6px",
                      fontWeight: 700,
                      marginLeft: "4px",
                    }}
                  >
                    {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* <div className={`flag ${position.isLong ? "isLong" : "isShort"}`}></div> */}
        </td>
        <td>
          <div>{position?.trigger.isTP ? "Take Profit" : "Stop Loss"}</div>
        </td>
        <td>
          {/* <div>${formatAmount(position.size, 30, 2, true)}</div>
          <div className="muted fz-12 bold">
            {formatAmount(posQty, 30, 4, true)} {symbol}
          </div> */}
          Decrease{" "}
          {currentPosition
            ? "$" +
              formatAmount(
                parseValue(currentPosition?.size || position?.size, 30)
                  .mul(parseValue(position.trigger.amountPercent / 100000, 30))
                  .div(parseValue(1, 30)),
                30,
                2,
                true
              )
            : ""}
          {!currentPosition
            ? "$" +
              formatAmount(
                position?.size.mul(parseValue(position.trigger.amountPercent / 100000, 30)).div(parseValue(1, 30)),
                30,
                2,
                true
              )
            : ""}
        </td>
        <td>
          {/* <div>${formatAmount(position.collateral, 30, 2, true)}</div> */}
          Mark Price {position.trigger.isTP ? (position.isLong ? "≥" : "≤") : !position.isLong ? "≥" : "≤"} $
          {conditionPrice}
        </td>
        <td>
          {/* {conditionPrice} <span className="muted">{position.trigger.isTP ? "TP" : "SL"}</span>
          <div className="fz12">
            {conditionQuantity?.toString().includes(".") ? limitDecimals(conditionQuantity, 2) : conditionQuantity}
            <span className="muted"> %</span>
          </div> */}
          <div>${formatAmount(position.size, 30, 2, true)}</div>
          <div className="muted fz-12 bold">
            {formatAmount(posQty, 30, 4, true)} {symbol}
          </div>
        </td>
        <td>${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}</td>

        <td style={{ width: "30px" }}>
          <div className="close-container">
            <button
              className="Exchange-list-action"
              onClick={(e) => {
                e.stopPropagation();
                handleCancelTrigger(completePosition);
              }}
              // disabled={position.size.eq(0)}
            >
              Cancel
            </button>
            <button
              className="PositionDropdown-dots-icon"
              onClick={() => {
                setSelectedPosition(completePosition);
                setIsEditTakeProfitModalOpen(true);
              }}
            >
              <img src={editIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
            </button>
          </div>
        </td>
      </tr>
    );
  } else if (position.type === "PENDDING") {
    const size = BigNumber.from(position.pendingSize);
    const collateral = BigNumber.from(position.pendingCollateral);
    const posQty = size
      .mul(parseValue(1, 30))
      .div(
        BigNumber.from(
          position.positionType === "Limit Order" || position.positionType === "Stop Limit"
            ? position?.lmtPrice
            : position?.stpPrice
        )
      );
    const leverage = size.gt(0) ? formatAmount(size.mul(parseValue(1, 30)).div(collateral), 30, 2, false) : null;
    return (
      <tr
        className="table-content"
        // onClick={() => handleSelectedPosition(completePosition)}
      >
        <td>
          <div>
            <img src={importImage("ic_" + symbol?.toLowerCase() + "_24.svg")} alt="tk" />
            <div className="tk_info">
              <div className="Exchange-list-title">
                {symbol}/USD <span className="muted">#{position.posId}</span>
              </div>
              <div className="Exchange-list-info-label">
                <span
                  className={cx({
                    positive: position.isLong,
                    negative: !position.isLong,
                    bold: true,
                  })}
                  style={{ color: position.isLong ? "#7EE55C" : "#F6475D" }}
                >
                  {position.isLong ? `LONG` : `SHORT`}
                </span>
                {leverage && (
                  <span
                    className=" leverage-box"
                    style={{
                      color: "#FFC700",
                      backgroundColor: "rgba(255, 199, 0, 0.20)",
                      height: 16,
                      display: "inline-block",
                      opacity: 1,
                      borderRadius: "100px",
                      padding: "0 4px",
                      fontSize: "12px",
                      lineHeight: "15.6px",
                      fontWeight: 700,
                      marginLeft: "4px",
                    }}
                  >
                    {leverage}X
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* <div className={`flag ${position.isLong ? "isLong" : "isShort"}`}></div> */}
        </td>
        {/* <td>
          {moment(new Date(position.createdAt * 1000)).format(dateFormat)}
          <div className="muted fz-12 bold">{moment(new Date(position.createdAt * 1000)).format(timeFormat)}</div>
        </td> */}
        <td>
          <div>{position?.positionType}</div>
          {/* <div>Limit</div> */}
        </td>
        <td>
          Increase position
          {/* <div>${formatAmount(size, 30, 2, true)}</div>
          <div className="muted fz-12 bold">
            {formatAmount(posQty, 30, 4, true)} {symbol}
          </div> */}
        </td>
        <td>
          {/* <div>${formatAmount(collateral, 30, 2, true)}</div> */}
          <div>
            Mark Price{" "}
            {position.positionType === "Stop Limit"
              ? !position.isLong
                ? "≤"
                : "≥"
              : position.positionType === "Stop Market"
              ? position.isLong
                ? "≥"
                : "≤"
              : !position.isLong
              ? "≥"
              : "≤"}{" "}
            $
            {formatAmount(
              position.positionType === "Limit Order" ? position?.lmtPrice : position?.stpPrice,
              30,
              currentMarket?.decimals || 2,
              true
            )}{" "}
            {/* <span className="muted">{position.positionType === "Limit Order" ? "LP" : "SP"}</span> */}
          </div>
          {position.positionType === "Stop Limit" && (
            <div>
              Mark Price {!position.isLong ? "≥" : "≤"} $
              {formatAmount(position?.lmtPrice, 30, currentMarket?.decimals || 2, true)}{" "}
              {/* <span className="muted">LP</span> */}
            </div>
          )}
        </td>
        <td>
          {/* <div>
            {formatAmount(
              position.positionType === "Limit Order" ? position?.lmtPrice : position?.stpPrice,
              30,
              currentMarket?.decimals || 2,
              true
            )}{" "}
            <span className="muted">{position.positionType === "Limit Order" ? "LP" : "SP"}</span>
          </div>
          {position.positionType === "Stop Limit" && (
            <div>
              {formatAmount(position?.lmtPrice, 30, currentMarket?.decimals || 2, true)}{" "}
              <span className="muted">LP</span>
            </div>
          )} */}
          <div>${formatAmount(size, 30, 2, true)}</div>
          <div className="muted fz-12 bold">
            {formatAmount(posQty, 30, 4, true)} {symbol}
          </div>
        </td>
        <td>${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}</td>

        <td style={{ width: "30px" }}>
          <div className="close-container">
            <button
              className="Exchange-list-action"
              onClick={(e) => {
                e.stopPropagation();
                handlePendingTrigger(position);
              }}
              // disabled={position.size.eq(0)}
            >
              Cancel
            </button>
          </div>
        </td>
      </tr>
    );
  } else {
    const size = BigNumber.from(position.size);
    const sizeDelta = BigNumber.from(position.sizeDelta);
    const averagePrice = BigNumber.from(position.averagePrice);
    const posQty = size.mul(parseValue(1, 30)).div(averagePrice);
    const collateral = BigNumber.from(position.collateral);
    const leverage = size.gt(0) ? formatAmount(size.mul(parseValue(1, 30)).div(collateral), 30, 2, false) : null;
    const deltaQuantity = sizeDelta.mul(PRECISION).div(averagePrice);
    const amountPercent = sizeDelta.mul(parseValue(100, 30)).div(size);
    const completedPosition = {
      ...position,
      quantity: posQty,
      averagePrice,
      symbol,
      size,
      sizeDelta,
      collateral,
      leverage,
      deltaQuantity,
      amountPercent,
    };
    return (
      <tr className="table-content">
        <td>
          <div>
            <img src={importImage("ic_" + symbol?.toLowerCase() + "_24.svg")} alt="tk" />{" "}
            <div className="tk_info">
              <div className="Exchange-list-title">
                {marketAsssets?.[position.tokenId]?.symbol} <span className="muted">#{position.posId}</span>
              </div>
              <div className="Exchange-list-info-label">
                <span
                  className={cx({ bold: true, positive: position.isLong, negative: !position.isLong })}
                  style={{ color: position.isLong ? "#7EE55C" : "#F6475D" }}
                >
                  {position.isLong ? `LONG` : `SHORT`}
                </span>
                {leverage && (
                  <span
                    className=" leverage-box"
                    style={{
                      color: "#FFC700",
                      backgroundColor: "rgba(255, 199, 0, 0.20)",
                      height: 16,
                      display: "inline-block",
                      opacity: 1,
                      borderRadius: "100px",
                      padding: "0 4px",
                      fontSize: "12px",
                      lineHeight: "15.6px",
                      fontWeight: 700,
                      marginLeft: "4px",
                    }}
                  >
                    {leverage}X
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* <div className={`flag ${position.isLong ? "isLong" : "isShort"}`}></div> */}
        </td>
        {/* <td>
          {moment(new Date(position.createdAt * 1000)).format(dateFormat)}
          <div className="muted fz-12 bold">{moment(new Date(position.createdAt * 1000)).format(timeFormat)}</div>
        </td> */}
        <td>
          <div>Trailing Stop</div>
        </td>
        <td>
          {/* <div>${formatAmount(size, 30, 2, true)}</div>
          <div className="muted fz-12 bold">
            {formatAmount(posQty, 30, 4, true)} {symbol}
          </div> */}
          Decrease $
          {currentPosition
            ? formatAmount(
                sizeDelta.gt(parseValue(currentPosition.size, 30)) ? parseValue(currentPosition.size, 30) : sizeDelta,
                30,
                2,
                true
              )
            : sizeDelta.gte(size)
            ? formatAmount(sizeDelta, 30, 2, true)
            : formatAmount(sizeDelta, 30, 2, true)}
        </td>
        <td>
          {/* <div>${formatAmount(collateral, 30, 2, true)}</div> */}
          <div>
            {position.stepType == 0
              ? "Trailing Amount = $" + formatAmount(BigNumber.from(position.stepAmount), 30, 2, true)
              : "Trailing Percentage = " + formatAmount(BigNumber.from(position.stepAmount), 3, 2, true) + "%"}
            {/* <span className="muted">{position.stepType == 1 ? "T%" : "TA"}</span> */}
          </div>
          {/* <div>
            {" "}
            {sizeDelta.gte(size) ? 100 : formatAmount(amountPercent, 30, 2, true)} <span className="muted">%</span>
          </div> */}
        </td>
        <td>
          {/* <div>
            {position.stepType == 0
              ? formatAmount(BigNumber.from(position.stepAmount), 30, currentMarket?.decimals || 2, true)
              : formatAmount(BigNumber.from(position.stepAmount), 3, currentMarket?.decimals || 2, true)}{" "}
            <span className="muted">{position.stepType == 1 ? "T%" : "TA"}</span>
          </div>
          <div>
            {" "}
            {sizeDelta.gte(size) ? 100 : formatAmount(amountPercent, 30, 2, true)} <span className="muted">%</span>
          </div> */}
          <div>${formatAmount(size, 30, 2, true)}</div>
          <div className="muted fz-12 bold">
            {formatAmount(posQty, 30, 4, true)} {symbol}
          </div>
        </td>
        <td>${formatAmount(markPrice, 30, currentMarket?.decimals, true)}</td>
        <td style={{ width: "30px" }}>
          <div className="close-container">
            <button
              className="Exchange-list-action"
              onClick={(e) => {
                e.stopPropagation();
                handlePendingTrigger(position);
              }}
              // disabled={position.size.eq(0)}
            >
              Cancel
            </button>
            <button
              className="PositionDropdown-dots-icon"
              onClick={() => {
                setSelectedPosition(completedPosition);
                setIsEditTralingStopModalOpen(true);
              }}
            >
              <img src={editIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
            </button>
          </div>
        </td>
      </tr>
    );
  }
};
export default OrderItemV2;
