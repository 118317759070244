const ClockIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1523 2.00193C9.25997 1.95865 6.64866 3.15636 4.79688 5.08982L2.85352 3.14646C2.65852 2.95146 2.34148 2.95146 2.14648 3.14646C2.04848 3.24346 2 3.37198 2 3.49998V7.99998C2 8.26519 2.10536 8.51955 2.29289 8.70708C2.48043 8.89462 2.73478 8.99998 3 8.99998H7.5C7.628 8.99998 7.75552 8.95049 7.85352 8.85349C8.04852 8.65849 8.04852 8.34146 7.85352 8.14646L6.2168 6.50974C8.02806 4.60115 10.7519 3.56904 13.6914 4.17576C16.7664 4.81076 19.2318 7.29984 19.8398 10.3808C20.8498 15.4988 16.941 20 12 20C7.881 20 4.47988 16.8702 4.04688 12.8652C3.99287 12.3682 3.5625 12 3.0625 12C2.4625 12 1.9955 12.5251 2.0625 13.1211C2.6215 18.1091 6.866 22 12 22C18.136 22 22.9993 16.4436 21.8223 10.0976C21.0773 6.07463 17.8171 2.86023 13.7871 2.15623C13.2347 2.05985 12.6888 2.00996 12.1523 2.00193Z"
        fill="currentColor"
      />
      <path
        d="M12 5.99921C11.448 5.99921 11 6.44721 11 6.99921V11.9992C11 12.2642 11.105 12.5192 11.293 12.7062L13.5859 14.9992C13.9759 15.3892 14.61 15.3892 15 14.9992C15.39 14.6092 15.39 13.9751 15 13.5851L13 11.5851V6.99921C13 6.44721 12.552 5.99921 12 5.99921Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ClockIcon;
