import axios from "axios";
import { useChainId } from "lib/chains";
import { useEffect, useState } from "react";
import { SUBGRAPH_URLS } from "config/subgraph";
import { ApolloClient, gql, HttpLink, InMemoryCache } from "@apollo/client";

export const DEFAULT_PAGE_SIZE_POSITIONS = 10;

const useUserPositionV1 = (account) => {
  const [positions, setPositions] = useState([]);
  const { chainId } = useChainId();
  const subgraphUrl = SUBGRAPH_URLS?.[chainId]?.positions;
  useEffect(() => {
    const fetchPositions = async () => {
      const query = `query MyQuery {
        trades(
          where: { account: "${account.toLowerCase()}", status: open }
          first: 1000
          skip: 0
        ) {
          account
          averagePrice
          collateral
          fee
          indexToken
          collateralToken
          isLong
          size
          status
          timestamp
          id
          key
        }
      }`;
      const querySource = gql(query);
      const client = new ApolloClient({
        link: new HttpLink({ uri: subgraphUrl, fetch }),
        cache: new InMemoryCache(),
      });
      const response = await client.query({ query: querySource });
      if(response?.data) {
        setPositions(response.data.trades);
      }

    };
    if (account) {
      fetchPositions();
      const interval = setInterval(async () => {
        fetchPositions();
      }, 8000);
      return () => clearInterval(interval);
    } else setPositions([]);
  }, [account, chainId]);

  return { positions };
};
export default useUserPositionV1;
