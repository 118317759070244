import { BigNumber } from "ethers";
import { secondsToDuration } from "lib/dates";
import { getLiqRisk, getLiqRiskV1, hashString } from "lib/legacy";
import { isNegative, limitDecimals, parseValue } from "lib/numbers";
import moment from "moment";
import { numberWithComma } from "../../../utils/helpers";

export const getUnixDate = (str) => +moment.utc(str).toDate() / 1000;
export const getUnixDateHour = (str) => moment.utc(str).format("HH:mm");

export const formatClosedPosition = (rawPosition, assets) => {
  const {
    size: _size,
    averagePrice: _averagePrice,
    closedTime: closedAt,
    openTime: createdAt,
    roi,
    indexToken,
    realisedPnl: _realisedPnl,
    exitPrice,
    type,
    id,
    key,
  } = rawPosition;
  const isV2 = type === "V2";
  const { decimals } = indexToken || {};
  const { tokenSymbol: symbol, decimals: displayDecimals } = assets[indexToken.id] || {
    tokenSymbol: indexToken.symbol,
  };
  const averagePrice = parseValue(_averagePrice, 30);
  const size = parseValue(_size, 30);
  const quantity = size.mul(parseValue("1", 30)).div(averagePrice);
  const duration = secondsToDuration(closedAt - createdAt);
  const roiPrefix = isNegative(roi) ? "-" : "+";
  const _roi = limitDecimals(Number(roi), 2);
  const roiText = (Number(_roi) <= -100 ? "-100" : roiPrefix + numberWithComma(_roi).replace("-", "")) + "%";
  const realisedPnl = parseValue(_realisedPnl, 30);
  const markPrice = parseValue(exitPrice, 30);

  const stringId = `${createdAt.toString()}-${key || id}-${createdAt.toString()}`;
  const posIdHash = isV2 ? undefined : hashString(stringId);

  return {
    ...rawPosition,
    quantity,
    symbol,
    decimals,
    duration,
    roiText,
    averagePrice,
    pnlAfterFee: realisedPnl,
    markPrice,
    size,
    displayDecimals,
    posIdHash,
  };
};

export const formatOpenPosition = (rawPosition, assets, marketTokensInfo) => {
  const {
    tokenId,
    leverage: rawLeverage,
    isLong,
    size,
    averagePrice,
    accruedFundingFee,
    accruedBorrowFee,
    accruedPositionFee,
    collateral: rawCollateral,
    pendingPnl,
    indexToken,
    type,
    openTime,
    id,
    pnlAfterFee,
    liquidPrice,
    marketPrice,
    key,
  } = rawPosition;
  const isV2 = type === "V2";
  // const {
  //   tokenSymbol,
  //   symbol,
  //   decimals: tokenDisplayDecimal,
  // } = isV2 ? assets?.[indexToken.id] || {} : { tokenSymbol: indexToken.symbol, decimals: 4 };
  const { tokenSymbol, symbol } = isV2 ? assets?.[indexToken.id] || {} : { tokenSymbol: indexToken.symbol };

  const leverage = parseValue(rawLeverage, 30);
  const _accruedFundingFee = parseValue(accruedFundingFee, 30);
  const _accruedBorrowFee = parseValue(accruedBorrowFee, 30);
  const _accruedPositionFee = parseValue(accruedPositionFee, 30);
  const pnlWithoutFee = parseValue(pendingPnl, 30);
  const _averagePrice = parseValue(averagePrice, 30);
  const _size = parseValue(size, 30);

  const currentMarket = marketTokensInfo?.find((x) => x.id === tokenId);
  const markPrice = BigNumber.from((isLong ? currentMarket?.askPrice : currentMarket?.bidPrice) || 0);

  // const pnlWithoutFee = isLong
  //   ? markPrice.sub(parseValue(averagePrice, 30)).mul(parseValue(size, 30)).div(parseValue(averagePrice, 30))
  //   : parseValue(averagePrice, 30).sub(markPrice).mul(parseValue(size, 30)).div(parseValue(averagePrice, 30));

  // const accruedFees =
  //   parseValue(accruedPositionFee, 30).add(parseValue(accruedFundingFee, 30)).add(parseValue(accruedBorrowFee, 30)) ||
  //   BigNumber.from(0);

  // const pnlWithFee = pnlWithoutFee.sub(accruedFees);
  // const pnlAfterFee = pnlWithoutFee.sub(_accruedFundingFee).sub(_accruedBorrowFee);
  const _pnlAfterFee = parseValue(pnlAfterFee, 30);
  // const percentPnl = _pnlAfterFee.mul(parseValue(100, 30)).div(parseValue(rawCollateral, 30));
  const prefix = _pnlAfterFee?.gt(0) ? "+" : _pnlAfterFee?.lt(0) ? "-" : "";
  const netValue = _pnlAfterFee.add(parseValue(rawCollateral, 30));

  const collateral = parseValue(rawCollateral, 30);
  const posQty = _size.mul(parseValue(1, 30)).div(_averagePrice);

  const _marketPrice = parseValue(marketPrice, 30);
  const _liquidPrice = parseValue(liquidPrice, 30);

  const LiqRisk = isV2
    ? getLiqRisk(_pnlAfterFee, collateral)
    : getLiqRiskV1({
        isLong,
        liquidPrice: _liquidPrice,
        markPrice: _marketPrice,
        entryPrice: _averagePrice,
        size: _size,
      });

  const stringId = `${openTime.toString()}-${key || id}-${openTime.toString()}`;
  const posIdHash = isV2 ? undefined : hashString(stringId);

  return {
    ...rawPosition,
    tokenSymbol,
    symbol,
    leverage,
    pnlWithoutFee,
    pnlAfterFee: _pnlAfterFee,
    // percentPnl,
    prefix,
    netValue,
    currentMarket,
    posQty,
    markPrice,
    LiqRisk,
    accruedFundingFee: _accruedFundingFee,
    accruedBorrowFee: _accruedBorrowFee,
    accruedPositionFee: _accruedPositionFee,
    isV2,
    posIdHash,
  };
};

export const formatOrder = (rawOrder, assets, marketTokensInfo, positionByOrder) => {
  const {
    tokenId,
    leverage: rawLeverage,
    id,
    timestamp,
    positionOpenTime,
    type,
    positionKey,
    size,
    positionSize,
    purchaseValue,
    orderType,
  } = rawOrder;
  const { tokenSymbol, symbol, decimals: tokenDisplayDecimal } = assets?.[tokenId] || {};
  const leverage = rawLeverage !== null ? parseValue(rawLeverage, 30) : undefined;
  const currentMarket = marketTokensInfo?.find((x) => x.id === tokenId);

  const isV2 = type === "V2";
  const stringId = `${positionOpenTime || timestamp.toString()}-${positionKey || id}-${
    positionOpenTime || timestamp.toString()
  }`;
  const posIdHash = isV2 ? undefined : hashString(stringId);

  const _size = Number(size);
  const _positionSize = Number(positionSize);
  const _collateral = Number(purchaseValue);
  const positionCollateral = positionByOrder?.collateral && Number(positionByOrder.collateral);

  let error;
  const isDecrease = ["TAKE_PROFIT", "STOP_LOSS"].includes(orderType);
  const isIncrease = orderType === "LIMIT_ORDER";
  if (isDecrease && _positionSize && _size > _positionSize) {
    error = "Order size is bigger than position, will only be executable if position increases.";
  }

  if (isIncrease && purchaseValue && Number(purchaseValue) < 10) {
    error =
      "Order collateral is smaller than min collateral ($10), will only be executable to increase an existing position.";
  }

  if (positionCollateral && _positionSize > _size && _positionSize - _size < positionCollateral - _collateral) {
    error = "Order cannot be executed as it would reduce the position's leverage below 1.";
  }

  return {
    ...rawOrder,
    tokenSymbol,
    symbol,
    tokenDisplayDecimal,
    leverage,
    currentMarket,
    isV2,
    posIdHash,
    error,
  };
};

export const formatHistory = (rawHistory, assets) => {
  const { tokenId, size, entryPrice, leverage, timestamp, positionKey, type, positionOpenTime } = rawHistory;
  const isV2 = type === "V2";
  const { tokenSymbol, symbol, decimals: tokenDisplayDecimal } = assets?.[tokenId] || {};
  // const leverage = BigNumber.from(tradeVolume || 0)
  //   .mul(parseValue("1", 30))
  //   .div(BigNumber.from(collateral || 0));

  const _size = parseValue(size, 30);
  const volume = _size.mul(parseValue(1, 30)).div(parseValue(entryPrice, 30));

  const stringId = `${positionOpenTime || timestamp.toString()}-${positionKey}-${
    positionOpenTime || timestamp.toString()
  }`;
  const posIdHash = isV2 ? undefined : hashString(stringId);

  return {
    ...rawHistory,
    tokenSymbol,
    symbol,
    tokenDisplayDecimal,
    leverage,
    size: _size,
    volume,
    posIdHash,
  };
};
