import { contractFetcher } from "lib/contracts";
import useSWR from "swr";

import PriceManager from "abis/PriceManager.json";
import { getContract } from "config/contracts";
import { bigNumberify, parseValue } from "lib/numbers";
import { useDebounce } from "lib/useDebounce";

const BASIS_POINTS_DIVISOR = 100000;

function useRedeemGm({
  chainId,
  fromValue,
  unstakingFee,
  gmPrice,
  isRedeem,
  totalUSD,
  totalSupplyBlp,
  destAddress,
  decimals,
}) {
  const usdcAddress = destAddress || getContract(chainId, "USDC");
  const priceManagerAddress = getContract(chainId, "PriceManager");

  const fromValueDebounce = useDebounce(fromValue, 500);

  let _unstakingFee;
  let usdAmountAfterFee;
  if (fromValueDebounce && unstakingFee && totalUSD && totalSupplyBlp && bigNumberify(totalSupplyBlp).gt(0)) {
    const usdAmount = parseValue(fromValueDebounce, 18)
      .mul(totalUSD)
      .div(totalSupplyBlp)
      .div(parseValue(1, 12 + (decimals || 6)));
    _unstakingFee = usdAmount.mul(unstakingFee).div(BASIS_POINTS_DIVISOR);
    usdAmountAfterFee = usdAmount.sub(_unstakingFee);
  }

  const { data: usdToToken } = useSWR(
    usdAmountAfterFee &&
      isRedeem && [`PriceManager:usdToToken:${usdAmountAfterFee}`, chainId, priceManagerAddress, "usdToToken"],
    {
      fetcher: contractFetcher(undefined, PriceManager, [usdcAddress, usdAmountAfterFee]),
    }
  );

  if (!isRedeem || !usdToToken) {
    return {};
  }
  // const outputRedeem = usdToToken.mul(parseValue(1, 24));
  const outputRedeem = usdToToken;

  return {
    outputRedeem,
    unstakingFee: _unstakingFee.mul(decimals > 6 ? parseValue(1, decimals - 6) : 1),
  };
}

export default useRedeemGm;
