import { t, Trans } from "@lingui/macro";
import cx from "classnames";
import { useUserReferralCodeV2 } from "domain/referrals";
import { useAffiliateCodes } from "domain/referrals";
import { toJpeg } from "html-to-image";
import gmyLogo from "img/logo_GMY.svg";
import gmyLogoV2 from "img/logo_GMY.svg";
import { default as copyIc } from "img/trade/copy.svg";
import { default as copyIcLight } from "img/icons/copy-icLight.svg";
import tweetIcLight from "img/icons/tweet-icLight.svg";
import downloadIcLight from "img/icons/download-icLight.svg";
import { default as copyIcDisabled } from "img/trade/copyDisabled.svg";
import tweetIc from "img/trade/tweet.svg";
import tweetIcDisabled from "img/trade/tweetDisabled.svg";
import downloadIc from "img/trade/download.svg";
import downloadIcDisabled from "img/trade/downloadDisabled.svg";
import icCheck from "img/icons/checkbox-ic.svg";
import icChecked from "img/icons/checkbox-ic1.svg";
import icCheckLight from "img/icons/checkbox-icLight.svg";
import icCheckedLight from "img/icons/checkbox-ic1Light.svg";

import shareBgImg from "img/trade/share_position.png";
import shareBgImgV2 from "img/trade/share_position_v2.png";
import { useChainId } from "lib/chains";
import downloadImage from "lib/downloadImage";
import { helperToast } from "lib/helperToast";
import { getAppBaseUrl, getHomeUrl, getRootShareApiUrl, getTwitterIntentURL, USD_DECIMALS } from "lib/legacy";
import { formatAmount, parseValue } from "lib/numbers";
import useLoadImage from "lib/useLoadImage";
import { TXT } from "pages/NFT/multichainTxt";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useRef, useState } from "react";
import { useCopyToClipboard, useMedia } from "react-use";
import SpinningLoader from "../Common/SpinningLoader";
import Modal from "../Modal/Modal";
import "./PositionShare.scss";
import { BigNumber } from "ethers";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { THEME_KEY } from "config/localStorage";
import { useThemeContext } from "contexts/ThemeProvider";
const ROOT_SHARE_URL = getRootShareApiUrl();
const UPLOAD_URL = ROOT_SHARE_URL + "/api/upload";
const UPLOAD_SHARE = ROOT_SHARE_URL + "/api/s";
const config = { quality: 0.95, canvasWidth: 518, canvasHeight: 292, type: "image/jpeg" };

function getShareURL(imageInfo, ref) {
  if (!imageInfo) return;
  let url = `${UPLOAD_SHARE}?id=${imageInfo.id}`;
  if (ref?.success && ref?.code) {
    url = url + `&ref=${ref.code}`;
  }
  return url;
}
export const getPositionNetValue = (position, markPrice) => {
  const { averagePrice, isLong, size } = position;

  const pnlWithoutFee = isLong
    ? markPrice.sub(parseValue(averagePrice, 30)).mul(parseValue(size, 30)).div(parseValue(averagePrice, 30))
    : parseValue(averagePrice, 30).sub(markPrice).mul(parseValue(size, 30)).div(parseValue(averagePrice, 30));

  const accruedFees =
    parseValue(position.accruedPositionFee, 30)
      .add(parseValue(position.accruedFundingFee, 30))
      .add(parseValue(position.accruedBorrowFee, 30)) || BigNumber.from(0);
  const accruedFeesWithoutPosition = parseValue(position.accruedFundingFee, 30).add(
    parseValue(position.accruedBorrowFee, 30)
  );
  const pnlWithFee = pnlWithoutFee.sub(accruedFees);
  // console.log("pnlWithoutFee", pnlWithFee);
  const percentPnl = pnlWithFee.mul(parseValue(100, 30)).div(parseValue(position.collateral, 30));
  const percentPnlWithoutFee = pnlWithoutFee.mul(parseValue(100, 30)).div(parseValue(position.collateral, 30));
  const pnlAfterPosition = pnlWithoutFee.sub(accruedFeesWithoutPosition);
  const pnl = pnlWithFee;
  return {
    percentPnl,
    pnl,
    pnlWithoutFee,
    pnlAfterPosition,
    percentPnlWithoutFee,
    netValue: pnlWithFee.add(parseValue(position.collateral, 30)),
    netValueWithoutFee: pnlWithoutFee.add(parseValue(position.collateral, 30)),
    prefix: pnl?.gt(0) ? "+" : pnl?.lt(0) ? "-" : "",
    prefixWithoutFee: pnlWithoutFee?.gt(0) ? "+" : pnlWithoutFee?.lt(0) ? "-" : "",
  };
};
function PositionClosedShare({
  setIsPositionShareModalOpen,
  isPositionShareModalOpen,
  selectedPosition,
  account,
  chainId,
  marketTokensInfo,
}) {
  const { isLightTheme } = useThemeContext();
  const currentMarket = marketTokensInfo?.find((x) => x.id === selectedPosition.tokenId);
  const { userReferralCode } = useUserReferralCodeV2(chainId, account);
  const userAffiliateCode = useAffiliateCodes(chainId, account);
  const [uploadedImageInfo, setUploadedImageInfo] = useState();
  const [uploadedImageError, setUploadedImageError] = useState();
  const [, copyToClipboard] = useCopyToClipboard();
  const sharePositionBgImg = useLoadImage(shareBgImgV2);
  const positionRef = useRef();
  const positionToShare = { ...selectedPosition };
  const tweetLink = getTwitterIntentURL(
    `Latest $${positionToShare?.symbol} trade on @yummyfi`,
    getShareURL(uploadedImageInfo, userAffiliateCode)
  );
  const isMobile = useMedia("(max-width: 800px)");
  useEffect(() => {
    (async function () {
      await handleUploadFile();
    })();
  }, [sharePositionBgImg]);

  async function handleDownload() {
    const element = positionRef.current;
    if (!element) return;
    const imgBlob = await toJpeg(element, config)
      .then(() => toJpeg(element, config))
      .then(() => toJpeg(element, config));
    downloadImage(imgBlob, "share.jpeg");
  }

  async function handleCopy() {
    // await handleUploadFile()
    if (!uploadedImageInfo) return;
    const url = getShareURL(uploadedImageInfo, userReferralCode);
    copyToClipboard(url);
    helperToast.success(`Link copied to clipboard.`);
  }

  const LIST = [
    {
      name: "Leverage",
      checked: true,
      value: "leverage",
    },
    {
      name: "ROI",
      checked: true,
      value: "pnl",
    },
    {
      name: "Price",
      checked: true,
      value: "price",
    },
  ];
  const [checkList, setCheckList] = useState(LIST);

  const handleCheck = (item) => {
    const newList = [...checkList];
    newList.map((checkbox) => {
      if (checkbox.value === item.value) {
        checkbox.checked = !item.checked;
      }
      return item;
    });

    setCheckList(newList);
  };

  async function handleUploadFile() {
    const element = positionRef.current;

    if (element && userAffiliateCode.success && sharePositionBgImg && positionToShare) {
      console.log("file: PositionShare.js:115 ### element:", element);
      // We have to call the toJpeg function multiple times to make sure the canvas renders all the elements like background image
      // @refer https://github.com/tsayen/dom-to-image/issues/343#issuecomment-652831863
      const image = await toJpeg(element, config)
        .then(() => toJpeg(element, config))
        .then(() => toJpeg(element, config));
      try {
        const imageInfo = await fetch(UPLOAD_URL, { method: "POST", body: image }).then((res) => res.json());
        setUploadedImageInfo(imageInfo);
      } catch (err) {
        setUploadedImageInfo(null);
        setUploadedImageError(`Image generation error, please refresh and try again.`);
      }
    }
  }
  return (
    <Modal
      className={`position-share-modal`}
      isVisible={isPositionShareModalOpen}
      setIsVisible={setIsPositionShareModalOpen}
      label={`Share Position`}
    >
      <PositionShareCard
        userAffiliateCode={userAffiliateCode}
        positionRef={positionRef}
        position={positionToShare}
        chainId={chainId}
        account={account}
        uploadedImageInfo={uploadedImageInfo}
        uploadedImageError={uploadedImageError}
        sharePositionBgImg={sharePositionBgImg}
        checkList={checkList}
        currentMarket={currentMarket}
        userReferralCode={userReferralCode}
      />
      {uploadedImageError && <span className="error">{uploadedImageError}</span>}
      <div className="checkbox-list">
        {checkList.map((item) => {
          return (
            <div key={item.name} className="checkbox-item">
              {item.checked ? (
                <img
                  className="img-check"
                  alt="img"
                  src={isLightTheme ? icCheckedLight : icChecked}
                  onClick={() => handleCheck(item)}
                />
              ) : (
                <img
                  className="img-check"
                  src={isLightTheme ? icCheckLight : icCheck}
                  alt="img"
                  onClick={() => handleCheck(item)}
                />
              )}
              {item.name}
            </div>
          );
        })}
      </div>

      <div className="actions">
        <button disabled={!uploadedImageInfo} className="mr-base App-button-option" onClick={handleCopy}>
          <img src={isLightTheme ? copyIcLight : copyIc} alt="img" className="icon" />
          {/* <img src={copyIcDisabled} alt="disabled" className="icon" /> */}
          <span>Copy</span>
        </button>
        <button disabled={!uploadedImageInfo} className="mr-base App-button-option" onClick={handleDownload}>
          <img src={isLightTheme ? downloadIcLight : downloadIc} alt="img" className="icon" />
          {/* <img src={downloadIcDisabled} alt="disabled" className="icon" /> */}
          <span>Download</span>
        </button>

        <button className={cx("App-button-option", { disabled: !uploadedImageInfo })}>
          <a
            target="_blank"
            className={cx("tweet-link", { disabled: !uploadedImageInfo })}
            rel="noreferrer"
            href={tweetLink}
          >
            <img src={isLightTheme ? tweetIcLight : tweetIc} alt="img" className="icon" />
            {/* <img src={tweetIcDisabled} alt="disabled" className="icon" /> */}
            <span>Tweet</span>
          </a>
        </button>
      </div>
    </Modal>
  );
}

function PositionShareCard({
  positionRef,
  position,
  userAffiliateCode,
  uploadedImageInfo,
  uploadedImageError,
  sharePositionBgImg,
  checkList,
  currentMarket,
  userReferralCode,
}) {
  const { chainId } = useChainId();
  const isMobile = useMedia("(max-width: 400px)");
  // const { code, success } = userAffiliateCode;
  const { code, success } = userAffiliateCode;
  const {
    deltaPercentageStr = "-",
    deltaStr,
    isLong,
    leverage,
    indexToken,
    averagePrice,
    markPrice,
    positionNetValue,
    symbol,
    totalROI,
    realisedPnl,
  } = position;

  const homeURL = getAppBaseUrl();
  return (
    <div className="relative">
      <div ref={positionRef} className="position-share" style={{ backgroundImage: `url(${sharePositionBgImg})` }}>
        <div className="logo_wrapper">
          <img className="logo" src={gmyLogoV2} alt="Gugmy Logo" />
          {/* <img className="chain_img" src={TXT[chainId].chainLogoShare} alt="img" /> */}
        </div>
        <ul className="info">
          <li className={`side ${isLong ? "long" : "short"}`}>{isLong ? "Long" : "Short"}</li>
          {checkList[0].checked && <li>{leverage}x&nbsp;</li>}

          <li>{symbol}/USD</li>
        </ul>
        {checkList[1].checked && (
          <h3 className={`pnl ${Number(totalROI) < 0 ? "negative" : "positive"}`}>
            {Number(totalROI) < 0 ? "-" : ""}
            {formatAmount(totalROI, 3, 2, true).replace("-", "")}%
            {/* <div className="change-dollar">
              ({Number(totalROI) < 0 ? "-" : ""}$
              {formatAmount(realisedPnl, 30, 2, true).replace("-", "")})
            </div> */}
          </h3>
        )}

        {checkList[2].checked && (
          <div className="prices">
            <div style={{ width: "fit-content" }}>
              <p className="muted" style={{ whiteSpace: "nowrap" }}>
                Entry price:
              </p>
              <p className="price" style={{ whiteSpace: "nowrap" }}>
                ${formatAmount(averagePrice, 30, currentMarket?.decimals, true)}
              </p>
            </div>
            <div style={{ width: "fit-content" }}>
              <p className="muted" style={{ whiteSpace: "nowrap" }}>
                Mark price:
              </p>
              <p className="price" style={{ whiteSpace: "nowrap" }}>
                ${formatAmount(markPrice, 30, currentMarket?.decimals, true)}
              </p>
            </div>
          </div>
        )}

        <div className="referral-code">
          <div>
            <QRCodeSVG
              size={isMobile ? 36 : 56}
              value={userAffiliateCode?.code ? `${homeURL}/#/?ref=${userAffiliateCode.code}` : `${homeURL}`}
            />
          </div>
          <div className="referral-code-info">
            {success && code ? (
              <>
                <p className="label">Referral code</p>
                <p className="code">{code}</p>
              </>
            ) : (
              <>
                <p className="label">Trade now on</p>
                <p className="code">yummy.fi</p>
              </>
            )}
          </div>
        </div>
      </div>
      {!uploadedImageInfo && !uploadedImageError && (
        <div className="image-overlay-wrapper">
          <div className="image-overlay">
            <SpinningLoader />
            <p className="loading-text">
              <span>Generating shareable image...</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default PositionClosedShare;
