import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// import GMY_ETH from "img/GMY-ETH.svg";
// import opBackdrop from "img/home/opBackdrop.svg";

import { ARBITRUM, BERACHAIN_BARTIO, FANTOM } from "config/chains";
import { formatAmountFree, formatNumber } from "lib/numbers";

import { useThemeContext } from "contexts/ThemeProvider";
import useProcessedData from "hooks/useProcessedData";
import useSwitchChain from "hooks/useSwitchChain";
import { caculateAPYHourly } from "lib/helper";
import Tokens from "./Tokens";

// const GMY_WETH_Address = "0x104d0312f95a1b3056435d19668f2272ab1e7df2"; // fix me later

const YieldVault = ({ chainId, active, library }) => {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const [switchChain] = useSwitchChain(chainId);

  // const processedDataFANTOM = useProcessedData(FANTOM);
  // const processedDataOP = useProcessedData(OP);
  const processedDataBARTIO = useProcessedData(BERACHAIN_BARTIO);

  const [statsGMYWFTM, setStatsGMYWFTM] = useState({
    price: 0,
    apr: 10,
  });

  // const [statsGMYWETH, setStatsGMYWETH] = useState({
  //   price: 0,
  //   apr: 1,
  // });

  const totalApyGMY_FTM = statsGMYWFTM.apr
    ? formatNumber(String(caculateAPYHourly(Number(statsGMYWFTM.apr), 4080)), 2)
    : "--";

  let aprTotalGmy;
  let apyGmy;
  // let dailyApyGmy;
  aprTotalGmy = parseFloat(formatAmountFree(processedDataBARTIO?.gmyAprTotal, 2));
  apyGmy = aprTotalGmy ? formatNumber(caculateAPYHourly(aprTotalGmy, 4080), 2) : null;
  // dailyApyGmy = aprTotalGmy ? formatNumber(caculateAPYDaily(aprTotalGmy, 1), 4) : null;

  let aprTotalGlp;
  let apyGlp;
  // let dailyApyGlp;
  aprTotalGlp = parseFloat(formatAmountFree(processedDataBARTIO?.glpAprTotal, 2));
  apyGlp = aprTotalGlp ? formatNumber(caculateAPYHourly(aprTotalGlp, 4080), 2) : null;

  // let aprTotalGmyARB;
  // let apyGmyARB;
  // // let dailyApyGmyARB;
  // aprTotalGmyARB = parseFloat(formatAmountFree(processedDataARB?.gmyAprTotal, 2));
  // apyGmyARB = aprTotalGmyARB ? formatNumber(caculateAPYHourly(aprTotalGmyARB, 4080), 2) : null;
  // // dailyApyGmyARB = aprTotalGmyARB ? formatNumber(caculateAPYDaily(aprTotalGmyARB, 1), 4) : null;

  // let aprTotalGlpARB;
  // let apyGlpARB;
  // // let dailyApyGlpARB;
  // aprTotalGlpARB = parseFloat(formatAmountFree(processedDataARB?.glpAprTotal, 2));
  // apyGlpARB = aprTotalGlpARB ? formatNumber(caculateAPYHourly(aprTotalGlpARB, 4080), 2) : null;
  // // dailyApyGlpARB = aprTotalGlpARB ? formatNumber(caculateAPYDaily(aprTotalGlpARB, 1), 4) : null;

  const apy = {
    apyGmy: {
      [BERACHAIN_BARTIO]: apyGmy,
      // [ARBITRUM]: apyGmyARB,
    },
    apyGlp: {
      [BERACHAIN_BARTIO]: apyGlp,
      // [ARBITRUM]: apyGlpARB,
    },
    apyGMY_FTM: {
      [BERACHAIN_BARTIO]: totalApyGMY_FTM,
    },
    priceGMY_FTM: {
      [BERACHAIN_BARTIO]: statsGMYWFTM.price,
    },
  };

  return (
    <Wrapper>
      <Tokens active={active} chainId={chainId} library={library} apy={apy} />
    </Wrapper>
  );
};

const TokenCardContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 767px) {
    margin-top: 16px;
    gap: 16px;
  }

  .content-glp {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--base-unit-xs-8, 8px);
  align-self: stretch;

  & > span {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
  }

  .desc {
    color: var(--white-60, rgba(255, 255, 255, 0.6));
    font-size: 16px;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
`;

const TokenItem = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #ffffff1a;
  border-radius: 16px;
  flex: 1;
  background: linear-gradient(90deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);

  &.op {
    background: linear-gradient(90deg, rgba(255, 4, 32, 0.1) 0%, rgba(255, 4, 32, 0) 100%);
  }

  .info {
    .symbol {
      display: flex;
      align-items: center;
      gap: 12px;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;

      img[alt="gmy_icon"] {
        width: 36px;
        height: 36px;
      }
      img:not([alt="gmy_icon"]) {
        width: 54px;
      }
    }

    .backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
    }

    .stats {
      display: flex;
      gap: 16px;
      margin-top: 16px;

      .stat-row {
        color: #ffffff99;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;

        .value {
          color: #176ce5;

          &.skeleton-box {
            width: 50px !important;
          }
        }

        &:last-child {
          padding-left: 16px;
          border-left: 1px solid #ffffff1a;
        }
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ViewPoolBtn = styled(Link)`
  width: 32px;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #080715;
  background-color: #176ce5;
  padding: 4px;
  border-radius: 8px;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 12px;
  &:hover {
    background: #4787e3;
    color: #080715 !important;
  }
`;

const Wrapper = styled.div`
  margin: 64px 0 0;

  @media (max-width: 767px) {
    margin: 36px 0 0;

    div.Tooltip-popup:has(.customTooltip) {
      transform: translateX(-100px);
    }
  }

  &.theme--light {
    .desc {
      color: #0d1a16 !important;
    }

    ${TokenItem} {
      border-color: var(--Border, rgba(13, 26, 22, 0.07));
      background: linear-gradient(90deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);

      &.arb {
        background: linear-gradient(90deg, rgba(18, 140, 255, 0.1) 0%, rgba(4, 134, 255, 0) 100%);
      }

      .stat-row {
        color: var(--Text-Text_Primary, #0d1a16);

        .value {
          color: var(--Primary, #02b27f);
        }
      }
    }

    ${ViewPoolBtn} {
      background: #02b27f;
    }
  }
`;

export default YieldVault;
