import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { ethers } from "ethers";
import { getContract } from "config/contracts";
import {
  getBuyGlpToAmount,
  getBuyGlpFromAmount,
  getSellGlpFromAmount,
  getSellGlpToAmount,
  adjustForDecimals,
  GLP_DECIMALS,
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  GLP_COOLDOWN_DURATION,
  USDG_DECIMALS,
  PLACEHOLDER_ACCOUNT,
} from "lib/legacy";

import ReaderV2 from "abis/ReaderV2.json";
import GlpManager from "abis/GlpManager.json";
import RewardTracker from "abis/RewardTracker.json";
import Vester from "abis/Vester.json";
import RewardRouter from "abis/RewardRouter.json";
import Token from "abis/Token.json";

import glp24Icon from "img/mglp.svg";
import arrowIcon from "img/ic_arrow.svg";

import { getChainName, IS_NETWORK_DISABLED, BASE } from "config/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens, useInfoTokens } from "domain/tokens";
import { useLocalStorageByChainId } from "lib/localStorage";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { getToken, getTokens, getWhitelistedTokens } from "config/tokens";
import { useChainId } from "lib/chains";
import Tooltip from "components/Tooltip/Tooltip";
import Tab from "components/Tab/Tab";
import BuyInputSection from "components/BuyInputSection/BuyInputSection";
import TokenSelector from "components/Exchange/TokenSelector";
import { helperToast } from "lib/helperToast";
import Countdown from "react-countdown";

const { AddressZero } = ethers.constants;

export default function BuySellMglp({
  savedSlippageAmount,
  isBuying,
  setPendingTxns,
  connectWallet,
  setIsBuying,
  savedShouldDisableValidationForTesting,
  glpValue,
  setGlpValue,
  glpAmount,
  glpPrice,
  usdgSupply,
  totalTokenWeights,
}) {
  const history = useHistory();
  const swapLabel = isBuying ? "Buy MGLP" : "Sell MGLP";
  const tabLabel = isBuying ? `Buy MGLP` : `Sell MGLP`;
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();
  // const chainName = getChainName(chainId)
  const tokens = getTokens(chainId);
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const [swapValue, setSwapValue] = useState("");
  // const [glpValue, setGlpValue] = useState("");
  const [swapTokenAddress, setSwapTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${swapLabel}-swap-token-address2`,
    AddressZero
  );
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [anchorOnSwapAmount, setAnchorOnSwapAmount] = useState(true);
  const [feeBasisPoints, setFeeBasisPoints] = useState("");

  const readerAddress = getContract(chainId, "Reader");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const feeGlpTrackerAddress = getContract(chainId, "FeeGlpTracker");
  const glpManagerAddress = getContract(chainId, "GlpManager");
  const rewardRouterAddress = getContract(chainId, "RewardRouter");

  const tokenAddresses = tokens.map((token) => token.address);
  const { data: tokenBalances } = useSWR(
    [
      `BuySellMglp:getTokenBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
      refreshInterval: 30000,
    }
  );

  const tokenAllowanceAddress = swapTokenAddress === AddressZero ? nativeTokenAddress : swapTokenAddress;
  const { data: tokenAllowance } = useSWR(
    [active, chainId, tokenAllowanceAddress, "allowance", account || PLACEHOLDER_ACCOUNT, glpManagerAddress],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 30000,
    }
  );

  const { data: lastPurchaseTime } = useSWR(
    [
      `BuySellMglp:lastPurchaseTime:${active}`,
      chainId,
      glpManagerAddress,
      "lastAddedAt",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, GlpManager),
      refreshInterval: 30000,
    }
  );

  const { data: glpBalance } = useSWR(
    [
      `BuySellMglp:glpBalance:${active}`,
      chainId,
      feeGlpTrackerAddress,
      "stakedAmounts",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardTracker),
      refreshInterval: 30000,
    }
  );

  const glpVesterAddress = getContract(chainId, "GlpVester");
  const { data: reservedAmount } = useSWR(
    [`BuySellMglp:reservedAmount:${active}`, chainId, glpVesterAddress, "pairAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, Vester),
      refreshInterval: 30000,
    }
  );

  const glpVesterV1Address = getContract(chainId, "GlpVesterV1");
  const { data: reservedAmountV1 } = useSWR(
    chainId !== BASE && [
      `BuySellMglp:reservedAmountV1:${active}`,
      chainId,
      glpVesterV1Address,
      "pairAmounts",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, Vester),
      refreshInterval: 30000,
    }
  );

  const redemptionTime = lastPurchaseTime ? lastPurchaseTime.add(GLP_COOLDOWN_DURATION) : undefined;
  const inCooldownWindow = redemptionTime && parseInt(Date.now() / 1000) < redemptionTime;

  var maxSellAmount = glpBalance;
  if (glpBalance && reservedAmount) {
    maxSellAmount = maxSellAmount.sub(reservedAmount);
  }
  if (glpBalance && reservedAmountV1) {
    maxSellAmount = maxSellAmount.sub(reservedAmountV1);
  }
  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  const swapToken = getToken(chainId, swapTokenAddress);
  const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);

  const swapTokenBalance = swapTokenInfo && swapTokenInfo.balance ? swapTokenInfo.balance : bigNumberify(0);

  const swapAmount = parseValue(swapValue, swapToken && swapToken.decimals);

  const needApproval =
    isBuying && swapTokenAddress !== AddressZero && tokenAllowance && swapAmount && swapAmount.gt(tokenAllowance);

  const swapUsdMin = getUsd(swapAmount, swapTokenAddress, false, infoTokens);
  const glpUsdMax = glpAmount && glpPrice ? glpAmount.mul(glpPrice).div(expandDecimals(1, GLP_DECIMALS)) : undefined;

  let isSwapTokenCapReached;
  if (swapTokenInfo.managedUsd && swapTokenInfo.maxUsdgAmount) {
    isSwapTokenCapReached = swapTokenInfo.managedUsd.gt(
      adjustForDecimals(swapTokenInfo.maxUsdgAmount, USDG_DECIMALS, USD_DECIMALS)
    );
  }

  const onSwapValueChange = (e) => {
    setAnchorOnSwapAmount(true);
    setSwapValue(e.target.value);
  };

  const onGlpValueChange = (e) => {
    setAnchorOnSwapAmount(false);
    setGlpValue(e.target.value);
  };

  const onSelectSwapToken = (token) => {
    setSwapTokenAddress(token.address);
    setIsWaitingForApproval(false);
  };

  useEffect(() => {
    const updateSwapAmounts = () => {
      if (anchorOnSwapAmount) {
        if (!swapAmount) {
          setGlpValue("");
          setFeeBasisPoints("");
          return;
        }

        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyGlpToAmount(
            swapAmount,
            swapTokenAddress,
            infoTokens,
            glpPrice,
            usdgSupply,
            totalTokenWeights
          );
          const nextValue = formatAmountFree(nextAmount, GLP_DECIMALS, GLP_DECIMALS);

          setGlpValue(nextValue);
          setFeeBasisPoints(feeBps);
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getSellGlpFromAmount(
            swapAmount,
            swapTokenAddress,
            infoTokens,
            glpPrice,
            usdgSupply,
            totalTokenWeights
          );
          const nextValue = formatAmountFree(nextAmount, GLP_DECIMALS, GLP_DECIMALS);
          setGlpValue(nextValue);
          setFeeBasisPoints(feeBps);
        }

        return;
      }

      if (!glpAmount) {
        setSwapValue("");
        setFeeBasisPoints("");
        return;
      }

      if (swapToken) {
        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyGlpFromAmount(
            glpAmount,
            swapTokenAddress,
            infoTokens,
            glpPrice,
            usdgSupply,
            totalTokenWeights
          );
          const nextValue = formatAmountFree(nextAmount, swapToken.decimals, swapToken.decimals);
          setSwapValue(nextValue);
          setFeeBasisPoints(feeBps);
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getSellGlpToAmount(
            glpAmount,
            swapTokenAddress,
            infoTokens,
            glpPrice,
            usdgSupply,
            totalTokenWeights,
            true
          );

          const nextValue = formatAmountFree(nextAmount, swapToken.decimals, swapToken.decimals);
          setSwapValue(nextValue);
          setFeeBasisPoints(feeBps);
        }
      }
    };

    updateSwapAmounts();
  }, [
    isBuying,
    anchorOnSwapAmount,
    swapAmount,
    glpAmount,
    swapToken,
    swapTokenAddress,
    infoTokens,
    glpPrice,
    usdgSupply,
    totalTokenWeights,
  ]);

  const switchSwapOption = (hash = "") => {
    history.push(`${history.location.pathname}#${hash}`);
    setIsBuying(hash === "redeem" ? false : true);
  };

  const fillMaxAmount = () => {
    if (isBuying) {
      setAnchorOnSwapAmount(true);
      setSwapValue(formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals));
      return;
    }

    setAnchorOnSwapAmount(false);
    setGlpValue(formatAmountFree(maxSellAmount, GLP_DECIMALS, GLP_DECIMALS));
  };

  const getError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      if (isBuying) return [`MGLP buy disabled, pending ${getChainName(chainId)} upgrade`];
      return [`MGLP sell disabled, pending ${getChainName(chainId)} upgrade`];
    }

    if (!isBuying && inCooldownWindow) {
      return [`Redemption time not yet reached`];
    }

    if (!swapAmount || swapAmount.eq(0)) {
      return [`Enter an amount`];
    }
    if (!glpAmount || glpAmount.eq(0)) {
      return [`Enter an amount`];
    }

    if (isBuying) {
      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
      if (
        !savedShouldDisableValidationForTesting &&
        swapTokenInfo &&
        swapTokenInfo.balance &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.balance)
      ) {
        return [`Insufficient ${swapTokenInfo.symbol} balance`];
      }

      if (swapTokenInfo.maxUsdgAmount && swapTokenInfo.usdgAmount && swapUsdMin) {
        const usdgFromAmount = adjustForDecimals(swapUsdMin, USD_DECIMALS, USDG_DECIMALS);
        const nextUsdgAmount = swapTokenInfo.usdgAmount.add(usdgFromAmount);
        if (swapTokenInfo.maxUsdgAmount.gt(0) && nextUsdgAmount.gt(swapTokenInfo.maxUsdgAmount)) {
          return [`${swapTokenInfo.symbol} pool exceeded, try different token`, true];
        }
      }
    }

    if (!isBuying) {
      if (maxSellAmount && glpAmount && glpAmount.gt(maxSellAmount)) {
        return [`Insufficient MGLP balance`];
      }

      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
      if (
        swapTokenInfo &&
        swapTokenInfo.availableAmount &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.availableAmount)
      ) {
        return [`Insufficient liquidity`];
      }
    }

    return [false];
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (!active) {
      return true;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (isBuying && isSwapTokenCapReached) {
      return false;
    }

    if (inCooldownWindow) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (!active) {
      return `Connect Wallet`;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }
    if (isBuying && isSwapTokenCapReached) {
      return `Max Capacity for ${swapToken.symbol} Reached`;
    }

    if (needApproval && isWaitingForApproval) {
      return `Waiting for Approval`;
    }
    if (isApproving) {
      return `Approving ${swapToken.symbol}...`;
    }
    if (needApproval) {
      return `Approve ${swapToken.symbol}`;
    }

    if (isSubmitting) {
      return isBuying ? `Buying...` : `Selling...`;
    }

    if (inCooldownWindow) {
      return "Cooldown to sell MGLP";
    }

    return isBuying ? `Buy MGLP` : `Sell MGLP`;
  };

  const approveFromToken = () => {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: swapToken.address,
      spender: glpManagerAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      infoTokens,
      getTokenInfo,
    });
  };

  const buyGlp = () => {
    setIsSubmitting(true);

    const minGlp = glpAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    const method = swapTokenAddress === AddressZero ? "mintAndStakeGlpETH" : "mintAndStakeGlp";
    const params = swapTokenAddress === AddressZero ? [0, minGlp] : [swapTokenAddress, swapAmount, 0, minGlp];
    const value = swapTokenAddress === AddressZero ? swapAmount : 0;

    callContract(chainId, contract, method, params, {
      value,
      sentMsg: `Buy submitted.`,
      failMsg: `Buy failed.`,
      successMsg: `${formatAmount(glpAmount, 18, 4, true)} MGLP bought with ${formatAmount(
        swapAmount,
        swapTokenInfo.decimals,
        4,
        true
      )} ${swapTokenInfo.symbol}!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const sellGlp = () => {
    setIsSubmitting(true);

    const minOut = swapAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    const method = swapTokenAddress === AddressZero ? "unstakeAndRedeemGlpETH" : "unstakeAndRedeemGlp";
    const params =
      swapTokenAddress === AddressZero ? [glpAmount, minOut, account] : [swapTokenAddress, glpAmount, minOut, account];

    callContract(chainId, contract, method, params, {
      sentMsg: `Sell submitted!`,
      failMsg: `Sell failed.`,
      successMsg: `${formatAmount(glpAmount, 18, 4, true)} MGLP sold for ${formatAmount(
        swapAmount,
        swapTokenInfo.decimals,
        4,
        true
      )} ${swapTokenInfo.symbol}!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onClickPrimary = () => {
    if (!active) {
      connectWallet();
      return;
    }

    if (needApproval) {
      approveFromToken();
      return;
    }

    const [err, modal] = getError();

    if (modal) {
      helperToast.error(err);
      return;
    }

    if (isBuying) {
      buyGlp();
    } else {
      sellGlp();
    }
  };

  let payLabel = `Pay`;
  let receiveLabel = `Receive`;
  let payBalance = "$0.00";
  let receiveBalance = "$0.00";
  if (isBuying) {
    if (swapUsdMin) {
      payBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    }
    if (glpUsdMax) {
      receiveBalance = `$${formatAmount(glpUsdMax, USD_DECIMALS, 2, true)}`;
    }
  } else {
    if (glpUsdMax) {
      payBalance = `$${formatAmount(glpUsdMax, USD_DECIMALS, 2, true)}`;
    }
    if (swapUsdMin) {
      receiveBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    }
  }

  let feePercentageText = formatAmount(feeBasisPoints, 2, 2, true, "-");
  if (feeBasisPoints !== undefined && feeBasisPoints.toString().length > 0) {
    feePercentageText += "%";
  }

  const onSwapOptionChange = (opt) => {
    if (opt === `Sell MGLP`) {
      switchSwapOption("redeem");
    } else {
      switchSwapOption();
    }
  };

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  return (
    <>
      <Tab options={[`Buy MGLP`, `Sell MGLP`]} option={tabLabel} onChange={onSwapOptionChange} />
      {isBuying && (
        <BuyInputSection
          topLeftLabel={payLabel}
          topRightLabel={`Balance:`}
          tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
          inputValue={swapValue}
          onInputValueChange={onSwapValueChange}
          showMaxButton={swapValue !== formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals)}
          onClickTopRightLabel={fillMaxAmount}
          onClickMax={fillMaxAmount}
          selectedToken={swapToken}
          balance={payBalance}
          onlyNumber
        >
          <TokenSelector
            label={`Pay`}
            chainId={chainId}
            tokenAddress={swapTokenAddress}
            onSelectToken={onSelectSwapToken}
            tokens={whitelistedTokens}
            infoTokens={infoTokens}
            showSymbolImage={true}
            showTokenImgInDropdown={true}
          />
        </BuyInputSection>
      )}

      {!isBuying && (
        <BuyInputSection
          topLeftLabel={payLabel}
          topRightLabel={`Available:`}
          tokenBalance={`${formatAmount(maxSellAmount, GLP_DECIMALS, 4, true)}`}
          inputValue={glpValue}
          onInputValueChange={onGlpValueChange}
          showMaxButton={glpValue !== formatAmountFree(maxSellAmount, GLP_DECIMALS, GLP_DECIMALS)}
          onClickTopRightLabel={fillMaxAmount}
          onClickMax={fillMaxAmount}
          balance={payBalance}
          defaultTokenName={"MGLP"}
          onlyNumber
        >
          <div className="selected-token">
            <img src={glp24Icon} alt="glp24Icon" />
            MGLP
          </div>
        </BuyInputSection>
      )}

      <div className="AppOrder-ball-container">
        <div className="AppOrder-ball">
          <img
            src={arrowIcon}
            alt="arrowIcon"
            onClick={() => {
              setIsBuying(!isBuying);
              switchSwapOption(isBuying ? "redeem" : "");
            }}
          />
        </div>
      </div>

      {isBuying && (
        <BuyInputSection
          topLeftLabel={receiveLabel}
          topRightLabel={`Balance:`}
          tokenBalance={`${formatAmount(glpBalance, GLP_DECIMALS, 4, true)}`}
          inputValue={glpValue}
          onInputValueChange={onGlpValueChange}
          balance={receiveBalance}
          defaultTokenName={"MGLP"}
          onlyNumber
        >
          <div className="selected-token">
            <img src={glp24Icon} alt="glp24Icon" />
            MGLP
          </div>
        </BuyInputSection>
      )}

      {!isBuying && (
        <BuyInputSection
          topLeftLabel={receiveLabel}
          topRightLabel={`Balance:`}
          tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
          inputValue={swapValue}
          onInputValueChange={onSwapValueChange}
          balance={receiveBalance}
          selectedToken={swapToken}
          onlyNumber
        >
          <TokenSelector
            label={`Receive`}
            chainId={chainId}
            tokenAddress={swapTokenAddress}
            onSelectToken={onSelectSwapToken}
            tokens={whitelistedTokens}
            infoTokens={infoTokens}
            className="BuySellMglp-from-token"
            showSymbolImage={true}
            showTokenImgInDropdown={true}
          />
        </BuyInputSection>
      )}

      <div>
        <div className="Exchange-info-row">
          <div className="Exchange-info-label">MGLP price</div>
          <div className="align-right fee-block">
            {glpPrice > 0 && <div className="text-white">{formatAmount(glpPrice, USD_DECIMALS, 2, true)} USD</div>}
          </div>
        </div>
        <div className="Exchange-info-row">
          <div className="Exchange-info-label">{feeBasisPoints > 50 ? `WARNING: High Fees` : `Fees`}</div>
          <div className="align-right fee-block">
            {isBuying && (
              <Tooltip
                handle={isBuying && isSwapTokenCapReached ? "NA" : feePercentageText}
                position="right-bottom"
                renderContent={() => {
                  if (!feeBasisPoints) {
                    return (
                      <div className="text-white">
                        <span>Fees will be shown once you have entered an amount in the order form.</span>
                      </div>
                    );
                  }
                  return (
                    <div className="text-white">
                      {feeBasisPoints > 50 && <span>To reduce fees, select a different asset to pay with.</span>}
                      <span>Check the "Save on Fees" section below to get the lowest fee percentages.</span>
                    </div>
                  );
                }}
              />
            )}
            {!isBuying && (
              <Tooltip
                handle={feePercentageText}
                position="right-bottom"
                renderContent={() => {
                  if (!feeBasisPoints) {
                    return (
                      <div className="text-white">
                        <span>Fees will be shown once you have entered an amount in the order form.</span>
                      </div>
                    );
                  }
                  return (
                    <div className="text-white">
                      {feeBasisPoints > 50 && <span>To reduce fees, select a different asset to receive.</span>}
                      <span>Check the "Save on Fees" section below to get the lowest fee percentages.</span>
                    </div>
                  );
                }}
              />
            )}
          </div>
        </div>
        {!isBuying && (
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">
              <Tooltip
                handle="Cooldown period"
                renderContent={() => {
                  return "There is a 10-minute cooldown period after each stake.";
                }}
              />
            </div>

            <div className="align-right fee-block">
              <Countdown
                key={redemptionTime}
                date={new Date(Number(redemptionTime) * 1000)}
                renderer={countdownRenderer}
                daysInHours
              />
            </div>
          </div>
        )}
      </div>
      <div className="BuySellMglp-cta Exchange-swap-button-container">
        <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
          {getPrimaryText()}
        </button>
      </div>
    </>
  );
}

const countdownRenderer = (countdownProps) => {
  const {
    formatted: { hours, minutes, seconds },
  } = countdownProps;
  return <>{`${hours}:${minutes}:${seconds}`}</>;
};
