import { getServerBaseUrl } from "config/backend";
import { useEffect, useState } from "react";
import axios from "axios";
import { parseValue } from "lib/numbers";

const DEFAULT_DATA = {
  portfolioValue: 0,
  totalCollateral: 0,
  totalSize: 0,
  totalPendingPnl: 0,
  totalFee: 0,
  freeCollateral: 0,
  marginUsage: 0,
  openLongs: 0,
  openShorts: 0,
  walletBalance: [],
};

export const usePortfolio1 = (chainId, account) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(DEFAULT_DATA);

  useEffect(() => {
    if (account) {
      const fetchPortfolioData = async () => {
        try {
          const { data: response } = await axios.get(`https://api.yummy.fi/stats/bartio/portfolio/${account}`);
          if (response.status === 200) {
            setData(response.data);
          } else {
            setData(DEFAULT_DATA);
          }

          // const formatted = {};

          // for (const [key, value] of Object.entries(response.data)) {
          //   formatted[key] = parseValue(value, 30);
          // }
        } finally {
          setIsLoading(false);
        }
      };
      fetchPortfolioData();
      const interval = setInterval(fetchPortfolioData, 30000);

      return () => clearInterval(interval);
    } else {
      setIsLoading(false);
      setData(DEFAULT_DATA);
    }
  }, [account, chainId]);

  return {
    data,
    isLoading,
  };
};
