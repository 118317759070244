function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <path
        fill="#13B5EC"
        fillRule="evenodd"
        d="M6.334 13.864c0-.419.339-.758.757-.758h11.364a.758.758 0 110 1.515H7.091a.758.758 0 01-.757-.757z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#13B5EC"
        fillRule="evenodd"
        d="M14.889 10.298a.758.758 0 011.071 0l3.03 3.03a.758.758 0 010 1.071l-3.03 3.03a.758.758 0 11-1.071-1.07l2.495-2.495-2.495-2.495a.758.758 0 010-1.071zM2.546 6.288c0-.419.34-.758.757-.758h12.122a.758.758 0 110 1.515H3.303a.758.758 0 01-.757-.757z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#13B5EC"
        fillRule="evenodd"
        d="M6.87 2.722a.758.758 0 010 1.071L4.374 6.288l2.494 2.495a.758.758 0 11-1.07 1.07l-3.031-3.03a.758.758 0 010-1.07l3.03-3.031a.758.758 0 011.071 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
