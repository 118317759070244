import axios from "axios";
import { useChainId } from "lib/chains";
import { useEffect, useState } from "react";
import { getServerBaseUrl, getServerUrl } from "config/backend";
import { FANTOM, SUPPORTED_V2_CHAINS } from "config/chains";
import { hashString } from "lib/legacy";

type Params = {
  pageSize: number;
};

export const DEFAULT_PAGE_SIZE_CLOSED = 10;


const useUserPositionActivitiesV1 = (account: string | undefined | null, posId: string) => {
  const [positions, setPositions] = useState([]);
  const [details, setDetails] = useState(undefined);
  const { chainId } = useChainId();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (account && chainId) {
      const fetchPositions = async () => {
        const { data: responseData } = await axios.get(`https://api.yummy.fi/stats/bartio/position-detail/${posId}`);
        if (responseData?.data) {
          setPositions(responseData?.data?.activities);
          setDetails(responseData?.data);
        }
        setIsLoading(false);
      };

      fetchPositions();

      const interval = setInterval(async () => {
        fetchPositions();
      }, 10000);
      return () => clearInterval(interval);
    } else {
      setIsLoading(false);
      setPositions([]);
      setDetails(undefined);
    }
  }, [chainId, account]);

  return { positions, isLoading, details };
};
export default useUserPositionActivitiesV1;
