import { Trans } from "@lingui/macro";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { BigNumber } from "ethers";
import { formatKeyAmount } from "lib/numbers";

type Props = {
  processedData: {
    gmyAprForEsGmy: BigNumber;
    gmyAprForNativeToken: BigNumber;
    gmyAprForNativeTokenWithBoost: BigNumber;
    gmyBoostAprForNativeToken?: BigNumber;
  };
  nativeTokenSymbol: string;
};

export default function GMYAprTooltip({ processedData, nativeTokenSymbol }: Props) {
  return (
    <>
      <StatsTooltipRow
        label="Escrowed YUM APR"
        showDollar={false}
        value={`${formatKeyAmount(processedData, "gmyAprForEsGmy", 2, 2, true)}%`}
      />
      {(!processedData.gmyBoostAprForNativeToken || processedData.gmyBoostAprForNativeToken.eq(0)) && (
        <StatsTooltipRow
          label={`${nativeTokenSymbol} APR`}
          showDollar={false}
          value={`${formatKeyAmount(processedData, "gmyAprForNativeToken", 2, 2, true)}%`}
        />
      )}
      {processedData.gmyBoostAprForNativeToken && processedData.gmyBoostAprForNativeToken.gt(0) && (
        <div>
          <StatsTooltipRow
            label={`${nativeTokenSymbol} Base APR`}
            showDollar={false}
            value={`${formatKeyAmount(processedData, "gmyAprForNativeToken", 2, 2, true)}%`}
          />
          <StatsTooltipRow
            label={`${nativeTokenSymbol} Boosted APR`}
            showDollar={false}
            value={`${formatKeyAmount(processedData, "gmyBoostAprForNativeToken", 2, 2, true)}%`}
          />
          <div className="Tooltip-divider" />
          <StatsTooltipRow
            label={`${nativeTokenSymbol} Total APR`}
            showDollar={false}
            value={`${formatKeyAmount(processedData, "gmyAprForNativeTokenWithBoost", 2, 2, true)}%`}
          />
          <div style={{ marginTop: "8px" }}>
            <span>The Boosted APR is from your staked Multiplier Points.</span>
          </div>
        </div>
      )}
      <div>
        {!processedData.gmyBoostAprForNativeToken ||
          (!processedData.gmyBoostAprForNativeToken.gt(0) && <div className="Tooltip-divider" />)}
        <div style={{ marginTop: "8px" }}>
          {" "}
          <span>APRs are updated weekly and will depend on the fees collected for the week.</span>
        </div>
      </div>
    </>
  );
}
