import { Spin } from "antd";
import { getPositionNetValue } from "components/ExchangeV2/PositionShare";
import { getTokenSymbolFromString } from "domain/tokens";
import { BigNumber } from "ethers";
import { getLiqRisk, getUnrealizedPnl, importImage, USD_DECIMALS } from "lib/legacy";
import { formatAmount, parseValue } from "lib/numbers";
import { LoadingOutlined } from "@ant-design/icons";
import cx from "classnames";
import Tooltip from "components/Tooltip/Tooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { renderRisk } from "lib/helper";
import moment from "moment";
import { useThemeContext } from "contexts/ThemeProvider";
import ic_chain from "img/trade/chains.svg";
import ic_chainLight from "img/trade/chains_light.svg";
import editIcon from "img/trade/edit.svg";
import PositionDropdown from "components/ExchangeV2/PositionDropdown";
import styled from "styled-components";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "#FFA722" }} spin />;

const PositionItemMobileV2 = (props) => {
  const {
    position,
    marketTokensInfo,
    marketAsssets,
    myOrders,
    handleSelectedPosition,
    setListSection,
    setPosOrders,
    setSelectedPosition,
    sellPosition,
    setIsEditLeverageModalOPen,
    setPositionToShare,
    setIsPositionShareModalOpen,
    setIsTPSLModalOPen,
    setIsAddToPosition,
    setIsAddCollateralModalOpen,
    setIsTrailingStopModalOpen,
    setIsEditTakeProfitModalOpen,
    setIsEditStopLossModalOpen,
  } = props;
  const theme = useThemeContext();
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);

  const accruedFees =
    parseValue(position?.accruedPositionFee || 0, 30)
      .add(parseValue(position?.accruedFundingFee || 0, 30))
      .add(parseValue(position?.accruedBorrowFee | 0, 30)) || BigNumber.from(0);
  const avgEntryPrice = parseValue(position.averagePrice, 30);
  const markPrice = BigNumber.from(position?.isLong ? currentMarket?.askPrice || 0 : currentMarket?.bidPrice || 0);
  const posOrders = myOrders?.filter((x) => x.posId === position.posId) || [];
  const paidFees =
    parseValue(position?.paidPositionFee || 0, 30)
      .add(parseValue(position?.paidBorrowFee || 0, 30))
      .add(parseValue(position?.paidFundingFee || 0, 30)) || BigNumber.from(0);
  const posQty = parseValue(position.size, 30).mul(parseValue(1, 30)).div(parseValue(position.averagePrice, 30));
  const positionNetValue = getPositionNetValue(position, markPrice);
  const uPnL =
    getUnrealizedPnl(accruedFees, markPrice, avgEntryPrice, position.isLong, posQty)?.div(parseValue(1, 30)) ||
    BigNumber.from(0);
  const collateral = parseValue(position.collateral, 30);
  const LiqRisk = getLiqRisk(positionNetValue?.pnl, collateral);
  const rPnL = positionNetValue?.pnlWithoutFee.sub(paidFees || 0);
  const completePosition = {
    positionNetValue,
    markPrice: markPrice,
    ...position,
    symbol: getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol),
    quantity: posQty,
    currentMarket,
    accruedFees,
    uPnL,
    LiqRisk,
    paidFees,
    rPnL,
    posOrders,
  };

  return (
    <div key={position.key} className="App-card">
      <div className="App-card-title" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
        <span className="Exchange-list-title">
          <img src={importImage("ic_" + completePosition?.symbol?.toLowerCase() + "_24.svg")} alt="" />{" "}
          {completePosition.symbol}/USD <span className="muted">#{position.posId}</span>
        </span>
      </div>
      <div className="App-card-divider" />
      <div className="App-card-content app-card-position">
        <div className="leverage-pad" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
          <div>
            <span
              className={cx({ positive: position.isLong, negative: !position.isLong })}
              style={{ color: position.isLong ? "#176CE5" : "#F6475D" }}
            >
              {position.isLong ? `LONG` : `SHORT`}
            </span>
          </div>
          {position.leverage && (
            <div
              className="leverage-box"
              style={{
                color: "#FFC700",
                backgroundColor: "rgba(255, 199, 0, 0.20)",
                height: 16,
                display: "inline-block",
                opacity: 1,
                borderRadius: "100px",
                padding: "0 4px",
                fontSize: "12px",
                lineHeight: "15.6px",
                fontWeight: 400,
                marginLeft: "4px",
              }}
            >
              {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X
            </div>
          )}
        </div>
        <div className="App-card-row" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
          <div className="label">
            <span>Net</span>
          </div>
          {position.liquidationPrice ? (
            <div className="contain-tooltip">
              <div>
                {positionNetValue.netValue.lt(0) ? "-" : ""}$
                {positionNetValue.netValue
                  ? formatAmount(positionNetValue.netValue, 30, 2, true).replace("-", "")
                  : "--"}
              </div>
            </div>
          ) : (
            <div>--</div>
          )}
        </div>
        <div className="App-card-row" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
          <div className="label">
            <span>PnL</span>
          </div>
          {position.liquidationPrice ? (
            <div
              className={cx("", {
                positive: positionNetValue?.pnl?.gt(0),
                negative: positionNetValue?.pnl.lt(0),
                muted: positionNetValue?.pnl.eq(0),
              })}
            >
              <Tooltip
                handle={
                  <>
                    {`${positionNetValue?.pnl?.lt(0) ? "-" : ""}$${formatAmount(
                      positionNetValue?.pnl,
                      30,
                      2,
                      true
                    ).replace("-", "")}`}
                    &nbsp;
                    <span
                      className={cx("", {
                        positive: positionNetValue?.pnl?.gt(0),
                        negative: positionNetValue?.pnl.lt(0),
                        muted: positionNetValue?.pnl.eq(0),
                      })}
                    >
                      ({positionNetValue?.pnl?.gt(0) && "+"}
                      {positionNetValue?.pnl.lt(0) && "-"}
                      {formatAmount(positionNetValue?.percentPnl, 30, 2, true).replace("-", "")}
                      %)
                    </span>
                  </>
                }
                position={"right-bottom"}
                styled={{ position: "relative", top: "3px" }}
                handleClassName={cx("plain", { negative: position.hasLowCollateral })}
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`PnL without fees`}
                        value={`${positionNetValue.prefixWithoutFee}$${formatAmount(
                          positionNetValue.pnlWithoutFee,
                          USD_DECIMALS,
                          2,
                          true
                        ).replace("-", "")}`}
                        showDollar={false}
                      />
                      <StatsTooltipRow
                        label={`Accrued fees`}
                        showDollar={false}
                        value={`${accruedFees.lt(0) ? "-" : ""}$${formatAmount(
                          accruedFees,
                          USD_DECIMALS,
                          2,
                          true
                        ).replace("-", "")}`}
                      />
                    </>
                  );
                }}
              />
            </div>
          ) : (
            <div>--</div>
          )}
        </div>
        <div className="App-card-row" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
          <div className="label">
            <span>Pos.Size</span>
          </div>
          <div className="position-list-collateral">
            <div>
              ${formatAmount(parseValue(position?.size, 30), 30, 2, true)}
              <div className="muted fz-12 bold">
                {formatAmount(posQty, 30, 4, true)}{" "}
                {getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol)}
              </div>
            </div>
          </div>
        </div>
        <div className="App-card-row" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
          <div className="label">
            <span>Collateral</span>
          </div>
          <div style={{ display: "flex" }}>
            ${formatAmount(parseValue(position.collateral, 30), 30, 2, true)}
            <StyledButton
              disabled={position && !position.liquidationPrice}
              style={{ position: "relative", marginLeft: "2px" }}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedPosition(completePosition);
                setIsAddCollateralModalOpen(true);
              }}
            >
              <img src={editIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
            </StyledButton>
          </div>
        </div>
        <div className="App-card-row" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
          <div className="label">
            <span>Liq.Risk</span>
          </div>
          <div>
            <div>{renderRisk(LiqRisk, false)}</div>
            {/* <div className="position-list-risk">
                          {formatAmount(LiqRisk, 30, LiqRisk.eq(0) ? 0 : 2, true).replace("-", "")}%
                        </div> */}
          </div>
        </div>
        <div className="App-card-row" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
          <div className="label">
            <span>Liq.Price</span>
          </div>
          <div>
            <div>
              ${formatAmount(parseValue(position.liquidationPrice, 30), 30, currentMarket?.decimals || 2, true)}
            </div>
          </div>
        </div>
        <div className="App-card-row" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
          <div className="label">
            <span>Mark Price</span>
          </div>
          <div>
            <div>${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}</div>
          </div>
        </div>
        <div className="App-card-row" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
          <div className="label">
            <span>Avg. Entry Price</span>
          </div>
          <div>${formatAmount(parseValue(position.averagePrice, 30), 30, currentMarket?.decimals || 2, true)}</div>
        </div>

        {posOrders.length > 0 && (
          <Tooltip
            position="right-top"
            hideInfoImg={true}
            handle={
              <div
                className={`chains-btn`}
                onClick={(e) => {
                  e.stopPropagation();
                  setListSection("Orders");
                  setPosOrders(posOrders);
                }}
              >
                <span>{posOrders.length}</span>
                <img src={theme?.isLightTheme ? ic_chainLight : ic_chain} alt="" style={{ marginLeft: "0px" }} />
              </div>
            }
            renderContent={() => {
              return (
                <>
                  {posOrders && posOrders.length > 0 && (
                    <>
                      <strong>
                        <span style={{ fontWeight: 700, color: "white" }}>Active Orders</span>
                      </strong>
                      {posOrders.map((order) => {
                        if (order.type === "TRAILING") {
                          const sizeDelta = BigNumber.from(order.sizeDelta);

                          const price =
                            order.stepType == 0
                              ? `$${formatAmount(BigNumber.from(order.stepAmount), 30, 2, true)} TA`
                              : `${formatAmount(BigNumber.from(order.stepAmount), 3, 2, true)} T%`;
                          return (
                            <div key={`${order.isLong}-${order.type}-${order.index}`} className="Position-list-order">
                              <div>
                                <span style={{ color: "white" }}>
                                  Trailing stop: {price} | -$
                                  {sizeDelta.gte(parseValue(position.size, 30))
                                    ? formatAmount(parseValue(position.size, 30), 30, 2, true)
                                    : formatAmount(sizeDelta, 30, 2, true)}
                                </span>
                              </div>
                            </div>
                          );
                        }
                        const conditionQuantity = Number(order.trigger.amountPercent / 100000);
                        const sizeDelta = parseValue(position.size, 30)
                          .mul(parseValue(conditionQuantity, USD_DECIMALS))
                          .div(parseValue(1, USD_DECIMALS));
                        return (
                          <div key={`${order.isLong}-${order.type}-${order.index}`} className="Position-list-order">
                            <div>
                              <span style={{ color: "white" }}>
                                {order?.trigger.isTP ? "Take profit" : "Stop loss"}:{" "}
                                {order?.trigger.isTP ? (position.isLong ? "≥" : "≤") : !position.isLong ? "≥" : "≤"}{" "}
                                {formatAmount(order.trigger.price, 30, 2, true)} | -$
                                {formatAmount(sizeDelta, 30, 2, true)}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </>
              );
            }}
          />
        )}
      </div>
      <div style={{ marginTop: "1.3rem" }} />
      {position.liquidationPrice ? (
        <div className="App-card-options">
          <button
            className="App-button-option App-card-option"
            onClick={() => handleSelectedPosition(completePosition)}
            // disabled={position.size == 0}
          >
            <span>View</span>
          </button>
          <button
            className="App-button-option App-card-option border-btn"
            onClick={(e) => {
              e.stopPropagation();
              sellPosition(completePosition);
            }}
            disabled={position.size == 0 || (position && !position.liquidationPrice)}
          >
            <span>Close</span>
          </button>
          <PositionDropdown
            position={position}
            handleEditLeverage={() => {
              setSelectedPosition(completePosition);
              setIsEditLeverageModalOPen(true);
            }}
            handleShare={() => {
              setPositionToShare(completePosition);
              setIsPositionShareModalOpen(true);
            }}
            handleAddTPSL={() => {
              setSelectedPosition(completePosition);
              setIsTPSLModalOPen(true);
            }}
            handlAddToPosition={() => {
              setSelectedPosition(completePosition);
              setIsAddToPosition(true);
            }}
            handlAddCollateral={() => {
              setSelectedPosition(completePosition);
              setIsAddCollateralModalOpen(true);
            }}
            handlAddTrailingStop={() => {
              setSelectedPosition(completePosition);
              setIsTrailingStopModalOpen(true);
            }}
            handleMarketSelect={() => {
              // onPositionClick(position);
            }}
            handleEditTakeProfit={() => {
              setSelectedPosition(completePosition);
              setIsEditTakeProfitModalOpen(true);
            }}
            handleEditStopLoss={() => {
              setSelectedPosition(completePosition);
              setIsEditStopLossModalOpen(true);
            }}
          />
        </div>
      ) : (
        <div className="processing">
          Processing <Spin indicator={antIcon} />
        </div>
      )}
    </div>
  );
};

const StyledButton = styled.div`
  background: transparent;
  outline: none;
  border: none;
  position: relative;
  top: -2px;
`;
export default PositionItemMobileV2;
