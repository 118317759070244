import React, { useState, useMemo } from "react";
import { BigNumber } from "ethers";
import cx from "classnames";
import { BASIS_POINTS_DIVISOR, USD_DECIMALS, getLiqPrice, getLiqRisk, getPnlWithoutFee } from "lib/legacy";
import { getContract } from "config/contracts";
import ExchangeInfoRow from "./ExchangeInfoRow";
import "./PositionSeller.css";
import { contractFetcher } from "lib/contracts";
import { formatAmount, parseValue, formatNumber } from "lib/numbers";
import GreenArraowIcon from "components/Icon/GreenArraowIcon";
import PercentageButtons from "./PercentageButtons";
import useSWR from "swr";
import SettingsManager from "abis/SettingsManager.json";
import { preventSpecialCharacters } from "utils/helpers";
import { formatShortNumber } from "utils/formatPrice";
import { renderRisk } from "lib/helper";
import Tooltip from "components/Tooltip/Tooltip";

export default function WithdrawCollateralV2(props) {
  const { handleEditPosition, position, chainId, library, marketTokensInfo, isConfirm } = props;
  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
  const [closeQuantity, setCloseQuantity] = useState(0);

  const handleSelectPercentageCloseQuantity = (percentage) => {
    if (percentage === 1) {
      const value = freeQuantity;
      setCloseQuantity(formatAmount(value, 30, 6, false));
    } else {
      const value = newMaxCollateral.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
      setCloseQuantity(formatAmount(value, 30, 6, false));
    }
  };
  const onEditPosition = () => {
    handleEditPosition &&
      handleEditPosition(
        position,
        parseValue(position.size, 30).mul(parseValue(1, 30)).div(parseValue(position.leverage, 30)).sub(newCollateral)
      );
  };

  const maxLeverage = currentMarket?.maxLeverage ? Number(currentMarket?.maxLeverage) / BASIS_POINTS_DIVISOR : 100;

  const minCollateral = parseValue(position.size, 30)
    .mul(parseValue(1, USD_DECIMALS))
    .div(parseValue(maxLeverage, USD_DECIMALS));

  const newMaxCollateral = parseValue(position.collateral, USD_DECIMALS).sub(minCollateral);
  const freeQuantity = newMaxCollateral;
  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const { data: liquidateThreshold } = useSWR(
    position.tokenId && [
      `ExChange:liquidateThreshold-${position.posId}`,
      chainId,
      settingsManagerAddress,
      "liquidateThreshold",
      position.tokenId,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const markPrice = BigNumber.from(position.isLong ? currentMarket?.askPrice || 0 : currentMarket?.bidPrice || 0);
  const accruedFees =
    parseValue(position.accruedPositionFee, 30)
      .add(parseValue(position.accruedFundingFee, 30))
      .add(parseValue(position.accruedBorrowFee, 30)) || BigNumber.from(0);
  const newCollateral = closeQuantity ? parseValue(position.collateral, 30).sub(parseValue(closeQuantity, 30)) : null;
  const newLeverage =
    newCollateral && !newCollateral.eq(0)
      ? parseValue(position.size, 30).mul(parseValue(1, 30)).div(newCollateral)
      : null;
  const newLiquidPrice =
    newCollateral && newLeverage && newLeverage.gt(parseValue(1, 30)) && liquidateThreshold
      ? getLiqPrice(
          liquidateThreshold,
          newCollateral,
          parseValue(position.size, 30),
          accruedFees,
          position.isLong,
          parseValue(position.averagePrice, 30)
        )
      : null;
  const liquidChange = newLiquidPrice ? newLiquidPrice.sub(parseValue(position.liquidationPrice, 30)) : null;

  const pnlWithoutFees = getPnlWithoutFee(
    position.isLong,
    markPrice,
    parseValue(position.averagePrice, 30),
    parseValue(position.size, 30)
  );
  const pnl = pnlWithoutFees.sub(accruedFees);
  const LiqRisk = getLiqRisk(pnl, parseValue(position.collateral, 30));
  const newLiqRisk = newCollateral ? getLiqRisk(pnl, newCollateral) : null;
  const liqRiskChanged = newLiqRisk ? newLiqRisk.sub(LiqRisk) : null;
  // console.log("??????", {LiqRisk, pnlWithoutFees});
  const changeToLiq = useMemo(() => {
    if (!position.liquidationPrice || (currentMarket && !currentMarket.askPrice) || !liquidChange || !newLiquidPrice) {
      return {};
    }

    //% Change to Liq = (Est.liq price - Market price ) / Market price
    const current = parseValue(position.liquidationPrice, 30)
      .sub(BigNumber.from(currentMarket.askPrice))
      .mul(parseValue(100, 30))
      .div(BigNumber.from(currentMarket.askPrice));

    const _new = newLiquidPrice
      .sub(BigNumber.from(currentMarket.askPrice))
      .mul(parseValue(100, 30))
      .div(BigNumber.from(currentMarket.askPrice));

    const change = (_new.lt(0) ? _new.mul(-1) : _new).sub(current.lt(0) ? current.mul(-1) : current);

    return {
      current: current,
      change: change,
      new: _new,
    };
  }, [currentMarket?.askPrice, liquidChange, newLiquidPrice, position.liquidationPrice]);
  const getErrorLeverage = () => {
    if (parseValue(closeQuantity || 0, 30).gt(parseValue(position.collateral, USD_DECIMALS))) {
      return `Insufficient balance`;
    }
    if (parseValue(closeQuantity || 0, 30).gt(parseValue(position.collateral, 30))) {
      return `Max withdraw: ${formatAmount(parseValue(position.collateral, 30), 30, 2, true)}`;
    }
    if (newLeverage && newLeverage.lt(parseValue(1, 30))) {
      return "Min leverage: 1x";
    }
    if (newLeverage && newLeverage.gt(parseValue(maxLeverage || 0, 30))) {
      return `Max leverage: ${maxLeverage || 0}x`;
    }
    return false;
  };

  const getErrorCollateral = () => {
    if (!Number(closeQuantity)) {
      return "Enter an amount";
    }
    if (newLiqRisk && markPrice) {
      if (newLiqRisk.lte(parseValue(-100, USD_DECIMALS))) return "Position might be liquidated";
    }

    return false;
  };

  const isDisableButton = () => {
    return !!getErrorLeverage() || !!getErrorCollateral();
  };
  return (
    <>
      <div>
        {/* <div
        className="align-right nonclickable free-colateral"
        style={{ opacity: 0.6, fontSize: "14px", marginBottom: "8px" }}
      >
        Exchange balance: ${formatAmount(userTokenBalances?.gusdBalance, USD_DECIMALS, 2, true)}
      </div> */}
        <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "0px" }}>
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <Tooltip
                position="left-top"
                handle={
                  <div className="muted">
                    <span>Withdraw</span>
                  </div>
                }
                renderContent={() => {
                  return <>Withdraw an amount to ensure the leverage is not greater than {maxLeverage}x.</>;
                }}
              />
            </div>
            <div className="muted align-right clickable">
              <span>Max: ${formatAmount(newMaxCollateral, USD_DECIMALS, 2, true)}</span>
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div className="Exchange-swap-input-container">
              <input
                type="number"
                min="0"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={closeQuantity}
                onChange={(e) => setCloseQuantity(e.target.value)}
                onKeyDown={preventSpecialCharacters}
              />
            </div>
            <div className="PositionEditor-token-symbol">USD</div>
          </div>
        </div>
        {/* <span className="validate-error">{getErrorCollateral()}</span> */}
        <PercentageButtons
          onChangePercentage={handleSelectPercentageCloseQuantity}
          balance={Number(formatAmount(newMaxCollateral, 30, 6, false))}
          value={closeQuantity}
          decimals={6}
          isUsd={true}
          fullBalance={newMaxCollateral}
        />
        <div className="PositionEditor-info-box" style={{ marginTop: "16px" }}>
          <div className="square-container square-fee-container">
            <ExchangeInfoRow label={`Mark Price`}>
              {currentMarket
                ? `$${formatAmount(currentMarket.askPrice, 30, currentMarket?.decimals || 2, true)}`
                : "--"}
            </ExchangeInfoRow>
            <ExchangeInfoRow label={`Size`}>
              ${formatAmount(parseValue(position.size, 30), 30, 2, true)}
            </ExchangeInfoRow>
            <ExchangeInfoRow label={`Entry Price`}>
              ${formatAmount(parseValue(position.averagePrice, 30), 30, 4, true)}
            </ExchangeInfoRow>
            {/* <ExchangeInfoRow label={`Pos. Qty`}>
            {formatAmount(position.quantity, 30, 4, true)} {position.symbol}
          </ExchangeInfoRow> */}
            {/* <ExchangeInfoRow label={`Avg. Entry Price`}>
            ${formatAmount(parseValue(position.averagePrice, 30), 30, currentMarket?.decimals || 2, true)}
          </ExchangeInfoRow> */}
            {/* <ExchangeInfoRow label={`Fees`}>$0.00</ExchangeInfoRow> */}
            <div style={{ height: "8px" }} />
            <div className="divider" />
            <div style={{ height: "8px" }} />
            <div className="position-change-line">
              <div className="position-change-info">Leverage</div>
              <div className="position-change-info change-value">
                {newLeverage && !getErrorLeverage() ? (
                  <span>
                    <span style={{ textDecoration: "line-through", color: "#919499" }}>
                      {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}x
                    </span>{" "}
                    <span style={{ position: "relative", top: "2px" }}>
                      {" "}
                      <GreenArraowIcon fill={"#919499"} />
                    </span>{" "}
                    {formatAmount(newLeverage, 30, 2, true)}x
                    {/* <span
                      className={cx("Exchange-list-info-label", {
                        positive: parseValue(position.leverage, 30).sub(newLeverage).lt(0),
                        negative: parseValue(position.leverage, 30).sub(newLeverage).gt(0),
                        muted: parseValue(position.leverage, 30).sub(newLeverage).eq(0),
                      })}
                      style={{ marginLeft: "4px", fontSize: "14px" }}
                    >
                      ({parseValue(position.leverage, 30).sub(newLeverage).lte(0) ? "+" : "-"}
                      {formatAmount(parseValue(position.leverage, 30).sub(newLeverage), 30, 2, true).replace("-", "")}x)
                    </span> */}
                  </span>
                ) : (
                  `${formatAmount(parseValue(position.leverage, 30), 30, 2, true)}x`
                )}
              </div>
            </div>
            <div className="position-change-line">
              <div className="position-change-info">Collateral</div>
              <div className="position-change-info change-value">
                {newCollateral && !getErrorLeverage() ? (
                  <span>
                    <span style={{ textDecoration: "line-through", color: "#919499" }}>
                      ${formatAmount(parseValue(position.collateral, 30), 30, 2)}
                    </span>{" "}
                    <span style={{ position: "relative", top: "2px" }}>
                      {" "}
                      <GreenArraowIcon fill={"#919499"} />
                    </span>{" "}
                    ${formatShortNumber(formatAmount(newCollateral, 30, 2, false), 2)}
                    {/* <span
                      className={cx("Exchange-list-info-label", {
                        negative: true,
                      })}
                      style={{ marginLeft: "4px", fontSize: "14px" }}
                    >
                      (-${formatNumber(Number(closeQuantity) || 0, 2)})
                    </span> */}
                  </span>
                ) : (
                  `$${formatAmount(parseValue(position.collateral, 30), 30, 2)}`
                )}
              </div>
            </div>
            <div className="position-change-line">
              <div className="position-change-info">Liq. Price</div>
              <div className="position-change-info change-value">
                {newLiquidPrice && !getErrorLeverage() ? (
                  <span>
                    <span style={{ textDecoration: "line-through", color: "#919499" }}>
                      ${formatAmount(parseValue(position.liquidationPrice, 30), 30, currentMarket?.decimals || 2, true)}
                    </span>{" "}
                    <span style={{ position: "relative", top: "2px" }}>
                      {" "}
                      <GreenArraowIcon fill={"#919499"} />
                    </span>{" "}
                    ${formatAmount(newLiquidPrice, 30, currentMarket?.decimals || 2, true)}
                    {/* <span
                      className={cx("Exchange-list-info-label", {
                        positive: liquidChange.gt(0),
                        negative: liquidChange.lt(0),
                        muted: liquidChange.eq(0),
                      })}
                      style={{ marginLeft: "4px", fontSize: "14px" }}
                    >
                      ({liquidChange.gte(0) ? "+" : "-"}${formatAmount(liquidChange, 30, 2, true).replace("-", "")})
                    </span> */}
                  </span>
                ) : (
                  `$${formatAmount(parseValue(position.liquidationPrice, 30), 30, currentMarket?.decimals || 2, true)}`
                )}
              </div>
            </div>
            <div className="position-change-line">
              <div className="position-change-info">Liq. Risk</div>
              <div className="position-change-info change-value">
                {newLiqRisk && !getErrorLeverage() ? (
                  <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                    <span style={{ textDecoration: "line-through", color: "#919499" }}>
                      {renderRisk(LiqRisk, false)}
                    </span>{" "}
                    <span style={{ position: "relative", top: "2px" }}>
                      {" "}
                      <GreenArraowIcon fill={"#919499"} />
                    </span>{" "}
                    <span>{renderRisk(newLiqRisk, false)}</span>
                    {/* <span
                      className={cx("Exchange-list-info-label", {
                        positive: liqRiskChanged.lt(0),
                        negative: liqRiskChanged.gt(0),
                        // muted: liqRiskChanged.eq(0),
                      })}
                      style={{ marginLeft: "4px", fontSize: "14px" }}
                    >
                      ({liqRiskChanged.lte(0) ? "+" : "-"}
                      {formatAmount(liqRiskChanged, 30, 2, true).replace("-", "")}%)
                    </span> */}
                  </span>
                ) : (
                  <span>{renderRisk(LiqRisk, false)}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <span className="validate-error">{getErrorLeverage()}</span> */}
      </div>
      <div className="Exchange-swap-button-container edit-modal-button">
        <button
          className="App-cta Exchange-swap-button"
          onClick={onEditPosition}
          disabled={isDisableButton() || isConfirm}
        >
          {getErrorCollateral() || getErrorLeverage() || (isConfirm ? "Withdrawing..." : "Wihdraw")}
        </button>
      </div>
    </>
  );
}
