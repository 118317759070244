import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <path
        fill="#8251FE"
        fillRule="evenodd"
        d="M4.63 3.055a.694.694 0 00-.695.695v1.389c0 .383.31.694.694.694h3.704c.384 0 .694-.31.694-.694v-1.39a.694.694 0 00-.694-.694H4.629zm-2.084.695c0-1.15.933-2.083 2.083-2.083h3.704c1.15 0 2.083.932 2.083 2.083v1.389c0 1.15-.932 2.083-2.083 2.083H4.629A2.083 2.083 0 012.546 5.14v-1.39zM3.24 12.143c.384 0 .695.31.695.694v3.413c0 .384.31.694.694.694h3.704c.384 0 .694-.31.694-.694a.695.695 0 011.39 0c0 1.15-.933 2.083-2.084 2.083H4.629a2.083 2.083 0 01-2.083-2.083v-3.413c0-.383.31-.694.694-.694zM4.63 9.537a.694.694 0 00-.695.694v.596a.695.695 0 01-1.39 0v-.596c0-1.15.934-2.083 2.084-2.083h3.704c1.15 0 2.083.933 2.083 2.083v3.817a.695.695 0 01-1.389 0v-3.817a.694.694 0 00-.694-.694H4.629zM11.342 14.861c0-1.15.933-2.083 2.084-2.083h3.703c1.151 0 2.084.932 2.084 2.083v1.39c0 1.15-.933 2.082-2.084 2.082h-3.703a2.083 2.083 0 01-2.084-2.083v-1.389zm2.084-.694a.694.694 0 00-.695.694v1.39c0 .383.311.694.695.694h3.703c.384 0 .695-.311.695-.695v-1.389a.694.694 0 00-.695-.694h-3.703zM11.342 3.75c0-1.15.933-2.083 2.084-2.083h3.703c1.151 0 2.084.932 2.084 2.083v3.324a.695.695 0 01-1.39 0V3.75a.694.694 0 00-.694-.695h-3.703a.694.694 0 00-.695.695v.842a.694.694 0 01-1.389 0V3.75zM12.037 6.064c.383 0 .694.311.694.695v3.01c0 .383.311.694.695.694h3.703c.384 0 .695-.311.695-.695a.694.694 0 111.389 0c0 1.15-.933 2.083-2.084 2.083h-3.703a2.083 2.083 0 01-2.084-2.083v-3.01c0-.383.311-.694.695-.694z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
