import { useMemo, useState } from "react";
import TradeActivityItemV1 from "./TradeActivityItemV1";
import TradeActivityItemV2 from "./TradeActivityItemV2";
import TradeActivity from "components/ExchangeV2/TradeActivity";
import { DEFAULT_PAGE_SIZE_POSITIONS } from "../../hooks/useUserPosition";
import PaginationV2 from "../../pages/AnalyticsV3/components/PaginationV2";
import TradeActivityItemMobileV1 from "./TradeActivityItemMobileV1";
import TradeActivityItemMobileV2 from "./TradeActivityItemMobileV2";
import TradeActivityV1 from "components/ExchangeV2/TradeActivityV1";

const TradeActivityList = ({ positions, loading, chainId, marketAsssets }) => {
  const [page, setPage] = useState(1);

  const [isTradeActivityVisible, setIsTradeActivityVisible] = useState(false);
  const [isTradeActivityVisibleV1, setIsTradeActivityVisibleV1] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState();

  const filterPositions = useMemo(() => {
    const startIndex = (page - 1) * DEFAULT_PAGE_SIZE_POSITIONS;
    const endIndex = page * DEFAULT_PAGE_SIZE_POSITIONS;
    return positions?.slice(startIndex, endIndex) || [];
  }, [positions, page]);

  const handleSelectedPosition = (position) => {
    setSelectedPosition(position);
    setIsTradeActivityVisible(true);
  };
  const handleSelectedPositionV1 = (position) => {
    setSelectedPosition(position);
    setIsTradeActivityVisibleV1(true);
  };

  return (
    <div className="PositionsList">
      {isTradeActivityVisible && (
        <TradeActivity
          isVisible={isTradeActivityVisible}
          setIsVisible={setIsTradeActivityVisible}
          position={selectedPosition}
          chainId={chainId}
        />
      )}
      {isTradeActivityVisibleV1 && (
        <TradeActivityV1
          isVisible={isTradeActivityVisibleV1}
          setIsVisible={setIsTradeActivityVisibleV1}
          position={selectedPosition}
          chainId={chainId}
        />
      )}
      <table className={`Exchange-list large App-box ${positions.length === 0 ? "no-array-length" : ""}`}>
        <tbody>
          <tr className="Exchange-list-header">
            <th>
              <span>Position</span>
            </th>
            <th>
              <span>Time</span>
            </th>
            <th>
              <span>Type</span>
            </th>
            <th>
              <span>Action</span>
            </th>
            <th>
              <span>Size Delta</span>
            </th>
            <th></th>
          </tr>
        </tbody>
        {positions.length === 0 && !loading && (
          <tr className="no-position">
            <td colSpan="15">
              <div className="no-data-record">No trades yet</div>
            </td>
          </tr>
        )}
        {filterPositions.length > 0 &&
          filterPositions.map((position, index) => {
            if (position.isV1)
              return (
                <TradeActivityItemV1
                  position={position}
                  chainId={chainId}
                  handleSelectedPosition={handleSelectedPositionV1}
                />
              );
            else
              return (
                <TradeActivityItemV2
                  marketAsssets={marketAsssets}
                  chainId={chainId}
                  position={position}
                  handleSelectedPosition={handleSelectedPosition}
                />
              );
          })}
      </table>
      <div className="Exchange-list small">
        {filterPositions.length > 0 &&
          filterPositions.map((position, index) => {
            if (position.isV1)
              return (
                <TradeActivityItemMobileV1
                  position={position}
                  chainId={chainId}
                  handleSelectedPosition={handleSelectedPositionV1}
                />
              );
            else
              return (
                <TradeActivityItemMobileV2
                  marketAsssets={marketAsssets}
                  chainId={chainId}
                  position={position}
                  handleSelectedPosition={handleSelectedPosition}
                />
              );
          })}
      </div>
      {positions && positions.length > DEFAULT_PAGE_SIZE_POSITIONS && (
        <PaginationV2
          rowsPerPage={DEFAULT_PAGE_SIZE_POSITIONS}
          rows={positions.length}
          currentPage={page}
          setCurrentPage={setPage}
        />
      )}
    </div>
  );
};
export default TradeActivityList;
