import React, { useState } from "react";
import { BigNumber } from "ethers";

import { importImage } from "lib/legacy";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import "./PositionSeller.css";
import { formatAmount, parseValue } from "lib/numbers";
import { titleCase } from "./TradeActivityList";
import moment from "moment";
import PositionHistory from "./PositionHistory";
import usePositionActivities from "hooks/usePositionActivities";
import { THEME_KEY } from "config/localStorage";
import { getTokenSymbolFromString } from "domain/tokens";
import ClockIcon from "../Icon/Clock";
import useUserPositionActivitiesV1 from "hooks/useUserPositionActivitiesV1";
import { useWeb3React } from "@web3-react/core";
import PositionHistoryV1 from "./PositionHistoryV1";

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm:ss";

export default function TradeActivityV1(props) {
  const { isVisible, position, setIsVisible, chainId } = props;
  const { account } = useWeb3React();

  const [isOpenPositionHistory, setIsOpenPositionHistory] = useState(false);
  const { positions: positionActivities, details } = useUserPositionActivitiesV1(account, position.positionId);
  // const profit = BigNumber.from(position.profitLoss);
  // const paidFeeds = BigNumber.from(position.positionFee)
  //   .add(BigNumber.from(position.fundingFee))
  //   .add(BigNumber.from(position.borrowFee));
  const posQty = parseValue(position.size, 30).mul(parseValue(1, 30)).div(parseValue(position.entryPrice, 30));
  // console.log("position", position);
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal  confirmation-modal details-position-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Trade Activity"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <div className="position-info-container">
              <div className="position-info">
                <img src={importImage("ic_" + position.indexToken.symbol.toLowerCase() + "_24.svg")} alt="" />
                <div className="title">{position.indexToken.symbol}</div>{" "}
                <div className="position-id">#{position?.posId}</div>
                <div className="postion-history-btn" onClick={() => setIsOpenPositionHistory(true)}>
                  <ClockIcon />
                </div>
              </div>
              <div className="position-tags">
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                </div>
                <div className="leverage-box">{formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X</div>
              </div>
            </div>
            {/* <div className="postion-history" onClick={() => setIsOpenPositionHistory(true)}>
              Position History
            </div> */}
          </div>
          <div className="content-container" style={{ marginBottom: 24 }}>
            <div className="gray-divider" />
            <div className="order-details-container">
              <div className="title">Activity Details</div>
              <div className="square-container">
                <ExchangeInfoRow label={`Action`}>
                  {position.action === "CLOSE" || position.action === "DECREASE"
                    ? "Decreased position"
                    : position.action === "OPEN" || position.action === "INCREASE"
                    ? "Increased position"
                    : position.action === "DEPOSIT"
                    ? "Deposited"
                    : position.action === "WITHDRAW"
                    ? "Withdrew"
                    : "Liquidated"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Order Type`}>{position.positionType}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Date/Time`}>
                  {moment(new Date(position.timestamp * 1000)).format(dateFormat)}&nbsp;
                  {moment(new Date(position.timestamp * 1000)).format(timeFormat)}
                </ExchangeInfoRow>
                {/* <ExchangeInfoRow label={`Execution Price`}>!$0.0900</ExchangeInfoRow> */}
                <ExchangeInfoRow label={`Pos. Collateral`}>
                  ${formatAmount(parseValue(position.collateral, 30), 30, 2, true)}
                </ExchangeInfoRow>
                {position.action !== "DEPOSIT" && position.action !== "WITHDRAW" && (
                  <ExchangeInfoRow label={`Size Delta`}>
                    ${formatAmount(parseValue(position.size, 30), 30, 2, true)}
                  </ExchangeInfoRow>
                )}
                {position.action !== "DEPOSIT" && position.action !== "WITHDRAW" && (
                  <ExchangeInfoRow label={`Size Qty`}>
                    {formatAmount(posQty, 30, 4, true)} {position.indexToken.symbol}
                  </ExchangeInfoRow>
                )}

                {/* <ExchangeInfoRow
                  label={`Realized PnL`}
                  isPositive={profit.gte(0)}
                  textStyled={{
                    color: profit.gt(0) ? "#176CE5" : profit.isZero() ? "#fff" : "#F6475D",
                  }}
                >
                  {profit.gt(0) ? "+" : profit.isZero() ? "" : "-"}${formatAmount(profit, 30, 2, true).replace("-", "")}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Fees Paid`}>
                  {" "}
                  {paidFeeds.lt(0) ? "-" : ""}${formatAmount(paidFeeds, 30, 2, true).replace("-", "")}
                </ExchangeInfoRow> */}
              </div>
            </div>
          </div>
          {details && (
            <PositionHistoryV1
              isVisible={isOpenPositionHistory}
              setIsVisible={setIsOpenPositionHistory}
              position={details}
              rows={positionActivities}
              chainId={chainId}
              optionalHeader={
                <div className="order-title-line order-title-line-details">
                  <div className="position-info-container">
                    <div className="position-info">
                      <img src={importImage("ic_" + position.indexToken.symbol.toLowerCase() + "_24.svg")} alt="" />
                      <div className="title">{position.indexToken.symbol}</div>
                      {/* <div className="position-id">#{position?.posId}</div> */}
                    </div>
                    <div className="position-tags">
                      <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                        {position?.isLong ? "LONG" : "SHORT"}
                      </div>
                      <div className="side">
                        {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          )}
        </Modal>
      )}
    </div>
  );
}
