import { SUPPORTED_V2_CHAINS } from "config/chains";
import { useChainId } from "lib/chains";
import { useEffect, useState } from "react";
import axios from "axios";
import { getServerUrlV2 } from "config/backend";
import {
  FIRST_DATE_TS,
  get24hTradesDataV1,
  getAmunData,
  getDailyInfoAnalyticsV2,
  getFee24hDataV1,
  getFeeDataV1,
  getFullUserDataCount,
  getLastLockedSnapShot,
  getListAssetsV2,
  getLockedChartKeys,
  getMergedOnlyChartV1Data,
  getMLPPoolData,
  getOpenInteresDataDetailstV1,
  getOpenInterestV1,
  getTotalGlpDataV1,
  getTotalMmyEsmmyStaked,
  getTotalMmyEsmmyStakedDelta,
  getTradesData,
  getUserDatav1,
  getUsertsDailyStats,
  getV1SubgraphData,
  getV1VolumeStats,
  getV2SubgraphData,
  getVolume24hDataV1,
  getVolumeDataV1,
  mergeChartLockedData,
  mergeOpenInterestData,
  mergeVolumeChartDataV1V2,
  NOW_TS,
  START_DATE_V2,
} from "utils/analyticsV3";
import { getV2ServerUrl } from "config/backendV2";
const useAnalyticsOverview = (chainName: string, from: number, to: number) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const { chainId } = useChainId();
  useEffect(() => {
    const requests: any[] = [];
    const getAnalyticsData = async () => {
      try {
        const _from = from || FIRST_DATE_TS;
        /// V1

        requests.push(
          axios.get(`https://api.yummy.fi/${chainName}/api/daily-fees?from=${_from}&to=${to || NOW_TS}`)
        );
        requests.push(getV1SubgraphData({ chainName, from: from, to: to }));
        if (SUPPORTED_V2_CHAINS.includes(chainId)) {
          // requests.push(axios.get(getV2ServerUrl(chainId, "/public/open_interests")));
          requests.push(
            axios.get(
              getV2ServerUrl(chainId, `/public/daily-infos?from=${from || START_DATE_V2[chainId]}&to=${to || NOW_TS}`)
            )
          );
          // requests.push(
          //   axios.get(
          //     getV2ServerUrl(
          //       chainId,
          //       `/public/last-24h-charts?from=${from || START_DATE_V2[chainId]}&to=${to || NOW_TS}`
          //     )
          //   )
          // );
          // requests.push(
          //   axios.get(getV2ServerUrl(chainId, `/assets/daily-volumes?from=${START_DATE_V2[chainId]}&to=${NOW_TS}`))
          // );
          // requests.push(
          //   axios.get(
          //     getServerUrlV2(chainId, `/public/app-stats?from=${from || START_DATE_V2[chainId]}&to=${to || NOW_TS}`)
          //   )
          // );
          // requests.push(getV2SubgraphData({ from: from || START_DATE_V2[chainId], to: to || NOW_TS, chainName }));
          requests.push(
            axios.get(`https://api.yummy.fi/stats/${chainName}/daily-stats?from=${_from}&to=${to || NOW_TS}`)

            //TODO: USE REAL API

            // axios.get(
            //   ` https://api.yummy.fi/stats/${chainName}/daily-stats?from=${from || FIRST_DATE_TS}&to=${
            //     to || NOW_TS
            //   }`
            // )
          );
          requests.push(axios.get(` https://api.yummy.fi/v2/${chainName}/assets`));
          requests.push(
            axios.get(` https://api.yummy.fi/v2/${chainName}/assets/daily-list?from=${_from}&to=${to || NOW_TS}`)
          );
          requests.push(
            axios.get(` https://api.yummy.fi/${chainName}/api/asset-daily?from=${_from}&to=${to || NOW_TS}`)
          );

          //mmy-esMMY staked
          // requests.push(
          //   axios.get(`https://api.mummy.finance/fantom/api/daily-stats?from=1600485569&to=1600585569`)

          //   //TODO: USE REAL API

          //   // axios.get(
          //   //   ` https://api.yummy.fi/${chainName}/api/daily-stats?from=${from || FIRST_DATE_TS}&to=${
          //   //     to || NOW_TS
          //   //   }`
          //   // )
          // );
        }
        const responses = await Promise.all(requests);
        if (responses.length > 1) {
          // console.log("????", { glpStats, tradingStats, userStats, volumeStats })
          const graphData = responses?.[1]?.data;
          const volumeStatsV1 = getV1VolumeStats(graphData, chainName);
          const feeDataV1 = getFeeDataV1(responses?.[0]?.data);
          const volumeDataV1 = getVolumeDataV1(volumeStatsV1);

          const tradesDataV1 =
            graphData?.userTradesStats?.reduce(
              (val, currentValue) => {
                val.trades += currentValue.actionMarginCount;
                return val;
              },
              { trades: 0 }
            ).trades || 0;
          const trades24hV1 =
            graphData?.userTradesStats?.[graphData?.userTradesStats?.length - 1]?.actionMarginCount || 0;
          const glpAmun = getAmunData(graphData?.glpStatsFantom, chainName);
          const glpStatsV1 = getTotalGlpDataV1(glpAmun, chainId);
          const tradeDataV1 = getTradesData(graphData);
          const netPnlV1 = tradeDataV1?.data?.[tradeDataV1?.data?.length - 1]?.currentPnlCumulative || 0;
          const netPnlData = tradeDataV1?.data || [];
          const openInterestV1 = getOpenInterestV1(tradeDataV1);
          const openInterestV1Details = getOpenInteresDataDetailstV1(tradeDataV1);
          const userDatav1 = getUserDatav1(graphData);

          let mergeData;
          if (SUPPORTED_V2_CHAINS.includes(chainId) && responses.length > 3) {
            const dataDailyInfo = responses?.[2]?.data?.data;
            const dailyStats = responses?.[3]?.data?.data;
            const listAssetsV2Data = responses?.[4]?.data?.data;
            const openInterestDataV2 = responses?.[5]?.data?.data;
            const openInterestDataV1 = responses?.[6]?.data;

            // const mmyEsMMYV1 = responses?.[7]?.data;
            const mmyEsMMYV1 = [
              {
                gmxStakedValue: 0,
                timestamp: 1600473600,
              },
              {
                gmxStakedValue: 0,
                timestamp: 1600560000,
              },
            ];

            const totalMmyEsMMYV1 = getTotalMmyEsmmyStaked(mmyEsMMYV1);
            const deltaMmyEsMMYV1 = getTotalMmyEsmmyStakedDelta(mmyEsMMYV1);
            const listPoolMLP = getMLPPoolData(graphData?.tokenStats, chainId);
            const chartLockedData = mergeChartLockedData(mmyEsMMYV1, listPoolMLP, dataDailyInfo);
            const listAssetsV2 = getListAssetsV2(listAssetsV2Data, chainId);
            const v2Data = getDailyInfoAnalyticsV2(dataDailyInfo);

            const mergeOpenInterest = mergeOpenInterestData(openInterestDataV1, openInterestDataV2, listAssetsV2);
            const totalLong =
              mergeOpenInterest?.totalOpenInterest?.[mergeOpenInterest?.totalOpenInterest.length - 1]?.totalLong || 0;
            const totalShort =
              mergeOpenInterest?.totalOpenInterest?.[mergeOpenInterest?.totalOpenInterest.length - 1]?.totalShort || 0;

            const isRecordZero = !userDatav1.some((u: any) => u.timestamp === _from);

            mergeData = {
              isV1V2: true,
              users: {
                totalV1: getFullUserDataCount(userDatav1, isRecordZero)?.total,
                deltaV1: getFullUserDataCount(userDatav1)?.delta,

                totalV2: v2Data.users.total,
                deltaV2: v2Data.users.delta,
                usersData: getUsertsDailyStats(dailyStats, graphData, dataDailyInfo),
              },
              feeDataV1: responses?.[0]?.data,
              tradeDataV1: tradeDataV1,
              volume: {
                total: volumeDataV1.total + v2Data.volume.total,
                v1: volumeDataV1.total,
                v2: v2Data.volume.total,
                delta: volumeDataV1.delta + v2Data.volume.delta,
              },
              lockedAssets: {
                chartData: chartLockedData,
                chartKeys: [
                  {
                    key: "USDC",
                    name: "USDC",
                  },
                  {
                    key: "BTC",
                    name: "BTC",
                  },
                  {
                    key: "GSLP",
                    name: "GSLP",
                  },
                  {
                    key: "ETH",
                    name: "ETH",
                  },
                  {
                    key: "USDT",
                    name: "USDT",
                  },
                  {
                    key: "WBERA",
                    name: "WBERA",
                  },
                ],
                // chartKeys: getLockedChartKeys(chartLockedData),
                chartPie: getLastLockedSnapShot(chartLockedData),
              },
              fees: {
                total: feeDataV1.total + v2Data.fees.total,
                v1: feeDataV1.total,
                v2: v2Data.fees.total,
                delta: feeDataV1.delta + v2Data.fees.delta,
              },
              openInterest: {
                total: totalLong + totalShort,
                v1: openInterestV1Details.total,
                v2: v2Data.openInterest.total,
                delta: openInterestV1.delta + v2Data.openInterest.delta,
                long: totalLong,
                short: totalShort,
                chartData: mergeOpenInterest?.data,
                chartKeys: mergeOpenInterest?.keys,
                totalOpenInterest: mergeOpenInterest?.totalOpenInterest,
              },
              assets: mergeOpenInterest?.dataOrigin,
              assetsVolumes: mergeOpenInterest?.assetVolumes,
              mlp: {
                total: glpStatsV1.total + totalMmyEsMMYV1 + v2Data.mlp.total,
                v1: glpStatsV1.total + totalMmyEsMMYV1,
                v2: v2Data.mlp.total,
                delta: glpStatsV1.delta + deltaMmyEsMMYV1 + v2Data.mlp.delta,
              },
              trades: {
                total: tradesDataV1 + v2Data.trades.total,
                v1: tradesDataV1,
                v2: v2Data.trades.total,
                delta: dailyStats?.[dailyStats.length - 1]?.actionMarginCount || 0,
              },
              chartData: mergeVolumeChartDataV1V2(chainName, true, graphData, responses?.[0]?.data, dataDailyInfo),
              pnl: {
                total: netPnlV1 + v2Data.pnl.total,
                v1Data: netPnlData,
                v2Data: v2Data.pnl.dailyData,
              },
            };
          } else {
            mergeData = {
              isV1V2: false,
              users: {
                totalV1: getFullUserDataCount(userDatav1)?.total,
                deltaV1: getFullUserDataCount(userDatav1)?.delta,
              },
              feeDataV1: responses?.[0]?.data,
              tradeDataV1: tradeDataV1,
              volume: {
                total: volumeDataV1.total,
                v1: volumeDataV1.total,
                delta: volumeDataV1.delta,
              },
              fees: {
                total: feeDataV1.total,
                v1: feeDataV1.total,
                delta: feeDataV1.delta,
              },
              openInterest: {
                total: openInterestV1Details.total,
                v1: openInterestV1Details.total,
                delta: openInterestV1.delta,
                long: openInterestV1Details?.long,
                short: openInterestV1Details?.short,
              },
              mlp: {
                total: glpStatsV1.total,
                v1: glpStatsV1.total,
                delta: glpStatsV1.delta,
              },
              trades: {
                total: tradesDataV1,
                v1: tradesDataV1,
                delta: trades24hV1,
              },
              chartData: getMergedOnlyChartV1Data(chainName, graphData, responses?.[0]?.data),
              pnl: {
                total: netPnlV1,
                v1Data: netPnlData,
                v2Data: [],
              },
            };
          }

          setData(mergeData);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    setLoading(true);
    getAnalyticsData();
    const interval = setInterval(async () => {
      getAnalyticsData();
    }, 20000);
    return () => {
      clearInterval(interval);
      setLoading(false);
    };
  }, [chainId, from, to]);
  return { loading, data };
};
export default useAnalyticsOverview;
