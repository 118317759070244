import { getTokenSymbolFromString } from "domain/tokens";
import { BigNumber } from "ethers";
import { importImage, USD_DECIMALS } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import cx from "classnames";
import shareIcon from "img/trade/share.svg";
import moment from "moment";
function secondsToHMS(secs) {
  function z(n) {
    return (n < 10 ? "0" : "") + n;
  }
  var sign = secs < 0 ? "-" : "";
  secs = Math.abs(secs);
  return sign + z((secs / 3600) | 0) + "h:" + z(((secs % 3600) / 60) | 0) + "m:" + z(secs % 60) + "s";
}
const ClosedPositionItemMobileV1 = (props) => {
  const { position, setIsPositionShareModalOpenV1, setPositionToShareV1, handleSelectedPosition } = props;
  const leverage = parseValue(position.size, 30)
    .mul(parseValue(1, USD_DECIMALS))
    .div(parseValue(position.collateral, 30));
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const duration = secondsToHMS(position.closedAt - position.openedAt);

  const roiPrefix = position.roi < 0 ? "-" : "+";
  const rPnLPrifix = position.realisedPnl.includes("-") ? "-" : "+";
  const isLoss = position.realisedPnl.includes("-");
  const pnlPrefix = position.realisedPnl.includes("-") ? "-" : "+";
  const posQty = parseValue(position.size, 30).mul(parseValue(1, 30)).div(parseValue(position.averagePrice, 30));

  return (
    <div key={position.index} className="App-card">
      <div className="App-card-title">
        <span className="Exchange-list-title">
          <img src={importImage("ic_" + position.indexToken.symbol?.toLowerCase() + "_24.svg")} alt="tk" />{" "}
          {position.indexToken.symbol}/USD <span className="muted">#{position.posId}</span>
        </span>
      </div>
      <div className="App-card-divider" />
      <div className="App-card-content app-card-position">
        <div className="leverage-pad">
          <div
            className={cx("Exchange-list-side", {
              positive: position.isLong,
              negative: !position.isLong,
            })}
          >
            {position.isLong ? "LONG" : "SHORT"}
          </div>
          {leverage && (
            <span
              className="leverage-box"
              style={{
                color: "#FFC700",
                backgroundColor: "rgba(255, 199, 0, 0.20)",
                height: 16,
                display: "inline-block",
                opacity: 1,
                borderRadius: "100px",
                padding: "0 4px",
                fontSize: "12px",
                lineHeight: "15.6px",
                fontWeight: 700,
                marginLeft: "4px",
              }}
            >
              {formatAmount(leverage, 30, 2, true)}X
            </span>
          )}
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Opened</span>
          </div>
          <div>
            <div>{moment(new Date(position.openedAt * 1000)).format(dateFormat)}</div>
            <div className="muted fz-12 bold">{moment(new Date(position.openedAt * 1000)).format(timeFormat)}</div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Closed</span>
          </div>
          <div>
            <div>{moment(new Date(position.closedAt * 1000)).format(dateFormat)}</div>
            <div className="muted fz-12 bold">{moment(new Date(position.closedAt * 1000)).format(timeFormat)}</div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Duration</span>
          </div>
          <div>{duration}</div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>PnL/ROI</span>
          </div>
          <div>
            <div
              className={`${!isLoss ? "isProfit" : "isLoss"} ${!isLoss ? "positive" : "negative"}`}
              style={{
                color: position.realisedPnl.includes("-") ? "#F6475D" : "#C1FF99 ",
              }}
            >
              {pnlPrefix}${formatAmount(parseValue(position.realisedPnl, 30), 30, 2, true).replace("-", "")}
            </div>
            <div className="muted fz-12 bold">
              {roiPrefix}
              {limitDecimals(Number(position.roi) < -100 ? -100 : Number(position.roi), 2).replace("-", "")}%
            </div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Avg. Entry Price</span>
          </div>
          <div>${formatAmount(parseValue(position.averagePrice, USD_DECIMALS), 30, 2, true)}</div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Close Price</span>
          </div>
          <div>${formatAmount(parseValue(position.exitPrice, USD_DECIMALS), 30, 2, true)}</div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Pos. Size</span>
          </div>
          <div>
            <div>${formatAmount(parseValue(position.size, USD_DECIMALS), 30, 2, true)}</div>
            <div className="muted muted fz-12 bold">
              {" "}
              {formatAmount(posQty, 30, 4, true)} {position.indexToken.symbol}
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "1.3rem" }} />
      <div className="App-card-options">
        <button
          className="App-button-option App-card-option"
          onClick={() => handleSelectedPosition(position)}
          // disabled={position.size == 0}
        >
          <span>View</span>
        </button>
        <button
          className="PositionDropdown-dots-icon"
          onClick={(e) => {
            e.stopPropagation();
            setPositionToShareV1(position);
            setIsPositionShareModalOpenV1(true);
          }}
        >
          <img src={shareIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
          Share
        </button>
      </div>
    </div>
  );
};
export default ClosedPositionItemMobileV1;
