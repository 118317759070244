/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { ApolloClient, gql, HttpLink, InMemoryCache } from "@apollo/client";
import * as ethers from "ethers";
import { fetcher } from "lib/fetcher";
import { formatAmount } from "lib/numbers";
import { chain, maxBy, minBy, sortBy, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";

const { JsonRpcProvider } = ethers.providers;
const providers = {
  fantom: new JsonRpcProvider("https://rpc.ftm.tools"),
  optimism: new JsonRpcProvider("https://optimism.publicnode.com"),
  arbitrum: new JsonRpcProvider("https://arbitrum.meowrpc.com"),
  base: new JsonRpcProvider("https://base.meowrpc.com"),
  bartio: new JsonRpcProvider("https://bartio.rpc.berachain.com"),
  // bnbchain: new JsonRpcProvider("https://mainnet.bnbchainlabs.com/v1/55e37d8975113ae7a44603ef8ce460aa"),
};
const NOW_TS = parseInt(Date.now() / 1000);
const FIRST_DATE_TS = parseInt(+new Date(2021, 7, 31) / 1000);
const MOVING_AVERAGE_DAYS = 7;
const MOVING_AVERAGE_PERIOD = 86400 * MOVING_AVERAGE_DAYS;

function getNanInfiniteValue(value, defaultVal = "0.00") {
  if (value === "NaN" || value === "Infinity") {
    return defaultVal;
  }

  return value;
}

export function useLastBlock(chainName = "bartio") {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    providers[chainName]
      .getBlock()
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  return [data, loading, error];
}
function getChainSubgraph(chainName) {
  return chainName === "fantom"
    ? "gummy-fantom-stats"
    : chainName === "optimism"
    ? "gummy-op-stats"
    : chainName === "base"
    ? "gummy-base-stats"
    : chainName === "bartio"
    ? "gummy-bartio-stats"
    : "gummy-arb-stats";
}
export function useGraph(querySource, { subgraph = null, subgraphUrl = null, chainName = "bartio" } = {}) {
  const query = gql(querySource);

  if (!subgraphUrl) {
    if (!subgraph) {
      // subgraph = "gummy-fantom-stats";
      subgraph = getChainSubgraph(chainName);
    }
    // if (chainName === "fantom") {
    //   subgraphUrl = `https://api.studio.thegraph.com/query/74668/${subgraph}`;
    // } else {
    //   subgraphUrl = `https://api.studio.thegraph.com/query/74668/${subgraph}`;
    // }
    // subgraphUrl = `https://api.studio.thegraph.com/query/74668/${subgraph}/version/latest`;
    subgraphUrl = `https://api.goldsky.com/api/public/project_cm1of30dksjme01wjgpyj8gph/subgraphs/${subgraph}/0.0.1/gn`;
    // if (chainName === "bnbchain") {
    //   subgraphUrl = `https://subgraph.yummy.fi/subgraphs/name/${subgraph}`;
    // }
  }

  const client = new ApolloClient({
    link: new HttpLink({ uri: subgraphUrl, fetch }),
    cache: new InMemoryCache(),
  });
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
  }, [querySource, setLoading]);

  useEffect(() => {
    client
      .query({ query })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((ex) => {
        console.warn("Subgraph request failed error: %s subgraphUrl: %s", ex.message, subgraphUrl);
        setError(ex);
        setLoading(false);
      });
  }, [querySource, setData, setError, setLoading]);

  return [data, loading, error];
}

export function useLastSubgraphBlock(chainName = "bartio") {
  const [data, loading, error] = useGraph(
    `{
      _meta {
        block {
          number
        }
      }
    }`,
    { chainName }
  );
  const [block, setBlock] = useState(null);

  useEffect(() => {
    if (!data) {
      return;
    }

    providers[chainName].getBlock(data._meta.block.number).then((block) => {
      setBlock(block);
    });
  }, [data, setBlock]);

  return [block, loading, error];
}

export function useVolumeData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "bartio" } = {}) {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const timestampProp =
    chainName === "fantom" ||
    chainName === "optimism" ||
    chainName === "arbitrum" ||
    chainName === "base" ||
    chainName === "bartio"
      ? "id"
      : "timestamp";
  const query = `{
      volumeStats(
        first: 1000,
        orderBy: ${timestampProp},
        orderDirection: desc
        where: { period: daily, ${timestampProp}_gte: ${from}, ${timestampProp}_lte: ${to} }
        subgraphError: allow
      ) {
        ${timestampProp}
        ${PROPS.join("\n")}
      }
    }`;

  const [graphData, loading, error] = useGraph(query, { chainName });

  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    let ret = sortBy(graphData.volumeStats, timestampProp).map((item) => {
      const ret = { timestamp: item[timestampProp] };
      let all = 0;
      PROPS.forEach((prop) => {
        ret[prop] = item[prop] / 1e30;
        all += ret[prop];
      });
      ret.all = all;
      return ret;
    });

    let cumulative = 0;
    const cumulativeByTs = {};
    return ret.map((item) => {
      cumulative += item.all;

      let movingAverageAll;
      const movingAverageTs = item.timestamp - MOVING_AVERAGE_PERIOD;
      if (movingAverageTs in cumulativeByTs) {
        movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS;
      }

      return {
        movingAverageAll,
        cumulative,
        ...item,
      };
    });
  }, [graphData]);

  return [data, loading, error];
}
export function useVolume24HData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "bartio" } = {}) {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const timestampProp =
    chainName === "fantom" ||
    chainName === "optimism" ||
    chainName === "arbitrum" ||
    chainName === "base" ||
    chainName === "bartio"
      ? "id"
      : "timestamp";
  const query = `{
      hourlyVolumes(
        first: 24,
        orderBy: ${timestampProp},
        orderDirection: desc
        subgraphError: allow
      ) {
        ${timestampProp}
        ${PROPS.join("\n")}
      }
    }`;
  const [graphData, loading, error] = useGraph(query, { chainName });
  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    let ret = sortBy(graphData.hourlyVolumes, timestampProp).map((item) => {
      const ret = { timestamp: item[timestampProp] };
      let all = 0;
      PROPS.forEach((prop) => {
        ret[prop] = item[prop] / 1e30;
        all += ret[prop];
      });
      ret.all = all;
      return ret;
    });

    let cumulative = 0;
    ret.forEach((item) => {
      cumulative += item.all;
    });
    return cumulative;
  }, [graphData]);

  return [data, loading, error];
}
export function useFee24HData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "bartio" } = {}) {
  const PROPS = "marginAndLiquidation swap mint burn".split(" ");
  const timestampProp =
    chainName === "fantom" ||
    chainName === "optimism" ||
    chainName === "arbitrum" ||
    chainName === "base" ||
    chainName === "bartio"
      ? "id"
      : "timestamp";
  const query = `{
      hourlyFees(
        first: 24,
        orderBy: ${timestampProp},
        orderDirection: desc
        subgraphError: allow
      ) {
        ${timestampProp}
        ${PROPS.join("\n")}
      }
    }`;
  const [graphData, loading, error] = useGraph(query, { chainName });
  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    let ret = sortBy(graphData.hourlyFees, timestampProp).map((item) => {
      const ret = { timestamp: item[timestampProp] };
      let all = 0;
      PROPS.forEach((prop) => {
        ret[prop] = item[prop] / 1e30;
        all += ret[prop];
      });
      ret.all = all;
      return ret;
    });

    let cumulative = 0;
    ret.forEach((item) => {
      cumulative += item.all;
    });
    return cumulative;
  }, [graphData]);

  return [data, loading, error];
}

export function useFeesData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "bartio" } = {}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  useEffect(() => {
    if (from !== to) fetchData();
  }, [from, to]);

  const fetchData = async () => {
    const url = `https://api.yummy.fi/${chainName}/api/daily-fees?from=${from}&to=${to}`;
    try {
      setLoading(true);
      const data = await fetcher(url);
      setData(data);
    } catch (ex) {
      setError(ex);
    }
    setLoading(false);
  };

  const feesChartData = useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }
    return data;
  }, [data]);

  return [feesChartData, loading, error];
  // return [fee, load, err];
  // const PROPS = "margin liquidation swap mint burn".split(" ");
  // const feesQuery = `{
  //     feeStats(
  //       first: 1000
  //       orderBy: id
  //       orderDirection: desc
  //       where: { period: daily, id_gte: ${from}, id_lte: ${to} }
  //       subgraphError: allow
  //     ) {
  //       id
  //       margin
  //       marginAndLiquidation
  //       swap
  //       mint
  //       burn
  //       ${chainName === "bnbchain" ? "timestamp" : ""}
  //     }
  //   }`;
  // let [feesData, loading, error] = useGraph(feesQuery, {
  //   chainName,
  // });

  // const feesChartData = useMemo(() => {
  //   if (!feesData) {
  //     return null;
  //   }

  //   let chartData = sortBy(feesData.feeStats, "id").map((item) => {
  //     const ret = { timestamp: item.timestamp || item.id };

  //     PROPS.forEach((prop) => {
  //       if (item[prop]) {
  //         ret[prop] = item[prop] / 1e30;
  //       }
  //     });

  //     ret.liquidation = item.marginAndLiquidation / 1e30 - item.margin / 1e30;
  //     ret.all = PROPS.reduce((memo, prop) => memo + ret[prop], 0);
  //     return ret;
  //   });

  //   let cumulative = 0;
  //   const cumulativeByTs = {};
  //   return chain(chartData)
  //     .groupBy((item) => item.timestamp)
  //     .map((values, timestamp) => {
  //       const all = sumBy(values, "all");
  //       cumulative += all;

  //       let movingAverageAll;
  //       const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD;
  //       if (movingAverageTs in cumulativeByTs) {
  //         movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS;
  //       }

  //       const ret = {
  //         timestamp: Number(timestamp),
  //         all,
  //         cumulative,
  //         movingAverageAll,
  //       };
  //       PROPS.forEach((prop) => {
  //         ret[prop] = sumBy(values, prop);
  //       });
  //       cumulativeByTs[timestamp] = cumulative;
  //       return ret;
  //     })
  //     .value()
  //     .filter((item) => item.timestamp >= from);
  // }, [feesData]);

  // return [feesChartData, loading, error];
}

function fillNa(arr) {
  const prevValues = {};
  let keys;
  if (arr.length > 0) {
    keys = Object.keys(arr[0]);
    delete keys.timestamp;
    delete keys.id;
  }

  for (const el of arr) {
    for (const key of keys) {
      if (!el[key]) {
        if (prevValues[key]) {
          el[key] = prevValues[key];
        }
      } else {
        prevValues[key] = el[key];
      }
    }
  }
  return arr;
}

export function useGlpData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "bartio" } = {}) {
  const timestampProp =
    chainName === "fantom" ||
    chainName === "optimism" ||
    chainName === "arbitrum" ||
    chainName === "base" ||
    chainName === "bartio"
      ? "id"
      : "timestamp";
  const query = `{
      glpStats(
        first: 1000
        orderBy: ${timestampProp}
        orderDirection: desc
        where: {
          period: daily
          ${timestampProp}_gte: ${from}
          ${timestampProp}_lte: ${to}
        }
        subgraphError: allow
      ) {
        ${timestampProp}
        aumInUsdg
        glpSupply
        distributedUsd
        distributedEth
      }
    }`;
  let [data, loading, error] = useGraph(query, { chainName });

  let cumulativeDistributedUsdPerGlp = 0;
  let cumulativeDistributedEthPerGlp = 0;
  const glpChartData = useMemo(() => {
    if (!data) {
      return null;
    }

    let prevGlpSupply;
    let prevAum;

    if (!data.glpStats.length) return [];
    let ret = sortBy(data.glpStats, (item) => item[timestampProp])
      .filter((item) => item[timestampProp] % 86400 === 0)
      .reduce((memo, item) => {
        const last = memo[memo.length - 1];

        const aum = Number(item.aumInUsdg) / 1e18;
        const glpSupply = Number(item.glpSupply) / 1e18;

        const distributedUsd = Number(item.distributedUsd) / 1e30;
        const distributedUsdPerGlp = distributedUsd / glpSupply || 0;
        cumulativeDistributedUsdPerGlp += distributedUsdPerGlp;

        const distributedEth = Number(item.distributedEth) / 1e18;
        const distributedEthPerGlp = distributedEth / glpSupply || 0;
        cumulativeDistributedEthPerGlp += distributedEthPerGlp;

        const glpPrice = aum / glpSupply;
        const timestamp = parseInt(item[timestampProp]);

        const newItem = {
          timestamp,
          aum,
          glpSupply,
          glpPrice,
          cumulativeDistributedEthPerGlp,
          cumulativeDistributedUsdPerGlp,
          distributedUsdPerGlp,
          distributedEthPerGlp,
        };

        if (last && last.timestamp === timestamp) {
          memo[memo.length - 1] = newItem;
        } else {
          memo.push(newItem);
        }

        return memo;
      }, [])
      .map((item) => {
        let { glpSupply, aum } = item;
        if (!glpSupply) {
          glpSupply = prevGlpSupply;
        }
        if (!aum) {
          aum = prevAum;
        }
        item.glpSupplyChange = prevGlpSupply ? ((glpSupply - prevGlpSupply) / prevGlpSupply) * 100 : 0;
        if (item.glpSupplyChange > 1000) {
          item.glpSupplyChange = 0;
        }
        item.aumChange = prevAum ? ((aum - prevAum) / prevAum) * 100 : 0;
        if (item.aumChange > 1000) {
          item.aumChange = 0;
        }
        prevGlpSupply = glpSupply;
        prevAum = aum;
        return item;
      });

    ret = fillNa(ret);
    return ret;
  }, [data]);

  return [glpChartData, loading, error];
}
export function useAumPerformanceData({ from = FIRST_DATE_TS, to = NOW_TS, groupPeriod }, feesData, feesLoading) {
  // const [feesData, feesLoading] = useFeesData({ from, to, groupPeriod });
  const [glpData, glpLoading] = useGlpData({ from, to, groupPeriod });
  const [volumeData, volumeLoading] = useVolumeData({ from, to, groupPeriod });

  const dailyCoef = 86400 / groupPeriod;

  const data = useMemo(() => {
    if (!feesData || !glpData || !volumeData) {
      return null;
    }

    const ret = feesData.map((feeItem, i) => {
      const glpItem = glpData[i];
      const volumeItem = volumeData[i];
      let apr = feeItem?.all && glpItem?.aum ? (feeItem.all / glpItem.aum) * 100 * 365 * dailyCoef : null;
      if (apr > 10000) {
        apr = null;
      }
      let usage = volumeItem?.all && glpItem?.aum ? (volumeItem.all / glpItem.aum) * 100 * dailyCoef : null;
      if (usage > 10000) {
        usage = null;
      }

      return {
        timestamp: feeItem.timestamp,
        apr,
        usage,
      };
    });
    const averageApr = ret.reduce((memo, item) => item.apr + memo, 0) / ret.length;
    ret.forEach((item) => (item.averageApr = averageApr));
    const averageUsage = ret.reduce((memo, item) => item.usage + memo, 0) / ret.length;
    ret.forEach((item) => (item.averageUsage = averageUsage));
    return ret;
  }, [feesData, glpData, volumeData]);

  return [data, feesLoading || glpLoading || volumeLoading];
}

export function useUsersData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "bartio" } = {}) {
  const query = `{
      userStats(
        first: 1000
        orderBy: timestamp
        orderDirection: desc
        where: { period: daily, timestamp_gte: ${from}, timestamp_lte: ${to} }
        subgraphError: allow
      ) {
        uniqueCount
        uniqueSwapCount
        uniqueMarginCount
        uniqueMintBurnCount
        uniqueCountCumulative
        uniqueSwapCountCumulative
        uniqueMarginCountCumulative
        uniqueMintBurnCountCumulative
        actionCount
        actionSwapCount
        actionMarginCount
        actionMintBurnCount
        timestamp
      }
    }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  const prevUniqueCountCumulative = {};
  let cumulativeNewUserCount = 0;
  const data = graphData
    ? sortBy(graphData.userStats, "timestamp").map((item) => {
        const newCountData = ["", "Swap", "Margin", "MintBurn"].reduce((memo, type) => {
          memo[`new${type}Count`] = prevUniqueCountCumulative[type]
            ? item[`unique${type}CountCumulative`] - prevUniqueCountCumulative[type]
            : item[`unique${type}Count`];
          prevUniqueCountCumulative[type] = item[`unique${type}CountCumulative`];
          return memo;
        }, {});
        cumulativeNewUserCount += newCountData.newCount;
        const oldCount = item.uniqueCount - newCountData.newCount;
        const oldPercent = ((oldCount / item.uniqueCount) * 100).toFixed(1);
        return {
          all: item.uniqueCount,
          uniqueSum: item.uniqueSwapCount + item.uniqueMarginCount + item.uniqueMintBurnCount,
          oldCount,
          oldPercent,
          cumulativeNewUserCount,
          ...newCountData,
          ...item,
        };
      })
    : null;

  return [data, loading, error];
}

export function useTradersData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "bartio" } = {}, feesData) {
  const [closedPositionsData, loading, error] = useGraph(
    `{
      tradingStats(
        first: 1000
        orderBy: timestamp
        orderDirection: desc
        where: { period: daily, timestamp_gte: ${from}, timestamp_lte: ${to} }
        subgraphError: allow
      ) {
        timestamp
        profit
        loss
        profitCumulative
        lossCumulative
        longOpenInterest
        shortOpenInterest
      }
    }`,
    { chainName }
  );
  // const [feesData] = useFeesData({ from, to, chainName });
  const marginFeesByTs = useMemo(() => {
    if (!feesData) {
      return {};
    }

    let feesCumulative = 0;
    return feesData.reduce((memo, { timestamp, margin: fees }) => {
      feesCumulative += fees;
      memo[timestamp] = {
        fees,
        feesCumulative,
      };
      return memo;
    }, {});
  }, [feesData]);

  let ret = null;
  let currentPnlCumulative = 0;
  let currentProfitCumulative = 0;
  let currentLossCumulative = 0;
  const data = closedPositionsData
    ? sortBy(closedPositionsData.tradingStats, (i) => i.timestamp).map((dataItem) => {
        const longOpenInterest = dataItem.longOpenInterest / 1e30;
        const shortOpenInterest = dataItem.shortOpenInterest / 1e30;
        const openInterest = longOpenInterest + shortOpenInterest;

        // const fees = (marginFeesByTs[dataItem.timestamp]?.fees || 0)
        // const feesCumulative = (marginFeesByTs[dataItem.timestamp]?.feesCumulative || 0)

        const profit = dataItem.profit / 1e30;
        const loss = dataItem.loss / 1e30;
        const profitCumulative = dataItem.profitCumulative / 1e30;
        const lossCumulative = dataItem.lossCumulative / 1e30;
        const pnlCumulative = profitCumulative - lossCumulative;
        const pnl = profit - loss;
        currentProfitCumulative += profit;
        currentLossCumulative -= loss;
        currentPnlCumulative += pnl;
        return {
          longOpenInterest,
          shortOpenInterest,
          openInterest,
          profit,
          loss: -loss,
          profitCumulative,
          lossCumulative: -lossCumulative,
          pnl,
          pnlCumulative,
          timestamp: dataItem.timestamp,
          currentPnlCumulative,
          currentLossCumulative,
          currentProfitCumulative,
        };
      })
    : null;

  if (data && data.length) {
    const maxProfit = maxBy(data, (item) => item.profit).profit;
    const maxLoss = minBy(data, (item) => item.loss).loss;
    const maxProfitLoss = Math.max(maxProfit, -maxLoss);

    const maxPnl = maxBy(data, (item) => item.pnl).pnl;
    const minPnl = minBy(data, (item) => item.pnl).pnl;
    const maxCurrentCumulativePnl = maxBy(data, (item) => item.currentPnlCumulative).currentPnlCumulative;
    const minCurrentCumulativePnl = minBy(data, (item) => item.currentPnlCumulative).currentPnlCumulative;

    const currentProfitCumulative = data[data.length - 1].currentProfitCumulative;
    const currentLossCumulative = data[data.length - 1].currentLossCumulative;
    const stats = {
      maxProfit,
      maxLoss,
      maxProfitLoss,
      currentProfitCumulative,
      currentLossCumulative,
      maxCurrentCumulativeProfitLoss: Math.max(currentProfitCumulative, -currentLossCumulative),

      maxAbsPnl: Math.max(Math.abs(maxPnl), Math.abs(minPnl)),
      maxAbsCumulativePnl: Math.max(Math.abs(maxCurrentCumulativePnl), Math.abs(minCurrentCumulativePnl)),
    };

    ret = {
      data,
      stats,
    };
  }

  return [ret, loading];
}
const defaultFetcher = (url) => fetch(url).then((res) => res.json());
export function useRequest(url, defaultValue, fetcher = defaultFetcher) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState(defaultValue);
  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await fetcher(url);
      setData(data);
    } catch (ex) {
      // console.error(ex);
      setError(ex);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  return [data, loading, error];
}
export function useCoingeckoPrices(symbol, { from = FIRST_DATE_TS, to } = {}) {
  // token ids https://api.coingecko.com/api/v3/coins
  const _symbol = {
    BTC: "bitcoin",
    ETH: "ethereum",
    FTM: "fantom",
    OP: "optimism",
    ARB: "arbitrum",
  }[symbol];

  const now = Date.now() / 1000;
  const days = Math.ceil(now / 86400) - Math.ceil(from / 86400) - 1;

  const url = `https://api.coingecko.com/api/v3/coins/${_symbol}/market_chart?vs_currency=usd&days=${days}&interval=daily`;

  const [res, loading, error] = useRequest(url);

  const data = useMemo(() => {
    if (!res || res.length === 0) {
      return null;
    }

    const ret = [];

    for (let i = 0; i < (res.prices || []).length; i++) {
      const item = res.prices[i];

      const timestamp = item[0] - 1;
      const groupTs = parseInt(timestamp / 1000 / 86400) * 86400;

      if (to && groupTs > to) break;

      ret.push({
        timestamp: groupTs,
        value: item[1],
      });
    }

    return ret;
  }, [res]);

  return [data, loading, error];
}
function getImpermanentLoss(change) {
  return (2 * Math.sqrt(change)) / (1 + change) - 1;
}
export function useGlpPerformanceData(glpData, feesData, { from = FIRST_DATE_TS, chainName = "bartio", to } = {}) {
  const [btcPrices] = useCoingeckoPrices("BTC", { from, to });
  const [ethPrices] = useCoingeckoPrices("ETH", { from, to });
  // const [ftmPrices] = useCoingeckoPrices("FTM", { from });
  // const [opPrices] = useCoingeckoPrices("OP", { from });
  let ftmPrices = 0;
  let opPrices = 0;
  let arbPrices = 0;
  // if (chainName === "fantom") {
  //   const [_ftmPrices] = useCoingeckoPrices("FTM", { from });
  //   ftmPrices = _ftmPrices;
  // }
  if (chainName === "bartio") {
    const [_ftmPrices] = useCoingeckoPrices("FTM", { from, to });
    ftmPrices = _ftmPrices;
  }
  if (chainName === "optimism") {
    const [_opPrices] = useCoingeckoPrices("OP", { from, to });
    opPrices = _opPrices;
  }
  if (chainName === "arbitrum") {
    const [_arbPrices] = useCoingeckoPrices("ARB", { from, to });
    arbPrices = _arbPrices;
  }
  const glpPerformanceChartData = useMemo(() => {
    if (
      !btcPrices ||
      !ethPrices ||
      // (!ftmPrices && chainName === "fantom") ||
      (!ftmPrices && chainName === "bartio") ||
      (!opPrices && chainName === "optimism") ||
      (!arbPrices && chainName === "arbitrum") ||
      !glpData ||
      !feesData ||
      !feesData.length
    ) {
      return null;
    }

    const glpDataById = glpData.reduce((memo, item) => {
      memo[item.timestamp] = item;
      return memo;
    }, {});

    const feesDataById = feesData.reduce((memo, item) => {
      memo[item.timestamp] = item;
      return memo;
    });

    let BTC_WEIGHT = 0;
    let ETH_WEIGHT = 0;
    let BNB_WEIGHT = 0;

    // if (chainName === "bnbchain") {
    //   BTC_WEIGHT = 0.166;
    //   ETH_WEIGHT = 0.166;
    //   BNB_WEIGHT = 0.166;
    // } else {
    //   BTC_WEIGHT = 0.25;
    //   ETH_WEIGHT = 0.25;
    // }
    if (
      chainName === "fantom" ||
      chainName === "optimism" ||
      chainName === "arbitrum" ||
      chainName === "base" ||
      chainName === "bartio"
    ) {
      BTC_WEIGHT = 0.166;
      ETH_WEIGHT = 0.166;
      BNB_WEIGHT = 0.166;
    } else {
      BTC_WEIGHT = 0.25;
      ETH_WEIGHT = 0.25;
    }

    const STABLE_WEIGHT = 1 - BTC_WEIGHT - ETH_WEIGHT - BNB_WEIGHT;
    const GLP_START_PRICE = glpDataById[btcPrices[0]?.timestamp]?.glpPrice || 0;

    const btcFirstPrice = btcPrices[0]?.value;
    const ethFirstPrice = ethPrices[0]?.value;
    const ftmFirstPrice =
      // chainName === "fantom"
      chainName === "bartio"
        ? ftmPrices[0]?.value
        : chainName === "optimism"
        ? opPrices[0]?.value
        : ethPrices[0]?.value;

    let indexBtcCount = (GLP_START_PRICE * BTC_WEIGHT) / btcFirstPrice;
    let indexEthCount = (GLP_START_PRICE * ETH_WEIGHT) / ethFirstPrice;
    let indexAvaxCount = (GLP_START_PRICE * BNB_WEIGHT) / ftmFirstPrice;
    let indexStableCount = GLP_START_PRICE * STABLE_WEIGHT;

    const lpBtcCount = (GLP_START_PRICE * 0.5) / btcFirstPrice;
    const lpEthCount = (GLP_START_PRICE * 0.5) / ethFirstPrice;
    const lpAvaxCount = (GLP_START_PRICE * 0.5) / ftmFirstPrice;

    const ret = [];
    let cumulativeFeesPerGlp = 0;
    let lastGlpItem;
    let lastFeesItem;

    let prevEthPrice = 3400;
    let prevAvaxPrice = 1000;
    for (let i = 0; i < btcPrices.length; i++) {
      const btcPrice = btcPrices[i].value;
      const ethPrice = ethPrices[i]?.value || prevEthPrice;
      const avaxPrice = ftmPrices[i]?.value || prevAvaxPrice;
      prevAvaxPrice = avaxPrice;
      prevEthPrice = ethPrice;

      const timestampGroup = parseInt(btcPrices[i].timestamp / 86400) * 86400;
      const glpItem = glpDataById[timestampGroup] || lastGlpItem;
      lastGlpItem = glpItem;

      const glpPrice = glpItem?.glpPrice;
      const glpSupply = glpItem?.glpSupply;

      const feesItem = feesDataById[timestampGroup] || lastFeesItem;
      lastFeesItem = feesItem;

      const dailyFees = feesItem?.all;

      const syntheticPrice =
        indexBtcCount * btcPrice + indexEthCount * ethPrice + indexAvaxCount * avaxPrice + indexStableCount;

      // rebalance each day. can rebalance each X days
      if (i % 1 == 0) {
        indexBtcCount = (syntheticPrice * BTC_WEIGHT) / btcPrice;
        indexEthCount = (syntheticPrice * ETH_WEIGHT) / ethPrice;
        indexAvaxCount = (syntheticPrice * BNB_WEIGHT) / avaxPrice;
        indexStableCount = syntheticPrice * STABLE_WEIGHT;
      }

      const lpBtcPrice =
        (lpBtcCount * btcPrice + GLP_START_PRICE / 2) * (1 + getImpermanentLoss(btcPrice / btcFirstPrice));

      const lpEthPrice =
        (lpEthCount * ethPrice + GLP_START_PRICE / 2) * (1 + getImpermanentLoss(ethPrice / ethFirstPrice));
      const lpAvaxPrice =
        (lpAvaxCount * avaxPrice + GLP_START_PRICE / 2) * (1 + getImpermanentLoss(avaxPrice / ftmFirstPrice));

      if (dailyFees && glpSupply) {
        const INCREASED_GLP_REWARDS_TIMESTAMP = 1635714000;
        const GLP_REWARDS_SHARE = timestampGroup >= INCREASED_GLP_REWARDS_TIMESTAMP ? 0.7 : 0.5;
        const collectedFeesPerGlp = (dailyFees / glpSupply) * GLP_REWARDS_SHARE;
        cumulativeFeesPerGlp += collectedFeesPerGlp;
      }

      let glpPlusFees = glpPrice;
      if (glpPrice && glpSupply && cumulativeFeesPerGlp) {
        glpPlusFees = glpPrice + cumulativeFeesPerGlp;
      }

      let glpApr;
      let glpPlusDistributedUsd;
      let glpPlusDistributedEth;
      if (glpItem) {
        if (glpItem.cumulativeDistributedUsdPerGlp) {
          glpPlusDistributedUsd = glpPrice + glpItem.cumulativeDistributedUsdPerGlp;
          // glpApr = glpItem.distributedUsdPerGlp / glpPrice * 365 * 100 // incorrect?
        }
        if (glpItem.cumulativeDistributedEthPerGlp) {
          glpPlusDistributedEth = glpPrice + glpItem.cumulativeDistributedEthPerGlp * ethPrice;
        }
      }

      console.log("sađá", lpBtcPrice);

      ret.push({
        timestamp: btcPrices[i].timestamp,
        syntheticPrice,
        lpBtcPrice,
        lpEthPrice,
        lpAvaxPrice,
        glpPrice,
        btcPrice,
        ethPrice,
        glpPlusFees,
        glpPlusDistributedUsd,
        glpPlusDistributedEth,

        indexBtcCount,
        indexEthCount,
        indexAvaxCount,
        indexStableCount,

        BTC_WEIGHT,
        ETH_WEIGHT,
        BNB_WEIGHT,
        STABLE_WEIGHT,

        performanceLpEth: getNanInfiniteValue(((glpPrice / lpEthPrice) * 100).toFixed(2)),
        performanceLpEthCollectedFees: getNanInfiniteValue(((glpPlusFees / lpEthPrice) * 100).toFixed(2)),
        performanceLpEthDistributedUsd: getNanInfiniteValue(((glpPlusDistributedUsd / lpEthPrice) * 100).toFixed(2)),
        performanceLpEthDistributedEth: getNanInfiniteValue(((glpPlusDistributedEth / lpEthPrice) * 100).toFixed(2)),

        performanceLpBtcCollectedFees: getNanInfiniteValue(((glpPlusFees / lpBtcPrice) * 100).toFixed(2)),

        performanceLpAvaxCollectedFees: getNanInfiniteValue(((glpPlusFees / lpAvaxPrice) * 100).toFixed(2)),

        performanceSynthetic: getNanInfiniteValue(((glpPrice / syntheticPrice) * 100).toFixed(2)),
        performanceSyntheticCollectedFees: getNanInfiniteValue(((glpPlusFees / syntheticPrice) * 100).toFixed(2)),
        performanceSyntheticDistributedUsd: getNanInfiniteValue(
          ((glpPlusDistributedUsd / syntheticPrice) * 100).toFixed(2)
        ),
        performanceSyntheticDistributedEth: getNanInfiniteValue(
          ((glpPlusDistributedEth / syntheticPrice) * 100).toFixed(2)
        ),

        glpApr,
      });
    }

    return ret;
  }, [btcPrices, ethPrices, glpData, feesData]);

  return [glpPerformanceChartData];
}

export const tokenSymbols = {
  // Fantom

  "0x321162cd933e2be498cd2267a90534a804051b11": "BTC",
  "0x74b23882a30290451a17c44f4f05243b6b58c76d": "ETH",
  "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83": "FTM",
  "0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8": "LINK",
  "0x1e4f97b9f9f913c46f1632781732927b9019c68b": "CRV",
  "0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454": "BNB",
  "0x04068da6c83afcfa0e13ba15a6696662335d5b75": "USDC",
  "0x049d68029688eabf473097a2fc38ef61633a3c7a": "USDT",
  "0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e": "DAI",
  "0x1b6382dbdea11d97f24495c9a90b7c88469134a4": "axlUSDC",
  "0xfe7eda5f2c56160d406869a8aa4b2f365d544c7b": "axlETH",
  "0xf1648c50d2863f780c57849d812b4b7686031a3d": "WBTC",
  "0xd226392c23fb3476274ed6759d4a478db3197d82": "axlUSDT",
  "0xcc1b99ddac1a33c201a742a1851662e87bc7f22c": "USDT",
  "0x91a40c733c97a6e1bf876eaf9ed8c08102eb491f": "DAI",
  "0x695921034f0387eac4e11620ee91b1b15a6a09fe": "ETH",
  "0x448d59b4302ab5d2dadf9611bed9457491926c8e": "axlBTC",
  "0x28a92dde19d9989f39a49905d7c9c2fac7799bdf": "USDC",
  // "0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454": "BNB",

  // BNBCHAIN
  "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": "BNB",
  "0x2170ed0880ac9a755fd29b2688956bd959f933f8": "WETH",
  "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c": "WBTC",
  "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d": "USDC",

  // Optimism
  "0x4200000000000000000000000000000000000042": "OP",
  "0x4200000000000000000000000000000000000006": "WETH",
  "0x68f180fcce6836688e9084f035309e29bf0a2095": "WBTC",
  "0x8700daec35af8ff88c16bdf0418774cb3d7599b4": "SNX",
  "0x6fd9d7ad17242c41f7131d257212c54a0e816691": "UNI",
  "0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6": "LINK",
  "0x67ccea5bb16181e7b4109c9c2143c24a1c2205be": "FXS",
  "0x7f5c764cbc14f9669b88837ca1490cca17c31607": "USDC",
  "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58": "USDT",
  "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1": "DAI",

  //Bartio
  "0x0e5d6a5040f24aedf422c69a1977039b6df3badb": "USDC",
  "0x3de78ba6587484e0e5a8c417ef73db2a9c7004e8": "BTC",
  "0x860cbe1f1f08063c55a7b03739f89fa4ff64b524": "USDT",
  "0x559d507e713047873f693c9b84c0b08aeefd678a": "ETH",
  "0xb427324e1592291c497ace6a5ad1267cc249f7b9": "BERA",
};

export function useTokenStats({ from = FIRST_DATE_TS, to = NOW_TS, period = "daily", chainName = "bartio" } = {}) {
  const getTokenStatsFragment = ({ skip = 0 } = {}) => `
      tokenStats(
        first: 1000,
        skip: ${skip},
        orderBy: timestamp,
        orderDirection: desc,
        where: { period: ${period}, timestamp_gte: ${from}, timestamp_lte: ${to} }
        subgraphError: allow
      ) {
        poolAmountUsd
        timestamp
        token
      }
    `;

  // Request more than 1000 records to retrieve maximum stats for period
  const query = `{
      a: ${getTokenStatsFragment()}
      b: ${getTokenStatsFragment({ skip: 1000 })},
      c: ${getTokenStatsFragment({ skip: 2000 })},
      d: ${getTokenStatsFragment({ skip: 3000 })},
      e: ${getTokenStatsFragment({ skip: 4000 })},
      f: ${getTokenStatsFragment({ skip: 5000 })},
    }`;

  const [graphData, loading, error] = useGraph(query, { chainName });

  const data = useMemo(() => {
    if (loading || !graphData) {
      return null;
    }

    const fullData = Object.values(graphData).reduce((memo, records) => {
      memo.push(...records);
      return memo;
    }, []);

    const sortedFullData = sortBy(uniqBy(fullData, "token"), [
      function (o) {
        return Number(formatAmount(o.poolAmountUsd, 30, 30));
      },
    ]);

    const retrievedTokens = [];

    sortedFullData.forEach((item) => {
      const { token } = item;

      const symbol = tokenSymbols[token] || token;
      retrievedTokens.unshift(symbol);
    });

    const timestampGroups = fullData.reduce((memo, item) => {
      const { timestamp, token, ...stats } = item;

      const symbol = tokenSymbols[token] || token;

      memo[timestamp] = memo[timestamp || 0] || {};

      memo[timestamp][symbol] = {
        poolAmountUsd: parseInt(stats.poolAmountUsd) / 1e30,
      };

      return memo;
    }, {});

    const poolAmountUsdRecords = [];

    Object.entries(timestampGroups).forEach(([timestamp, dataItem]) => {
      const poolAmountUsdRecord = Object.entries(dataItem).reduce(
        (memo, [token, stats]) => {
          memo.all += stats.poolAmountUsd;
          memo[token] = stats.poolAmountUsd;
          memo.timestamp = timestamp;

          return memo;
        },
        { all: 0 }
      );

      poolAmountUsdRecords.push(poolAmountUsdRecord);
    });

    return {
      poolAmountUsd: poolAmountUsdRecords,
      tokenSymbols: Array.from(retrievedTokens),
    };
  }, [graphData, loading]);

  return [data, loading, error];
}
export function useFundingRateData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "bartio" } = {}) {
  const query = `{
      fundingRates(
        first: 1000,
        orderBy: timestamp,
        orderDirection: desc,
        where: { period: daily, id_gte: ${from}, id_lte: ${to} }
        subgraphError: allow
      ) {
        id,
        token,
        timestamp,
        startFundingRate,
        startTimestamp,
        endFundingRate,
        endTimestamp
      }
    }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    const groups = graphData.fundingRates.reduce((memo, item) => {
      const symbol = tokenSymbols[item.token];
      if (symbol === "MIM") {
        return memo;
      }
      memo[item.timestamp] = memo[item.timestamp] || {
        timestamp: item.timestamp,
      };
      const group = memo[item.timestamp];
      const timeDelta = parseInt((item.endTimestamp - item.startTimestamp) / 3600) * 3600;

      let fundingRate = 0;
      if (item.endFundingRate && item.startFundingRate) {
        const fundingDelta = item.endFundingRate - item.startFundingRate;
        const divisor = timeDelta / 86400;
        fundingRate = (fundingDelta / divisor / 10000) * 365;
      }
      group[symbol] = fundingRate;
      return memo;
    }, {});

    return fillNa(sortBy(Object.values(groups), "timestamp"));
  }, [graphData]);

  return [data, loading, error];
}

function getSwapSourcesFragment(skip = 0, from, to) {
  return `
      hourlyVolumeBySources(
        first: 1000
        skip: ${skip}
        orderBy: timestamp
        orderDirection: desc
        where: { timestamp_gte: ${from}, timestamp_lte: ${to} }
        subgraphError: allow
      ) {
        timestamp
        source
        swap
      }
    `;
}
const knownSwapSources = {
  fantom: {
    //   "0xabbc5f99639c9b6bcb58544ddf04efa6802f4064": "GMY", // Router
    //   "0x09f77e8a13de9a35a7231028187e9fd5db8a2acb": "GMY", // Orderbook
    //   "0x98a00666cfcb2ba5a405415c2bf6547c63bf5491": "GMY", // PositionManager old
    //   "0x87a4088bd721f83b6c2e5102e2fa47022cb1c831": "GMY", // PositionManager
    //   "0x7257ac5d0a0aac04aa7ba2ac0a6eb742e332c3fb": "GMY", // OrderExecutor
    //   "0x1a0ad27350cccd6f7f168e052100b4960efdb774": "GMY", // FastPriceFeed
    //   "0x3b6067d4caa8a14c63fdbe6318f27a0bbc9f9237": "Dodo",
    //    "0x11111112542d85b3ef69ae05771c2dccff4faa26": "1inch",
    //    "0x6352a56caadc4f1e25cd6c75970fa768a3304e64": "OpenOcean",
    //   "0x4775af8fef4809fe10bf05867d2b038a4b5b2146": "Gelato",
    //  "0x5a9fd7c39a6c488e715437d7b1f3c823d5596ed1": "LiFiDiamond",
    //   "0x1d838be5d58cc131ae4a23359bc6ad2dddb8b75a": "Vovo",
    //   "0xc4bed5eeeccbe84780c44c5472e800d3a5053454": "Vovo",
    //   "0xe40beb54ba00838abe076f6448b27528dd45e4f0": "Vovo",
    //   "0x9ba57a1d3f6c61ff500f598f16b97007eb02e346": "Vovo",
    //   "0xfa82f1ba00b0697227e2ad6c668abb4c50ca0b1f": "JonesDAO",
    //   "0x226cb17a52709034e2ec6abe0d2f0a9ebcec1059": "WardenSwap",
    //   "0x1111111254fb6c44bac0bed2854e76f90643097d": "1inch",
    //   "0x6d7a3177f3500bea64914642a49d0b5c0a7dae6d": "deBridge",
    //   "0xc30141b657f4216252dc59af2e7cdb9d8792e1b0": "socket.tech",

    "0xe0c38b2a8d09aad53f1c67734b9a95e43d5981c0": "FireBird",
    "0x3319161b131401124e61ffcdff0aa7f6fdcbbfed": "OpenOcean",
    "0xddcaf38d2ae216f7b86af7a12a174cbe168b034b": "BeefyZap",
    "0x1231deb6f5749ef6ce6943a275a1d3e7486f4eae": "LiFiDiamond",
    "0xda241399697fa3f6cd496edafab6191498ec37f5": "XSwapper",
    "0x957301825dc21d4a92919c9e72dc9e6c6a29e7f8": "OneInchSwapImpl",
    "0x1111111254eeb25477b68fb85ed929f73a960582": "1inch",
  },
  bnbchain: {
    "0x4296e307f108b2f583ff2f7b7270ee7831574ae5": "GMY",
    "0x5f719c2f1095f7b9fc68a68e35b51194f4b6abe8": "GMY",
    "0x7d9d108445f7e59a67da7c16a2ceb08c85b76a35": "GMY", // FastPriceFeed
    "0xf2ec2e52c3b5f8b8bd5a3f93945d05628a233216": "GMY", // PositionManager
    "0xc4729e56b831d74bbc18797e0e17a295fa77488c": "Yak",
    "0x409e377a7affb1fd3369cfc24880ad58895d1dd9": "Dodo",
    "0x6352a56caadc4f1e25cd6c75970fa768a3304e64": "OpenOcean",
    "0x7c5c4af1618220c090a6863175de47afb20fa9df": "Gelato",
    "0x1111111254fb6c44bac0bed2854e76f90643097d": "1inch",
    "0xdef171fe48cf0115b1d80b88dc8eab59176fee57": "ParaSwap",
    "0x2ecf2a2e74b19aab2a62312167aff4b78e93b6c5": "ParaSwap",
  },
  optimism: {
    "0x4296e307f108b2f583ff2f7b7270ee7831574ae5": "GMY",
    "0x5f719c2f1095f7b9fc68a68e35b51194f4b6abe8": "GMY",
    "0x7d9d108445f7e59a67da7c16a2ceb08c85b76a35": "GMY", // FastPriceFeed
    "0xf2ec2e52c3b5f8b8bd5a3f93945d05628a233216": "GMY", // PositionManager
    "0xc4729e56b831d74bbc18797e0e17a295fa77488c": "Yak",
    "0x409e377a7affb1fd3369cfc24880ad58895d1dd9": "Dodo",
    "0x6352a56caadc4f1e25cd6c75970fa768a3304e64": "OpenOcean",
    "0x7c5c4af1618220c090a6863175de47afb20fa9df": "Gelato",
    "0x1111111254fb6c44bac0bed2854e76f90643097d": "1inch",
    "0xdef171fe48cf0115b1d80b88dc8eab59176fee57": "ParaSwap",
    "0x2ecf2a2e74b19aab2a62312167aff4b78e93b6c5": "ParaSwap",
    "0x0c6134Abc08A1EafC3E2Dc9A5AD023Bb08Da86C3": "FireBird",
  },
  arbitrum: {
    "0x0c6134Abc08A1EafC3E2Dc9A5AD023Bb08Da86C3": "FireBird",
  },
  base: {},
};
export function useSwapSources({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "bartio" } = {}) {
  const query = `{
      a: ${getSwapSourcesFragment(0, from, to)}
      b: ${getSwapSourcesFragment(1000, from, to)}
      c: ${getSwapSourcesFragment(2000, from, to)}
      d: ${getSwapSourcesFragment(3000, from, to)}
      e: ${getSwapSourcesFragment(4000, from, to)}
    }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  let data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    const { a, b, c, d, e } = graphData;
    const all = [...a, ...b, ...c, ...d, ...e];

    const totalVolumeBySource = a.reduce((acc, item) => {
      const source = knownSwapSources[chainName]?.[item.source] || item.source;
      if (!acc[source]) {
        acc[source] = 0;
      }
      acc[source] += item.swap / 1e30;
      return acc;
    }, {});
    const topVolumeSources = new Set(
      Object.entries(totalVolumeBySource)
        .sort((a, b) => b[1] - a[1])
        .map((item) => item[0])
        .slice(0, 30)
    );

    let ret = chain(all)
      .groupBy((item) => parseInt(item.timestamp / 86400) * 86400)
      .map((values, timestamp) => {
        let all = 0;
        const retItem = {
          timestamp: Number(timestamp),
          ...values.reduce((memo, item) => {
            let source = knownSwapSources[chainName]?.[item.source] || item.source;
            if (!topVolumeSources.has(source)) {
              source = "Other";
            }
            if (item.swap != 0) {
              const volume = item.swap / 1e30;
              memo[source] = memo[source] || 0;
              memo[source] += volume;
              all += volume;
            }
            return memo;
          }, {}),
        };

        retItem.all = all;

        return retItem;
      })
      .sortBy((item) => item.timestamp)
      .value();

    return ret;
  }, [graphData]);

  return [data, loading, error];
}
