import { useEffect, useState } from "react";
import "./PercentageButtons.scss";
import { limitDecimals } from "lib/numbers";
import Slider, { SliderTooltip } from "rc-slider";
const TakeProfitLongTriggerButton = ({ onChangePercentage, value, balance, percentArray = [0.25, 0.5, 0.75, 1] }) => {
  const [val, setVal] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  let timeout = null;
  const leverageSliderHandle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${parseFloat(value).toFixed(1)}%`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Slider.Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };
  const onChange = (e) => {
    const inputValue = e.target.value;
    if (timeout) clearTimeout(timeout);
    if (!isTyping) setIsTyping(true);
    if (!inputValue.includes("-")) {
      if (inputValue <= 100) {
        if (inputValue.includes(".")) {
          const arr = inputValue.split(".")[1];
          if (arr.length <= 2) {
            setVal(inputValue);
            if (inputValue) onChangePercentage && onChangePercentage(inputValue / 100);
          }
        } else {
          setVal(inputValue);
          if (inputValue) onChangePercentage && onChangePercentage(inputValue / 100);
        }
      }
    }
  };

  const handleOnChangePercentage = (percentage) => {
    onChangePercentage && onChangePercentage(percentage);
    setTimeout(() => {
      setVal(percentage * 100);
    }, 0.5);
  };
  useEffect(() => {
    if (!timeout) {
      timeout = setTimeout(() => {
        setIsTyping(false);
      }, 1500);
    }
  }, [val]);
  useEffect(() => {
    if (!isTyping) {
      if (value) {
        const percent = limitDecimals((value / balance) * 100, 2);
        if (percent !== val) setVal(Number(percent - 100).toFixed(2));
      } else setVal(0);
    }
  }, [value]);
  return (
    <div className="Exchange-leverage-slider-container">
      <div className={`percentahe-value ${val <= 0 && "init-value"}`}>
        <input onChange={onChange} value={val} placeholder="0" />
        <span>%</span>
      </div>
      {/* {percentArray.map((item) => (
        <div
          className={`percentage-btn ${
            limitDecimals(balance + balance * item, 6) == limitDecimals(value, 6) && balance !== 0
              ? "percentage-btn-selected"
              : ""
          }`}
          key={item}
          onClick={() => handleOnChangePercentage(item)}
        >
          {`${item * 100}%`}
        </div>
      ))} */}
      <div className="Exchange-leverage-slider App-slider normalSlider">
        <Slider
          min={0}
          max={5}
          step={0.1}
          marks={{
            0: "0%",
            1: "1%",
            2: "2%",
            3: "3%",
            4: "4%",
            5: "5%",
          }}
          handle={leverageSliderHandle}
          onChange={(value) => handleOnChangePercentage(value / 100)}
          defaultValue={0}
          value={val}
        />
      </div>
    </div>
  );
};
export default TakeProfitLongTriggerButton;
