import { getExplorerUrl } from "config/chains";
import { getTokenSymbolFromString } from "domain/tokens";
import { BigNumber } from "ethers";
import { importImage } from "lib/legacy";
import { formatAmount, parseValue } from "lib/numbers";
import cx from "classnames";
import moment from "moment";
import { TYPE_MAP_TYPE } from "pages/AnalyticsYummy/components";
import ExternalLink from "components/ExternalLink/ExternalLink";
import ic_new_tab from "img/trade/new_tab.svg";

const TradeActivityItemMobileV2 = (props) => {
  const { marketAsssets, chainId, position, handleSelectedPosition } = props;
  const { symbol = "...", decimals = 4 } = marketAsssets?.[position.tokenId] || {};
  const leverage = BigNumber.from(position?.tradeVolume || 0)
    .mul(parseValue("1", 30))
    .div(BigNumber.from(position?.collateral || 0));
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const posQty = parseValue(position.tradeVolume, 30).mul(parseValue(1, 30)).div(parseValue(position.averagePrice, 30));

  const completePosition = {
    ...position,
    leverage,
    symbol,
    quantity: posQty,
  };

  const txUrl = getExplorerUrl(chainId) + "tx/" + position.transactionHash;

  return (
    <div key={position.index} className="App-card">
      <div className="App-card-title">
        <span className="Exchange-list-title">
          <img src={importImage("ic_" + getTokenSymbolFromString(symbol)?.toLowerCase() + "_24.svg")} alt="" /> {symbol}{" "}
          <span className="muted">#{position.posId}</span>
        </span>
      </div>
      <div className="App-card-divider" />
      <div className="App-card-content app-card-position">
        <div className="leverage-pad">
          <div
            className={cx("Exchange-list-side", {
              positive: position.isLong,
              negative: !position.isLong,
            })}
          >
            {position.isLong ? "LONG" : "SHORT"}
          </div>
          {/* {leverage && (
            <span
              className=" leverage-box"
              style={{
                color: "#FFC700",
                backgroundColor: "rgba(255, 199, 0, 0.20)",
                height: 16,
                display: "inline-block",
                opacity: 1,
                borderRadius: "100px",
                padding: "0 4px",
                fontSize: "12px",
                lineHeight: "15.6px",
                fontWeight: 700,
                marginLeft: "4px",
              }}
            >
              {formatAmount(leverage, 30, 2, true)}X
            </span>
          )} */}
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Date/Time</span>
          </div>
          <div>
            <div>{moment(new Date(position.createdAt * 1000)).format(dateFormat)}</div>
            <div className="muted fz-12">{moment(new Date(position.createdAt * 1000)).format(timeFormat)}</div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Action</span>
          </div>
          <div className="position-list-collateral">
            {/* {titleCase(position.actionType.replace("_", " "))} */}
            {position.actionType === "ADD_TRAILING_STOP" ? (
              position.trailingStopAll ? (
                "Close"
              ) : (
                "Decrease"
              )
            ) : (
              <span>
                {position.actionType === "EDIT_COLLATERAL"
                  ? position.isPlus
                    ? "Deposited"
                    : "Withdrew"
                  : TYPE_MAP_TYPE[position.actionType]}{" "}
                <span className="muted">(Price ${formatAmount(position.markPrice, 30, decimals, true)})</span>
              </span>
            )}
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Type</span>
          </div>
          <div>
            {/* {position.actionType === "CLOSE_POSITION" ? "Close" : "Open"} */}
            {position.positionType}
          </div>
        </div>
        {position.actionType !== "EDIT_COLLATERAL" && (
          <div className="App-card-row">
            <div className="label">
              <span>Size Delta</span>
            </div>
            <div>
              <div> ${formatAmount(position.tradeVolume, 30, 2, true)}</div>
              <div className="muted fz-12">
                {formatAmount(posQty, 30, 4, true)} {getTokenSymbolFromString(symbol)}
              </div>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: "1.3rem" }} />
      <div className="App-card-options">
        <button
          className="App-button-option App-card-option"
          onClick={() => handleSelectedPosition(completePosition)}
          // disabled={position.size == 0}
        >
          <span>View</span>
        </button>
        <button
          style={{ width: 40, display: "flex", justifyContent: "center", alignItems: "center" }}
          className="PositionDropdown-dots-icon"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ExternalLink href={txUrl} className="tx">
            <img style={{ width: 16 }} src={ic_new_tab} alt="ic_new_tab" />
          </ExternalLink>
        </button>
      </div>
    </div>
  );
};
export default TradeActivityItemMobileV2;
