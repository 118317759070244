const RefreshIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" className="shareic">
      <g fill="none" fill-rule="evenodd">
        <path
          fill="#b9b9b9"
          d="M.524 14c-.395-.124-.525-.403-.524-.804.007-3.297.004-6.595.004-9.893 0-.51.243-.755.751-.755h6.177c.356 0 .595.168.681.474.1.354-.139.73-.502.785-.08.012-.163.016-.244.016-1.809 0-3.617.002-5.425-.002-.129 0-.168.026-.168.162.004 2.86.004 5.721 0 8.582 0 .132.034.166.166.165 2.86-.004 5.72-.004 8.581 0 .136 0 .163-.04.163-.167-.004-1.822-.003-3.644-.003-5.466 0-.35.143-.587.412-.688.385-.146.796.1.855.514.01.067.008.136.008.204 0 2.023-.005 4.045.004 6.067.002.4-.128.68-.522.806H.524z"
        />
        <path
          fill="#b9b9b9"
          d="M14 5.854c-.048.092-.075.193-.144.275a.64.64 0 0 1-.677.208.623.623 0 0 1-.441-.536 2.069 2.069 0 0 1-.01-.218l-.001-3.212v-.157c-.071.013-.099.063-.134.099a9219.954 9219.954 0 0 0-6.34 6.338c-.184.185-.386.301-.657.242a.631.631 0 0 1-.363-1.009c.045-.057.097-.108.148-.16l6.3-6.3c.038-.037.088-.064.15-.108-.09-.05-.145-.038-.196-.038l-3.252-.001a.859.859 0 0 1-.374-.06.63.63 0 0 1-.357-.686.63.63 0 0 1 .57-.523c.054-.005.11-.004.164-.004 1.603 0 3.206.006 4.81-.004.4-.003.68.129.804.524v5.33z"
        />
      </g>
    </svg>
  );
};

export default RefreshIcon;
