import { getServerUrl } from "config/backend";
import { switchNetwork } from "lib/wallets";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";

import { BERACHAIN_BARTIO, DEFAULT_CHAIN_ID } from "config/chains";
import { getWhitelistedTokens } from "config/tokens";
import { useGmyPrice, useTotalGmyStaked, useTotalGmySupplyAll } from "domain/legacy";
import { useInfoTokens } from "domain/tokens";
import { BASIS_POINTS_DIVISOR, GLP_DECIMALS, GMY_DECIMALS, PLACEHOLDER_ACCOUNT, USD_DECIMALS } from "lib/legacy";
import { bigNumberify, expandDecimals, formatAmount, formatKeyAmount, formatNumber, parseValue } from "lib/numbers";

import ExternalLink from "components/ExternalLink/ExternalLink";
import { useThemeContext } from "contexts/ThemeProvider";
import useGMYFarmContractMulticall from "hooks/contracts/useGMYFarmContractMulticall";
import useGmContract from "hooks/contracts/useGmContract";
import useGmApr from "hooks/useGmApr";
import useInfoGm from "hooks/useInfoGm";
import useMspPriceMultiChain from "hooks/useMspPriceMultiChain";
import useTokensPrice from "hooks/useTokensPrice";
import useUsdPrice from "hooks/useUsdPrice";

import glp40Icon from "img/mglp.svg";
import msp40Icon from "img/gslp.svg";
import gmy40Icon from "img/gum.svg";

import useProcessedData from "hooks/useProcessedData";
import { caculateAPYHourly2 } from "lib/helper";
import { useMedia } from "react-use";

const ArrowIcon = ({ isLightTheme }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.87521 15.6967C6.54977 16.0221 6.54977 16.5498 6.87521 16.8752C7.20065 17.2006 7.72828 17.2006 8.05372 16.8752L14.8807 10.0482L14.8807 15.1074C14.8807 15.5677 15.2538 15.9408 15.714 15.9408C16.1743 15.9408 16.5474 15.5677 16.5474 15.1074L16.5474 8.03638C16.5474 7.81536 16.4596 7.6034 16.3033 7.44712C16.147 7.29084 15.9351 7.20304 15.714 7.20304L8.64298 7.20304C8.18274 7.20304 7.80964 7.57614 7.80964 8.03638C7.80964 8.49661 8.18274 8.86971 8.64298 8.86971L13.7022 8.86971L6.87521 15.6967Z"
          fill={isLightTheme ? "#0D1A16" : "white"}
        />
      </g>
    </svg>
  );
};

const Tokens = ({ chainId, active, library, apy }) => {
  // const processedData = useAprContext();

  const processedData = useProcessedData(BERACHAIN_BARTIO);

  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const isTabletOnly = useMedia("(min-width: 768px) and (max-width: 1023px)");

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  const { data: glpInfo } = useSWR(getServerUrl(chainId, "/glp-info"), {
    refreshInterval: 30000,
  });
  const { data: glpInfoBARTIO } = useSWR(getServerUrl(BERACHAIN_BARTIO, "/glp-info"), {
    refreshInterval: 30000,
  });

  // const { data: glpInfoOP } = useSWR(getServerUrl(OP, "/glp-info"), {
  //   refreshInterval: 30000,
  // });

  // const { data: glpInfoArbitrum } = useSWR(getServerUrl(ARBITRUM, "/glp-info"), {
  //   refreshInterval: 30000,
  // });
  // const { data: glpInfoBase } = useSWR(getServerUrl(BASE, "/glp-info"), {
  //   refreshInterval: 30000,
  // });

  let { bartio: totalGmySupplyBARTIO, total: totalGmySupply } = useTotalGmySupplyAll();
  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);
  const { gmyPriceFromBARTIO, gmyPriceFromOP, gmyPriceFromArbitrum, gmyPriceFromBase, gmyPrice } = useGmyPrice(
    chainId,
    undefined,
    active
  );

  let { bartio: bartioStakedGmy } = useTotalGmyStaked();

  let bartioStakedGmyUsd;
  if (bartioStakedGmy && gmyPriceFromArbitrum) {
    bartioStakedGmyUsd = bartioStakedGmy.mul(gmyPriceFromArbitrum).div(expandDecimals(1, GMY_DECIMALS));
  }

  let stakedGmySupplyUsd;
  if (gmyPriceFromBARTIO && bartioStakedGmy) {
    stakedGmySupplyUsd = bartioStakedGmy.mul(gmyPriceFromBARTIO).div(expandDecimals(1, GMY_DECIMALS));
  }

  let glpPrice;
  let glpSupply;
  let glpMarketCap;
  // let glpPriceFantomLegacy;
  let glpPriceBARTIO;
  let glpSupplyBARTIO;
  // let glpMarketCapFantomLegacy;
  let glpMarketCapBARTIO;
  let totalGlpSupply;
  if (glpInfo) {
    glpSupply = bigNumberify(glpInfo?.totalSupply || "0");

    glpPrice = bigNumberify(glpInfo?.price || "0");
    glpMarketCap = glpPrice.mul(glpSupply).div(expandDecimals(1, GLP_DECIMALS));
  }
  if (glpInfoBARTIO) {
    glpSupplyBARTIO = bigNumberify(glpInfoBARTIO?.totalSupply || "0");

    glpPriceBARTIO = bigNumberify(glpInfoBARTIO?.price || "0");
    glpMarketCapBARTIO = glpPriceBARTIO.mul(glpSupplyBARTIO).div(expandDecimals(1, GLP_DECIMALS));
  }

  if (glpSupplyBARTIO) {
    totalGlpSupply = glpSupplyBARTIO;
  }
  if (glpMarketCapBARTIO) {
    glpMarketCap = glpMarketCapBARTIO;
    // .add(glpMarketCapBARTIOLegacy)
    // .add(glpMarketCapBase);
  }
  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  let stableGlp = 0;
  let totalGlp = 0;

  tokenList.forEach((token) => {
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo.usdgAmount && adjustedUsdgSupply && adjustedUsdgSupply.gt(0)) {
      const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply);
      if (tokenInfo.isStable) {
        stableGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      }
      totalGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
    }
  });

  let stablePercentage = totalGlp > 0 ? ((stableGlp * 100) / totalGlp).toFixed(2) : "0.0";

  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  const BuyLink = ({ className, to, children, network }) => {
    return (
      <Link to={to} className={className} onClick={() => changeNetwork(network)}>
        {children}
      </Link>
    );
  };

  const mspPrices = useMspPriceMultiChain();
  const mspPrice = mspPrices[chainId] || mspPrices[DEFAULT_CHAIN_ID];

  const { totalSupply: totalSupplyGmBARTIO, decimals: gmDecimalBARTIO } = useGmContract(
    BERACHAIN_BARTIO,
    active,
    PLACEHOLDER_ACCOUNT
  );

  // const { totalSupply: totalSupplyGmOp, decimals: gmDecimalOp } = useGmContract(OP, active, PLACEHOLDER_ACCOUNT);
  // const totalSupplyGm = totalSupplyGmBARTIO && totalSupplyGmOp ? totalSupplyGmBARTIO.add(totalSupplyGmOp) : undefined;
  const { totalSupply: totalSupplyGm } = useInfoGm(chainId);

  // const {
  //   glpPoolInfo: [totalStakedBARTIO],
  //   poolRewardsPerSec: poolRewardsPerSecBARTIO,
  // } = useGMYFarmContractMulticall(BERACHAIN_BARTIO, active, PLACEHOLDER_ACCOUNT, library);

  // const {
  //   glpPoolInfo: [totalStakedOp],
  //   poolRewardsPerSec: poolRewardsPerSecOp,
  // } = useGMYFarmContractMulticall(OP, active, PLACEHOLDER_ACCOUNT, library);

  // const totalStaked = {
  //   [BERACHAIN_BARTIO]: useUsdPrice(totalStakedBARTIO, mspPrice, 30),
  //   // [OP]: useUsdPrice(totalStakedOp, mspPrice, 30),
  // };
  // const totalStakedAll = totalStaked[BERACHAIN_BARTIO] ? totalStaked[BERACHAIN_BARTIO] : undefined;

  // const rewardsPriceBARTIO = useTokensPrice({
  //   gmyPrice: gmyPriceFromBARTIO,
  //   BERACHAIN_BARTIO,
  // });

  // const aprBARTIO = useGmApr({
  //   gmPrice: mspPrices[BERACHAIN_BARTIO],
  //   rewardsPrice: rewardsPriceBARTIO,
  //   totalStaked: totalStakedBARTIO,
  //   poolRewardsPerSec: poolRewardsPerSecBARTIO,
  // });

  //GMY
  const gmyAprBARTIO = parseFloat(formatAmount(processedData[BERACHAIN_BARTIO]?.gmyAprTotal, 2)) / 100;
  const gmyApyBARTIO = caculateAPYHourly2(gmyAprBARTIO) * 100;

  //GLP
  const glpAprBARTIO = parseFloat(formatAmount(processedData[BERACHAIN_BARTIO]?.glpAprTotal, 2)) / 100;
  const glpApyBARTIO = caculateAPYHourly2(glpAprBARTIO) * 100;

  let { total: totalGmyStaked } = useTotalGmyStaked();

  const totalGumstake = gmyPrice ? gmyPrice.mul(totalGmyStaked || 0).div(parseValue(1, USD_DECIMALS)) : 0;
  return (
    <Wrapper className={lightThemeClassName}>
      <Title>
        <span>Tokens</span>
      </Title>
      <TokenCardContainer>
        <TokenCard>
          <CardTitle>
            <TokenImg>
              <img src={gmy40Icon} alt="YUM Token Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">YUM</div>
                <div className="token-desc">YUM Finance Governance Token</div>
              </div>
            </TokenInfo>
          </CardTitle>
          <TokenAPR>
            <div className="label"> APR </div>
            <div className="apr-wrap">
              {processedData?.gmyAprTotalWithBoost ? (
                <span>{formatKeyAmount(processedData, "gmyAprTotalWithBoost", 2, 2, true)}%</span>
              ) : (
                <div className="skeleton-box" style={{ width: "60px" }} />
              )}
            </div>
          </TokenAPR>{" "}
          <TokenCardInfo>
            <div className="label">
              <span>Supply</span>
            </div>
            <div className={`value`}>
              {totalGmySupply && processedData?.gmySupplyUsd ? (
                <>
                  {"$" + formatAmount(totalGmySupply, GMY_DECIMALS, 0, true) + " YUM"}
                  <div className="value-dollar">${formatKeyAmount(processedData, "gmySupplyUsd", 30, 2, true)} </div>
                </>
              ) : (
                <div
                  className="skeleton-box"
                  style={{
                    width: 48,
                  }}
                />
              )}
            </div>
          </TokenCardInfo>
          <TokenCardInfo>
            <div className="label">
              <span>Total Staked</span>
            </div>
            <div className="value">
              {totalGmyStaked && totalGumstake ? (
                <>
                  {formatAmount(totalGmyStaked, 18, 0, true)} YUM
                  <div className="value-dollar">${formatNumber(formatAmount(totalGumstake, 18, 2), 2)}</div>
                </>
              ) : (
                <div
                  className="skeleton-box"
                  style={{
                    width: 48,
                  }}
                />
              )}
            </div>
          </TokenCardInfo>
          <BuyLink to="/earn" className="btn-token default-btn" network={BERACHAIN_BARTIO}>
            Deposit
          </BuyLink>
        </TokenCard>
        <TokenCard>
          <CardTitle>
            <TokenImg>
              <img src={glp40Icon} alt="glp40Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">
                  MGLP{" "}
                  {/* <ExternalLink href="/#">
                    <ArrowIcon isLightTheme={isLightTheme} />
                  </ExternalLink> */}
                </div>
                <div className="token-desc">YUM Liquidity Pool Token</div>
              </div>{" "}
            </TokenInfo>
          </CardTitle>

          <TokenAPR>
            <div className="label"> APY</div>{" "}
            <div className="apr-wrap">
              {processedData?.glpAprTotal ? (
                <span>{formatKeyAmount(processedData, "glpAprTotal", 2, 2, true)}%</span>
              ) : (
                <div className="skeleton-box" style={{ width: "60px" }} />
              )}
            </div>
          </TokenAPR>
          <TokenCardInfo>
            <div className="label">
              <span>Supply</span>
            </div>
            <div className={`value`}>
              {processedData?.glpSupply && processedData?.glpSupplyUsd ? (
                <>
                  {formatAmount(processedData?.glpSupply, GLP_DECIMALS, 0, true)} MGLP
                  <div className="value-dollar">
                    ${formatNumber(formatKeyAmount(processedData, "glpSupplyUsd", USD_DECIMALS, 2), 2)}
                  </div>
                </>
              ) : (
                <div
                  className="skeleton-box"
                  style={{
                    width: 48,
                  }}
                />
              )}
            </div>
          </TokenCardInfo>
          <TokenCardInfo>
            <div className="label">
              <span>Total Staked</span>
            </div>
            <div className="value">
              {processedData?.glpSupply && processedData?.glpSupplyUsd ? (
                <>
                  {formatKeyAmount(processedData, "glpSupply", 18, 0, true)} MGLP
                  <div className="value-dollar">
                    ${formatNumber(formatKeyAmount(processedData, "glpSupplyUsd", USD_DECIMALS, 2), 2)}
                  </div>
                </>
              ) : (
                <div
                  className="skeleton-box"
                  style={{
                    width: 48,
                  }}
                />
              )}
            </div>
          </TokenCardInfo>

          <BuyLink to="/earn/mglp" className="btn-token default-btn" network={BERACHAIN_BARTIO}>
            Deposit
          </BuyLink>
        </TokenCard>
        <TokenCard>
          <CardTitle>
            <TokenImg className="golden">
              <img src={msp40Icon} alt="msp40Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">GSLP </div>
                <div className="token-desc">YUM Stable Liquidity Pool Token</div>

                {/* <div className="token-price">
                  Price:{" "}
                  {!mspPrice && (
                    <div
                      className="skeleton-box"
                      style={{
                        width: 64,
                        height: 19.59,
                      }}
                    />
                  )}
                  {mspPrice && (
                    <TooltipComponent
                      position="left-bottom"
                      className="nowrap excep"
                      handle={"$" + formatAmount(mspPrice, 30, 2)}
                      renderContent={() => (
                        <div
                          className="customTooltip"
                          style={{
                            minWidth: 220,
                          }}
                        >
                          <StatsTooltipRow
                            label={`Price on BARTIO`}
                            value={formatAmount(mspPrices[FANTOM], 30, 2)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={`Price on Optimism`}
                            value={formatAmount(mspPrices[OP], 30, 2)}
                            showDollar={true}
                          />
                        </div>
                      )}
                    />
                  )}
                </div> */}
              </div>{" "}
            </TokenInfo>
          </CardTitle>
          <TokenAPR>
            <div className="label">APR </div>
            <div className="apr-wrap">
              <span>
                {processedData?.gmAprTotal ? (
                  <>{formatKeyAmount(processedData, "gmAprTotal", 2, 2, true)}%</>
                ) : (
                  <div className="skeleton-box" style={{ width: "60px" }} />
                )}
              </span>
            </div>
          </TokenAPR>
          <TokenCardInfo>
            <div className="label">
              <span>Supply</span>
            </div>
            <div className={`value `}>
              {totalSupplyGm && processedData?.gmSupplyUsd ? (
                <>
                  {formatAmount(totalSupplyGm, gmDecimalBARTIO, 0, true)} GSLP
                  <div className="value-dollar">
                    ${formatNumber(formatKeyAmount(processedData, "gmSupplyUsd", USD_DECIMALS, 2), 2)}
                  </div>
                </>
              ) : (
                <div
                  className="skeleton-box"
                  style={{
                    width: 48,
                  }}
                />
              )}
            </div>
          </TokenCardInfo>
          <TokenCardInfo>
            <div className="label">
              <span>Total Staked</span>
            </div>
            <div className="value">
              {processedData?.gmSupply && processedData?.gmSupplyUsd ? (
                <>
                  {formatKeyAmount(processedData, "gmSupply", 18, 0, true)} GSLP
                  <div className="value-dollar">
                    ${formatNumber(formatKeyAmount(processedData, "gmSupplyUsd", USD_DECIMALS, 2), 2)}
                  </div>
                </>
              ) : (
                <div
                  className="skeleton-box"
                  style={{
                    width: 48,
                  }}
                />
              )}
            </div>
          </TokenCardInfo>
          {/* <TokenCardInfo>
            <div className="label">
              <span>Market Cap</span>
            </div>
            <div className="value">
              {mspMarketCap ? (
                <TooltipComponent
                  position="right-bottom"
                  className="nowrap"
                  handle={`$${formatAmount(mspMarketCap, GLP_DECIMALS, 0, true)}`}
                  renderContent={() => (
                    <>
                      <StatsTooltipRow
                        label={t`Marketcap on BARTIO`}
                        value={formatAmount(mspMarketCapBARTIO, GLP_DECIMALS, 0, true)}
                        showDollar={true}
                      />
                      <StatsTooltipRow
                        label={t`Marketcap on Optimism`}
                        value={formatAmount(mspMarketCapOp, GLP_DECIMALS, 0, true)}
                        showDollar={true}
                      />
                      <div className="divider"></div>
                      <StatsTooltipRow
                        label={t`Total`}
                        value={formatAmount(mspMarketCap, GLP_DECIMALS, 0, true)}
                        showDollar={true}
                      />
                    </>
                  )}
                />
              ) : (
                <div
                  className="skeleton-box"
                  style={{
                    width: 48,
                  }}
                />
              )}
            </div>
          </TokenCardInfo> */}
          <BuyLink to="/earn/gslp" className="btn-token default-btn" network={BERACHAIN_BARTIO}>
            Deposit
          </BuyLink>
        </TokenCard>
      </TokenCardContainer>
    </Wrapper>
  );
};

const TokenAPR = styled.div`
  flex: 1;
  .apr-wrap {
    display: flex;
    flex-direction: column;

    color: var(--Text-Text_Primary, #fff);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    & > span {
      color: var(--main-primary, #7ee55c);
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
      display: flex;
    }

    @media screen and (max-width: 767px) {
      flex-direction: row;
      gap: 4px;
    }
  }

  > .row {
    width: 100%;

    gap: 8px;

    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff99;
      display: flex;
      flex-direction: column;

      gap: 8px;

      > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #176ce5;
      }

      @media screen and (max-width: 767px) {
        flex-direction: row;
      }
    }

    .btn-buy {
      display: flex;
      height: 40px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--stroke-10, rgba(255, 255, 255, 0.1));
      background-color: transparent;
      /* color: #080715; */
      text-decoration: none;

      color: var(--white-100, #fff);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */

      &:hover {
        color: #176ce5 !important;

        box-shadow: none !important;
        border: 1px solid #176ce5 !important;
      }
    }

    @media (max-width: 767px) {
      flex-direction: row;

      .btn-buy {
        width: fit-content;
      }
    }
  }

  @media (max-width: 1023px) {
    display: flex;
    justify-content: space-between;
  }
`;

const TokenCardInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .value {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    align-items: center;
    display: flex;
    gap: 4px;
    .value-dollar {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: var(--Text-Text_Secondary, #919499);
    }
  }
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: 767px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const TokenInfo = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .token-symbol {
    color: var(--Text-Text_Primary, #fff);
    font-size: 16px;
    font-weight: 700;
    line-height: 140%; /* 22.4px */

    display: flex;
    align-items: center;
    gap: var(--Border_radius, 4px);

    & > a {
      &:hover {
        svg {
          g {
            opacity: 1;
          }
          path {
            fill: #176ce5;
          }
        }
      }
    }
  }
  .token-desc {
    color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
  .token-price {
    color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */

    .Tooltip-handle {
      color: var(--Primary, #176ce5);
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
    }
  }
  /* .btn-readmore {
    height: 40px;
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 8px 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: #fff;
  } */
`;

const TokenImg = styled.div`
  img {
    width: 40px;
    height: 40px;
  }
`;

const CardTitle = styled.div`
  width: 340px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1024px) {
    width: 250px;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
  /* position: absolute;
  top: 0;
  left: 24px;
  right: 24px; */

  /* @media (max-width: 767px) {
    left: 16px;
    right: 16px;
  } */
`;

const TokenCard = styled.div`
  height: 94px;
  padding: 24px;
  background-color: #18191a;
  border-radius: 16px;
  gap: 24px;
  position: relative;
  display: flex;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    height: fit-content;
    flex-direction: column;
  }
  @media (max-width: 700px) {
    gap: 12px;
    padding: 16px;
  }
`;

const TokenCardContainer = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  .label {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;
    @media (max-width: 1023px) {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 767px) {
    gap: 16px;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Base-Unit-XS-8, 8px);

  &:not(:first-child) {
    margin-top: 48px;

    @media screen and (max-width: 767px) {
      margin-top: 48px;
    }
  }

  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 4px;
  }

  div {
    color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
`;

const Wrapper = styled.div`
  .btn-token {
    border-radius: var(--Border-Radius-Medium, 12px);
    background: var(--Button-Background, #176ce5);
    display: flex;
    color: #fff;
    width: 100px;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    align-items: center;

    text-decoration: none !important;

    @media screen and (max-width: 700px) {
      width: 100%;
      margin-top: 8px;
    }
  }
  margin: 0 0 48px;

  .lp {
    margin-top: 16px;
    display: flex;
    padding: 24px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid transparent;
    background: linear-gradient(180deg, #191b2e 0%, #191b2e 100%);
    position: relative;
    overflow: hidden;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
    }

    & > div.lp-col {
      &.tablet {
        display: none;
      }

      .default-btn {
        color: #000;
        font-weight: 700;
      }

      &:has(.lp-symbol) {
        display: flex;
        align-items: center;
        gap: 24px;

        .lp-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: var(--Border_radius, 4px);
          width: 178px;
        }

        .lp-symbol {
          display: flex;
          align-items: center;
          gap: var(--Border_radius, 4px);
          color: var(--Text-Text_Primary, #fff);
          font-size: 16px;
          font-weight: 700;
          line-height: 120%; /* 22.4px */
        }

        .lp-price {
          color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */

          span {
            color: var(--Primary, #176ce5);
            font-size: 14px;
            font-weight: 700;
            line-height: 140%; /* 19.6px */
          }
        }
      }

      &:not(:has(.default-btn, img)) {
        flex: 1;

        &:has(.lp-sumbol) {
        }

        .lp-col-key {
          color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }

        .lp-col-value {
          color: var(--Text-Text_Primary, #fff);
          font-size: 14px;
          font-weight: 700;
          line-height: 140%; /* 19.6px */

          &.green {
            color: var(--Primary, #176ce5);
          }

          &:has(span) {
            display: flex;
            align-items: center;
            gap: var(--Border_radius, 4px);
            align-self: stretch;
          }

          span {
            color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
            font-size: 14px;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
          }
        }
      }
    }

    @media screen and (max-width: 1023px) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      & > div.lp-col {
        &.tablet {
          display: block;
          grid-column: 3/5;
          margin-left: auto;
        }

        &:has(.lp-symbol) {
          grid-column: 1/3;
        }

        &:not(.tablet):has(.default-btn) {
          display: none;
        }
      }
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 12px;
      padding: 16px;

      & > div.lp-col {
        grid-column: unset !important;

        &:has(.lp-symbol) {
          margin-bottom: 12px;
        }

        &.tablet {
          display: none;
        }

        &:not(.tablet):has(.default-btn) {
          display: block;

          .default-btn {
            width: 100%;
          }
        }

        &:has(.lp-col-key, .lp-col-value) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  @media (max-width: 767px) {
    margin: 0;
    margin-bottom: 48px;

    div.Tooltip-popup:has(.customTooltip) {
      transform: translateX(-100px);
    }
  }

  &.theme--light {
    ${TokenCard} {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: var(--Nature-1, #fff);

      ${TokenImg} {
        width: 40px;
        height: 40px;
      }

      ${TokenInfo} {
        .token-symbol {
          color: var(--Text-Text_Primary, #0d1a16);

          & > a {
            &:hover {
              svg {
                g {
                  opacity: 1;
                }
                path {
                  fill: #02b27f;
                }
              }
            }
          }
        }

        .token-desc,
        .token-price {
          color: #919499 !important;
        }
      }

      ${TokenAPR} {
        border-color: var(--Border, rgba(13, 26, 22, 0.07)) !important;

        .btn-buy.border-btn {
          color: var(--Text-Text_Primary, #0d1a16) !important;
          border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;

          &:hover {
            color: #02b27f !important;
            border-color: #02b27f !important;
          }
        }
      }

      ${TokenCardInfo} {
        .label span {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }
      }
    }

    .lp {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: var(--Nature-1, #fff);

      .lp-info {
        .lp-symbol {
          color: var(--Text-Text_Primary, #0d1a16) !important;
        }

        .lp-price {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;

          span {
            color: var(--Primary, #02b27f) !important;
          }
        }
      }

      .lp-col-key {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      }

      .lp-col-value {
        color: var(--Text-Text_Primary, #0d1a16) !important;

        &.green {
          color: var(--Primary, #02b27f) !important;
        }

        span {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }
      }
    }
  }
`;

const StyleButton = styled(Link)`
  border-radius: var(--Border-Radius-Medium, 12px);
  background: var(--Button-Background, #176ce5);
  display: flex;
  width: 100px;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;
export default Tokens;
