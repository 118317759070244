import { useWeb3React } from "@web3-react/core";
import { ARBITRUM, BASE, FANTOM, OP, SUPPORTED_CHAIN_IDS } from "config/chains";
import { getContract } from "config/contracts";
import { uniqBy } from "lodash";

import Vault from "abis/Vault.json";
import ReaderV2 from "abis/ReaderV2.json";
import Multicall from "abis/Multicall.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import GlpManager from "abis/GlpManager.json";
import { formatMulticallReponse } from "lib/contracts";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getProvider } from "lib/rpc";
import { ethers } from "ethers";
import {
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
} from "lib/legacy";
import multicall from "domain/multicall";
import { useGmyPrice } from "domain/legacy";

const AprContext = createContext({});

export default function AprProvider({ children, active }) {
  const contractAddresses = Object.fromEntries(
    SUPPORTED_CHAIN_IDS.map((chainId) => [
      chainId,
      {
        rewardReaderAddress: getContract(chainId, "RewardReader"),
        readerAddress: getContract(chainId, "Reader"),
        vaultAddress: getContract(chainId, "Vault"),
        nativeTokenAddress: getContract(chainId, "NATIVE_TOKEN"),
        gmyAddress: getContract(chainId, "GMY"),
        esGmyAddress: getContract(chainId, "ES_GMY"),
        bnGmyAddress: getContract(chainId, "BN_GMY"),
        glpAddress: getContract(chainId, "GLP"),
        gmAddress: getContract(chainId, "GM"),
        usdgAddress: getContract(chainId, "USDG"),
        stakedGmyTrackerAddress: getContract(chainId, "StakedGmyTracker"),
        bonusGmyTrackerAddress: getContract(chainId, "BonusGmyTracker"),
        feeGmyTrackerAddress: getContract(chainId, "FeeGmyTracker"),
        stakedGlpTrackerAddress: getContract(chainId, "StakedGlpTracker"),
        feeGlpTrackerAddress: getContract(chainId, "FeeGlpTracker"),
        stakedGmTrackerAddress: getContract(chainId, "StakedGmTracker"),
        feeGmTrackerAddress: getContract(chainId, "FeeGmTracker"),
        glpManagerAddress: getContract(chainId, "GlpManager"),
        gmyVesterAddress: getContract(chainId, "GmyVester"),
        glpVesterAddress: getContract(chainId, "GlpVester"),
        gmVesterAddress: getContract(chainId, "GmVester"),
      },
    ])
  );

  const ABI = uniqBy(
    [...ReaderV2.abi, ...RewardReader.abi, ...Token.abi, ...GlpManager.abi, ...Vault.abi],
    (e) => e.name
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetch = async (chainId) => {
      const multicallAddress = getContract(chainId, "Multicall");
      try {
        const addresses = contractAddresses[chainId];

        const vesterAddresses = [addresses.gmyVesterAddress, addresses.glpVesterAddress, addresses.gmVesterAddress];

        const walletTokens = [
          addresses.gmyAddress,
          addresses.esGmyAddress,
          addresses.glpAddress,
          addresses.stakedGmyTrackerAddress,
          addresses.gmAddress,
        ];
        const depositTokens = [
          addresses.gmyAddress,
          addresses.esGmyAddress,
          addresses.stakedGmyTrackerAddress,
          addresses.bonusGmyTrackerAddress,
          addresses.bnGmyAddress,
          addresses.glpAddress,
          addresses.gmAddress,
        ];
        const rewardTrackersForDepositBalances = [
          addresses.stakedGmyTrackerAddress,
          addresses.stakedGmyTrackerAddress,
          addresses.bonusGmyTrackerAddress,
          addresses.feeGmyTrackerAddress,
          addresses.feeGmyTrackerAddress,
          addresses.feeGlpTrackerAddress,
          addresses.feeGmTrackerAddress,
        ];
        const rewardTrackersForStakingInfo = [
          addresses.stakedGmyTrackerAddress,
          addresses.bonusGmyTrackerAddress,
          addresses.feeGmyTrackerAddress,
          addresses.stakedGlpTrackerAddress,
          addresses.feeGlpTrackerAddress,
          addresses.stakedGmTrackerAddress,
          addresses.feeGmTrackerAddress,
        ];
        const tokensForSupplyQuery = [addresses.gmyAddress, addresses.glpAddress, addresses.usdgAddress, addresses.gmAddress];

        const provider = getProvider(undefined, chainId);
        if (!provider) return;
        const multicallContract = new ethers.Contract(multicallAddress, Multicall.abi, provider);
        const calls = [
          {
            address: addresses.readerAddress,
            name: "getTokenBalancesWithSupplies",
            params: [PLACEHOLDER_ACCOUNT, walletTokens],
            key: "walletBalances",
          },
          {
            address: addresses.rewardReaderAddress,
            name: "getDepositBalances",
            params: [PLACEHOLDER_ACCOUNT, depositTokens, rewardTrackersForDepositBalances],
            key: "depositBalances",
          },
          {
            address: addresses.rewardReaderAddress,
            name: "getStakingInfo",
            params: [PLACEHOLDER_ACCOUNT, rewardTrackersForStakingInfo],
            key: "stakingInfo",
          },
          {
            address: addresses.gmyAddress,
            name: "balanceOf",
            params: [addresses.stakedGmyTrackerAddress],
            key: "stakedGmySupply",
          },
          {
            address: addresses.glpManagerAddress,
            name: "getAums",
            params: [],
            key: "aums",
          },
          {
            address: addresses.vaultAddress,
            name: "getMinPrice",
            params: [addresses.nativeTokenAddress],
            key: "nativeTokenPrice",
          },
          {
            address: addresses.readerAddress,
            name: "getVestingInfo",
            params: [PLACEHOLDER_ACCOUNT, vesterAddresses],
            key: "vestingInfo",
          },
          {
            address: addresses.readerAddress,
            name: "getTokenBalancesWithSupplies",
            params: [PLACEHOLDER_ACCOUNT, tokensForSupplyQuery],
            key: "totalSupplies",
          },
        ];

        const keyOfCalls = calls.map((call) => call.key || call.name);
        const response = await multicall(
          multicallContract,
          ABI,
          calls.map(({ address, name, params }) => ({
            address,
            name,
            params,
          }))
        );
        const result = {};
        for (const index in keyOfCalls) {
          result[keyOfCalls[index]] = response[index];
        }

        return formatMulticallReponse(result);

        // setData(formatMulticallReponse(result));
      } catch (error) {
        console.error("[ERROR]: useGmContracts", chainId, error);
      }
    };

    function handler() {
      Promise.all(SUPPORTED_CHAIN_IDS.map(fetch))
        .then((result) => {
          const mappedData = SUPPORTED_CHAIN_IDS.map((chainId, index) => [chainId, result[index]]);
          setData(mappedData);
        })
        .catch(console.error);
    }

    handler();
    const timerId = setInterval(handler, 30000);

    return () => clearInterval(timerId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(ABI), JSON.stringify(contractAddresses)]);

  const { gmyPriceFromArbitrum, gmyPriceFromOP, gmyPriceFromFantom, gmyPriceFromBase } = useGmyPrice(
    undefined,
    {},
    active
  );

  const processedDatas = useMemo(() => {
    const gmyPriceMapped = {
      [ARBITRUM]: gmyPriceFromArbitrum,
      [OP]: gmyPriceFromOP,
      [FANTOM]: gmyPriceFromFantom,
      [BASE]: gmyPriceFromBase,
    };

    const datas = data.map(([chainId, _data]) => {
      const entriesData = Object.fromEntries(data)[chainId];
      let gmySupply;
      if (entriesData?.totalSupplies && entriesData?.totalSupplies[1]) {
        gmySupply = entriesData?.totalSupplies[1];
      }

      let aum;
      if (entriesData?.aums && entriesData?.aums.length > 0) {
        aum = entriesData?.aums[0].add(entriesData?.aums[1]).div(2);
      }

      const { balanceData, supplyData } = getBalanceAndSupplyData(entriesData?.walletBalances);
      const depositBalanceData = getDepositBalanceData(entriesData?.depositBalances);
      const stakingData = getStakingData(entriesData?.stakingInfo);
      const vestingData = getVestingData(entriesData?.vestingInfo);

      return [
        chainId,
        getProcessedData(
          balanceData,
          supplyData,
          depositBalanceData,
          stakingData,
          vestingData,
          aum,
          entriesData?.nativeTokenPrice,
          entriesData?.stakedGmySupply,
          gmyPriceMapped[chainId],
          gmySupply
        ),
      ];
    });
    return Object.fromEntries(datas);
  }, [data, gmyPriceFromArbitrum, gmyPriceFromBase, gmyPriceFromFantom, gmyPriceFromOP]);

  return <AprContext.Provider value={processedDatas}>{children}</AprContext.Provider>;
}

export const useAprContext = () => {
  const value = useContext(AprContext);

  if (!value) throw new Error("Apr value must be inside AprProvider!");

  return value;
};
//formatKeyAmount(processedData, label, 2, 2, true)
