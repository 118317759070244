function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <path
        fill="#72CC37"
        fillRule="evenodd"
        d="M13.718 5h4.995a.5.5 0 01.5.5v5.212a.5.5 0 01-.861.346l-1.946-2.03-4.984 4.458a.81.81 0 01-1.176-.094L8.281 11l-4.368 3.798a.81.81 0 01-1.174-.11.896.896 0 01.107-1.226l5-4.347a.81.81 0 011.166.102L10.97 11.6l4.254-3.805-1.867-1.949a.5.5 0 01.36-.846z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
