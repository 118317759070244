import Overview from "./Overview";
import TopTradersTab from "./TopTradersTab";
import TopPositionTab from "./TopPositionTab";
import ContestTab from "./ContestTab";
import SearchTab from "./SearchTab";
import PositionDetail from "./PositionDetail";
import TraderDetail from "./TraderDetail";

export const TYPE_MAP_TYPE = {
  EDIT_COLLATERAL: "Edited Collateral",
  DEPOSIT: "Deposited",
  CLOSE: "Decreased position",
  DECREASE: "Decreased position",
  INCREASE: "Increased position",
  OPEN: "Increased position",
  WITHDRAW: "Withdrew",
  LIQUIDATE: "Liquidated",
  OPEN_POSITION: "Increased position",
  ADD_TRAILING_STOP: "Decreased/Closed",
  CLOSE_POSITION: "Decreased position",
  LIQUIDATE_POSITION: "Liquidated",
  DECREASE_POSITION: "Decreased position",
  ADD_POSITION: "Increased position",
};
export { Overview, TopTradersTab, TopPositionTab, ContestTab, SearchTab, PositionDetail, TraderDetail };
