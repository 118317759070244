import { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { ethers } from "ethers";
import { getContract } from "config/contracts";

import {
  adjustForDecimals,
  GLP_DECIMALS,
  USD_DECIMALS,
  GLP_COOLDOWN_DURATION,
  USDG_DECIMALS,
  PLACEHOLDER_ACCOUNT,
} from "lib/legacy";

import ReaderV2 from "abis/ReaderV2.json";
import VaultMsp from "abis/VaultMsp.json";
import RewardTracker from "abis/RewardTracker.json";
import Vester from "abis/Vester.json";
import RewardRouter from "abis/RewardRouter.json";
import Token from "abis/Token.json";

import glp24Icon from "img/gslp.svg";
import usdcIcon from "img/ic_usdc_24.svg";
import arrowIcon from "img/ic_arrow.svg";

import { getChainName, IS_NETWORK_DISABLED, BASE } from "config/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens, useInfoTokens } from "domain/tokens";
import { useLocalStorageByChainId } from "lib/localStorage";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { DEFAULT_FROM_V2_TOKEN, getToken, getTokens, getWhitelistedTokens } from "config/tokens";
import { useChainId } from "lib/chains";
import Tooltip from "components/Tooltip/Tooltip";
import Tab from "components/Tab/Tab";
import BuyInputSection from "components/BuyInputSection/BuyInputSection";
import useSettingsManagerContract from "hooks/contracts/useSettingsManagerContract";
import useInfoGm from "hooks/useInfoGm";
import useMintGm from "hooks/useMintGm";
import useVaultGmyContract from "hooks/contracts/useVaultGmyContract";
import useRedeemGm from "hooks/useRedeemGm";
import { helperToast } from "lib/helperToast";
import Countdown from "react-countdown";

const { AddressZero } = ethers.constants;

export default function BuySellGslp({
  isBuying,
  setPendingTxns,
  connectWallet,
  setIsBuying,
  savedShouldDisableValidationForTesting,
}) {
  const history = useHistory();
  const swapLabel = isBuying ? "Buy GSLP" : "Sell GSLP";
  const tabLabel = isBuying ? `Buy GSLP` : `Sell GSLP`;
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();
  const tokens = getTokens(chainId);
  const usdcAddress = getContract(chainId, "USDC");

  const { stakingFee, unstakingFee, maxTotalGm } = useSettingsManagerContract(chainId, active, account);
  const { price: gmPrice, totalSupply: totalSupplyGm } = useInfoGm(chainId);

  const gmAvailableToMint = useMemo(() => {
    if (maxTotalGm && totalSupplyGm) {
      return maxTotalGm.sub(totalSupplyGm);
    }
  }, [maxTotalGm, totalSupplyGm]);
  const whitelistedTokens = getWhitelistedTokens(chainId).filter((t) =>
    DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(t.address)
  );
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const [swapValue, setSwapValue] = useState("");
  const [gmValue, setGmValue] = useState("");
  const [swapTokenAddress, setSwapTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${swapLabel}-swap-token-address2`,
    usdcAddress
  );
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const readerAddress = getContract(chainId, "Reader");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const feeGmTrackerAddress = getContract(chainId, "FeeGmTracker");
  const gmManagerAddress = getContract(chainId, "VaultMsp");
  const gmAddress = getContract(chainId, "GM");
  const rewardRouterAddress = getContract(chainId, "RewardRouter");

  const tokenAddresses = tokens.map((token) => token.address);
  const { data: tokenBalances } = useSWR(
    [
      `BuySellGslp:getTokenBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
      refreshInterval: 30000,
    }
  );

  const tokenAllowanceAddress = swapTokenAddress === AddressZero ? nativeTokenAddress : swapTokenAddress;
  const { data: tokenAllowance } = useSWR(
    [active, chainId, tokenAllowanceAddress, "allowance", account || PLACEHOLDER_ACCOUNT, rewardRouterAddress],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 30000,
    }
  );

  const { data: lastPurchaseTime } = useSWR(
    [
      `BuySellGslp:lastPurchaseTime:${active}`,
      chainId,
      gmManagerAddress,
      "lastStakedAt",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, VaultMsp),
      refreshInterval: 30000,
    }
  );

  const { data: gmBalance } = useSWR(
    [`BuySellGslp:gmBalance:${active}`, chainId, feeGmTrackerAddress, "stakedAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardTracker),
      refreshInterval: 30000,
    }
  );

  const gmVesterAddress = getContract(chainId, "GmVester");
  const { data: reservedAmount } = useSWR(
    [`BuySellGslp:reservedAmount:${active}`, chainId, gmVesterAddress, "pairAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, Vester),
      refreshInterval: 30000,
    }
  );

  const glpVesterV1Address = getContract(chainId, "GlpVesterV1");
  const { data: reservedAmountV1 } = useSWR(
    chainId !== BASE && [
      `BuySellGslp:reservedAmountV1:${active}`,
      chainId,
      glpVesterV1Address,
      "pairAmounts",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, Vester),
      refreshInterval: 30000,
    }
  );

  const redemptionTime = lastPurchaseTime ? lastPurchaseTime.add(GLP_COOLDOWN_DURATION) : undefined;
  const inCooldownWindow = redemptionTime && parseInt(Date.now() / 1000) < redemptionTime;
  // const inCooldownWindow = false;
  // const gmSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0);
  // const usdgSupply = balancesAndSupplies ? balancesAndSupplies[3] : bigNumberify(0);
  // let aum;
  // if (aums && aums.length > 0) {
  //   aum = isBuying ? aums[0] : aums[1];
  // }
  // const gmPrice =
  //   aum && aum.gt(0) && gmSupply.gt(0)
  //     ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(gmSupply)
  //     : expandDecimals(1, USD_DECIMALS);

  var maxSellAmount = gmBalance;
  if (gmBalance && reservedAmount) {
    maxSellAmount = maxSellAmount.sub(reservedAmount);
  }
  if (gmBalance && reservedAmountV1) {
    maxSellAmount = maxSellAmount.sub(reservedAmountV1);
  }
  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  const swapToken = getToken(chainId, swapTokenAddress);
  const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);

  const swapTokenBalance = swapTokenInfo && swapTokenInfo.balance ? swapTokenInfo.balance : bigNumberify(0);

  const swapAmount = parseValue(swapValue, swapToken && swapToken.decimals);
  const gmAmount = parseValue(gmValue, 18);

  const needApproval =
    isBuying && swapTokenAddress !== AddressZero && tokenAllowance && swapAmount && swapAmount.gt(tokenAllowance);

  const swapUsdMin = getUsd(swapAmount, swapTokenAddress, false, infoTokens);
  const gmUsdMax = gmAmount && gmPrice ? gmAmount.mul(gmPrice).div(expandDecimals(1, GLP_DECIMALS)) : undefined;

  let isSwapTokenCapReached;
  if (swapTokenInfo.managedUsd && swapTokenInfo.maxUsdgAmount) {
    isSwapTokenCapReached = swapTokenInfo.managedUsd.gt(
      adjustForDecimals(swapTokenInfo.maxUsdgAmount, USDG_DECIMALS, USD_DECIMALS)
    );
  }
  const { data: totalSupplyBlp } = useSWR([`Blp:getTotalSupply`, chainId, gmAddress, "totalSupply"], {
    fetcher: contractFetcher(undefined, Token),
    refreshInterval: 10000,
  });
  const { totalUSD } = useVaultGmyContract(chainId);

  const { outputMint } = useMintGm({
    chainId,
    fromValue: swapAmount,
    gmPrice: gmPrice,
    stakingFee,
    isMint: isBuying,
    totalUSD,
    totalSupplyBlp,
    fromAddress: swapToken?.address,
  });

  const { outputRedeem } = useRedeemGm({
    chainId,
    fromValue: gmAmount,
    gmPrice: gmPrice,
    unstakingFee,
    isRedeem: !isBuying,
    totalUSD,
    totalSupplyBlp,
    destToken: swapToken?.symbol,
    destAddress: swapToken.address,
    decimals: swapToken.decimals,
  });

  const onSwapValueChange = (e) => {
    // setAnchorOnSwapAmount(true);
    setSwapValue(e.target.value);
  };

  const onGmValueChange = (e) => {
    // setAnchorOnSwapAmount(false);
    setGmValue(e.target.value);
  };

  // const onSelectSwapToken = (token) => {
  //   setSwapTokenAddress(token.address);
  //   setIsWaitingForApproval(false);
  // };

  useEffect(() => {
    //THIS CALCULATE OUTPUT AMOUNT
    if (isBuying && outputMint !== null) {
      setGmValue(formatAmount(outputMint, 30, 18));
    }
  }, [outputMint, isBuying]);

  useEffect(() => {
    if (!isBuying && outputRedeem !== null) {
      setSwapValue(formatAmount(outputRedeem, 6, swapToken.decimals || 6));
    }
  }, [isBuying, outputRedeem, swapAmount, swapToken]);
  const switchSwapOption = (hash = "") => {
    history.push(`${history.location.pathname}#${hash}`);
    setIsBuying(hash === "redeem" ? false : true);
  };

  const fillMaxAmount = () => {
    if (isBuying) {
      // setAnchorOnSwapAmount(true);
      setSwapValue(formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals));
      return;
    }

    // setAnchorOnSwapAmount(false);
    setGmValue(formatAmountFree(maxSellAmount, GLP_DECIMALS, GLP_DECIMALS));
  };

  const getError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      if (isBuying) return [`GSLP buy disabled, pending ${getChainName(chainId)} upgrade`];
      return [`GSLP sell disabled, pending ${getChainName(chainId)} upgrade`];
    }

    if (!isBuying && inCooldownWindow) {
      return [`Redemption time not yet reached`];
    }

    if ((!gmAmount || gmAmount.eq(0)) && (!swapAmount || swapAmount.eq(0))) {
      return [`Enter an amount`];
    }

    if (isBuying) {
      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
      if (
        !savedShouldDisableValidationForTesting &&
        swapTokenInfo &&
        swapTokenInfo.balance &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.balance)
      ) {
        return [`Insufficient ${swapTokenInfo.symbol} balance`];
      }

      if (swapTokenInfo.maxUsdgAmount && swapTokenInfo.usdgAmount && swapUsdMin) {
        const usdgFromAmount = adjustForDecimals(swapUsdMin, USD_DECIMALS, USDG_DECIMALS);
        const nextUsdgAmount = swapTokenInfo.usdgAmount.add(usdgFromAmount);
        if (swapTokenInfo.maxUsdgAmount.gt(0) && nextUsdgAmount.gt(swapTokenInfo.maxUsdgAmount)) {
          return [`${swapTokenInfo.symbol} pool exceeded, try different token`, true];
        }
      }
    }

    if (!isBuying) {
      if (maxSellAmount && gmAmount && gmAmount.gt(maxSellAmount)) {
        return [`Insufficient GSLP balance`];
      }

      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
      if (
        swapTokenInfo &&
        swapTokenInfo.availableAmount &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.availableAmount)
      ) {
        return [`Insufficient liquidity`];
      }
    }

    return [false];
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (!active) {
      return true;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (isBuying && isSwapTokenCapReached) {
      return false;
    }

    if (inCooldownWindow) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (!active) {
      return `Connect Wallet`;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }
    if (isBuying && isSwapTokenCapReached) {
      return `Max Capacity for ${swapToken.symbol} Reached`;
    }

    if (needApproval && isWaitingForApproval) {
      return `Waiting for Approval`;
    }
    if (isApproving) {
      return `Approving ${swapToken.symbol}...`;
    }
    if (needApproval) {
      return `Approve ${swapToken.symbol}`;
    }

    if (isSubmitting) {
      return isBuying ? `Buying...` : `Selling...`;
    }

    if (inCooldownWindow) {
      return "Cooldown to sell GSLP";
    }

    return isBuying ? `Buy GSLP` : `Sell GSLP`;
  };

  const approveFromToken = () => {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: swapToken.address,
      spender: rewardRouterAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      infoTokens,
      getTokenInfo,
    });
  };

  const buyGm = () => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    const method = "mintAndStakeGm";
    const params = [swapTokenAddress, swapAmount];
    const value = 0;

    callContract(chainId, contract, method, params, {
      value,
      sentMsg: `Buy submitted.`,
      failMsg: `Buy failed.`,
      successMsg: `${formatAmount(gmAmount, 18, 4, true)} GSLP bought with ${formatAmount(
        swapAmount,
        swapTokenInfo.decimals,
        4,
        true
      )} ${swapTokenInfo.symbol}!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const sellGm = () => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    const method = "unstakeAndRedeemGm";
    const params = [swapTokenAddress, gmAmount, outputRedeem];

    callContract(chainId, contract, method, params, {
      sentMsg: `Sell submitted!`,
      failMsg: `Sell failed.`,
      successMsg: `${formatAmount(gmAmount, 18, 4, true)} GSLP sold for ${formatAmount(
        swapAmount,
        swapTokenInfo.decimals,
        4,
        true
      )} ${swapTokenInfo.symbol}!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onClickPrimary = () => {
    if (!active) {
      connectWallet();
      return;
    }

    if (needApproval) {
      approveFromToken();
      return;
    }

    const [err, modal] = getError();

    if (modal) {
      helperToast.error(err);
      return;
    }

    if (isBuying) {
      buyGm();
    } else {
      sellGm();
    }
  };

  let payLabel = `Pay`;
  let receiveLabel = `Receive`;
  let payBalance = "$0.00";
  let receiveBalance = "$0.00";
  if (isBuying) {
    if (swapUsdMin) {
      payBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    }
    if (gmUsdMax) {
      receiveBalance = `$${formatAmount(gmUsdMax, USD_DECIMALS, 2, true)}`;
    }
  } else {
    if (gmUsdMax) {
      payBalance = `$${formatAmount(gmUsdMax, USD_DECIMALS, 2, true)}`;
    }
    if (swapUsdMin) {
      receiveBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    }
  }

  // let feePercentageText = formatAmount(isBuying ? stakingFee : unstakingFee, 2, 2, true, "-");

  const feePercentageText = useMemo(() => {
    if (!swapValue || Number(swapValue) <= 0) return "--";
    let fee;

    if (isBuying) {
      if (stakingFee && swapUsdMin) {
        fee = Number(formatAmount(swapUsdMin, USD_DECIMALS)) * (stakingFee.toNumber() / 1000);
        return (fee / 100).toFixed(2);
      }
    } else {
      if (unstakingFee && gmUsdMax) {
        fee = Number(formatAmount(gmUsdMax, USD_DECIMALS)) * (unstakingFee.toNumber() / 1000);
        return (fee / 100).toFixed(2);
      }
    }

    return "--";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapValue, gmValue]);

  const onSwapOptionChange = (opt) => {
    if (opt === `Sell GSLP`) {
      switchSwapOption("redeem");
    } else {
      switchSwapOption();
    }
  };

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  return (
    <>
      <Tab options={[`Buy GSLP`, `Sell GSLP`]} option={tabLabel} onChange={onSwapOptionChange} />
      {isBuying && (
        <BuyInputSection
          topLeftLabel={payLabel}
          topRightLabel={`Balance:`}
          tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
          inputValue={swapValue}
          onInputValueChange={onSwapValueChange}
          showMaxButton={swapValue !== formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals)}
          onClickTopRightLabel={fillMaxAmount}
          onClickMax={fillMaxAmount}
          selectedToken={swapToken}
          balance={payBalance}
          onlyNumber
        >
          <div className="selected-token">
            <img src={usdcIcon} alt="usdcIcon" />
            USDC
          </div>
        </BuyInputSection>
      )}

      {!isBuying && (
        <BuyInputSection
          topLeftLabel={payLabel}
          topRightLabel={`Available:`}
          tokenBalance={`${formatAmount(maxSellAmount, GLP_DECIMALS, 4, true)}`}
          inputValue={gmValue}
          onInputValueChange={onGmValueChange}
          showMaxButton={gmValue !== formatAmountFree(maxSellAmount, GLP_DECIMALS, GLP_DECIMALS)}
          onClickTopRightLabel={fillMaxAmount}
          onClickMax={fillMaxAmount}
          balance={payBalance}
          defaultTokenName={"GSLP"}
          onlyNumber
        >
          <div className="selected-token">
            <img src={glp24Icon} alt="glp24Icon" />
            GSLP
          </div>
        </BuyInputSection>
      )}

      <div className="AppOrder-ball-container">
        <div className="AppOrder-ball">
          <img
            src={arrowIcon}
            alt="arrowIcon"
            onClick={() => {
              setIsBuying(!isBuying);
              switchSwapOption(isBuying ? "redeem" : "");
            }}
          />
        </div>
      </div>

      {isBuying && (
        <BuyInputSection
          topLeftLabel={receiveLabel}
          topRightLabel={`Balance:`}
          tokenBalance={`${formatAmount(gmBalance, GLP_DECIMALS, 4, true)}`}
          inputValue={gmValue}
          onInputValueChange={onGmValueChange}
          balance={receiveBalance}
          defaultTokenName={"GSLP"}
          onlyNumber
        >
          <div className="selected-token">
            <img src={glp24Icon} alt="glp24Icon" />
            GSLP
          </div>
        </BuyInputSection>
      )}

      {!isBuying && (
        <BuyInputSection
          topLeftLabel={receiveLabel}
          topRightLabel={`Balance:`}
          tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
          inputValue={swapValue}
          onInputValueChange={onSwapValueChange}
          balance={receiveBalance}
          selectedToken={swapToken}
          onlyNumber
        >
          {/* <TokenSelector
            label={`Receive`}
            chainId={chainId}
            tokenAddress={swapTokenAddress}
            onSelectToken={onSelectSwapToken}
            tokens={whitelistedTokens}
            infoTokens={infoTokens}
            className="BuySellGslp-from-token"
            showSymbolImage={true}
            showTokenImgInDropdown={true}
          /> */}
          <div className="selected-token">
            <img src={usdcIcon} alt="usdcIcon" />
            USDC
          </div>
        </BuyInputSection>
      )}
      <div>
        <div className="Exchange-info-row">
          <div className="Exchange-info-label">GSLP price</div>
          <div className="align-right fee-block">
            {gmPrice > 0 && <div className="text-white">{formatAmount(gmPrice, 30, 4, true)} USD</div>}
          </div>
        </div>
        <div className="Exchange-info-row">
          {/* <div className="Exchange-info-label">{feeBasisPoints > 50 ? `WARNING: High Fees` : `Fees`}</div> */}
          <div className="Exchange-info-label">Fees</div>
          <div className="align-right fee-block">
            {isBuying && (
              <Tooltip
                handle={isBuying && isSwapTokenCapReached ? "NA" : feePercentageText + " USD"}
                position="right-bottom"
                className="fit-content"
                renderContent={() => {
                  // if (!feeBasisPoints) {
                  //   return (
                  //     <div className="text-white">
                  //       <span>Fees will be shown once you have entered an amount in the order form.</span>
                  //     </div>
                  //   );
                  // }
                  return (
                    <div className="text-white">
                      {/* {feeBasisPoints > 50 && <span>To reduce fees, select a different asset to pay with.</span>} */}
                      <span>0.05% mint amount.</span>
                    </div>
                  );
                }}
              />
            )}
            {!isBuying && (
              <Tooltip
                handle={feePercentageText + " USD"}
                position="right-bottom"
                className="fit-content"
                renderContent={() => {
                  // if (!feeBasisPoints) {
                  //   return (
                  //     <div className="text-white">
                  //       <span>Fees will be shown once you have entered an amount in the order form.</span>
                  //     </div>
                  //   );
                  // }
                  return (
                    <div className="text-white">
                      {/* {feeBasisPoints > 50 && <span>To reduce fees, select a different asset to receive.</span>} */}
                      <span>0.15% redeem amount.</span>
                    </div>
                  );
                }}
              />
            )}
          </div>
        </div>
        {isBuying && (
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">Remaining Mintable Amount</div>
            <div className="align-right fee-block">
              {gmAvailableToMint > 0 && (
                <div className="text-white">{formatAmount(gmAvailableToMint, 18, 0, true)} USD</div>
              )}
            </div>
          </div>
        )}
        {!isBuying && (
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">
              <Tooltip
                handle="Cooldown period"
                renderContent={() => {
                  return "There is a 10-minute cooldown period after each stake.";
                }}
              />
            </div>

            <div className="align-right fee-block">
              <Countdown
                key={redemptionTime}
                date={new Date(Number(redemptionTime) * 1000)}
                renderer={countdownRenderer}
                daysInHours
              />
            </div>
          </div>
        )}
      </div>
      <div className="BuySellGslp-cta Exchange-swap-button-container">
        <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
          {getPrimaryText()}
        </button>
      </div>
    </>
  );
}

const countdownRenderer = (countdownProps) => {
  const {
    formatted: { hours, minutes, seconds },
  } = countdownProps;
  return <>{`${hours}:${minutes}:${seconds}`}</>;
};
