import Tooltip from "components/Tooltip/Tooltip";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  formatKeyAmount,
  formatKeyAmountFixed,
  formatNumber,
  parseValue,
} from "lib/numbers";

import gum_ic from "img/gum.svg";
import multipoint_ic from "img/multipoint.svg";
import bera_ic from "img/ic_bera_24.svg";
import esgum_ic from "img/esgum.svg";
import { Link } from "react-router-dom";
import GMYAprTooltip from "components/Stake/GMYAprTooltip";
import { useTotalEsGmyStaked, useTotalGmyStaked } from "domain/legacy";
import { BASIS_POINTS_DIVISOR, USD_DECIMALS } from "lib/legacy";
import ExternalLink from "components/ExternalLink/ExternalLink";
import styled from "styled-components";
import { ARBITRUM, BASE, getConstant } from "config/chains";
import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { getContract } from "config/contracts";
import { helperToast } from "lib/helperToast";
import useSWR from "swr";
import { callContract, contractFetcher } from "lib/contracts";

import Token from "abis/Token.json";
import RewardRouter from "abis/RewardRouter.json";

import { ethers } from "ethers";
import { approveTokens } from "domain/tokens";
import Modal from "components/Modal/Modal";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import Checkbox from "components/Checkbox/Checkbox";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { useMedia } from "react-use";

const { AddressZero } = ethers.constants;

const StakeSection = ({ processedData, gmyPrice, vestingData, setPendingTxns, totalRewardTokens }) => {
  const isMobile = useMedia("(max-width: 767px)");

  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);
  const [isCompoundModalVisible, setIsCompoundModalVisible] = useState(false);

  let { total: totalGmyStaked } = useTotalGmyStaked();
  let { total: totalEsGmyStaked } = useTotalEsGmyStaked();
  const { chainId } = useChainId();

  let stakedGmySupplyUsd;
  if (!totalGmyStaked.isZero() && gmyPrice) {
    stakedGmySupplyUsd = totalGmyStaked.mul(gmyPrice).div(expandDecimals(1, 18));
  }

  let stakedEsGmySupplyUsd;
  if (!totalEsGmyStaked.isZero() && gmyPrice) {
    stakedEsGmySupplyUsd = totalEsGmyStaked.mul(gmyPrice).div(expandDecimals(1, 18));
  }

  let estGMYRewardsPerWeek = 0;
  if (processedData && processedData.gmyAprTotal && processedData.gmyBalanceUsd) {
    estGMYRewardsPerWeek = processedData.gmyAprTotal.mul(processedData.gmyBalanceUsd).div(365).mul(7).div(100);
  }

  let estEsGMYRewardsPerWeek = 0;
  if (processedData && processedData.gmyAprTotal && processedData.esGmyBalanceUsd) {
    estEsGMYRewardsPerWeek = processedData.gmyAprTotal.mul(processedData.esGmyBalanceUsd).div(365).mul(7).div(100);
  }

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const { account, active, library } = useWeb3React();
  const isGmyTransferEnabled = true;

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakingFarmAddress, setStakingFarmAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");
  const stakedGmyTrackerAddress = getContract(chainId, "StakedGmyTracker");

  const esGmyAddress = getContract(chainId, "ES_GMY");
  const gmyAddress = getContract(chainId, "GMY");

  const showStakeGmyModal = () => {
    if (!isGmyTransferEnabled) {
      helperToast.error(`YUM transfers not yet enabled`);
      return;
    }

    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake YUM`);
    setStakeModalMaxAmount(processedData.gmyBalance);
    setStakeValue("");
    setStakingTokenSymbol("YUM");
    setStakingTokenAddress(gmyAddress);
    setStakingFarmAddress(stakedGmyTrackerAddress);
    setStakeMethodName("stakeGmy");
  };

  const showStakeEsGmyModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake esYUM`);
    setStakeModalMaxAmount(processedData.esGmyBalance);
    setStakeValue("");
    setStakingTokenSymbol("esYUM");
    setStakingTokenAddress(esGmyAddress);
    setStakingFarmAddress(AddressZero);
    setStakeMethodName("stakeEsGmy");
  };
  const showUnstakeEsGmyModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake esYUM`);
    let maxAmount = processedData.esGmyInStakedGmy;
    if (
      processedData.esGmyInStakedGmy &&
      vestingData &&
      vestingData.gmyVesterPairAmount.gt(0) &&
      maxUnstakeableGmy &&
      maxUnstakeableGmy.lt(processedData.esGmyInStakedGmy)
    ) {
      maxAmount = maxUnstakeableGmy;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.gmyVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("esYUM");
    setUnstakeMethodName("unstakeEsGmy");
  };

  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeModalReservedAmount, setUnstakeModalReservedAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");
  const rewardRouterAddress = getContract(chainId, "RewardRouter");

  let stakedGmyMultiplierPoints = bigNumberify(0);
  if (
    processedData &&
    processedData.gmyInStakedGmy &&
    processedData.bnGmyInFeeGmy &&
    processedData.esGmyInStakedGmy &&
    (processedData.gmyInStakedGmy.gt(0) || processedData.esGmyInStakedGmy.gt(0))
  ) {
    stakedGmyMultiplierPoints = processedData.gmyInStakedGmy
      .mul(expandDecimals(1, 18))
      .div(processedData.gmyInStakedGmy.add(processedData.esGmyInStakedGmy))
      .mul(processedData.bnGmyInFeeGmy)
      .div(expandDecimals(1, 18));
  }

  let stakedEsGmyMultiplierPoints = bigNumberify(0);
  if (
    processedData &&
    processedData.esGmyInStakedGmy &&
    processedData.bnGmyInFeeGmy &&
    processedData.gmyInStakedGmy &&
    (processedData.gmyInStakedGmy.gt(0) || processedData.esGmyInStakedGmy.gt(0))
  ) {
    stakedEsGmyMultiplierPoints = processedData.esGmyInStakedGmy
      .mul(expandDecimals(1, 18))
      .div(processedData.gmyInStakedGmy.add(processedData.esGmyInStakedGmy))
      .mul(processedData.bnGmyInFeeGmy)
      .div(expandDecimals(1, 18));
  }

  let hasMultiplierPoints = false;
  let multiplierPointsAmount;
  if (processedData && processedData.bonusGmyTrackerRewards && processedData.bnGmyInFeeGmy) {
    multiplierPointsAmount = processedData.bonusGmyTrackerRewards.add(processedData.bnGmyInFeeGmy);
    if (multiplierPointsAmount.gt(0)) {
      hasMultiplierPoints = true;
    }
  }

  let maxUnstakeableGmy = bigNumberify(0);
  if (
    totalRewardTokens &&
    vestingData &&
    vestingData.gmyVesterPairAmount &&
    multiplierPointsAmount &&
    processedData.bonusGmyInFeeGmy
  ) {
    const availableTokens = totalRewardTokens.sub(vestingData.gmyVesterPairAmount);
    const stakedTokens = processedData.bonusGmyInFeeGmy;
    const divisor = multiplierPointsAmount.add(stakedTokens);
    if (divisor.gt(0)) {
      maxUnstakeableGmy = availableTokens.mul(stakedTokens).div(divisor);
    }
  }

  const bonusGmyInFeeGmy = processedData ? processedData.bonusGmyInFeeGmy : undefined;

  const showUnstakeGmyModal = () => {
    if (!isGmyTransferEnabled) {
      helperToast.error(`YUM transfers not yet enabled`);
      return;
    }
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake YUM`);
    let maxAmount = processedData.gmyInStakedGmy;
    if (
      processedData.gmyInStakedGmy &&
      vestingData &&
      vestingData.gmyVesterPairAmount.gt(0) &&
      maxUnstakeableGmy &&
      maxUnstakeableGmy.lt(processedData.gmyInStakedGmy)
    ) {
      maxAmount = maxUnstakeableGmy;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.gmyVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("YUM");
    setUnstakeMethodName("unstakeGmy");
  };

  return (
    <section className="Earn-Section">
      <h1 className="Section-Title">Stake</h1>

      <div className="Section-StakePapers">
        <div className="Section-StakePaper">
          <div className="Section-StakePaperHead">
            <div className="Section-StakePaperHeadDetails">
              <img src={gum_ic} alt="gum_ic" />
              <div>
                <h3>YUM & esYUM</h3>
                <p>Stake Yummy Governance Token</p>
              </div>
            </div>
            <div className="Section-StakePaperHeadItems">
              <div className="Section-StakePaperHeadItem">
                APR
                {processedData.gmyAprTotalWithBoost ? (
                  <Tooltip
                    className="tooltip-apr"
                    handle={
                      <div className="positive">
                        {formatKeyAmount(processedData, "gmyAprTotalWithBoost", 2, 2, true)}%
                      </div>
                    }
                    renderContent={() => (
                      <GMYAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "60px", height: "19.6px" }} />
                )}
              </div>
              <div className="Section-StakePaperHeadItem">
                MPs APR
                <Tooltip
                  className="excep"
                  handle={`100.00%`}
                  position="center-bottom"
                  renderContent={() => {
                    return (
                      <span>
                        Boost your rewards with Multiplier Points.&nbsp;
                        <ExternalLink href="https://docs.yummy.fi/rewards#multiplier-points">More info</ExternalLink>.
                      </span>
                    );
                  }}
                />
              </div>
              <div className="Section-StakePaperHeadItem">
                Boost
                {processedData.boostBasisPoints ? (
                  <Tooltip
                    position={isMobile ? "left-bottom" : "right-bottom"}
                    handle={`${formatAmount(processedData.boostBasisPoints, 2, 2, false)}%`}
                    renderContent={() => {
                      return (
                        <div>
                          You are earning{" "}
                          <span style={{ fontWeight: 700, color: "white" }}>
                            {formatAmount(processedData.boostBasisPoints, 2, 2, false)}%
                          </span>{" "}
                          more {nativeTokenSymbol} rewards using{" "}
                          <span style={{ fontWeight: 700, color: "white" }}>
                            {formatAmount(processedData.bnGmyInFeeGmy, 18, 4, 2, true)}
                          </span>{" "}
                          Staked Multiplier Points.
                          <br />
                          <div style={{ marginTop: "8px" }}>
                            <span>Use the "Compound" button to stake your Multiplier Points.</span>
                          </div>
                        </div>
                      );
                    }}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                )}
              </div>
            </div>
            <Link className="Section-StakePaperHeadAction border-btn" to="/buy-gum">
              Buy YUM
            </Link>
          </div>
          <div className="Section-StakePaperBody">
            <div className="Section-StakePaperBodyItem">
              <div className="Section-StakePaperBodyItemStats">
                <div className="Section-StakePaperBodyItemStatItem">
                  Total YUM staked
                  <div>
                    {totalGmyStaked ? (
                      <>
                        <h5>{formatAmount(totalGmyStaked, 18, 2, true)}</h5> $
                        {formatNumber(formatAmount(stakedGmySupplyUsd, USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="divider" />
                <div className="Section-StakePaperBodyItemStatItem">
                  MPs staked
                  <div>
                    <h5>{formatAmount(stakedGmyMultiplierPoints, 18, 2, true)}</h5>
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemStatItem">
                  YUM in wallet
                  <div>
                    {processedData?.gmyBalance ? (
                      <>
                        <h5>{formatKeyAmountFixed(processedData, "gmyBalance", 18, 2, true)}</h5> $
                        {formatNumber(formatKeyAmountFixed(processedData, "gmyBalanceUsd", USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    )}
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemStatItem">
                  YUM staked
                  <div>
                    {processedData?.gmyInStakedGmy ? (
                      <>
                        <h5>{formatKeyAmount(processedData, "gmyInStakedGmy", 18, 2, true)}</h5>$
                        {formatNumber(formatKeyAmount(processedData, "gmyInStakedGmyUsd", USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <>
                        <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                        <span className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                      </>
                    )}
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemActions">
                  <button className="default-btn" onClick={showStakeGmyModal}>
                    Stake
                  </button>
                  <button className="border-btn" onClick={showUnstakeGmyModal}>
                    Unstake
                  </button>
                </div>
              </div>
            </div>
            <div className="Section-StakePaperBodyItem">
              <div className="Section-StakePaperBodyItemStats">
                <div className="Section-StakePaperBodyItemStatItem">
                  Total esYUM staked
                  <div>
                    {totalEsGmyStaked ? (
                      <>
                        <h5> {formatAmount(totalEsGmyStaked, 18, 2, true)}</h5> $
                        {formatNumber(formatAmount(stakedEsGmySupplyUsd, USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="divider" />
                <div className="Section-StakePaperBodyItemStatItem">
                  MPs staked
                  <div>
                    <h5>{formatAmount(stakedEsGmyMultiplierPoints, 18, 2, true)}</h5>
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemStatItem">
                  esYUM in wallet
                  <div>
                    {processedData.esGmyBalance ? (
                      <>
                        <h5>{formatKeyAmountFixed(processedData, "esGmyBalance", 18, 2, true)}</h5> $
                        {formatNumber(
                          formatKeyAmountFixed(processedData, "esGmyBalanceUsd", USD_DECIMALS, USD_DECIMALS)
                        )}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    )}
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemStatItem">
                  esYUM staked
                  <div>
                    {processedData?.esGmyInStakedGmy ? (
                      <>
                        <h5>{formatKeyAmount(processedData, "esGmyInStakedGmy", 18, 2, true)}</h5>$
                        {formatNumber(
                          formatKeyAmount(processedData, "esGmyInStakedGmyUsd", USD_DECIMALS, USD_DECIMALS)
                        )}
                      </>
                    ) : (
                      <>
                        <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                        <span className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                      </>
                    )}
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemActions">
                  <button className="default-btn" onClick={showStakeEsGmyModal}>
                    Stake
                  </button>
                  <button className="border-btn" onClick={showUnstakeEsGmyModal}>
                    Unstake
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Section-StakePaper">
          <div className="Section-StakePaperHead">
            <div className="Section-StakePaperHeadDetails">
              <div>
                <h3>Total Rewards</h3>
                <p>Rewards from staking YUM, esYUM, MGLP and GSLP</p>
              </div>
              <div className="Section-StakePaperHeadDetailTotal">
                <h3>Total</h3>
                <p>
                  {processedData.totalRewardsUsd ? (
                    `$${formatNumber(formatKeyAmount(processedData, "totalRewardsUsd", USD_DECIMALS, USD_DECIMALS))}`
                  ) : (
                    <div
                      className="skeleton-box"
                      style={{
                        height: 20,
                      }}
                    />
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="right-box-rows">
            <div className="right-box-row">
              <div className="right-box-row-title">
                <img src={bera_ic} alt="bera_ic" />
                {nativeTokenSymbol} ({wrappedTokenSymbol})
              </div>
              <div className="right-box-row-value">
                {processedData.totalNativeTokenRewards ? (
                  <div className="value native-token-reward">
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={
                        <>
                          {formatKeyAmount(processedData, "totalNativeTokenRewards", 18, 4, true)}{" "}
                          <span class="reward-dollar">
                            $
                            {formatNumber(
                              formatKeyAmount(processedData, "totalNativeTokenRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )}
                          </span>
                        </>
                      }
                      renderContent={() => (
                        <>
                          <StatsTooltipRow
                            label={`YUM & esYUM`}
                            showDollar={false}
                            value={`${formatKeyAmount(
                              processedData,
                              "feeGmyTrackerRewards",
                              18,
                              4,
                              true
                            )} ($${formatNumber(
                              formatKeyAmount(processedData, "feeGmyTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                          <StatsTooltipRow
                            label={`MGLP`}
                            showDollar={false}
                            value={`${formatKeyAmount(
                              processedData,
                              "feeGlpTrackerRewards",
                              18,
                              4,
                              true
                            )} ($${formatNumber(
                              formatKeyAmount(processedData, "feeGlpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                          <StatsTooltipRow
                            label="GSLP"
                            showDollar={false}
                            value={`${formatKeyAmount(
                              processedData,
                              "feeGmTrackerRewards",
                              18,
                              4,
                              true
                            )} ($${formatNumber(
                              formatKeyAmount(processedData, "feeGmTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                        </>
                      )}
                    />
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                  </div>
                )}
              </div>
            </div>
            <div className="right-box-row">
              <div className="right-box-row-title">
                <img src={gum_ic} alt="gum_ic" />
                YUM
              </div>
              <div className="right-box-row-value">
                {processedData.totalVesterRewards ? (
                  <div className="value native-token-reward">
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={
                        <>
                          {formatKeyAmount(processedData, "totalVesterRewards", 18, 4, true)}{" "}
                          <span class="reward-dollar">
                            $
                            {formatNumber(
                              formatKeyAmount(processedData, "totalVesterRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )}
                          </span>
                        </>
                      }
                      renderContent={() => (
                        <>
                          <StatsTooltipRow
                            label={`YUM Vault`}
                            showDollar={false}
                            value={`${formatKeyAmount(processedData, "gmyVesterRewards", 18, 4, true)} ($${formatNumber(
                              formatKeyAmount(processedData, "gmyVesterRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                          <StatsTooltipRow
                            label={`MGLP Vault`}
                            showDollar={false}
                            value={`${formatKeyAmount(
                              processedData,
                              "glpVesterRewards",
                              18,
                              4,
                              true
                            )} ($${formatKeyAmount(processedData, "glpVesterRewardsUsd", USD_DECIMALS, 2, true)})`}
                          />
                          <StatsTooltipRow
                            label="GSLP Vault"
                            showDollar={false}
                            value={`${formatKeyAmount(processedData, "gmVesterRewards", 18, 4, true)} ($${formatNumber(
                              formatKeyAmount(processedData, "gmVesterRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                        </>
                      )}
                    />
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                  </div>
                )}
              </div>
            </div>
            <div className="right-box-row">
              <div className="right-box-row-title">
                <img src={esgum_ic} alt="esgum_ic" />
                esYUM
              </div>
              <div className="right-box-row-value">
                {processedData.totalEsGmyRewards ? (
                  <div className="value native-token-reward">
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={
                        <>
                          {formatKeyAmount(processedData, "totalEsGmyRewards", 18, 4, true)}{" "}
                          <span class="reward-dollar">
                            $
                            {formatNumber(
                              formatKeyAmount(processedData, "totalEsGmyRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )}
                          </span>
                        </>
                      }
                      renderContent={() => (
                        <>
                          <StatsTooltipRow
                            label={`YUM & esYUM`}
                            showDollar={false}
                            value={`${formatKeyAmount(
                              processedData,
                              "stakedGmyTrackerRewards",
                              18,
                              4,
                              true
                            )} ($${formatNumber(
                              formatKeyAmount(processedData, "stakedGmyTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                          <StatsTooltipRow
                            label={`MGLP`}
                            showDollar={false}
                            value={`${formatKeyAmount(
                              processedData,
                              "stakedGlpTrackerRewards",
                              18,
                              4
                            )} ($${formatNumber(
                              formatKeyAmount(processedData, "stakedGlpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                          <StatsTooltipRow
                            label="GSLP"
                            showDollar={false}
                            value={`${formatKeyAmount(
                              processedData,
                              "stakedGmTrackerRewards",
                              18,
                              4,
                              true
                            )} ($${formatNumber(
                              formatKeyAmount(processedData, "stakedGmTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                        </>
                      )}
                    />
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                  </div>
                )}
              </div>
            </div>
            <div className="right-box-row">
              <div className="right-box-row-title">
                <img src={multipoint_ic} alt="multipoint_ic" />
                Multiplier Points
              </div>
              <div className="right-box-row-value">
                {processedData?.bonusGmyTrackerRewards ? (
                  `${formatKeyAmount(processedData, "bonusGmyTrackerRewards", 18, 4, true)}`
                ) : (
                  <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                )}
              </div>
            </div>
            {/* <div className="right-box-row">
              <div className="right-box-row-title">Staked Multiplier Points</div>
              <div className="right-box-row-value">
                {processedData?.bnGmyInFeeGmy ? (
                  `${formatKeyAmount(processedData, "bnGmyInFeeGmy", 18, 4, true)}`
                ) : (
                  <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                )}
              </div>
            </div> */}
            {/* <div className="right-box-row">
              <div className="right-box-row-title">Total</div>
              <div className="right-box-row-value">

              </div>
            </div> */}
            <div className="actions">
              <button
                className="default-btn"
                disabled={!processedData?.totalRewardsUsd || processedData.totalRewardsUsd.eq(0)}
                onClick={() => setIsCompoundModalVisible(true)}
              >
                Compound
              </button>
              <button
                className="view-btn border-btn"
                onClick={() => setIsClaimModalVisible(true)}
                disabled={!processedData?.totalRewardsUsd || processedData.totalRewardsUsd.eq(0)}
              >
                Claim
              </button>
            </div>
          </div>
        </div>
      </div>
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        farmAddress={stakingFarmAddress}
        rewardRouterAddress={rewardRouterAddress}
        stakeMethodName={stakeMethodName}
        hasMultiplierPoints={hasMultiplierPoints}
        setPendingTxns={setPendingTxns}
        nativeTokenSymbol={nativeTokenSymbol}
        wrappedTokenSymbol={wrappedTokenSymbol}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={unstakeModalMaxAmount}
        reservedAmount={unstakeModalReservedAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={unstakingTokenSymbol}
        rewardRouterAddress={rewardRouterAddress}
        unstakeMethodName={unstakeMethodName}
        multiplierPointsAmount={multiplierPointsAmount}
        bonusGmyInFeeGmy={bonusGmyInFeeGmy}
      />
      <ClaimModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isClaimModalVisible}
        setIsVisible={setIsClaimModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData?.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />
      <CompoundModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isCompoundModalVisible}
        setIsVisible={setIsCompoundModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />
    </section>
  );
};

function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    library,
    stakingTokenSymbol,
    stakingTokenAddress,
    farmAddress,
    rewardRouterAddress,
    stakeMethodName,
    setPendingTxns,
  } = props;
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active &&
      stakingTokenAddress && [
        `allowance:${active}:${account}:${farmAddress}`,
        chainId,
        stakingTokenAddress,
        "allowance",
        account,
        farmAddress,
      ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );
  let amount = parseValue(value, 18);
  const needApproval = farmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());

    callContract(chainId, contract, stakeMethodName, [amount], {
      sentMsg: `Stake submitted!`,
      failMsg: `Stake failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return `Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return `Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return `Staking...`;
    }
    return `Stake`;
  };

  return (
    <ModalWrapper className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <StyledSectionTop className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Stake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </StyledSectionTop>
          <div className="Exchange-swap-section-bottom">
            <div>
              <StyledInput
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <StyledTokenSymbol className="PositionEditor-token-symbol">{stakingTokenSymbol}</StyledTokenSymbol>
          </div>
        </div>
        {stakingTokenSymbol === "esGMY" && chainId !== ARBITRUM && chainId !== BASE ? (
          <Warning>
            Please note that your staked esYUM amount includes the esYUM bonus from minting NFTs. Keep in mind that if
            you decide to unstake and vest esYUM to YUM later, you will be required to deposit a reserve amount of YUM
            that corresponds to the rewards generated.
          </Warning>
        ) : null}

        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}
function UnstakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    library,
    unstakingTokenSymbol,
    rewardRouterAddress,
    unstakeMethodName,
    multiplierPointsAmount,
    reservedAmount,
    bonusGmyInFeeGmy,
    setPendingTxns,
  } = props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);
  let burnAmount;

  if (
    multiplierPointsAmount &&
    multiplierPointsAmount.gt(0) &&
    amount &&
    amount.gt(0) &&
    bonusGmyInFeeGmy &&
    bonusGmyInFeeGmy.gt(0)
  ) {
    burnAmount = multiplierPointsAmount.mul(amount).div(bonusGmyInFeeGmy);
  }

  const shouldShowReductionAmount = true;
  let rewardReductionBasisPoints;
  if (burnAmount && bonusGmyInFeeGmy) {
    rewardReductionBasisPoints = burnAmount.mul(BASIS_POINTS_DIVISOR).div(bonusGmyInFeeGmy);
  }

  const getError = () => {
    if (!amount) {
      return `Enter an amount`;
    }
    if (amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(chainId, contract, unstakeMethodName, [amount], {
      sentMsg: `Unstake submitted!`,
      failMsg: `Unstake failed.`,
      successMsg: `Unstake completed!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return `Unstaking...`;
    }
    return `Unstake`;
  };

  return (
    <ModalWrapper className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <StyledSectionTop className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Unstake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </StyledSectionTop>
          <div className="Exchange-swap-section-bottom">
            <div>
              <StyledInput
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <StyledTokenSymbol className="PositionEditor-token-symbol">{unstakingTokenSymbol}</StyledTokenSymbol>
          </div>
        </div>
        {reservedAmount && reservedAmount.gt(0) && (
          <div className="Modal-note">
            You have {formatAmount(reservedAmount, 18, 2, true)} tokens reserved for vesting.
          </div>
        )}
        {burnAmount && burnAmount.gt(0) && rewardReductionBasisPoints && rewardReductionBasisPoints.gt(0) && (
          <div className="Modal-note">
            Unstaking will burn&nbsp;
            <ExternalLink href="https://docs.yummy.fi/rewards">
              {formatAmount(burnAmount, 18, 4, true)} Multiplier Points
            </ExternalLink>
            .&nbsp;
            {shouldShowReductionAmount && (
              <span>Boost Percentage: -{formatAmount(rewardReductionBasisPoints, 2, 2)}%.</span>
            )}
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}

const Warning = styled.div`
  margin: 8px 0;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 19.6px;
`;

const StyledTokenSymbol = styled.div`
  font-size: 16px !important;
`;

const StyledInput = styled.input`
  font-weight: 700 !important;
`;
const StyledSectionTop = styled.div`
  > div {
    font-weight: 400 !important;
  }
`;
const ModalWrapper = styled.div`
  .Modal-body {
    padding-left: 24px !important;
    padding-right: 24px !important;

    @media (max-width: 767px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
      padding-top: 16px !important;
    }
  }
`;

function ClaimModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    library,
    chainId,
    setPendingTxns,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isClaiming, setIsClaiming] = useState(false);
  const [shouldClaimGmy, setShouldClaimGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-gmy"],
    true
  );
  const [shouldClaimEsGmy, setShouldClaimEsGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-es-gmy"],
    true
  );
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-convert-weth"],
    true
  );

  const isPrimaryEnabled = () => {
    return !isClaiming;
  };

  const getPrimaryText = () => {
    if (isClaiming) {
      return `Claiming...`;
    }
    return `Claim`;
  };

  const onClickPrimary = () => {
    setIsClaiming(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimGmy,
        false, // shouldStakeGmy
        shouldClaimEsGmy,
        false, // shouldStakeEsGmy
        false, // shouldStakeMultiplierPoints
        shouldClaimWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: `Claim submitted.`,
        failMsg: `Claim failed.`,
        successMsg: `Claim completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsClaiming(false);
      });
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={`Claim General Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox isChecked={shouldClaimGmy} setIsChecked={setShouldClaimGmy}>
              <span>Claim YUM Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsGmy} setIsChecked={setShouldClaimEsGmy}>
              <span>Claim esYUM Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              <span>Claim {wrappedTokenSymbol} Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              <span>
                Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
              </span>
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}
function CompoundModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    active,
    account,
    library,
    chainId,
    setPendingTxns,
    totalVesterRewards,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isCompounding, setIsCompounding] = useState(false);
  // const shouldClaimGmy = false; // delete here
  // const shouldStakeGmy = false; // delete here

  // const [shouldClaimGmytest,setShouldClaimGmy] = useLocalStorageSerializeKey([chainId, "StakeV2-compound-should-claim-gmy"], true); // delete here
  // const [shouldStakeGmytest,setShouldStakeGmy] = useLocalStorageSerializeKey([chainId, "StakeV2-compound-should-stake-gmy"], true); // delete here

  // open here //

  const [shouldClaimGmy, setShouldClaimGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-gmy"],
    true
  );
  const [shouldStakeGmy, setShouldStakeGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-gmy"],
    true
  );

  // open here //

  const [shouldClaimEsGmy, setShouldClaimEsGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-es-gmy"],
    true
  );
  const [shouldStakeEsGmy, setShouldStakeEsGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-es-gmy"],
    true
  );
  const [shouldStakeMultiplierPoints, setShouldStakeMultiplierPoints] = useState(true);
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-convert-weth"],
    true
  );

  const gmyAddress = getContract(chainId, "GMY");
  const stakedGmyTrackerAddress = getContract(chainId, "StakedGmyTracker");

  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && [active, chainId, gmyAddress, "allowance", account, stakedGmyTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const needApproval = shouldStakeGmy && tokenAllowance && totalVesterRewards && totalVesterRewards.gt(tokenAllowance);

  const isPrimaryEnabled = () => {
    return !isCompounding && !isApproving && !isCompounding;
  };

  const getPrimaryText = () => {
    if (isApproving) {
      return `Approving YUM...`;
    }
    if (needApproval) {
      return `Approve YUM`;
    }
    if (isCompounding) {
      return `Compounding...`;
    }
    return `Compound`;
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: gmyAddress,
        spender: stakedGmyTrackerAddress,
        chainId,
      });
      return;
    }

    setIsCompounding(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimGmy || shouldStakeGmy,
        shouldStakeGmy,
        shouldClaimEsGmy || shouldStakeEsGmy,
        shouldStakeEsGmy,
        shouldStakeMultiplierPoints,
        shouldClaimWeth || shouldConvertWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: `Compound submitted!`,
        failMsg: `Compound failed.`,
        successMsg: `Compound completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsCompounding(false);
      });
  };

  const toggleShouldStakeGmy = (value) => {
    if (value) {
      setShouldClaimGmy(true);
    }
    setShouldStakeGmy(value);
  };

  const toggleShouldStakeEsGmy = (value) => {
    if (value) {
      setShouldClaimEsGmy(true);
    }
    setShouldStakeEsGmy(value);
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={`Compound General Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox
              isChecked={shouldStakeMultiplierPoints}
              setIsChecked={setShouldStakeMultiplierPoints}
              disabled={true}
            >
              <span>Stake Multiplier Points</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimGmy} setIsChecked={setShouldClaimGmy} disabled={shouldStakeGmy}>
              <span>Claim YUM Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeGmy} setIsChecked={toggleShouldStakeGmy}>
              <span>Stake YUM Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsGmy} setIsChecked={setShouldClaimEsGmy} disabled={shouldStakeEsGmy}>
              <span>Claim esYUM Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeEsGmy} setIsChecked={toggleShouldStakeEsGmy}>
              <span>Stake esYUM Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              <span>Claim {wrappedTokenSymbol} Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              <span>
                Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
              </span>
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default StakeSection;
