import { useMemo } from "react";
import { getContract } from "config/contracts";
import usePoolTokenBalance from "hooks/usePoolTokenBalance";
import { BigNumber } from "ethers";
import { formatAmount, parseValue } from "lib/numbers";
import { getTokenInfo, useInfoTokens } from "domain/tokens";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { importImage, PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { getTokens } from "config/tokens";
import ReaderV2 from "abis/ReaderV2.json";

import { contractFetcher } from "lib/contracts";
import { DataTable, TableRow } from "components/Table";
import AssetDropdown from "pages/Dashboard/AssetDropdown";
import { useChainIdWithDefault } from "lib/chains";
import { SUPPORTED_CHAIN_ID_GM } from "config/chains";

const PoolCompositionGslp = () => {
  const { account, active, library } = useWeb3React();
  const chainId = useChainIdWithDefault({
    chains: SUPPORTED_CHAIN_ID_GM,
    isUseDefaultChain: false,
  });

  const vaultGmyAddress = getContract(chainId, "VaultMsp");
  const readerAddress = getContract(chainId, "Reader");

  const tokens = getTokens(chainId);
  const tokenAddresses = tokens.map((token) => token.address);

  const { data: tokenBalances } = useSWR(
    [`GlpSwap:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
      refreshInterval: 5000,
    }
  );

  const poolTokenBalances = usePoolTokenBalance(chainId, vaultGmyAddress);
  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);

  const mapPoolComposition = useMemo(() => {
    const origin = [...poolTokenBalances];
    if (poolTokenBalances && poolTokenBalances.length > 0) {
      let total = BigNumber.from(0);
      for (let i = 0; i < poolTokenBalances.length; i++) {
        total = total.add(
          poolTokenBalances[i].balance.div(
            poolTokenBalances[i].decimals !== 6 ? parseValue(1, poolTokenBalances[i].decimals - 6) : 1
          )
        );
      }
      for (let i = 0; i < origin.length; i++) {
        const curTokenInfo = getTokenInfo(infoTokens, origin[i].address);
        const amountBalance = poolTokenBalances[i].balance.div(
          poolTokenBalances[i].decimals !== 6 ? parseValue(1, poolTokenBalances[i].decimals - 6) : 1
        );
        origin[i] = {
          ...poolTokenBalances[i],
          amountPool: amountBalance,
          amountPoolUSD:
            curTokenInfo?.minPrice && curTokenInfo?.maxPrice && amountBalance
              ? amountBalance.mul(curTokenInfo?.minPrice.add(curTokenInfo?.maxPrice).div(2) || parseValue(1, 30))
              : amountBalance,
          percentPool: total.gt(0) ? amountBalance.mul(100).mul(parseValue(1, 6)).div(total) : 0,
        };
      }
    }
    return origin;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolTokenBalances]);
  return (
    <div className="Pool-Paper Pool-Composition">
      <h3>Pool Composition</h3>
      <DataTable $noIndex $rowHeightSkeletonMobile="146.38px">
        <div className="head">
          <div className="col-name">Token</div>
          <div className="col-name">Amount</div>
          <div className="col-name">Value</div>
          <div className="col-name">Weight</div>
        </div>
        <div className="body">
          {mapPoolComposition.map((token, index) => {
            const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");

            return (
              <TableRow key={index} noScaleOnHover>
                <div d-label="Token" className="col-body">
                  <div className="token-symbol-wrapper">
                    <div className="App-card-title-info-icon">
                      <img src={tokenImage} alt="usdc" width="40px" />
                    </div>
                    <div className="App-card-title-info-text">
                      <div className="App-card-info-subtitle">
                        {token.symbol}{" "}
                        <div className="dropdown-clickable">
                          <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                        </div>
                      </div>
                      <div className="App-card-info-title">{token.name}</div>
                    </div>
                  </div>
                </div>
                <div d-label="Amount" className="col-body">
                  {token?.amountPool ? formatAmount(token?.amountPool, 6, 2, true) : "--"}
                </div>
                <div d-label="Value" className="col-body">
                  ${token?.amountPool ? formatAmount(token?.amountPoolUSD, 36, 2, true) : "--"}
                </div>
                <div d-label="Weight" className="col-body">
                  {token?.percentPool ? formatAmount(token?.percentPool, 6, 2, true) : "--"}%
                </div>
              </TableRow>
            );
          })}
        </div>
      </DataTable>
    </div>
  );
};

export default PoolCompositionGslp;
