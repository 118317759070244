import pie_chart_ic from "img/earn/piechart.svg";
import Tooltip from "components/Tooltip/Tooltip";

import mglp_ic from "img/mglp.svg";
import gslp_ic from "img/gslp.svg";
import Countdown from "react-countdown";
import useVaultGmyContract from "hooks/contracts/useVaultGmyContract";

import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import { useState } from "react";
import { PoolCompositionGslpModal } from "pages/Earn/PoolCompositionGslpModal";
import { PoolCompositionMglpModal } from "pages/Earn/PoolCompositionMglpModal";
import { formatAddKeysAmount, formatKeyAmount, formatNumber, formatAmount } from "lib/numbers";
import {
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
  GLP_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  USD_DECIMALS,
  GLP_COOLDOWN_DURATION,
  GM_COOLDOWN_DURATION,
} from "lib/legacy";
import { FANTOM, getConstant } from "config/chains";
import { useGmyPrice } from "domain/legacy";
import { contractFetcher } from "lib/contracts";
import useSWR from "swr";
import { getContract } from "config/contracts";
import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";

import GlpManager from "abis/GlpManager.json";
import VaultMsp from "abis/VaultMsp.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import Vault from "abis/Vault.json";
import { ethers } from "ethers";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { EarnFrame } from "pages/Earn/styles";
import VestSection from "pages/Earn/VestSection";
import { useInfoTokens } from "domain/tokens";
import { getTokens } from "config/tokens";
import StakeSection from "pages/Earn/StakeSection";

const { AddressZero } = ethers.constants;

const Earn = ({ setPendingTxns }) => {
  const [isModalCompositionGslpOpen, setIsModalCompositionGslpOpen] = useState(false);
  const [isModalCompositionMglpOpen, setIsModalCompositionMglpOpen] = useState(false);

  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const { gmyPrice } = useGmyPrice(chainId, { arbitrum: chainId === FANTOM ? library : undefined }, active);
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const gmyAddress = getContract(chainId, "GMY");
  const gmAddress = getContract(chainId, "GM");
  const esGmyAddress = getContract(chainId, "ES_GMY");
  const bnGmyAddress = getContract(chainId, "BN_GMY");
  const glpAddress = getContract(chainId, "GLP");
  const usdgAddress = getContract(chainId, "USDG");

  const stakedGmyTrackerAddress = getContract(chainId, "StakedGmyTracker");
  const bonusGmyTrackerAddress = getContract(chainId, "BonusGmyTracker");
  const feeGmyTrackerAddress = getContract(chainId, "FeeGmyTracker");

  const stakedGlpTrackerAddress = getContract(chainId, "StakedGlpTracker");
  const feeGlpTrackerAddress = getContract(chainId, "FeeGlpTracker");

  const stakedGmTrackerAddress = getContract(chainId, "StakedGmTracker");
  const feeGmTrackerAddress = getContract(chainId, "FeeGmTracker");

  const glpManagerAddress = getContract(chainId, "GlpManager");
  const gmManagerAddress = getContract(chainId, "VaultMsp");

  const gmyVesterAddress = getContract(chainId, "GmyVester");
  const glpVesterAddress = getContract(chainId, "GlpVester");
  const gmVesterAddress = getContract(chainId, "GmVester");

  const vesterAddresses = [gmyVesterAddress, glpVesterAddress, gmVesterAddress];

  const walletTokens = [gmyAddress, esGmyAddress, glpAddress, stakedGmyTrackerAddress, gmAddress];
  const depositTokens = [
    gmyAddress,
    esGmyAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    bnGmyAddress,
    glpAddress,
    gmAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedGmyTrackerAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGlpTrackerAddress,
    feeGmTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    stakedGlpTrackerAddress,
    feeGlpTrackerAddress,
    stakedGmTrackerAddress,
    feeGmTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}:${walletTokens}:${account}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}:${depositTokens}:${rewardTrackersForDepositBalances}:${account}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );

  const { data: stakingInfo } = useSWR(
    [
      `StakeV2:stakingInfo:${active}:${rewardTrackersForStakingInfo}:${account}`,
      chainId,
      rewardReaderAddress,
      "getStakingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 10000,
    }
  );

  const { data: stakedGmySupply } = useSWR(
    [
      `StakeV2:stakedGmySupply:${active}:${stakedGmyTrackerAddress}`,
      chainId,
      gmyAddress,
      "balanceOf",
      stakedGmyTrackerAddress,
    ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 10000,
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const { data: vestingInfo } = useSWR(
    [
      `StakeV2:vestingInfo:${active}:${vesterAddresses}:${account}`,
      chainId,
      readerAddress,
      "getVestingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );
  const tokensForSupplyQuery = [gmyAddress, glpAddress, usdgAddress, gmAddress];

  const tokens = getTokens(chainId);
  const tokenAddresses = tokens.map((token) => token.address);

  const { data: totalSupplies } = useSWR(
    [
      `StakeV2:totalSupplies:${active}:${tokensForSupplyQuery}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 10000,
    }
  );

  const { data: lastPurchaseTime } = useSWR(
    [`GlpSwap:lastPurchaseTime:${active}`, chainId, glpManagerAddress, "lastAddedAt", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, GlpManager),
    }
  );

  const { data: lastPurchaseTimeGSLP } = useSWR(
    [
      `GlpSwap:lastPurchaseTimeGSLP:${active}`,
      chainId,
      gmManagerAddress,
      "lastStakedAt",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, VaultMsp),
    }
  );

  const { data: tokenBalances } = useSWR(
    [`GlpSwap:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
      refreshInterval: 5000,
    }
  );

  const redemptionTime = lastPurchaseTime ? lastPurchaseTime.add(GLP_COOLDOWN_DURATION) : undefined;
  const inCooldownWindow = redemptionTime && parseInt(Date.now() / 1000) < redemptionTime;

  const redemptionTimeGSLP = lastPurchaseTimeGSLP ? lastPurchaseTimeGSLP.add(GM_COOLDOWN_DURATION) : undefined;
  const inCooldownWindowGSLP = redemptionTimeGSLP && parseInt(Date.now() / 1000) < redemptionTimeGSLP;

  let totalSupply;
  if (totalSupplies && totalSupplies[1]) {
    totalSupply = totalSupplies[1];
  }

  let gmySupply = totalSupply;

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);
  const { gmPrice } = useVaultGmyContract(chainId);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedGmySupply,
    gmyPrice,
    gmySupply,
    gmPrice
  );

  let estMGLPRewardsPerWeek = 0;
  if (processedData && processedData.glpAprTotal && processedData.glpBalanceUsd) {
    estMGLPRewardsPerWeek = processedData.glpAprTotal.mul(processedData.glpBalanceUsd).div(365).mul(7).div(100);
  }
  let estMSLPRewardsPerWeek = 0;
  if (processedData && processedData.gmAprTotal && processedData.gmBalanceUsd) {
    estMSLPRewardsPerWeek = processedData.gmAprTotal.mul(processedData.gmBalanceUsd).div(365).mul(7).div(100);
  }

  let totalRewardTokens;
  if (processedData && processedData.bnGmyInFeeGmy && processedData.bonusGmyInFeeGmy) {
    totalRewardTokens = processedData.bnGmyInFeeGmy.add(processedData.bonusGmyInFeeGmy);
  }
  const countdownRenderer = (countdownProps) => {
    const {
      formatted: { hours, minutes, seconds },
    } = countdownProps;
    return <>{`${hours}:${minutes}:${seconds}`}</>;
  };

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);

  return (
    <EarnFrame>
      <StakeSection
        processedData={processedData}
        gmyPrice={gmyPrice}
        vestingData={vestingData}
        setPendingTxns={setPendingTxns}
        totalRewardTokens={totalRewardTokens}
      />

      <section className="Earn-Section">
        <h1 className="Section-Title">Pools</h1>
        <div className="Section-DoublePaper">
          <div className="Section-Paper">
            <div className="Section-PaperHead">
              <img src={mglp_ic} alt="mglp_ic" />
              <div className="Section-PaperHeadDetails">
                <h3>
                  Majors pool - MGLP
                  <img src={pie_chart_ic} alt="pie_chart_ic" onClick={() => setIsModalCompositionMglpOpen(true)} />
                </h3>
                <p>Index by crypto assets</p>
              </div>
              <Link className="Section-PaperHeadAction border-btn" to="/earn/mglp">
                Get MGLP
              </Link>
            </div>
            <div className="divider" />
            <div className="Section-PaperBody">
              <div className="Section-PaperBodyTop">
                <div className="Section-PaperBodyTopItem">
                  APR
                  {processedData.glpAprTotal ? (
                    <Tooltip
                      className="tooltip-apr"
                      handle={
                        <h5 className="positive">
                          {formatNumber(formatKeyAmount(processedData, "glpAprTotal", 2, 2), 2)}%
                        </h5>
                      }
                      renderContent={() => {
                        return (
                          <>
                            <StatsTooltipRow
                              label={`${nativeTokenSymbol} (${wrappedTokenSymbol}) APR`}
                              value={`${formatNumber(
                                formatKeyAmount(processedData, "glpAprForNativeToken", 2, 2),
                                2
                              )}%`}
                              showDollar={false}
                            />
                            <StatsTooltipRow
                              label="Escrowed YUM APR"
                              value={`${formatNumber(formatKeyAmount(processedData, "glpAprForEsGmy", 2, 2), 2)}%`}
                              showDollar={false}
                            />
                            <div style={{ marginTop: "8px", fontWeight: 400 }}>
                              <span>APRs are updated weekly and will depend on the fees collected for the week.</span>
                            </div>
                          </>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                  )}
                </div>
                <div className="Section-PaperBodyTopItem">
                  Total staked
                  <div>
                    {processedData.glpSupply ? (
                      <>
                        <span>{formatKeyAmount(processedData, "glpSupply", 18, 2, true)}</span> $
                        {formatNumber(formatKeyAmount(processedData, "glpSupplyUsd", USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="Section-PaperBodyTopItem">
                  Wallet balance
                  <div>
                    {processedData.glpBalance ? (
                      <>
                        <span>{formatKeyAmount(processedData, "glpBalance", GLP_DECIMALS, 2, true)}</span>$
                        {formatNumber(formatKeyAmount(processedData, "glpBalanceUsd", USD_DECIMALS, GLP_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    )}
                  </div>
                </div>
              </div>
              <div className="Section-PaperBodyBottom">
                <div className="Section-PaperBodyBottomItem">
                  <span>Deposited</span>
                  {processedData.glpBalance ? (
                    <>
                      <h5>{formatKeyAmount(processedData, "glpBalance", GLP_DECIMALS, 2, true)} MSLP</h5>
                      <span>${formatNumber(formatKeyAmount(processedData, "glpBalanceUsd", USD_DECIMALS, 2))}</span>
                    </>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    </div>
                  )}
                </div>
                <div className="Section-PaperBodyBottomItem">
                  <span>Rewards</span>
                  <h5>
                    $
                    {formatNumber(
                      formatAddKeysAmount(
                        processedData,
                        "feeGlpTrackerRewardsUsd",
                        "stakedGlpTrackerRewardsUsd",
                        USD_DECIMALS,
                        USD_DECIMALS
                      )
                    )}
                  </h5>
                  <span>
                    {processedData?.feeGlpTrackerRewards &&
                      `$${formatNumber(
                        formatKeyAmount(processedData, "feeGlpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )} ${wrappedTokenSymbol}, `}
                    {processedData.stakedGlpTrackerRewards &&
                      `$${formatNumber(
                        formatKeyAmount(processedData, "stakedGlpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )} esYUM`}
                  </span>
                </div>
              </div>
              <Link
                className={`border-btn full ${inCooldownWindow ? "disabled" : ""}`}
                to={inCooldownWindow ? "#" : "/earn/mglp"}
              >
                {inCooldownWindow ? "Cooldown to unstake" : "Unstake"}
              </Link>
              <div className="Section-PaperBodyBottomItemRows">
                <Tooltip
                  handle="Cooldown period"
                  renderContent={() => {
                    return "There is a 10-minute cooldown period after each stake.";
                  }}
                />
                <h5>
                  <Countdown
                    key={redemptionTime}
                    date={new Date(Number(redemptionTime) * 1000)}
                    renderer={countdownRenderer}
                    daysInHours
                  />
                </h5>
              </div>
            </div>
          </div>
          <div className="Section-Paper">
            <div className="Section-PaperHead">
              <img src={gslp_ic} alt="gslp_ic" />
              <div className="Section-PaperHeadDetails">
                <h3>
                  Stable pool - GSLP
                  <img src={pie_chart_ic} alt="pie_chart_ic" onClick={() => setIsModalCompositionGslpOpen(true)} />
                </h3>
                <p>Index by stable assets</p>
              </div>
              <Link className="Section-PaperHeadAction border-btn" to="/earn/gslp">
                Get GSLP
              </Link>
            </div>
            <div className="divider" />
            <div className="Section-PaperBody">
              <div className="Section-PaperBodyTop">
                <div className="Section-PaperBodyTopItem">
                  APR
                  {processedData.gmAprTotal ? (
                    <Tooltip
                      className="tooltip-apr"
                      handle={
                        <h5 className="positive">
                          {formatNumber(formatKeyAmount(processedData, "gmAprTotal", 2, 2), 2)}%
                        </h5>
                      }
                      renderContent={() => {
                        return (
                          <>
                            <StatsTooltipRow
                              label={`${nativeTokenSymbol} (${wrappedTokenSymbol}) APR`}
                              value={`${formatNumber(formatKeyAmount(processedData, "gmAprForNativeToken", 2, 2), 2)}%`}
                              showDollar={false}
                            />
                            <StatsTooltipRow
                              label="Escrowed YUM APR"
                              value={`${formatNumber(formatKeyAmount(processedData, "gmAprForEsGmy", 2, 2), 2)}%`}
                              showDollar={false}
                            />
                            <div style={{ marginTop: "8px", fontWeight: 400 }}>
                              <span>APRs are updated weekly and will depend on the fees collected for the week.</span>
                            </div>
                          </>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                  )}
                </div>
                <div className="Section-PaperBodyTopItem">
                  Total staked
                  <div>
                    {processedData.gmSupply ? (
                      <>
                        <span>{formatKeyAmount(processedData, "gmSupply", 18, 2, true)}</span> $
                        {formatNumber(formatKeyAmount(processedData, "gmSupplyUsd", USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="Section-PaperBodyTopItem">
                  Wallet balance
                  <div>
                    {processedData.gmBalance ? (
                      <>
                        <span>{formatKeyAmount(processedData, "gmBalance", GLP_DECIMALS, 2, true)}</span>$
                        {formatNumber(formatKeyAmount(processedData, "gmBalanceUsd", USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    )}
                  </div>
                </div>
              </div>
              <div className="Section-PaperBodyBottom">
                <div className="Section-PaperBodyBottomItem">
                  <span>Deposited</span>
                  {processedData.gmBalance ? (
                    <>
                      <h5>{formatKeyAmount(processedData, "gmBalance", GLP_DECIMALS, 2, true)} MGLP</h5>
                      <span>
                        ${formatNumber(formatKeyAmount(processedData, "gmBalanceUsd", USD_DECIMALS, USD_DECIMALS), 2)}
                      </span>
                    </>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    </div>
                  )}
                </div>
                <div className="Section-PaperBodyBottomItem">
                  <span>Rewards</span>
                  <h5>
                    $
                    {formatNumber(
                      formatAddKeysAmount(
                        processedData,
                        "feeGmTrackerRewardsUsd",
                        "stakedGmTrackerRewardsUsd",
                        USD_DECIMALS,
                        USD_DECIMALS
                      )
                    )}
                  </h5>
                  <span>
                    {processedData?.feeGmTrackerRewards &&
                      `$${formatNumber(
                        formatKeyAmount(processedData, "feeGmTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )} ${wrappedTokenSymbol}, `}
                    {processedData.stakedGmTrackerRewards &&
                      `$${formatNumber(
                        formatKeyAmount(processedData, "stakedGmTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )} esYUM`}
                  </span>
                </div>
              </div>

              <Link
                className={`border-btn full ${inCooldownWindowGSLP ? "disabled" : ""}`}
                to={inCooldownWindowGSLP ? "#" : "/earn/gslp"}
              >
                {inCooldownWindowGSLP ? "Cooldown to unstake" : "Unstake"}
              </Link>
              <div className="Section-PaperBodyBottomItemRows">
                <Tooltip
                  handle="Cooldown period"
                  renderContent={() => {
                    return "There is a 10-minute cooldown period after each stake.";
                  }}
                />
                <h5>
                  <Countdown
                    key={redemptionTimeGSLP}
                    date={new Date(Number(redemptionTimeGSLP) * 1000)}
                    renderer={countdownRenderer}
                    daysInHours
                  />
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <VestSection
        vestingData={vestingData}
        totalRewardTokens={totalRewardTokens}
        processedData={processedData}
        setPendingTxns={setPendingTxns}
      />
      <PoolCompositionMglpModal
        isVisible={isModalCompositionMglpOpen}
        setIsVisible={setIsModalCompositionMglpOpen}
        infoTokens={infoTokens}
      />
      <PoolCompositionGslpModal
        isVisible={isModalCompositionGslpOpen}
        setIsVisible={setIsModalCompositionGslpOpen}
        infoTokens={infoTokens}
      />
      <Footer />
    </EarnFrame>
  );
};

export default Earn;
