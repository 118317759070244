const RefreshIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8598 3.83325L12.1657 5.52733L14.9728 8.33435L16.6668 6.64027L13.8598 3.83325ZM11.1062 6.58682L3.3335 14.3596V17.1666H6.14051L13.9133 9.39383L11.1062 6.58682Z"
        fill="white"
      />
    </svg>
  );
};

export default RefreshIcon;
