import { useWeb3React } from "@web3-react/core";
import Token from "abis/Token.json";
import VaultGmy from "abis/VaultGmy.json";
import { getContract } from "config/contracts";
import { ethers } from "ethers";

import ModalWithPortal from "components/Modal/ModalWithPortal";
import UsdcIcon from "img/ic_usdc.e_24.svg";
import { callContract, contractFetcher } from "lib/contracts";
import { formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { useState } from "react";
import useSWR from "swr";
import "./index.scss";
import Reader from "abis/ReaderV2.json";
import { useLocalStorageByChainId } from "lib/localStorage";
import { DEFAULT_FROM_V2_TOKEN, getTokens, getWhitelistedTokens } from "config/tokens";
import { useInfoTokens } from "domain/tokens";
import TokenSelector from "components/Exchange/TokenSelector";
import { FANTOM } from "config/chains";
import usePoolTokenBalance from "hooks/usePoolTokenBalance";
const WithdrawModal = (props) => {
  const {
    isVisible,
    setIsVisible,
    chainId,
    // value = 0,
    // setValue = () => {},
    // balance,
    library,
    // vesterAddress,
    setPendingTxns,
  } = props;
  const { active, account } = useWeb3React();

  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [value, setValue] = useState("");
  const decimals = 30;
  const usdcAddress = getContract(chainId, "USDC");
  const readerAddress = getContract(chainId, "Reader");
  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address);
  const [destAddress, setDestAddress] = useLocalStorageByChainId(
    chainId,
    `withdraw-default-dest-token-address`,
    usdcAddress
  );
  const tokens = getTokens(chainId);
  const destTokens = tokens.filter((x) => DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(x.address));
  const tokenAddresses = tokens.map((token) => token.address);
  const { data: tokenBalances } = useSWR(active && [active, chainId, readerAddress, "getTokenBalances", account], {
    fetcher: contractFetcher(library, Reader, [tokenAddresses]),
  });

  const { data: fundingRateInfo } = useSWR([active, chainId, readerAddress, "getFundingRates"], {
    fetcher: contractFetcher(library, Reader, [vaultAddress, nativeTokenAddress, whitelistedTokenAddresses]),
  });
  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, fundingRateInfo);
  const vaultGmyAddress = getContract(chainId, "VaultMsp");

  const { data: gusdBalance } = useSWR(
    [`StakeV2:gusdBalance:${chainId}`, chainId, getContract(chainId, "GUSD"), "balanceOf", account],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );
  let amount = parseValue(value, decimals);
  // const { data: balanceOfDestToken } = useSWR(
  //   [`StakeV2:balanceOfDestToken:${vaultGmyAddress}`, chainId || FANTOM, destAddress, "balanceOf"],
  //   {
  //     fetcher: contractFetcher(library, Token, [vaultGmyAddress]),
  //   }
  // );

  const poolTokenBalances = usePoolTokenBalance(chainId, vaultGmyAddress);

  const destToken =
    poolTokenBalances &&
    Array.isArray(poolTokenBalances) &&
    poolTokenBalances.find((tokenBalance) => tokenBalance.address.toLowerCase() === destAddress.toLowerCase());

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (gusdBalance && amount.gt(gusdBalance)) {
      return `Amount exceeds balance`;
    }

    if (!destToken || amount.gt(destToken.balance.mul(parseValue(1, 30 - destToken.decimals)))) {
      return "Vault balance exceeded";
    }
    // if (balanceOfDestToken && amount.gt(balanceOfDestToken.mul(parseValue(1, 24)))) return "Vault balance exceeded";
  };

  const onClickWithdrawPrimary = () => {
    setIsWithdrawing(true);
    const contract = new ethers.Contract(vaultGmyAddress, VaultGmy.abi, library.getSigner());

    callContract(chainId, contract, "withdraw", [destAddress, parseValue(value, decimals)], {
      sentMsg: `Withdraw submitted.`,
      failMsg: `Withdraw failed.`,
      successMsg: `Withdrawn!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsWithdrawing(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }

    if (isWithdrawing) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isWithdrawing) {
      return `Withdrawing...`;
    }
    return "Withdraw";
  };
  const handleInput = (e) => {
    const value = e.target.value;
    if (value.includes(".")) {
      const arr = value.split(".")[1];
      if (arr.length <= 6) {
        setValue(value);
      }
    } else setValue(value);
  };
  const onSelectDestToken = (token) => {
    setDestAddress(token.address);
    setValue("");
    // setIsWaitingForApproval(false);
  };
  const handleMaxBalance = () => {
    if (destToken && destToken.balance) {
      if (gusdBalance.gt(destToken.balance.mul(parseValue(1, 30 - destToken.decimals)))) {
        setValue(formatAmountFree(destToken.balance, destToken.decimals, destToken.decimals));
      } else setValue(formatAmountFree(gusdBalance, decimals, destToken?.decimals || 6));
    } else setValue(0);
  };
  return (
    <ModalWithPortal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      label="Withdraw Collateral"
      className={`hasDivider non-scrollable Collateral-Modal-Withdraw`}
    >
      <span className="Modal-description">
        Funds are withdrawn from the exchange balance into your standard wallet.
      </span>
      <div className="Exchange-swap-section">
        <div className="Exchange-swap-section-top">
          <div className="muted">
            <div className="Exchange-swap-usd">{/* <span>Withdraw</span> */}</div>
          </div>

          <div
            className="muted align-right clickable"
            onClick={() => setValue(formatAmountFree(gusdBalance, decimals, destToken?.decimals || 6))}
          >
            <span>Balance: {formatAmount(gusdBalance, decimals, 4, true)}</span>
          </div>
        </div>
        <div className="Exchange-swap-section-bottom">
          <div className="Exchange-swap-input-container">
            <input
              type="number"
              placeholder="0.0"
              className="Exchange-swap-input"
              value={value}
              onChange={handleInput}
            />

            {(!amount && gusdBalance && gusdBalance.gt(0)) || (amount && amount.lt(gusdBalance)) ? (
              <div className="Exchange-swap-max" onClick={handleMaxBalance}>
                <span>MAX</span>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="PositionEditor-token-symbol">
            <div className="TokenSelector">
              <div className="wrapper-symbol">
                {/* <img src={UsdcIcon} alt="icon" className="TokenSelector-box-symbol" /> */}
                {/* <span className="symbol-styles">USDC</span> */}
                <TokenSelector
                  label={`To`}
                  chainId={chainId}
                  tokenAddress={destAddress}
                  onSelectToken={onSelectDestToken}
                  tokens={destTokens}
                  infoTokens={infoTokens}
                  showMintingCap={false}
                  showSymbolImage={true}
                  showTokenImgInDropdown={true}
                  className="GlpSwap-from-token"
                  showBalances={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="Exchange-swap-button-container">
      </div> */}
      <button className="default-btn full" onClick={onClickWithdrawPrimary} disabled={!isPrimaryEnabled()}>
        {getPrimaryText()}
      </button>
    </ModalWithPortal>
  );
};

export default WithdrawModal;
