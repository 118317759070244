import axios from "axios";

import { getServerBaseUrl } from "config/backend";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { useEffect, useState } from "react";
import { parseValue } from "../lib/numbers";

function useMergedTraderInfo(chainId, account = PLACEHOLDER_ACCOUNT) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    async function getTraderInfo() {
      try {
        (async () => {
          const { data: response } = await axios.get(`https://api.yummy.fi/stats/bartio/trader/${account}/overview`);

          const data = {
            volume: parseValue(response.data.totalTradingVolume, 30) || 0,
            profitLoss: parseValue(response.data.totalPnl, 30) || 0,
            fees: parseValue(response.data.totalFee, 30) || 0,
          };

          setData(data);
          setIsLoading(false);
        })();
      } catch (error) {
        console.error("[ERROR]: useMergedTraderInfo fetch failed");
      }
    }

    getTraderInfo();
    const timerId = setInterval(getTraderInfo, 30000);

    return () => clearInterval(timerId);
  }, [account, chainId]);

  return {
    data,
    isLoading,
  };
}

export default useMergedTraderInfo;
