import { useWeb3React } from "@web3-react/core";
import ExternalLink from "components/ExternalLink/ExternalLink";
import GMYAprTooltip from "components/Stake/GMYAprTooltip";
import StatsTooltip from "components/StatsTooltip/StatsTooltip";
import Tooltip from "components/Tooltip/Tooltip";
import { ARBITRUM, BASE, getConstant } from "config/chains";
import { getContract } from "config/contracts";
import { useTotalGmyStaked, useTotalEsGmyStaked } from "domain/legacy";
import { ethers } from "ethers";
import TokenIcon from "img/earn/gmy_coin.svg";
import { useChainId } from "lib/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import { BASIS_POINTS_DIVISOR, USD_DECIMALS } from "lib/legacy";
import {
  formatAddKeysAmount,
  formatAmount,
  formatAmountFree,
  formatKeyAmount,
  formatKeyAmountFixed,
  parseValue,
} from "lib/numbers";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";
import "./Earn.scss";
import "./EarnTheme.scss";
import Token from "abis/Token.json";
import { approveTokens } from "domain/tokens";
import RewardRouter from "abis/RewardRouter.json";
import Modal from "components/Modal/Modal";
import { useThemeContext } from "contexts/ThemeProvider";
const { AddressZero } = ethers.constants;
function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    library,
    stakingTokenSymbol,
    stakingTokenAddress,
    farmAddress,
    rewardRouterAddress,
    stakeMethodName,
    setPendingTxns,
  } = props;
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active &&
      stakingTokenAddress && [
        `allowance:${active}:${account}:${farmAddress}`,
        chainId,
        stakingTokenAddress,
        "allowance",
        account,
        farmAddress,
      ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );
  let amount = parseValue(value, 18);
  const needApproval = farmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());

    callContract(chainId, contract, stakeMethodName, [amount], {
      sentMsg: `Stake submitted!`,
      failMsg: `Stake failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return `Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return `Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return `Staking...`;
    }
    return `Stake`;
  };

  return (
    <ModalWrapper className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <StyledSectionTop className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Stake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </StyledSectionTop>
          <div className="Exchange-swap-section-bottom">
            <div>
              <StyledInput
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <StyledTokenSymbol className="PositionEditor-token-symbol">{stakingTokenSymbol}</StyledTokenSymbol>
          </div>
        </div>
        {stakingTokenSymbol === "esGMY" && chainId !== ARBITRUM && chainId !== BASE ? (
          <Warning>
            Please note that your staked esGMY amount includes the esGMY bonus from minting NFTs. Keep in mind that if
            you decide to unstake and vest esGMY to GMY later, you will be required to deposit a reserve amount of GMY
            that corresponds to the rewards generated.
          </Warning>
        ) : null}

        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}
function UnstakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    library,
    unstakingTokenSymbol,
    rewardRouterAddress,
    unstakeMethodName,
    multiplierPointsAmount,
    reservedAmount,
    bonusGmyInFeeGmy,
    setPendingTxns,
  } = props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);
  let burnAmount;

  if (
    multiplierPointsAmount &&
    multiplierPointsAmount.gt(0) &&
    amount &&
    amount.gt(0) &&
    bonusGmyInFeeGmy &&
    bonusGmyInFeeGmy.gt(0)
  ) {
    burnAmount = multiplierPointsAmount.mul(amount).div(bonusGmyInFeeGmy);
  }

  const shouldShowReductionAmount = true;
  let rewardReductionBasisPoints;
  if (burnAmount && bonusGmyInFeeGmy) {
    rewardReductionBasisPoints = burnAmount.mul(BASIS_POINTS_DIVISOR).div(bonusGmyInFeeGmy);
  }

  const getError = () => {
    if (!amount) {
      return `Enter an amount`;
    }
    if (amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(chainId, contract, unstakeMethodName, [amount], {
      sentMsg: `Unstake submitted!`,
      failMsg: `Unstake failed.`,
      successMsg: `Unstake completed!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return `Unstaking...`;
    }
    return `Unstake`;
  };

  return (
    <ModalWrapper className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <StyledSectionTop className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Unstake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </StyledSectionTop>
          <div className="Exchange-swap-section-bottom">
            <div>
              <StyledInput
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <StyledTokenSymbol className="PositionEditor-token-symbol">{unstakingTokenSymbol}</StyledTokenSymbol>
          </div>
        </div>
        {reservedAmount && reservedAmount.gt(0) && (
          <div className="Modal-note">
            You have {formatAmount(reservedAmount, 18, 2, true)} tokens reserved for vesting.
          </div>
        )}
        {burnAmount && burnAmount.gt(0) && rewardReductionBasisPoints && rewardReductionBasisPoints.gt(0) && (
          <div className="Modal-note">
            Unstaking will burn&nbsp;
            <ExternalLink href="https://docs.yummy.fi/rewards">
              {formatAmount(burnAmount, 18, 4, true)} Multiplier Points
            </ExternalLink>
            .&nbsp;
            {shouldShowReductionAmount && (
              <span>Boost Percentage: -{formatAmount(rewardReductionBasisPoints, 2, 2)}%.</span>
            )}
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}
const GMYandESGMY = (props) => {
  const { lightThemeClassName } = useThemeContext();
  const { chainId } = useChainId();
  const {
    setPendingTxns,
    bonusGmyInFeeGmy,
    processedData,
    multiplierPointsAmount,
    connectWallet,
    maxUnstakeableGmy,
    vestingData,
    gmyPrice,
    stakedGmySupplyUsd,
    stakedEsGmySupplyUsd,
    hasMultiplierPoints,
    totalGmySupply,
    totalSupplyUsd,
    esGmySupplyUsd,
    esGmySupply,
  } = props;
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");
  let {
    op: opGmyStaked,
    // oplegacy: opGmyStakedLegacy,
    fantom: fantomGmyStaked,
    // fantomlegacy: fantomGmyStakedLegacy,
    total: totalGmyStaked,
    arbitrum: arbitrumGmyStaked,
    base: baseGmyStaked,
  } = useTotalGmyStaked();
  let {
    op: opEsGmyStaked,
    fantom: fantomEsGmyStaked,
    // fantomlegacy: fantomEsGmyStakedLegacy,
    arbitrum: arbitrumEsGmyStaked,
    base: baseEsGmyStaked,
    total: totalEsGmyStaked,
  } = useTotalEsGmyStaked();

  const { account, active, library } = useWeb3React();
  const isGmyTransferEnabled = true;

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakingFarmAddress, setStakingFarmAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");
  const stakedGmyTrackerAddress = getContract(chainId, "StakedGmyTracker");

  const esGmyAddress = getContract(chainId, "ES_GMY");
  const gmyAddress = getContract(chainId, "GMY");
  const showStakeGmyModal = () => {
    if (!isGmyTransferEnabled) {
      helperToast.error(`GMY transfers not yet enabled`);
      return;
    }

    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake GMY`);
    setStakeModalMaxAmount(processedData.gmyBalance);
    setStakeValue("");
    setStakingTokenSymbol("GMY");
    setStakingTokenAddress(gmyAddress);
    setStakingFarmAddress(stakedGmyTrackerAddress);
    setStakeMethodName("stakeGmy");
  };

  const showStakeEsGmyModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake esGMY`);
    setStakeModalMaxAmount(processedData.esGmyBalance);
    setStakeValue("");
    setStakingTokenSymbol("esGMY");
    setStakingTokenAddress(esGmyAddress);
    setStakingFarmAddress(AddressZero);
    setStakeMethodName("stakeEsGmy");
  };
  const showUnstakeEsGmyModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake esGMY`);
    let maxAmount = processedData.esGmyInStakedGmy;
    if (
      processedData.esGmyInStakedGmy &&
      vestingData &&
      vestingData.gmyVesterPairAmount.gt(0) &&
      maxUnstakeableGmy &&
      maxUnstakeableGmy.lt(processedData.esGmyInStakedGmy)
    ) {
      maxAmount = maxUnstakeableGmy;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.gmyVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("esGMY");
    setUnstakeMethodName("unstakeEsGmy");
  };
  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeModalReservedAmount, setUnstakeModalReservedAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");
  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const showUnstakeGmyModal = () => {
    if (!isGmyTransferEnabled) {
      helperToast.error(`GMY transfers not yet enabled`);
      return;
    }
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake GMY`);
    let maxAmount = processedData.gmyInStakedGmy;
    if (
      processedData.gmyInStakedGmy &&
      vestingData &&
      vestingData.gmyVesterPairAmount.gt(0) &&
      maxUnstakeableGmy &&
      maxUnstakeableGmy.lt(processedData.gmyInStakedGmy)
    ) {
      maxAmount = maxUnstakeableGmy;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.gmyVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("GMY");
    setUnstakeMethodName("unstakeGmy");
  };
  return (
    <div className={`earn-content-container ${lightThemeClassName}`}>
      <div className="tab-content-title">GMY & esGMY</div>
      <div className="tab-content-desc">Earn rewards by staking GMY and esGMY tokens.</div>
      <div className="tab-main-content">
        <div className="main-content left-content">
          <div className="left-box-content main-content right-content">
            <div className="right-content-border-box">
              <div className="box-main-price">
                <div className="price-info">
                  <div className="title">GMY price</div>
                  <div className="value">
                    {gmyPrice ? (
                      "$" + formatAmount(gmyPrice, USD_DECIMALS, 3, true)
                    ) : (
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    )}
                  </div>
                </div>
                <img src={TokenIcon} alt="gmy-tokens" />
              </div>
              <div className="buy-btn-container">
                <Link className="default-btn" to="/buy_gmy">
                  <span>Buy GMY</span>
                </Link>
              </div>
              <div className="box-devider">
                <div className="divider-title">GMY APR</div>
                <div className="divider-line" />
              </div>
              <div className="box-apr">
                {processedData.gmyAprTotalWithBoost ? (
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "gmyAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => (
                      <GMYAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                )}
              </div>
              <div className="box-token-info">
                <div className="title">Total Staked</div>
                <div className="value">
                  {totalGmyStaked ? (
                    // formatAmount(totalGmyStaked, 18, 0, true) +
                    // " GMY" +
                    // ` ($${formatAmount(stakedGmySupplyUsd, USD_DECIMALS, 2, true)})`
                    <>
                      <Tooltip
                        position="right-bottom"
                        className="nowrap"
                        handle={<>{formatAmount(totalGmyStaked, 18, 0, true)} GMY</>}
                        renderContent={() => (
                          <StatsTooltip
                            showDollar={false}
                            title={`Staked`}
                            opValue={opGmyStaked}
                            // opLegacyValue={opGmyStakedLegacy}
                            arbValue={arbitrumGmyStaked}
                            baseValue={baseGmyStaked}
                            fantomValue={fantomGmyStaked}
                            // fantomLegacyValue={fantomGmyStakedLegacy}
                            total={totalGmyStaked}
                            decimalsForConversion={18}
                            symbol="GMY"
                          />
                        )}
                      />
                      <span>${formatAmount(stakedGmySupplyUsd, USD_DECIMALS, 0, true)}</span>
                    </>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="box-token-info">
                <div className="title">Total Supply</div>
                <div className="value">
                  {totalGmySupply ? (
                    <>
                      {formatAmount(totalGmySupply, 18, 0, true)} GMY{" "}
                      <span>${formatAmount(totalSupplyUsd, USD_DECIMALS, 0, true)}</span>
                    </>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="box-token-info">
                <div className="title">MPs APR</div>
                <div className="value">
                  <Tooltip
                    handle={`100.00%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <span>
                          Boost your rewards with Multiplier Points.&nbsp;
                          <ExternalLink href="https://docs.yummy.fi/rewards#multiplier-points">
                            More info
                          </ExternalLink>
                          .
                        </span>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="box-token-info">
                <div className="title">Boost Percentage</div>
                <div className="value">
                  {processedData.boostBasisPoints ? (
                    <Tooltip
                      handle={`${formatAmount(processedData.boostBasisPoints, 2, 2, false)}%`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            You are earning{" "}
                            <span style={{ fontWeight: 700, color: "white" }}>
                              {formatAmount(processedData.boostBasisPoints, 2, 2, false)}%
                            </span>{" "}
                            more {nativeTokenSymbol} rewards using{" "}
                            <span style={{ fontWeight: 700, color: "white" }}>
                              {formatAmount(processedData.bnGmyInFeeGmy, 18, 4, 2, true)}
                            </span>{" "}
                            Staked Multiplier Points.
                            <br />
                            <div style={{ marginTop: "8px" }}>
                              <span>Use the "Compound" button to stake your Multiplier Points.</span>
                            </div>
                          </div>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="box-devider">
                <div className="divider-title">esGMY APR</div>
                <div className="divider-line" />
              </div>
              <div className="box-apr">
                {processedData.gmyAprTotalWithBoost ? (
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "gmyAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => (
                      <GMYAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                )}
              </div>
              <div className="box-token-info">
                <div className="title">Total Staked</div>
                <div className="value">
                  {totalEsGmyStaked ? (
                    <>
                      <Tooltip
                        position="right-bottom"
                        className="nowrap"
                        handle={<>{formatAmount(totalEsGmyStaked, 18, 0, true)} esGMY</>}
                        renderContent={() => (
                          <StatsTooltip
                            showDollar={false}
                            title={`Staked`}
                            opValue={opEsGmyStaked}
                            fantomValue={fantomEsGmyStaked}
                            // fantomLegacyValue={fantomEsGmyStakedLegacy}
                            arbValue={arbitrumEsGmyStaked}
                            baseValue={baseEsGmyStaked}
                            total={totalEsGmyStaked}
                            decimalsForConversion={18}
                            symbol="esGMY"
                          />
                        )}
                      />
                      <span>${formatAmount(stakedEsGmySupplyUsd, USD_DECIMALS, 0, true)}</span>
                    </>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="box-token-info">
                <div className="title">Total Supply</div>
                <div className="value">
                  {esGmySupply ? (
                    <>
                      {formatAmount(esGmySupply, 18, 0, true)} esGMY
                      <span>${formatAmount(esGmySupplyUsd, USD_DECIMALS, 0, true)}</span>
                    </>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="box-token-info">
                <div className="title">MPs APR</div>
                <div className="value">
                  <Tooltip
                    handle={`100%`}
                    position="right-bottom"
                    renderContent={() => (
                      <GMYAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="left-box-content">
            <div className="main-title-container">
              <div className="main-title">GMY</div>
            </div>
            <div className="box-desc">Stake GMY to earn {nativeTokenSymbol}, esGMY, and MPs.</div>
            <div className="rewards-es-container">
              <div className="account-info">
                <div className="reward-info">
                  <div className="title">Wallet</div>
                  {processedData?.gmyBalance ? (
                    <div className="value">
                      {formatKeyAmountFixed(processedData, "gmyBalance", 18, 2, true)} GMY{" "}
                      <span>${formatKeyAmountFixed(processedData, "gmyBalanceUsd", USD_DECIMALS, 2, true)}</span>
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    </div>
                  )}
                </div>
                <div className="reward-info">
                  <div className="title">Staked</div>
                  {processedData?.gmyInStakedGmy ? (
                    <div className="value">
                      {formatKeyAmount(processedData, "gmyInStakedGmy", 18, 2, true)} GMY{" "}
                      <span>${formatKeyAmount(processedData, "gmyInStakedGmyUsd", USD_DECIMALS, 2, true)}</span>
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    </div>
                  )}
                </div>
              </div>
              <div className="button-actions">
                {account && (
                  <button onClick={() => showStakeGmyModal()} className="App-button-option App-card-option">
                    <span>Stake</span>
                  </button>
                )}
                {account && (
                  <button
                    onClick={() => showUnstakeGmyModal()}
                    className="App-button-option App-card-option border-btn"
                  >
                    Unstake
                  </button>
                )}
                {!account && (
                  <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="left-box-content">
            <div className="main-title-container">
              <div className="main-title">esGMY</div>
            </div>
            <div className="box-desc">Stake esGMY to earn {nativeTokenSymbol}, esGMY, and MPs.</div>
            <div className="rewards-es-container">
              <div className="account-info">
                <div className="reward-info">
                  <div className="title">Wallet</div>
                  {processedData.esGmyBalance ? (
                    <div className="value">
                      {formatKeyAmountFixed(processedData, "esGmyBalance", 18, 2, true)} esGMY{" "}
                      <span>${formatKeyAmountFixed(processedData, "esGmyBalanceUsd", USD_DECIMALS, 2, true)}</span>
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    </div>
                  )}
                </div>
                <div className="reward-info">
                  <div className="title">Staked</div>
                  {processedData?.esGmyInStakedGmy ? (
                    <div className="value">
                      {formatKeyAmount(processedData, "esGmyInStakedGmy", 18, 2, true)} esGMY{" "}
                      <span>${formatKeyAmount(processedData, "esGmyInStakedGmyUsd", USD_DECIMALS, 2, true)}</span>
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    </div>
                  )}
                </div>
              </div>
              <div className="button-actions">
                {account && (
                  <button onClick={() => showStakeEsGmyModal()} className="App-button-option App-card-option">
                    <span>Stake</span>
                  </button>
                )}
                {account && (
                  <button
                    onClick={() => showUnstakeEsGmyModal()}
                    className="App-button-option App-card-option border-btn"
                  >
                    Unstake
                  </button>
                )}
                {!account && (
                  <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="main-content left-content right-content">
          <div className="left-box-content">
            <div className="main-title-container">
              <div className="main-title">Pending rewards</div>
              {/* <div className="text-button-green">
                <Link to="/earn-v2/">Claim page</Link>
              </div> */}
            </div>
            <div className="green-value green-pending">
              $
              {formatAddKeysAmount(
                processedData,
                "feeGmyTrackerRewardsUsd",
                "stakedGmyTrackerRewardsUsd",
                USD_DECIMALS,
                2,
                true
              )}
            </div>
            <div className="rewards-container  pending-rewards-content">
              <div className="reward-info">
                <div className="title">
                  {nativeTokenSymbol} ({wrappedTokenSymbol})
                </div>
                {processedData?.feeGmyTrackerRewards ? (
                  <div className="value">
                    {formatKeyAmount(processedData, "feeGmyTrackerRewards", 18, 4, true)}{" "}
                    <span>${formatKeyAmount(processedData, "feeGmyTrackerRewardsUsd", USD_DECIMALS, 2, true)}</span>
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                  </div>
                )}
              </div>
              <div className="reward-info">
                <div className="title">esGMY</div>
                {processedData?.stakedGmyTrackerRewards ? (
                  <div className="value">
                    {formatKeyAmount(processedData, "stakedGmyTrackerRewards", 18, 4, true)}{" "}
                    <span>${formatKeyAmount(processedData, "stakedGmyTrackerRewardsUsd", USD_DECIMALS, 2, true)}</span>
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                  </div>
                )}
              </div>
            </div>
            <div className={`btns no-chain`}>
              <Link to="/earn-v2/" className="border-btn full">
                Claim page
              </Link>
            </div>
          </div>
        </div>
      </div>
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        farmAddress={stakingFarmAddress}
        rewardRouterAddress={rewardRouterAddress}
        stakeMethodName={stakeMethodName}
        hasMultiplierPoints={hasMultiplierPoints}
        setPendingTxns={setPendingTxns}
        nativeTokenSymbol={nativeTokenSymbol}
        wrappedTokenSymbol={wrappedTokenSymbol}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={unstakeModalMaxAmount}
        reservedAmount={unstakeModalReservedAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={unstakingTokenSymbol}
        rewardRouterAddress={rewardRouterAddress}
        unstakeMethodName={unstakeMethodName}
        multiplierPointsAmount={multiplierPointsAmount}
        bonusGmyInFeeGmy={bonusGmyInFeeGmy}
      />
    </div>
  );
};
export default GMYandESGMY;
const Warning = styled.div`
  margin: 8px 0;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 19.6px;
`;

const StyledTokenSymbol = styled.div`
  font-size: 16px !important;
`;

const StyledInput = styled.input`
  font-weight: 700 !important;
`;
const StyledSectionTop = styled.div`
  > div {
    font-weight: 400 !important;
  }
`;
const ModalWrapper = styled.div`
  .Modal-body {
    padding-left: 24px !important;
    padding-right: 24px !important;

    @media (max-width: 767px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
      padding-top: 16px !important;
    }
  }
`;
