import { t } from "@lingui/macro";
import "./Footer.css";
import twitterIcon from "img/ic_twitter.svg";
import twitterIconLight from "img/ic_twitterLight.svg";
import linktreeIcon from "img/ic_linktree.svg";
import discordIcon from "img/ic_discord.svg";
import discordIconLight from "img/ic_discordLight.svg";
import emailIcon from "img/ic_email.svg";
import telegramIcon from "img/ic_telegram.svg";
import telegramIconLight from "img/ic_telegramLight.svg";
import githubIcon from "img/ic_github.svg";
import mediumIcon from "img/ic_medium.svg";
import mediumIconLight from "img/ic_mediumLight.svg";

type Link = {
  text: string;
  link: string;
  external?: boolean;
  isAppLink?: boolean;
};

type SocialLink = {
  link: string;
  name: string;
  icon: string;
  iconLight: string;
  disabled: boolean;
};

export const FOOTER_LINKS: { home: Link[]; app: Link[] } = {
  home: [
    // { text: `Terms and Conditions`, link: "/terms-and-conditions" },
    // { text: `Referral Terms`, link: "/referral-terms" },
    // { text: `Media Kit`, link: "#", external: true },
    // { text: "Jobs", link: "/jobs", isAppLink: true },
  ],
  app: [
    // { text: `Media Kit`, link: "#", external: true },
    // { text: "Jobs", link: "/jobs" },
  ],
};

export const SOCIAL_LINKS: SocialLink[] = [
  {
    link: "https://x.com/yummy_fi?s=21",
    name: "X",
    icon: twitterIcon,
    iconLight: twitterIconLight,
    disabled: false,
  },
  {
    link: "https://medium.com/@Yummy_Fi",
    name: "Medium",
    icon: mediumIcon,
    iconLight: mediumIconLight,
    disabled: false,
  },
  // { link: "https://github.com/gmy-io", name: "Github", icon: githubIcon, iconLight: githubIconLight },
  {
    link: "https://t.me/YummyFinancePortal",
    name: "Telegram",
    icon: telegramIcon,
    iconLight: telegramIconLight,
    disabled: false,
  },
  {
    link: "https://linktr.ee/Yummy.Fi",
    name: "LinkTree",
    icon: linktreeIcon,
    iconLight: linktreeIcon,
    disabled: false,
  },
  // { link: "/#", name: "Discord", icon: discordIcon, iconLight: discordIconLight, disabled: true },
  // { link: "mailto:contact@yummy.fi", name: "contact", icon: emailIcon, iconLight: emailIconLight },
];
