const ToastSuccessIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.9923 9.3502 20.9363 6.81113 19.0626 4.93743C17.1889 3.06373 14.6498 2.00769 12 2V2ZM10.3333 16.5117L5.82167 12L7 10.8217L10.3333 14.155L17 7.48833L18.1783 8.66667L10.3333 16.5117Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ToastSuccessIcon;
