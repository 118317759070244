function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <path
        fill="#57FF97"
        d="M5.878 3.333a1.667 1.667 0 101.442 2.5h1.892V10h-5V8.334H2.545v8.333h1.667V15h10v1.667h5v-3.334A3.346 3.346 0 0015.878 10h-5V5.833h1.893a1.666 1.666 0 10-.002-1.666h-1.89v-.833H9.211v.833H7.319a1.667 1.667 0 00-1.44-.833zm-1.666 8.334h11.666c.93 0 1.667.736 1.667 1.667V15h-1.667v-1.666H4.212v-1.667z"
      ></path>
    </svg>
  );
}

export default Icon;
