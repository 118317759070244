import { formatNumberWithNegative } from "lib/numbers";
import { useMemo } from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import styled from "styled-components";
import { formatTimestamp2LocalDate } from "utils/formatDate";

interface Props {
  data: [string | number, number][];
  isSwapToUsd?: boolean;
}

const CustomTooltip = (props) => {
  const { active, payload, label, isSwapToUsd } = props;
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload.map((p) => {
          const { payload: data } = p;
          const dateFormat = formatTimestamp2LocalDate(Number(data.timestamp) * 1000, "YYYY-MM-DD hh:mm");
          return (
            <div className="data-group" key={p.dataKey}>
              <div className="value">{dateFormat}</div>
              <div className="data-row">
                <div
                  className="dot"
                  style={{
                    width: 16,
                    height: 16,
                    backgroundColor: "#176CE5",
                  }}
                />
                <div className="data">
                  {Number(data[p.dataKey]) < 0 ? "-" : ""}
                  {isSwapToUsd && "$"}
                  {formatNumberWithNegative(Math.abs(data[p.dataKey]), 2)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

const SparklinesChart = (props: Props) => {
  const { data, isSwapToUsd } = props;

  const chartData = useMemo(() => {
    let convertData: any = [];
    if (data && data.length > 0) {
      convertData = data.map(([value, timestamp]) => {
        return {
          value,
          timestamp,
        };
      });
    } else {
      convertData = [
        {
          value: 0,
          timestamp: 0,
        },
        {
          value: 0,
          timestamp: 0,
        },
      ];
    }
    return convertData;
  }, [data]);
  const CustomShape = (props) => {
    const { points, color } = props;

    // Modify points to create a pointed shape
    const modifiedPoints = [
      points[0],
      { ...points[1], y: points[1].y - 10 }, // Adjust the second point to create a pointed shape
      points[2],
      points[3],
    ];

    return <polygon points={modifiedPoints.map((point) => `${point.x},${point.y}`).join(" ")} fill={color} />;
  };

  return (
    <ChartWrapper>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={chartData} stackOffset="sign">
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#176CE5" stopOpacity={0.6} />
              <stop offset="95%" stopColor="#176CE5" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Tooltip content={<CustomTooltip isSwapToUsd={isSwapToUsd} />} />
          <Area
            type="monotone"
            dataKey="value"
            stroke="#176CE5"
            fillOpacity={1}
            fill="url(#colorUv)"
            // shapeRendering={(<CustomShape />) as any}
          />
        </AreaChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

const ChartWrapper = styled.div`
  height: 130px;

  .custom-tooltip {
    font-size: var(--font-sm);
    line-height: 140%;
    border-radius: 4px;
    padding: 1.05rem;
    letter-spacing: 0.4px;
    text-align: left;
    background: var(--background-pop-over-trade-background, #302f2f);
    .data-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .data-row {
      display: flex;
      align-items: center;
    }

    .label {
      color: white;
      font-weight: 500;
    }
  }
`;

export default SparklinesChart;
