function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <path
        fill="#FFF757"
        d="M10.878.833C7.81.833 5.31 3.35 5.31 6.433c0 2.837 2.147 5.098 4.872 5.458v3.666a5.732 5.732 0 00-.742-.486c.194-.917-.072-1.787-.664-2.298-.64-.553-1.502-.74-2.402-.74-1.686 0-3.377.971-3.377.971l-.451.242.095.506s.265 1.443 1.354 2.771a3.008 3.008 0 002.224 1.108c.366.012.704-.039 1.003-.145.3-.107.578-.244.789-.576l-1.173-.754c.065-.104.034-.032-.08.01a1.568 1.568 0 01-1.688-.533 4.93 4.93 0 01-.898-1.614c.529-.233 1.405-.585 2.202-.585.687 0 1.239.18 1.495.401.194.168.306.331.27.67-.738-.254-1.314-.36-1.314-.36l-.244 1.378s.968.178 1.91.631c.47.227.92.52 1.225.85.304.33.466.663.466 1.1v.23H11.577v-.23c0-.437.162-.77.466-1.1.304-.33.754-.623 1.225-.85a8.573 8.573 0 011.91-.631l-.245-1.379s-.575.107-1.313.361c-.036-.339.075-.502.269-.67.257-.221.809-.402 1.495-.402.797 0 1.673.353 2.203.586a4.93 4.93 0 01-.898 1.613 1.568 1.568 0 01-1.688.533c-.114-.04-.146-.113-.08-.01l-1.173.755c.21.332.489.47.788.576.3.106.638.157 1.003.145a3.009 3.009 0 002.224-1.108c1.09-1.328 1.354-2.77 1.354-2.77l.096-.507-.452-.242s-1.69-.97-3.377-.97c-.9 0-1.762.186-2.402.74-.592.51-.858 1.38-.663 2.297-.254.14-.505.302-.745.487v-3.667c2.725-.36 4.872-2.621 4.872-5.457 0-3.085-2.501-5.6-5.568-5.6zm0 1.4a4.178 4.178 0 014.176 4.2c0 2.328-1.861 4.2-4.176 4.2a4.178 4.178 0 01-4.176-4.2c0-2.327 1.861-4.2 4.176-4.2zm-.695.7v.847c-.767.182-1.392.782-1.392 1.603 0 .959.787 1.75 1.74 1.75h.696c.2 0 .348.15.348.35 0 .201-.148.35-.348.35H8.791v1.4h1.392v.7h1.392v-.846c.767-.182 1.392-.781 1.392-1.603 0-.959-.787-1.75-1.74-1.75h-.696a.34.34 0 01-.348-.35c0-.202.147-.35.347-.35h1.741v-1.4h-.696v-.7h-1.392z"
      ></path>
    </svg>
  );
}

export default Icon;
