import React from "react";

const ChevronLeftIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4 7.99984L10 12.6186L10 3.38104L4 7.99984Z" fill="white" />
    </svg>
  );
};

export default ChevronLeftIcon;
