import axios from "axios";

import { getServerBaseUrl } from "config/backend";
import { parseValue } from "lib/numbers";
import { useEffect, useState } from "react";

function useMergedPositionInfo(chainId, posId) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setIsLoading(true);
    async function getPositionInfo() {
      try {
        const { data: response } = await axios.get(`https://api.yummy.fi/stats/bartio/position-detail/${posId}`, {
          cancelToken: source.token,
        });

        setData(formatPositionInfo(response.data) || {});
      } catch (error) {
        if (axios.isCancel(error)) {
          console.error("Request canceled", error.message);
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    }

    getPositionInfo();
    const timerId = setInterval(getPositionInfo, 30000);

    return () => {
      source.cancel();
      clearInterval(timerId);
    };
  }, [posId, chainId]);

  return {
    data,
    isLoading,
  };
}

function formatPositionInfo(raw) {
  //String -> BigNumber
  const keysNeedFormat = [
    "size",
    "collateral",
    "pendingPnl",
    "pnlAfterFee",
    "netPnl",
    "entryPrice",
    "marketPrice",
    "liquidPrice",
    "accruedPositionFee",
    "accruedFundingFee",
    "accruedBorrowFee",
    "fee",
    "paidPositionFee",
    "paidFundingFee",
    "paidBorrowFee",
  ];
  const formatted = Object.entries(raw).map(([key, rawValue]) => {
    if (keysNeedFormat.includes(key)) {
      return [key, stringToBigNumber(rawValue)];
    }

    return [key, rawValue];
  });

  const formattedObj = Object.fromEntries(formatted);
  const isV2 = formattedObj.type === "V2";

  const isClosed = !raw.isOpenning;

  //Caculate
  // let pnlAfterFee;
  // if (raw.positionStatus === "OPEN") {
  //   pnlAfterFee = formattedObj.netPnl;
  // }

  // let liquidationPrice = formattedObj.liquidationPrice;

  //if is closed position
  // if (isClosed) {
  // liquidationPrice = null;
  // pnlAfterFee = formattedObj.realisedPnl;
  // }

  // const paidFees = formattedObj.paidPositionFee.add(formattedObj.paidBorrowFee).add(formattedObj.paidFundingFee);
  // const pnlWithoutFee = formattedObj.pnlAfterFee.add(formattedObj.accruedFundingFee).add(formattedObj.accruedBorrowFee);

  const pendingPnl = isV2 ? formattedObj.netPnl : formattedObj.pendingPnl;
  return {
    ...formattedObj,
    pendingPnl,
    isV2,
    isClosed,
  };

  function stringToBigNumber(string) {
    return string ? parseValue(string, 30) : undefined;
  }
}

export default useMergedPositionInfo;
