import axios from "axios";
import { useChainId } from "lib/chains";
import { useEffect, useRef, useState } from "react";
import { getServerBaseUrl, getServerUrl } from "config/backend";
import { FANTOM, SUPPORTED_V2_CHAINS } from "config/chains";

export const DEFAULT_PAGE_SIZE_POSITIONS = 10;

const useUserPosition = (account, params) => {
  const { pageSize = DEFAULT_PAGE_SIZE_POSITIONS } = params || {};
  const [positions, setPositions] = useState([]);
  const [positionsDataIsLoading, setPositionsDataIsLoading] = useState(true);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const { chainId } = useChainId();
  var timeout = useRef(null);
  var oldAccount = useRef(null);
  useEffect(() => {
    const fetchPositions = async () => {
      const { data: responseData } = await axios.get(
        `${getServerBaseUrl(chainId, true)}/public/open_positions/${account}`,
        {
          params: {
            page,
            pageSize,
          },
        },
      );

      if (responseData?.data) {
        const { rows = [], count } = responseData?.data;
        setPositions(rows);
        setCount(count);
        setPositionsDataIsLoading(false);
      }
    };
    if (account && SUPPORTED_V2_CHAINS.includes(chainId)) {
      if (oldAccount.current !== account) oldAccount.current = account
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
      fetchPositions();
      const interval = setInterval(async () => {
        fetchPositions();
      }, 5000);
      return () => clearInterval(interval);

    } else {
      if (!oldAccount.current) {
        timeout.current = setTimeout(() => {
          setPositions([]);
          setCount(0);
          setPositionsDataIsLoading(false);
        }, 3500)
      }
      else {
        setPositions([]);
        setCount(0);
        setPositionsDataIsLoading(false);
      }

    }
  }, [account, chainId, page, pageSize]);

  return { positions, count, currentPage: page, setCurrenPage: setPage, positionsDataIsLoading };
};
export default useUserPosition;
