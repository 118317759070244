import React, { useState, useRef, useEffect } from "react";
import "./ConfirmationBox.css";
import { BASIS_POINTS_DIVISOR, LIMIT, STOP_LIMIT, STOP_MARKET, USD_DECIMALS, importImage } from "lib/legacy";

import Modal from "../Modal/Modal";
import cx from "classnames";

import ExchangeInfoRow from "./ExchangeInfoRow";
import Tooltip from "components/Tooltip/Tooltip";
import { formatAmount, parseValue } from "lib/numbers";
import { helperToast } from "lib/helperToast";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import iconEdit from "img/icons/ic-edit.svg";
import { useMedia } from "react-use";

import { Spin } from "antd";
import { THEME_KEY } from "config/localStorage";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "grey" }} spin />;

const DEFAULT_LIST_SLIPPAGE = [0.1, 0.5, 1, 2];

export default function ConfirmationBox(props) {
  const {
    isSwap,
    isLong,
    orderOption,
    onConfirmationClick,
    setIsConfirming,
    leverage,
    spread,
    openPosFee,
    markPrice,
    posQty,
    posSize,
    liqPrice,
    isSubmitting,
    isMarketOrder,
    isPendingConfirmation,
    takeProfitTriggerPrice,
    stopLossTriggerPrice,
    isTakeProfit,
    isStopLoss,
    savedSlippageAmount,
    setSavedSlippageAmount,
    chartSymbol,
    isLimitOrder,
    limitPrice,
    isStopOrder,
    stopPrice,
    isStopLimitOrder,
    currentMarket,
    collateral,
  } = props;
  const slippage = parseInt(savedSlippageAmount);

  const [slippageAmount, setSlippageAmount] = useState((slippage / BASIS_POINTS_DIVISOR) * 100);
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const ref = useRef(null);

  const handleClick = () => {
    ref.current.focus();
  };
  const isPad = useMedia("(max-width: 1350px)");

  useEffect(() => {
    if (isSettingsVisible) {
      const slippage = savedSlippageAmount / 100;
      setSlippageAmount(slippage);
    }
  }, [isSettingsVisible]);

  const openSettings = () => {
    setIsSettingsVisible(true);
  };

  const saveAndCloseSettings = () => {
    const slippage = parseFloat(slippageAmount);
    if (isNaN(slippage)) {
      helperToast.error(`Invalid slippage value`);
      return;
    }
    if (slippage > 5) {
      helperToast.error(`Slippage should be less than 5%`);
      return;
    }
    const basisPoints = (slippage * BASIS_POINTS_DIVISOR) / 100;
    if (parseInt(basisPoints) !== parseFloat(basisPoints)) {
      helperToast.error(`Max slippage precision is 0.01%`);
      return;
    }

    setSavedSlippageAmount(basisPoints);
    setIsSettingsVisible(false);
  };

  const renderMarginSection = () => {
    return (
      <>
        <div>
          <div className="order-details-container">
            <div className="square-container">
              <DivStyled>
                <ExchangeInfoRow label={`Pos. Size`}>${formatAmount(posSize, 5, 2, true)}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Qty`}>
                  {formatAmount(posQty, 5, 3, true)} {chartSymbol}
                </ExchangeInfoRow>
              </DivStyled>

              {collateral && <ExchangeInfoRow label={`Collateral`}>${collateral}</ExchangeInfoRow>}
              <DividerStyled />

              {!isLimitOrder && !isStopOrder && !isStopLimitOrder && (
                <ExchangeInfoRow label={`Entry Price`}>
                  ${formatAmount(markPrice, USD_DECIMALS, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
              )}
              {(isLimitOrder || isStopLimitOrder) && (
                <ExchangeInfoRow label={`Limit Price`}>
                  ${formatAmount(parseValue(limitPrice, 30), 30, currentMarket?.decimals, true)}
                </ExchangeInfoRow>
              )}
              {(isStopOrder || isStopLimitOrder) && (
                <ExchangeInfoRow label={`Stop Price`}>
                  ${formatAmount(parseValue(stopPrice, 30), 30, currentMarket?.decimals, true)}
                </ExchangeInfoRow>
              )}
              {isTakeProfit && (
                <ExchangeInfoRow label={`Take Profit Price`}>
                  ${formatAmount(parseValue(takeProfitTriggerPrice, 30), 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
              )}
              {isStopLoss && (
                <ExchangeInfoRow label={`Stop Loss Price`}>
                  ${formatAmount(parseValue(stopLossTriggerPrice, 30), 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
              )}
              <ExchangeInfoRow label={`Liq. Price`}>
                ${formatAmount(liqPrice, USD_DECIMALS, currentMarket?.decimals, true)}
              </ExchangeInfoRow>
              <ExchangeInfoRow label={`Fees`}>
                <Tooltip
                  handle={<span>${formatAmount(openPosFee, 10, 3, true)}</span>}
                  position={"right-top"}
                  className="fit-content nowrap"
                  handleClassName={cx("plain", "")}
                  renderContent={() => {
                    return (
                      <span>
                        Position Fee (0.08% of position size):{" "}
                        <span style={{ color: "#fff", fontWeight: 700 }}>
                          {" "}
                          ${openPosFee ? +formatAmount(openPosFee, 10, 3, true) : "--"}{" "}
                        </span>
                      </span>
                    );
                  }}
                />{" "}
                {/* <Tooltip
                  placement={isPad ? "topRight" : "top"}
                  autoAdjustOverflow={isPad ? true : false}
                  overlayClassName={`custom-popup-antd-tooltip `}
                  className="custom-antd-tooltip"
                  title={<div className="customTooltip">....</div>}
                >
                  <span>
                    ${formatAmount(openPosFee, 10, 3, true)} <img src={infoIcon} alt="info icon" />
                  </span>
                </Tooltip>{" "} */}
              </ExchangeInfoRow>
              {/* <ExchangeInfoRow label={`Fee Discount (0%)`}>~${formatAmount(feeDiscount, 18, 3, true)}</ExchangeInfoRow> */}
              {/* <ExchangeInfoRow label={`Fee Discount (--%)`}>$--</ExchangeInfoRow> */}
              <ExchangeInfoRow label={`Spread`}>{formatAmount(spread, 30, 3, true)}%</ExchangeInfoRow>
            </div>
          </div>
          {!isLimitOrder && !isStopOrder && !isStopLimitOrder && (
            <>
              <DividerStyled />
              <div className="allow-slippaged-line">
                <ExchangeInfoRow label={`Slippage`}>
                  <div className="allow-slippage-container">
                    <input
                      ref={ref}
                      type="number"
                      className="App-slippage-tolerance-input"
                      min="0"
                      value={slippageAmount}
                      onChange={(e) => setSlippageAmount(e.target.value)}
                    />
                    <div>%</div>
                    <img alt="icon-edit" onClick={openSettings} src={iconEdit} className="icon-edit" />
                  </div>
                </ExchangeInfoRow>
              </div>
            </>
          )}
          {/* {renderExecutionFee()} */}
        </div>
      </>
    );
  };

  const getTitle = () => {
    if (!isMarketOrder) {
      if (orderOption === LIMIT) return `Confirm Limit Order`;
      if (orderOption === STOP_LIMIT) return `Confirm Stop Limit`;
      if (orderOption === STOP_MARKET) return `Confirm Stop Market`;
    }
    return isLong ? `Confirm Long` : `Confirm Short`;
  };

  const getPrimaryText = () => {
    if (!isPendingConfirmation) {
      const action = isMarketOrder ? (isLong ? `Long` : `Short`) : `Create Order`;
      return action;
    }
    if (isMarketOrder) {
      if (isLong) {
        return `Longing...`;
      }
      return `Shorting...`;
    } else return "Creating...";
  };
  const isPrimaryEnabled = () => {
    return !isPendingConfirmation && !isSubmitting;
  };
  const handleConfirmClick = async () => {
    const slippage = parseFloat(slippageAmount);
    if (isNaN(slippage)) {
      helperToast.error(`Invalid slippage value`);
      return;
    }
    if (slippage > 5) {
      helperToast.error(`Slippage should be less than 5%`);
      return;
    }
    const basisPoints = (slippage * BASIS_POINTS_DIVISOR) / 100;
    if (parseInt(basisPoints) !== parseFloat(basisPoints)) {
      helperToast.error(`Max slippage precision is 0.01%`);
      return;
    }
    await setSavedSlippageAmount(basisPoints);
    onConfirmationClick && onConfirmationClick();
  };
  return (
    <div className="Confirmation-box">
      <Modal
        isVisible={true}
        setIsVisible={() => setIsConfirming(false)}
        label={getTitle()}
        allowContentTouchMove
        className={`confirmation-modal`}
      >
        <div className="order-title-line order-title-line-details">
          <div className="position-info-container">
            <div className="position-info">
              <img className="small" src={importImage("ic_" + chartSymbol.toLowerCase() + "_24.svg")} alt="" />
              <div className="title">{chartSymbol}</div>
            </div>
            <div className="position-tags">
              <div className={`side ${isLong ? "side-long" : "side-short"}`}>{isLong ? "LONG" : "SHORT"}</div>
              <div className="side">{orderOption}</div>
              <div className="position-list-leve">{leverage}x</div>
            </div>
          </div>
        </div>
        <div className="content-container mobile-padding-top">{renderMarginSection()}</div>
        <div className="Confirmation-box-row">
          <button
            onClick={handleConfirmClick}
            className={`App-cta Confirmation-box-button ${isLong ? "long-btn-v2" : "short-btn-v2"}`}
            disabled={!isPrimaryEnabled()}
          >
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
      <Modal
        className="App-settings"
        isVisible={isSettingsVisible}
        setIsVisible={setIsSettingsVisible}
        label={`Settings`}
      >
        <div className="settings-row-container">
          <div className="setting-title">Allowed Slippage</div>
          <div className="allowed-slippage">
            {DEFAULT_LIST_SLIPPAGE.map((item) => (
              <button
                className={`slippage-tab ${item === Number(slippageAmount) ? "slippage-tab--active" : ""}`}
                key={item}
                disabled={item === Number(slippageAmount)}
                onClick={() => setSlippageAmount(item)}
              >
                {item}%
              </button>
            ))}
            <div className="App-slippage-tolerance-input-container">
              <input
                type="number"
                className="App-slippage-tolerance-input"
                min="0"
                placeholder="Custom"
                value={slippageAmount}
                onChange={(e) => setSlippageAmount(e.target.value)}
              />
              <div className="App-slippage-tolerance-input-percent">%</div>
            </div>
          </div>
        </div>

        <button
          style={{ marginTop: "24px" }}
          className="default-btn Exchange-swap-button"
          onClick={saveAndCloseSettings}
        >
          <span>Save</span>
        </button>
      </Modal>
    </div>
  );
}
const DivStyled = styled.div`
  border-radius: 16px;
  background: var(--Nature-1, #1a1919);
  display: flex;
  padding: 12px 16px;
  align-items: center;
  margin-bottom: 16px;
  > div {
    flex: 1;
    display: flex !important;
    flex-direction: column;
    .Exchange-info-label {
      margin-right: 0;
      margin-bottom: 8px;
    }
    .align-right {
      justify-content: flex-start;
    }
  }
`;

const DividerStyled = styled.div`
  width: 100%;
  height: 1px;
  margin: 4px 0 12px;
  background: var(--Border, rgba(239, 236, 235, 0.08));
`;
