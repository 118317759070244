import { useEffect, useState } from "react";
import axios from "axios";
import { getServerBaseUrl } from "config/backend";
import { configContest } from "./useLeaderboard";

export const START_TIMESTAMP = 1698278400;
export const END_TIMESTAMP = 1705536000;

export const DEFAULT_SORT = "netPnl DESC";

function useTopPositions(chainId, params = {}) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [sort, setSort] = useState(() => {
    const [field, type] = DEFAULT_SORT.split(" ");
    return {
      field,
      type,
    };
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    setIsLoading(true);
    async function getTopPositions() {
      try {
        const { data: response } = await axios(`${getServerBaseUrl(chainId, true)}/public/top-positions`, {
          params: {
            page: currentPage,
            pageSize: rowsPerPage,
            order: `${sort.field} ${sort.type}`,
            ...params,
            to: params?.toNow ? Date.now() / 1000 : configContest[chainId]?.END_TIMESTAMP,
          },
          cancelToken: source.token,
        });

        setData(response.data || {});
      } catch (error) {
        if (axios.isCancel(error)) {
          console.error("Request canceled", error.message);
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    }

    getTopPositions();
    const timerId = setInterval(getTopPositions, 30000);

    return () => {
      source.cancel();
      clearInterval(timerId);
    };
  }, [chainId, currentPage, rowsPerPage, sort, params.from]);

  const handleSort = (field, type = undefined) => {
    if (type) {
      setSort({ field, type });
    } else {
      setSort((prev) => {
        if (prev.field === field) {
          return {
            field,
            type: prev.type === "ASC" ? "DESC" : "ASC",
          };
        }

        return {
          field,
          type: "DESC",
        };
      });
    }
  };

  return {
    sort,
    handleSort,
    isLoading,
    data,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
  };
}

export default useTopPositions;
