import { useChainIdWithDefault } from "lib/chains";
import { useEffect, useState } from "react";
import styled from "styled-components";

import Footer from "components/Footer/Footer";
import { useThemeContext } from "contexts/ThemeProvider.js";
import { TXT } from "pages/NFT/multichainTxt.js";
import { Link, Route, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import "styles/AnalyticV1.scss";
import OverviewV1 from "pages/Analytics/components/Overview";
import OverviewV2 from "pages/AnalyticsV2/components/Overview";
import StatsProviderV2 from "contexts/StatsProviderV2";
import { StyledAnalyticsContent } from "./styles/AnalyticsV3.styled";
import { SUPPORTED_CHAIN_IDS, SUPPORTED_V2_CHAINS } from "config/chains";
import { StyledAnalyticsContentV2 } from "pages/AnalyticsV2/styles/AnalyticsV2.styled";
import { THEME_LIGHT } from "lib/legacy";
export default function AnalyticsSplits() {
  const { isLightTheme } = useThemeContext() as any;
  const location = useLocation();
  const { path } = useRouteMatch();
  const history = useHistory();
  // const { chainId } = useChainId();
  const chainId = useChainIdWithDefault({
    isUseDefaultChain: false,
  });
  const [activedTab, setActivedTab] = useState(location.pathname);

  useEffect(() => {
    setActivedTab(location.pathname);
  }, [location.pathname]);
  const Versions = [
    {
      name: "V1",
      links: ["/analytics/v1", "/analytics"],
      value: 1,
      href: "/analytics",
    },
    {
      name: "V2",
      links: ["/analytics/v2"],
      value: 2,
      href: "/analytics/v2",
    },
  ];

  const [curentVersion, setCurentVersion] = useState(1);
  useEffect(() => {
    if (history?.location?.pathname) {
      if (Versions[1].links.includes(history?.location?.pathname)) {
        if (curentVersion !== 2) setCurentVersion(2);
      } else {
        if (curentVersion !== 1) setCurentVersion(1);
      }
    }
  }, [history?.location?.pathname]);
  useEffect(() => {
    if (!SUPPORTED_V2_CHAINS.includes(chainId)) {
      if (history?.location?.pathname === "/analytics/v2") {
        history.push("/analytics");
      }
    }
  }, [chainId]);
  return (
    <div className={`analytic-v1-wrapper ${isLightTheme ? `analytic-v1-wrapper--light ${THEME_LIGHT}` : ""}`}>
      <HeadWraper $isThemeLight={isLightTheme}>
        <div className="analytic-container header">
          <Title>
            <div className="title-container">
              <span className="title-head">Analytics</span>
              <img src={TXT[chainId].icon} alt="img" />{" "}
            </div>
            <p className="desc">The comprehensive statistics and data visualization.</p>
            {SUPPORTED_V2_CHAINS.includes(chainId) && (
              <div className="version-container">
                {Versions.map((item) => (
                  <Link
                    to={item.href}
                    className={
                      item.value === curentVersion ? "version-content version-content--active" : "version-content"
                    }
                    key={item.value}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            )}
          </Title>
        </div>
      </HeadWraper>
      <Wrapper>
        <StyledAnalyticsContent>
          <Route path={`${path}/v1`}>
            <OverviewV1 />
          </Route>
        </StyledAnalyticsContent>
        {SUPPORTED_V2_CHAINS.includes(chainId) && (
          <Route path={`${path}/v2`}>
            <StatsProviderV2>
              <StyledAnalyticsContentV2 className="v2-container">
                <OverviewV2 />
              </StyledAnalyticsContentV2>
            </StatsProviderV2>
          </Route>
        )}

        <Route exact path={`${path}/`}>
          <OverviewV1 />
        </Route>

        <Footer />
      </Wrapper>
    </div>
  );
}
const Wrapper = styled.div`
  .analytic-container,
  .v2-container {
    width: 100%;
    max-width: calc(1320px + 48px);
    padding-left: 24px;
    padding-right: 24px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 700px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .v2-container {
    padding-bottom: 250px;
  }
`;
const TabWrapper = styled.div`
  width: 100%;
  max-width: calc(1320px + 48px);
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto 24px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-direction: column;
    padding-left: 20px;
  }

  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;

    > .tab-container {
      width: 100%;
    }

    > span {
      padding-left: 20px;
      margin-top: 10px;
      width: 100% !important;
    }
  }

  > span {
    flex: 1 45%;
    text-align: right;
    align-self: flex-end;
    color: #ffffff99 !important;

    @media (max-width: 1023px) {
      margin-top: 16px;
      padding-right: 20px;
    }
  }

  .tab-container {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    padding: 4px;
    background-color: #191b2e;
    border-radius: 0px 0px 16px 16px;

    &::-webkit-scrollbar {
      height: 2px;
    }

    &::-webkit-scrollbar-track-piece:end {
      margin-right: 10px;
    }

    &::-webkit-scrollbar-track-piece:start {
      margin-left: 10px;
    }

    @media (max-width: 1023px) {
      justify-content: space-between;
    }
  }
`;
const TabItem = styled(Link)`
  padding: 12px 16px;
  white-space: nowrap;

  font-size: 14px;
  text-decoration: none;
  font-weight: 500 !important;
  color: rgb(255, 255, 255);
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: #04d397 !important;
  }
  &.tab--actived {
    color: #03f5ae !important;
    background-color: #ffffff1a;
    border-radius: 12px;
  }
`;
const Title = styled.div`
  width: 1218px;
  max-width: 100%;
  text-transform: uppercase;
  align-self: flex-start;
  margin-top: 64px;

  @media (max-width: 767px) {
    padding-bottom: 24px;
  }

  .title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    @media (max-width: 1023px) {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  span {
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    color: rgb(255, 255, 255);

    @media (max-width: 1023px) {
      font-size: 24px;
      line-height: 140%;
    }
  }
  .version-styled {
    color: #03f5ae !important;
  }

  img {
    width: 48px;
    height: 48px;
  }

  .desc {
    text-transform: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.6) !important;
    margin-top: 4px;
    margin-bottom: 0;
  }
`;
const HeadWraper = styled.div<{ $isThemeLight: Boolean }>`
  width: 100%;
  overflow: hidden;
  position: relative;
  @media (max-width: 767px) {
    height: unset;
  }

  .header {
    width: 100%;
    max-width: calc(1320px + 48px);
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
    display: flex;
    position: relative;

    justify-content: space-between;
    align-items: flex-end;
    height: 100%;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
`;
const ImgContainer = styled.div`
  width: 376px;
  height: 225px;
  overflow: hidden;
  object-fit: contain;
  margin-left: auto;
  flex-shrink: 0;

  @media screen and (max-width: 767px) {
    height: 160px;

    img {
      height: 100%;
    }
  }
`;
