import axios from "axios";
import { getServerBaseUrl } from "config/backend";
import { SECONDS_PER_DAY } from "lib/legacy";
import { BigNumber } from "ethers";
import { parseValue } from "lib/numbers";

function getTypeOrder(key) {
  switch (key) {
    case "triggerOrders":
      return "TRIGGER";
    case "pendingOrders":
      return "PENDDING";
    case "trailingStops":
      return "TRAILING";
    default:
      return "UNKNOWN";
  }
}

const getTypeText = (type, isTP, position) => {
  if (type === "TRIGGER") {
    return isTP ? "Take Profit" : "Stop Loss";
  } else if (type === "PENDDING") return position.positionType;
  else return "Trailing Stop";
};

export async function getOpenPositions(chainId, account, pagination) {
  const { currentPage: page, pageSize } = pagination;

  const { data: response } = await axios(`https://api.yummy.fi/stats/bartio/trader/${account}/open-positions`, {
    params: {
      page,
      pageSize,
    },
  });
  return response.data;
}

export async function getClosedPositions(chainId, account, pagination) {
  const { currentPage: page, pageSize } = pagination;

  const { data: response } = await axios(`https://api.yummy.fi/stats/bartio/trader/${account}/closed-positions`, {
    params: {
      page,
      pageSize,
      order: "closedTime DESC",
    },
  });
  return response.data;
}

// export async function getOrders(chainId, account, pagination) {
//   const { currentPage: page, pageSize } = pagination;

//   const { data: response } = await axios(`https://api.yummy.fi/stats/bartio/trader/${account}/open-orders`, {
//     params: {
//       page,
//       pageSize,
//     },
//   });

//   const mergedData = {
//     count: 0,
//     rows: [],
//   };

//   for (const [key, orders] of Object.entries(response.data || {})) {
//     if (key === "triggerOrders") {
//       orders.forEach((order) => {
//         const { triggers } = order;

//         triggers.forEach((trigger) => {
//           const type = getTypeOrder(key);
//           const typeText = getTypeText(type, trigger.isTP, order);
//           const triggerOrder = {
//             type,
//             createdAt: trigger.createdAt,
//             typeText,
//             trigger,
//             ...order,
//           };

//           mergedData.rows = [...mergedData.rows, triggerOrder];
//           mergedData.count++;
//         });
//       });
//     } else {
//       const formattedOrders = orders.map((order) => {
//         const type = getTypeOrder(key);
//         const typeText = getTypeText(type, undefined, order);
//         let pendingLeverage;
//         //pendingOrders
//         if (key === "pendingOrders") {
//           const pendingSize = BigNumber.from(order.pendingSize);
//           const pendingCollateral = BigNumber.from(order.pendingCollateral);
//           pendingLeverage = pendingSize.mul(parseValue(1, 30)).div(pendingCollateral);
//         }
//         return { ...order, type, typeText, pendingLeverage };
//       });

//       mergedData.rows = [...mergedData.rows, ...formattedOrders];
//       mergedData.count += formattedOrders.length;
//     }
//   }
//   console.log(">> Check | mergedData:", mergedData);
//   return mergedData;
// }

export async function getOrders(chainId, account, pagination) {
  const { currentPage: page, pageSize } = pagination;

  const { data: response } = await axios(`https://api.yummy.fi/stats/bartio/trader/${account}/open-orders`, {
    params: {
      page,
      pageSize,
    },
  });
  return response.data;
}

export async function getTradeHistories(chainId, account, pagination, timestamp) {
  const { currentPage: page, pageSize } = pagination;
  const { from, to } = timestamp || {};
  const { data: response } = await axios(`https://api.yummy.fi/stats/bartio/trader/${account}/trade-activities`, {
    params: {
      page,
      pageSize,
      from,
      to: to ? to + SECONDS_PER_DAY : undefined,
    },
  });
  return response.data;
}
