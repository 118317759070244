import React, { useState } from "react";

import { importImage } from "lib/legacy";
import Tab from "../Tab/Tab";
import Modal from "../Modal/Modal";
import "./PositionSeller.css";
import { formatAmount, parseValue } from "lib/numbers";

import DepositCollateralV2 from "./DepositCollateralV2"; // $0.10
import WithdrawCollateralV2 from "./WithdrawCollateralV2";
const ADD_COLLATERAL = "Add Collateral";
const WITHDRAW_COLLATERAL = "Withdraw Collateral";
export default function EditCollateral(props) {
  const {
    handleEditPosition,
    userTokenBalances,
    isVisible,
    position,
    setIsVisible,
    chainId,
    library,
    marketTokensInfo,
    isConfirm,
    handleEditLeverage,
  } = props;
  const orderOPtions = [ADD_COLLATERAL, WITHDRAW_COLLATERAL];
  const [orderOption, setOrderOption] = useState(ADD_COLLATERAL);
  const ORDER_OPTION_LABELS = {
    [ADD_COLLATERAL]: "Deposit",
    [WITHDRAW_COLLATERAL]: "Withdraw",
  };
  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal confirmation-modal edit-collateral-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Edit Collateral"}
          allowContentTouchMove
          maxWidth="480px"
        >
          <div className="order-title-line order-title-line-details">
            <div className="position-info-container">
              <div className="position-info">
                <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
                <div className="title">{position.symbol}</div>
                <div className="position-id">#{position?.posId}</div>
              </div>
              <div className="position-tags">
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                </div>
                <div className="leverage-box">{formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X</div>
              </div>
            </div>
          </div>
          <div className="content-container">
            <Tab
              options={orderOPtions}
              option={orderOption}
              optionLabels={ORDER_OPTION_LABELS}
              onChange={onOrderOptionChange}
            />
            {orderOption === ADD_COLLATERAL ? (
              <DepositCollateralV2
                handleEditPosition={handleEditPosition}
                userTokenBalances={userTokenBalances}
                position={position}
                chainId={chainId}
                library={library}
                marketTokensInfo={marketTokensInfo}
                isConfirm={isConfirm}
              />
            ) : (
              <WithdrawCollateralV2
                handleEditPosition={handleEditLeverage}
                userTokenBalances={userTokenBalances}
                position={position}
                chainId={chainId}
                library={library}
                marketTokensInfo={marketTokensInfo}
                isConfirm={isConfirm}
              />
            )}
          </div>
        </Modal>
      )}
    </div>
  );
}
