import { useContext } from "react";
import CountUp from "react-countup";
import styled from "styled-components";

import { USD_DECIMALS } from "lib/legacy";
import { formatAmount, parseValue } from "lib/numbers";

import { ARBITRUM, BASE, BERACHAIN_BARTIO, FANTOM, OP, getChainName } from "config/chains";
import arb24Icon from "img/ic_arbitrum_24.svg";

import baseImg from "img/ic_base_40.svg";
import ftm24Icon from "img/ic_ftm_24.svg";
import opImg from "img/ic_op_40.svg";

import TooltipComponent from "components/Tooltip/Tooltip";
import longPosition from "img/dashboard/longPosition.svg";
import shortPosition from "img/dashboard/shortPosition.svg";
import since from "img/dashboard/since.svg";
import stats3 from "img/dashboard/tfee.svg";
import stats1 from "img/dashboard/tvl.svg";
import stats2 from "img/dashboard/tvolumn.svg";
import iconToken from "img/icons/ic-token.svg";
import { formatDate } from "lib/dates";
import { useMediaQuery } from "react-responsive";
import VolumeChart from "./VolumeChart";

import { StatsContext } from "contexts/StatsProvider";
import { useThemeContext } from "contexts/ThemeProvider";
import { BigNumber } from "ethers";

const STATS_CHAINS = [
  {
    chainId: FANTOM,
    img: ftm24Icon,
  },
  {
    chainId: OP,
    img: opImg,
  },
  {
    chainId: ARBITRUM,
    img: arb24Icon,
  },
  {
    chainId: BASE,
    img: baseImg,
  },
];

const Colors = ["#CAFF92", "#99E5FF", "#B1BEE9", "#CAFF92", "#FF99A6", "#FFA17E"];

const Stats = ({ chainId, active }) => {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const isTablet = useMediaQuery({ query: "(max-width: 1023px) and (min-width: 767px)" });
  const totalStatsStartDate = {
    [FANTOM]: `05 Dec 2022`,
    [OP]: `03 Mar 2023`,
    [ARBITRUM]: `29 Jun 2023`,
    [BASE]: `16 Oct 2023`,
    [BERACHAIN_BARTIO]: `14 Aug 2024`,
  };
  const chainName = getChainName(chainId);

  const {
    v2,
    totalAllVersion,
    totalValueLocked,
    totalFees,
    totalTradingVolume,
    longOpenInterest,
    shortOpenInterest,
    feeSinceToNow,
  } = useContext(StatsContext);

  return (
    <Wrapper className={lightThemeClassName}>
      <Title>
        <span>Berachain Stats</span> {<img src={iconToken} alt="24Icon" />}
      </Title>
      <Description>
        {/* {chainName}  */}
        Total Stats start from {totalStatsStartDate[chainId]}
        {/* . For detailed stats:{" "} */}.
      </Description>

      <StatsContainer>
        <StatBlock>
          <div className="stats-img">
            <img src={stats1} alt="img" />
          </div>
          <div className="stats-info total-value">
            <div className="label">Total Value Locked</div>
            <div className="stats-value" style={{ color: Colors[0] }}>
              {/**/}
              {totalValueLocked?.[chainId] ? (
                // <TooltipComponent
                //   position="left-bottom"
                //   handle={
                //     <CountUp
                //       end={
                //         chainId === FANTOM && totalValueLocked?.["totalV2"]
                //           ? parseFloat(
                //               formatAmount(
                //                 totalValueLocked?.[chainId]?.add(totalValueLocked?.["totalV2"]),
                //                 USD_DECIMALS,
                //                 0
                //               )
                //             )
                //           : formatAmount(
                //               totalValueLocked?.[chainId]?.add(totalValueLocked?.v2?.[chainId] || 0),
                //               USD_DECIMALS,
                //               0
                //             )
                //       }
                //       separator=","
                //       prefix="$"
                //     />
                //   }
                //   className="excep"
                //   renderContent={() => (
                //     <CustomContent>
                //       <div className="k-v-box">
                //         <div className="k-v-label">Total value locked</div>
                //         <div className="k-v-value">
                //           ${formatAmount(totalValueLocked?.["totalAll"], USD_DECIMALS, 0, true)}
                //         </div>
                //       </div>
                //       <div className="divider" />
                //       <div className="tooltip-row">
                //         <div className="k-v-box">
                //           <div className="k-v-label">
                //             Total value locked <span className="ver">V1</span>
                //           </div>
                //           <div className="k-v-value">
                //             ${formatAmount(totalValueLocked?.["total"], USD_DECIMALS, 0, true)}
                //           </div>
                //         </div>
                //         <div className="chains">
                //           {STATS_CHAINS.map((chain) => {
                //             return (
                //               <div key={chain.chainId} className="chain">
                //                 <img className="chain-img" src={chain.img} alt={chain.chainId} />
                //                 <div className="chain-value">
                //                   ${formatAmount(totalValueLocked?.[chain.chainId], USD_DECIMALS, 0, true)}
                //                 </div>
                //               </div>
                //             );
                //           })}
                //         </div>
                //       </div>
                //       <div className="divider" />
                //       <div className="tooltip-row">
                //         <div className="k-v-box">
                //           <div className="k-v-label">
                //             Total value locked <span className="ver v2">V2</span>
                //           </div>
                //           <div className="k-v-value">
                //             ${formatAmount(totalValueLocked?.["totalV2"], USD_DECIMALS, 0, true)}
                //           </div>
                //         </div>
                //         <div className="chains">
                //           {/* <div className="chain">
                //             <img className="chain-img" src={fantomImg} alt="chain" />
                //             <div className="chain-value">${formatAmount(tvl, 18, 0, true)}</div>
                //           </div> */}
                //           {STATS_CHAINS.map((chain) => {
                //             if (!v2?.[chain.chainId]) return null;
                //             return (
                //               <div key={chain.chainId} className="chain">
                //                 <img className="chain-img" src={chain.img} alt={chain.chainId} />
                //                 <div className="chain-value">
                //                   {" "}
                //                   ${formatAmount(totalValueLocked.v2?.[chain.chainId], USD_DECIMALS, 0, true)}
                //                   {/* $
                //                   {formatAmount(
                //                     parseValue(v2?.[chain.chainId]["volume"], USD_DECIMALS),
                //                     USD_DECIMALS,
                //                     0,
                //                     true
                //                   )} */}
                //                 </div>
                //               </div>
                //             );
                //           })}
                //         </div>
                //       </div>
                //       {/* <div className="divider" />
                //       <div className="k-v-box">
                //         <div className="k-v-label">Total value locked on Fantom Legacy</div>
                //         <div className="k-v-value">
                //           ${formatAmount(totalValueLocked?.[FANTOM_LEGACY], USD_DECIMALS, 0, true)}
                //         </div>
                //       </div> */}
                //     </CustomContent>
                //   )}
                // />
                <div>
                  {"$" +
                    formatAmount(
                      totalValueLocked?.[chainId]?.add(totalValueLocked?.v2?.[chainId] || 0),
                      USD_DECIMALS,
                      0,
                      true
                    )}
                </div>
              ) : (
                <div>$--</div>
                // <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="stats-img">
            <img src={stats2} alt="img" />
          </div>
          <div className="stats-info total-volumn ">
            <div className="label">Total Volume</div>
            <div className="stats-value" style={{ color: Colors[1] }}>
              {totalTradingVolume?.[chainId] ? (
                "$" +
                formatAmount(
                  BigNumber.from(totalTradingVolume?.[chainId]).add(
                    parseValue(v2?.[chainId]?.["volume"] || 0, USD_DECIMALS)
                  ),
                  USD_DECIMALS,
                  0,
                  true
                )
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="stats-img">
            <img src={stats3} alt="img" />
          </div>
          <div className="stats-info total-fee">
            <div className="label">Total Fees</div>
            <div className="stats-value" style={{ color: Colors[2] }}>
              {totalFees[chainId] ? (
                "$" +
                formatAmount(
                  BigNumber.from(totalFees[chainId]).add(parseValue(v2?.[chainId]?.["fees"] || 0, USD_DECIMALS)),
                  USD_DECIMALS,
                  0,
                  true
                )
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="stats-img">
            <img src={longPosition} alt="img" />
          </div>
          <div className="stats-info long-position">
            <div className="label">Long Positions</div>
            <div className="stats-value" style={{ color: Colors[3] }}>
              {longOpenInterest[chainId] ? (
                "$" +
                formatAmount(
                  BigNumber.from(longOpenInterest[chainId]).add(
                    parseValue(v2?.[chainId]?.["longAmountInterest"] || 0, USD_DECIMALS)
                  ),
                  USD_DECIMALS,
                  0,
                  true
                )
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="stats-img">
            <img src={shortPosition} alt="img" />
          </div>
          <div
            className="stats-info short-position"
            style={{ background: "linear-gradient(180deg, #260309 0%, #141312 100%)" }}
          >
            <div className="label">Short Positions</div>
            <div className="stats-value" style={{ color: Colors[4] }}>
              {shortOpenInterest[chainId] ? (
                "$" +
                formatAmount(
                  BigNumber.from(shortOpenInterest[chainId]).add(
                    parseValue(v2?.[chainId]?.["shortAmountInterest"] || 0, USD_DECIMALS)
                  ),
                  USD_DECIMALS,
                  0,
                  true
                )
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="stats-img ">
            <img src={since} alt="img" />
          </div>

          <div className="stats-info fee-since">
            <div className="label">
              Fees since {feeSinceToNow[chainId]?.timestamps ? formatDate(feeSinceToNow[chainId]?.timestamps) : ""}
            </div>
            <div className="stats-value" style={{ color: Colors[5] }}>
              {feeSinceToNow?.[chainId]?.timestamps && feeSinceToNow?.[chainId]?.value ? (
                "$" +
                formatAmount(
                  BigNumber.from(feeSinceToNow?.[chainId]?.value)?.add(
                    parseValue(v2?.[chainId]?.["feeSince"] || 0, USD_DECIMALS)
                  ),
                  USD_DECIMALS,
                  0,
                  true
                )
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
      </StatsContainer>
      <VolumeChart chainId={chainId} />
    </Wrapper>
  );
};

const ChainImg = styled.img`
  position: absolute;
  width: 202px;
  height: 202px;
  top: -36px;
  right: -40px;

  @media (max-width: 1023px) {
    width: 160px;
    height: 160px;
    right: -36px;
  }

  @media (max-width: 767px) {
    width: 64px;
    height: 64px;
    top: -16px;
    right: -16px;
  }
`;

const StatBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  position: relative;
  .stats-info {
    border-radius: 16px;
    text-align: center;
    width: 100%;
    padding: 100px 24px 24px 24px;
    position: relative;
  }

  .total-value {
    /* background: linear-gradient(180deg, #1c260e 0%, #141312 100%); */
    border: 2px solid rgba(121, 153, 88, 0);

    background: linear-gradient(180deg, #1c260e 0%, #141312 100%);

    /* border: 2px solid transparent; */
    /* &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: 2px;
      border-radius: inherit; 
      background: linear-gradient(to right, red, orange);
    } */
  }
  .total-volumn {
    background: linear-gradient(180deg, #0e2733 0%, #141312 100%);
    border: 2px solid rgba(92, 137, 153, 0);
  }
  .total-fee {
    border: 2px solid rgba(100, 107, 131, 0);

    background: linear-gradient(180deg, #271c33 0%, #141312 100%);
  }
  .long-position {
    border: 2px solid rgba(121, 153, 88, 0);

    background: linear-gradient(180deg, #1c260e 0%, #141312 100%);
  }
  .short-position {
    border: 2px solid rgba(121, 153, 88, 0);

    background: linear-gradient(180deg, #1c260e 0%, #141312 100%);
  }
  .fee-since {
    border: 2px solid rgba(153, 96, 76, 0);

    background: linear-gradient(180deg, #331f03 0%, #141312 100%);
  }

  .stats-img {
    position: absolute;
    z-index: 4;
    top: -60px;
  }
  .label {
    margin-bottom: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
    line-height: 140%;
    color: #fff;
  }
  .stats-value {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;

    @media (max-width: 1023px) {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    .stats-info.total-volumn .Tooltip-popup,
    .stats-info.long-position .Tooltip-popup,
    .stats-info.fee-since .Tooltip-popup {
      left: -200px;
      right: 0;
      width: 85vw !important;
    }
  }
`;

const StatsContainer = styled.div`
  margin: 120px 0 24px 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 24px;
  z-index: 3 !important;

  @media screen and (max-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > div {
      min-width: 200px;
      &:nth-child(6),
      &:nth-child(5) {
        margin-top: 50px;
      }
    }
  }
  @media screen and (max-width: 930px) {
    > div {
      &:nth-child(4) {
        margin-top: 50px;
      }
    }
  }

  @media (max-width: 767px) {
    margin-top: 60px;
    gap: 16px;
    .stats-img img {
      width: 100px;
      height: 100px;
    }
    > div {
      min-width: calc(50% - 8px);
      width: calc(50% - 8px);

      margin-top: 50px;
      .label {
        font-size: 11px;
      }
    }
    .stats-info {
      padding: 50px 16px 16px 16px;
    }
  }
`;

const Description = styled.div`
  color: #999691;
  font-size: 14px;

  font-weight: 500;
  line-height: 140%;
`;

const Title = styled.div`
  color: #fff;
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  margin-bottom: 8px;

  img {
    width: 36px;
    height: 36px;
  }
`;

const CustomContent = styled.div`
  width: 296px;

  .k-v-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--base-unit-xs-8, 8px);

    .k-v-label {
      color: var(--white-60, rgba(255, 255, 255, 0.6));
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */

      &:has(.ver) {
        display: flex;
        align-items: center;
        gap: var(--Border_radius, 4px);

        .ver {
          display: flex;
          padding: 0px var(--base-unit-xs-8, 8px);
          flex-direction: column;
          align-items: flex-start;
          gap: var(--base-unit-xs-8, 8px);
          border-radius: var(--Border_radius, 4px);
          border: 2px solid var(--main-primary, #176ce5);
          height: 20px;

          color: var(--main-primary, #176ce5);
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */

          &.v2 {
            color: var(--Warning, #ffdf76);
            border-radius: var(--Border_radius, 4px);
            border: 2px solid var(--Warning, #ffdf76);
          }
        }
      }
    }

    & > .k-v-value {
      color: #fff !important;
      font-size: 16px;
      font-weight: 700 !important;
      line-height: 140%; /* 22.4px */
    }
  }

  .divider {
    margin: 12px 0;
  }

  .tooltip-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }

  .chains {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--base-unit-xs-8, 8px);
  }

  .chain {
    display: flex;
    align-items: center;
    gap: var(--base-unit-xs-8, 8px);

    .chain-img {
      display: flex;
      width: 16px;
      height: 16px;
      justify-content: center;
      align-items: center;
    }

    .chain-value {
      color: var(--white-100, #fff);
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  > *:not(.background-chain) {
    position: relative;
    z-index: 2;
  }

  /* .background-chain {
    background: radial-gradient(50% 285.34% at 100% 0%, rgba(0, 46, 88, 0.8) 0%, rgba(25, 27, 46, 0.8) 100%);
    position: absolute;
    border-radius: 24px;
    inset: 0;
    overflow: hidden;
    z-index: 1;

    &.op-content {
      background: radial-gradient(50% 285.34% at 100% 0%, rgba(88, 0, 0, 0.8) 0%, rgba(25, 27, 46, 0.8) 100%);
    }
  } */

  /* @media screen and (max-width: 600px) {
    padding: 16px;
  } */

  @media (max-width: 767px) {
    .Tooltip-popup {
      min-width: 240px !important;
      width: unset !important;
    }
  }

  &.theme--light {
    border-radius: 24px;
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

    .recharts-layer text {
      fill: #0d1a16b2 !important;
    }

    /* .background-chain {
      background: radial-gradient(50% 285.34% at 100% 0%, #a5e8ff 0%, #fff 100%);

      &.op-content {
        background: radial-gradient(50% 285.34% at 100% 0%, #ffa5af 0%, #fff 100%);
      }

      &.arb-content {
        background: radial-gradient(50% 285.34% at 100% 0%, #a5d3ff 0%, #fff 100%);
      }

      &.base-content {
        background: radial-gradient(50% 285.34% at 100% 0%, #aac5ff 0%, #fff 100%);
      }
    } */

    ${Description} {
      * {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
      }
    }

    .stats-img {
      position: absolute;
    }
  }
`;

export default Stats;
