import { getExplorerUrl } from "config/chains";
import { getTokenSymbolFromString } from "domain/tokens";
import { BigNumber } from "ethers";
import { importImage } from "lib/legacy";
import { formatAmount, parseValue } from "lib/numbers";
import { useState } from "react";
import cx from "classnames";
import moment from "moment";
import { TYPE_MAP_TYPE } from "pages/AnalyticsYummy/components";
import ExternalLink from "components/ExternalLink/ExternalLink";
import ic_new_tab from "img/trade/new_tab.svg";
const TradeActivityItemV2 = (props) => {
  const { marketAsssets, chainId, position, handleSelectedPosition } = props;
  const { symbol = "...", decimals = 4 } = marketAsssets?.[position.tokenId] || {};
  const leverage = BigNumber.from(position?.tradeVolume || 0)
    .mul(parseValue("1", 30))
    .div(BigNumber.from(position?.collateral || 0));
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const posQty = parseValue(position.tradeVolume, 30).mul(parseValue(1, 30)).div(parseValue(position.averagePrice, 30));

  const completePosition = {
    ...position,
    leverage,
    symbol,
    quantity: posQty,
  };

  const txUrl = getExplorerUrl(chainId) + "tx/" + position.transactionHash;

  return (
    <tr className="table-content clickable" onClick={() => handleSelectedPosition(completePosition)}>
      <td className="clickable">
        <div>
          <img src={importImage("ic_" + getTokenSymbolFromString(symbol)?.toLowerCase() + "_24.svg")} alt="tk" />{" "}
          <div className="tk_info">
            <div className="Exchange-list-title">
              {symbol} <span className="muted">#{position.posId}</span>
            </div>
            <div className="Exchange-list-info-label">
              <span
                className={cx({ bold: true, positive: position.isLong, negative: !position.isLong })}
                style={{ color: position.isLong ? "#C1FF99" : "#F6475D" }}
              >
                {position.isLong ? `LONG` : `SHORT`}
              </span>
              {/* {leverage && (
                <span
                  className=" leverage-box"
                  style={{
                    color: "#FFC700",
                    backgroundColor: "rgba(255, 199, 0, 0.20)",
                    height: 16,
                    display: "inline-block",
                    opacity: 1,
                    borderRadius: "100px",
                    padding: "0 4px",
                    fontSize: "12px",
                    lineHeight: "15.6px",
                    fontWeight: 700,
                    marginLeft: "4px",
                  }}
                >
                  {formatAmount(leverage, 30, 2, true)}X
                </span>
              )} */}
            </div>
          </div>
        </div>
        {/* <div className={`flag ${position.isLong ? "isLong" : "isShort"}`}></div> */}
      </td>
      <td>
        <div style={{ marginBottom: 4 }}>{moment(new Date(position.createdAt * 1000)).format(dateFormat)}</div>
        <div className="muted fz-12 bold">{moment(new Date(position.createdAt * 1000)).format(timeFormat)}</div>
      </td>
      <td>
        {/* {position.isTrigger
        ? BigNumber.from(position.profitLoss).gt(0)
          ? "Take Profit"
          : "Stop Loss"
        : position.positionType} */}
        {position.positionType}
      </td>
      <td>
        <div className="">
          {position.actionType === "ADD_TRAILING_STOP" ? (
            position.trailingStopAll ? (
              "Close"
            ) : (
              "Decrease"
            )
          ) : (
            <span>
              {position.actionType === "EDIT_COLLATERAL"
                ? position.isPlus
                  ? "Deposited"
                  : "Withdrew"
                : TYPE_MAP_TYPE[position.actionType]}{" "}
              <span className="muted">(Price ${formatAmount(position.markPrice, 30, decimals, true)})</span>
            </span>
          )}
        </div>
      </td>
      <td>
        {position.actionType !== "EDIT_COLLATERAL" && (
          <>
            <div> ${formatAmount(position.tradeVolume, 30, 2, true)}</div>
            <div className="muted fz-12 bold">
              {formatAmount(posQty, 30, 4, true)} {getTokenSymbolFromString(symbol)}
            </div>
          </>
        )}
      </td>
      <td>
        <ExternalLink href={txUrl} onClick={(e) => e.stopPropagation()}>
          <img style={{ width: 20, height: 20 }} src={ic_new_tab} alt="ic_new_tab" />
        </ExternalLink>
      </td>
    </tr>
  );
};
export default TradeActivityItemV2;
