import { useChainId, useChainIdWithDefault } from "lib/chains";
import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import cx from "classnames";

import ExternalLink from "components/ExternalLink/ExternalLink";
import { useLastSubgraphBlock } from "dataProvider";
import { useThemeContext } from "contexts/ThemeProvider.js";

import "styles/AnalyticV2.scss";
import Footer from "components/Footer/Footer";
import { ARBITRUM, BASE, BERACHAIN_BARTIO, FANTOM, OP, SUPPORTED_V2_CHAINS } from "config/chains";
import useSwitchChain from "hooks/useSwitchChain";
import rightImg from "img/analytics/right_img-v2.svg";
import WarningIcon from "img/analytics/warning_icon.svg";
import bg from "img/analytics/bg.svg";
import Account from "pages/AnalyticsV2/components/Account";
import { TXT } from "pages/NFT/multichainTxt";
import Bounce from "react-reveal/Fade";
import { Link, Route, useLocation, useRouteMatch } from "react-router-dom";
import { useMedia } from "react-use";
import { ContestTab, Overview, PositionDetail, SearchTab, TopPositionTab, TopTradersTab } from "./components";
import "./styles/AnalyticsV2.styled";
import { StyledAnalyticsContent } from "./styles/AnalyticsV2.styled";
import { ACTIVE_CHAIN_IDS, ACTIVE_CHAIN_IDS_V2 } from "lib/legacy";

const TABS = [
  {
    label: "Overview",
    networks: [BERACHAIN_BARTIO],
    link: "/analytic-v2/overview",
  },
  {
    label: "Top Traders",
    networks: [BERACHAIN_BARTIO],
    link: "/analytic-v2/top-traders",
  },
  {
    label: "Top Positions",
    // networks: [FANTOM],
    link: "/analytic-v2/top-positions",
  },
  {
    label: "Contest",
    networks: [BERACHAIN_BARTIO],
    hideOn: [ARBITRUM, BASE],
    link: "/analytic-v2/contest",
  },
  {
    label: "Search",
    // networks: [FANTOM],
    link: "/analytic-v2/search",
  },
];

export enum FilterEnumType {
  LASTMONTH = "Last month",
  LAST2MONTH = "Last 2 months",
  ALLTIME = "All time",
  CUSTOM = "Custom",
}
const AnalyticsV2 = () => {
  const { lightThemeClassName } = useThemeContext() as any;
  const chainId = useChainIdWithDefault({
    isUseDefaultChain: false,
  });
  const location = useLocation();
  const isTablet = useMedia("(max-width:1000px)");
  const [switchChain] = useSwitchChain(chainId);
  const isValidChain = SUPPORTED_V2_CHAINS.includes(chainId);
  const [activedTab, setActivedTab] = useState(location.pathname);
  const { path } = useRouteMatch();

  const onSwitchNetwork = () => {
    switchChain(FANTOM);
  };

  useEffect(() => {
    setActivedTab(location.pathname);
  }, [location.pathname]);

  return (
    <Wrapper $isTablet={isTablet} className="analytic-v2-container">
      <BannerWrapper className="banner-wrapper">
        <div className="analytic-container-banner">
          <Title>
            <div className="title-container">
              <span className="version-styled">V2</span> <span className="title-banner-styled">Analytics</span>{" "}
              {/* <img src={TXT[chainId]?.icon} alt="img" />{" "} */}
            </div>
            <p className="desc">
              The comprehensive statistics and data visualization. <br />
              Does not include{" "}
              <ExternalLink
                target="_self"
                className="link-styled"
                href="https://app.yummy.fi/#/analytic/overview"
              >
                V1
              </ExternalLink>{" "}
              data
            </p>
          </Title>
          {/* <ImgContainer>
            <img src={rightImg} alt="img" className="img-header" />
          </ImgContainer> */}
        </div>
      </BannerWrapper>

      <BodyContentWrapper className={lightThemeClassName}>
        <div className="analytic-container-body">
          <TabWrapper
            className={cx({
              "wrong-chain": !isValidChain,
            })}
          >
            <div className="tab-container">
              {TABS.map((item) => {
                const hide = item.hideOn && item.hideOn.some((chain) => chainId === chain);
                if (hide) return null;
                return (
                  <TabItem
                    onClick={() => setActivedTab(item.link)}
                    key={item.link}
                    to={item.link}
                    className={`${activedTab === item.link ? "tab--item tab--actived" : "tab--item"}`}
                  >
                    <div>{item.label}</div>
                  </TabItem>
                );
              })}
            </div>
          </TabWrapper>

          {!isValidChain && (
            <Bounce>
              <WarningWrapper>
                <div>
                  <img src={WarningIcon} alt="Warning icon" />
                </div>
                <div className="warning-right-content">
                  <div className="warning-content">
                    <div className="label-warning">
                      This page is supported only supported in Fantom Opera, Optimism.
                    </div>
                    <div className="desc-warning">Please connect to the correct network to continue.</div>
                  </div>
                  <button className="default-btn" onClick={onSwitchNetwork} disabled={false}>
                    Switch network
                  </button>
                </div>
              </WarningWrapper>
            </Bounce>
          )}
          <StyledAnalyticsContent>
            <Route path={`${path}/overview`}>
              <Overview />
            </Route>
            <Route path={`${path}/top-traders`}>
              <TopTradersTab chainId={chainId} isValidChain={isValidChain} />
            </Route>
            <Route path={`${path}/top-positions`}>
              <TopPositionTab chainId={chainId} isValidChain={isValidChain} />
            </Route>
            <Route path={`${path}/position/:posId`}>
              <PositionDetail chainId={chainId} isValidChain={isValidChain} />
            </Route>
            <Route path={`${path}/account/:account`}>
              <Account chainId={chainId} isValidChain={isValidChain} />
            </Route>
            <Route path={`${path}/contest`}>
              <ContestTab chainId={chainId} isValidChain={isValidChain} />
            </Route>
            <Route path={`${path}/search`}>
              <SearchTab />
            </Route>
            <Route exact path={`${path}/`}>
              <Overview />
            </Route>
          </StyledAnalyticsContent>
        </div>
      </BodyContentWrapper>
      <Footer />
    </Wrapper>
  );
};

export default memo(AnalyticsV2);

const Wrapper = styled.div<{ $isTablet: Boolean }>`
  flex-direction: column;
  background-image: url(${bg});

  .fz-12 {
    font-size: 12px;
  }

  .react-dropdown-select-dropdown-handle {
    display: none;
  }
  .react-dropdown-select {
    box-sizing: border-box;
    position: relative;
    display: flex;
    width: 100%;
    border-radius: 2px;
    padding: 2px 5px;
    flex-direction: row;
    direction: ltr;
    align-items: center;
    cursor: pointer;
    min-height: 36px;
    pointer-events: all;
    background: transparent;
    border: none;
    box-shadow: none !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .date-range-items {
    overflow: auto;
    min-height: 10px;
    max-height: 200px;
  }

  .date-range-selector-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    .date-container {
      display: flex;
      border-radius: 4px;
      border: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
      background: var(--Natural-1, #18191a);
      padding: 0 16px 0 6px;
      align-items: center;
      height: 48px;
    }
  }
  .date-range-item {
    display: flex;
    margin: 0.5rem;
    padding: 0.5rem;
    align-items: baseline;
  }
  .rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
    padding-top: 10px;
    box-sizing: inherit;
    display: flex;
    justify-content: space-between;
  }
  .rdrDay {
    box-sizing: inherit;
    width: calc(100% / 7);
    position: relative;
    font: inherit;
    cursor: pointer;
  }
  .rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .date-range-selector-wrapper .rdrMonthAndYearPickers select {
    color: #fff;
    background: #18191a;
  }
  .date-range-selector-wrapper {
    .rdrStartEdge,
    .rdrInRange,
    .rdrEndEdge {
      color: #02b27f !important;
    }
  }
  .date-range-selector-wrapper .rdrCalendarWrapper {
    background-color: #302f2f;
  }
  .rdrCalendarWrapper {
    color: #000;
    font-size: 12px;
  }
  .rdrNextPrevButton {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin: 0;
    border-radius: var(--Border_radius, 4px);
    border: 1px solid var(--Border_Highlight, rgba(255, 255, 255, 0.1));
    background-color: transparent;
  }
  .rdrNextPrevButton i {
    margin: 0;
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.6);
  }
  .rdrPprevButton i {
    border-color: transparent rgba(255, 255, 255, 0.6) transparent transparent;
  }
  .react-dropdown-select-dropdown {
    background-color: #302f2f;
    border: 1px solid #ffffff14;
    position: absolute;
    top: 43px;
    /* right: ${({ $isTablet }) => ($isTablet ? "auto" : 0)};
    left: unset !important; */
    left: -6px !important;
    border: 1px solid #ccc;
    width: 348px;
    padding: 0;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    overflow: auto;
    z-index: 9;
    max-height: 475px !important;
    width: fit-content !important;
  }
  .date-range-custom {
    color: #000;
    margin: 0.5rem;
    text-align: center;
  }
  .page-description {
    margin-bottom: 0;
    color: rgb(173, 171, 171);

    a {
      color: rgb(173, 171, 171);
    }
  }

  .analytic-container-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: calc(1320px + 48px);
    padding-left: 24px;
    padding-right: 24px;
    margin: auto;
    @media screen and (max-width: 700px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .link-styled {
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    color: #ffffff99;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      color: #fff;
      transition: all.5s;
    }
  }
  .desc {
    /* display: flex; */
  }
`;

const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  background-image: linear-gradient(to left, rgba(0, 126, 204, 0.8), rgba(34, 57, 102, 0.8));
  background-position: center;
  width: 100%;
  height: 289px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  .header {
    width: 100%;
    max-width: calc(1320px + 48px);
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  .analytic-container-banner {
    width: 100%;
    max-width: calc(1320px + 48px);
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 225px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      height: 289px;
      margin-top: 32px;
    }
  }

  @media (max-width: 767px) {
    justify-content: center;
  }
`;
const Title = styled.div`
  width: 1218px;
  max-width: 100%;
  text-transform: uppercase;

  @media (max-width: 767px) {
    padding-bottom: 24px;
  }

  .title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    @media (max-width: 1024px) {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  span {
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    color: rgb(255, 255, 255);
  }
  .version-styled {
    color: #ffdf76;
  }
  img {
    width: 48px;
    height: 48px;
  }

  .desc {
    text-transform: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 4px;
    margin-bottom: 0;
  }
`;
const ImgContainer = styled.div`
  display: flex;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
  object-fit: contain;
  margin-left: auto;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;

  @media screen and (max-width: 1024px) {
    img {
      width: 600px;
    }
  }
  @media screen and (max-width: 767px) {
    img {
      width: 100%;
    }
  }
`;
const BodyContentWrapper = styled.div`
  padding: 0px 0px 286px;
  .data-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .react-dropdown-select-dropdown-handle {
    display: none;
  }
  .react-dropdown-select {
    box-sizing: border-box;
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    direction: ltr;
    align-items: center;
    cursor: pointer;
    min-height: 36px;
    pointer-events: all;
    background: #191b2e;
    border: 1px solid #ffffff1a;
    border-radius: 8px;
    padding: 12px 16px;
    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.2);
      transition: 0.5s;
    }
  }
  .date-range-items {
    overflow: auto;
    min-height: 10px;
    max-height: 200px;
  }

  .date-range-item {
    display: flex;
    margin: 0.5rem;
    padding: 0.5rem;
    align-items: baseline;
  }
  .rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
    padding-top: 10px;
    box-sizing: inherit;
    display: flex;
    justify-content: space-between;
  }
  .rdrDay {
    box-sizing: inherit;
    width: calc(100% / 7);
    position: relative;
    font: inherit;
    cursor: pointer;
  }
  .rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .date-range-selector-wrapper .rdrMonthAndYearPickers select {
    color: #fff;
    background: #16182e;
  }
  .date-range-selector-wrapper .rdrCalendarWrapper {
    background-color: #16182e;
  }
  .rdrCalendarWrapper {
    color: #000;
    font-size: 12px;
  }
  .react-dropdown-select-dropdown {
    background-color: #101124;
    border: 1px solid #ffffff14;
    position: absolute;
    top: 43px;
    right: 0;
    left: unset !important;
    /* border: 1px solid #ccc; */
    width: 348px;
    padding: 0;
    display: flex;
    flex-direction: column;
    /* background: #fff; */
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    overflow: auto;
    z-index: 9;
    max-height: 475px !important;
    width: fit-content !important;
  }
  .date-range-custom {
    color: #000;
    margin: 0.5rem;
    text-align: center;
  }
  @media (max-width: 1023px) {
    padding: 0 0px 286px;
  }
  @media (max-width: 767px) {
    padding: 0 0px 286px;
  }

  &.theme--light {
    background: linear-gradient(180deg, #f7fffd 0%, #eef1f7 100%);
  }
`;
const WarningWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px;
  margin: 24px 0px;
  border-radius: var(--base-unit-s-12, 12px);
  border: 1px solid #ffa722;
  background: rgba(255, 167, 34, 0.1);
  gap: 16px;

  .warning-right-content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .warning-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    gap: 8px;
    .label-warning {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 28px */
    }
    .desc-warning {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      color: rgba(255, 255, 255, 0.6);
    }
  }

  @media (max-width: 767px) {
    align-items: flex-start;
    padding: 18px;
    img {
      width: 24px;
      height: 24px;
    }
    button {
      align-self: start;
    }
    .warning-right-content {
      flex-direction: column;
      gap: 16px;
    }
    .warning-content {
      .label-warning {
        font-size: 14px;
      }
      .desc-warning {
        font-size: 14px;
      }
    }
  }
`;

const TabWrapper = styled.div`
  width: 100%;
  max-width: calc(1320px + 48px);
  /* padding-left: 24px;
  padding-right: 24px; */
  margin: 0 auto 24px;
  display: flex;
  justify-content: space-between;

  &.wrong-chain {
    margin-bottom: 0;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    margin-bottom: 36px;
    /* padding-left: 20px; */

    > .tab-container {
      width: fit-content;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 24px;
    /* padding-left: 16px;
    padding-right: 16px; */

    /* > .tab-container {
      width: 100%;
    } */

    > span {
      padding-left: 20px;
      margin-top: 10px;
      width: 100% !important;
    }
  }

  > span {
    flex: 1 45%;
    text-align: right;
    align-self: flex-end;
    color: #ffffff99;

    @media (max-width: 1023px) {
      margin-top: 16px;
      padding-right: 20px;
    }
  }

  .tab-container {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    padding: 4px;
    background-color: #191b2e;
    border-radius: 0px 0px 16px 16px;
    max-width: 100%;

    &::-webkit-scrollbar {
      height: 2px;
    }

    /* &::-webkit-scrollbar-track-piece:end {
      margin-right: 6px;
    }

    &::-webkit-scrollbar-track-piece:start {
      margin-left: 10px;
    } */

    @media (max-width: 1023px) {
      justify-content: space-between;
    }
  }
`;

const TabItem = styled(Link)`
  padding: 12px 16px;
  white-space: nowrap;

  font-size: 14px;
  text-decoration: none;
  font-weight: 500 !important;
  color: rgb(255, 255, 255);
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: #4787e3;
  }
  &.tab--actived {
    color: #176ce5;
    background-color: #ffffff1a;
    border-radius: 12px;
  }
`;
