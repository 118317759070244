import { useMemo, useState } from "react";
import ClosedPositionItemV1 from "./ClosedPositionItemV1";
import ClosedPositionItemV2 from "./ClosedPositionItemV2";
import PositionClosedShare from "components/ExchangeV2/PositionClosedShare";
import PositionClosed from "components/ExchangeV2/PositionClosed";
import { DEFAULT_PAGE_SIZE_POSITIONS } from "../../hooks/useUserPosition";
import PaginationV2 from "../../pages/AnalyticsV3/components/PaginationV2";
import ClosedPositionItemMobileV2 from "./ClosedPositionItemMobileV2";
import PositionShareClosedV1 from "components/Exchange/PositionShareClosedV1";
import ClosedPositionItemMobileV1 from "./ClosedPositionItemMobileV1";
import PositionClosedV1 from "components/ExchangeV2/PositionClosedV1";

const ClosedPositionList = (props) => {
  const [page, setPage] = useState(1);

  const { positions, loading, marketTokensInfo, marketAsssets, chainId, account } = props;
  const [positionToShare, setPositionToShare] = useState(null);
  const [positionToShareV1, setPositionToShareV1] = useState(null);
  const [isPositionDetailsVisible, setIsPositionDetailsVisible] = useState(undefined);
  const [isPositionDetailsVisibleV1, setIsPositionDetailsVisibleV1] = useState(undefined);
  const [selectedPosition, setSelectedPosition] = useState(undefined);
  const [isPositionShareModalOpen, setIsPositionShareModalOpen] = useState(false);
  const [isPositionShareModalOpenV1, setIsPositionShareModalOpenV1] = useState(false);

  const filterPositions = useMemo(() => {
    const startIndex = (page - 1) * DEFAULT_PAGE_SIZE_POSITIONS;
    const endIndex = page * DEFAULT_PAGE_SIZE_POSITIONS;
    return positions?.slice(startIndex, endIndex) || [];
  }, [positions, page]);
  // console.log("????----", filterPositions);

  const handleSelectedPosition = (position) => {
    setSelectedPosition(position);
    setIsPositionDetailsVisible(true);
  };
  const handleSelectedPositionV1 = (position) => {
    setSelectedPosition(position);
    setIsPositionDetailsVisibleV1(true);
  };
  return (
    <div className="PositionsList">
      <table className={`Exchange-list large App-box ${positions?.length === 0 ? "no-array-length" : ""}`}>
        <tbody>
          <tr className="Exchange-list-header">
            <th>
              <span>Position</span>
            </th>
            <th>
              <span>Opened</span>
            </th>
            <th>
              <span>Closed</span>
            </th>
            <th>
              <span>Duration</span>
            </th>
            <th>
              <span>PnL/ROI</span>
            </th>
            <th>
              <span>Avg. Entry Price</span>
            </th>
            <th>
              <span>Close Price</span>
            </th>
            <th>
              <span>Pos. Size</span>
            </th>
            <th></th>
          </tr>
          {!loading && positions.length === 0 && (
            <tr className="no-position">
              <td colSpan="15">
                <div className="no-data-record">No closed positions</div>
              </td>
            </tr>
          )}
          {filterPositions.length > 0 &&
            filterPositions.map((position, index) => {
              if (position.isV1) {
                return (
                  <ClosedPositionItemV1
                    key={index}
                    position={position}
                    setPositionToShareV1={setPositionToShareV1}
                    setIsPositionShareModalOpenV1={setIsPositionShareModalOpenV1}
                    handleSelectedPosition={handleSelectedPositionV1}
                  />
                );
              } else {
                return (
                  <ClosedPositionItemV2
                    key={index}
                    position={position}
                    marketTokensInfo={marketTokensInfo}
                    marketAsssets={marketAsssets}
                    chainId={chainId}
                    handleSelectedPosition={handleSelectedPosition}
                    setIsPositionShareModalOpen={setIsPositionShareModalOpen}
                    setPositionToShare={setPositionToShare}
                  />
                );
              }
            })}
        </tbody>
      </table>
      <div className="Exchange-list small">
        {filterPositions.length > 0 &&
          filterPositions.map((position, index) => {
            if (position.isV1)
              return (
                <ClosedPositionItemMobileV1
                  key={index}
                  position={position}
                  setPositionToShareV1={setPositionToShareV1}
                  setIsPositionShareModalOpenV1={setIsPositionShareModalOpenV1}
                  handleSelectedPosition={handleSelectedPositionV1}
                />
              );
            else
              return (
                <ClosedPositionItemMobileV2
                  key={index}
                  position={position}
                  marketTokensInfo={marketTokensInfo}
                  marketAsssets={marketAsssets}
                  chainId={chainId}
                  handleSelectedPosition={handleSelectedPosition}
                  setIsPositionShareModalOpen={setIsPositionShareModalOpen}
                  setPositionToShare={setPositionToShare}
                />
              );
          })}
      </div>
      {isPositionShareModalOpen && (
        <PositionClosedShare
          setIsPositionShareModalOpen={setIsPositionShareModalOpen}
          isPositionShareModalOpen={isPositionShareModalOpen}
          selectedPosition={positionToShare}
          chainId={chainId}
          account={account}
          marketTokensInfo={marketTokensInfo}
        />
      )}
      {isPositionShareModalOpenV1 && (
        <PositionShareClosedV1
          setIsPositionShareModalOpen={setIsPositionShareModalOpenV1}
          isPositionShareModalOpen={isPositionShareModalOpenV1}
          positionToShare={positionToShareV1}
          chainId={chainId}
          account={account}
        />
      )}
      {isPositionDetailsVisible && (
        <PositionClosed
          isVisible={isPositionDetailsVisible}
          setIsVisible={setIsPositionDetailsVisible}
          position={selectedPosition}
          chainId={chainId}
          marketTokensInfo={marketTokensInfo}
        />
      )}
      {isPositionDetailsVisibleV1 && (
        <PositionClosedV1
          isVisible={isPositionDetailsVisibleV1}
          setIsVisible={setIsPositionDetailsVisibleV1}
          position={selectedPosition}
          chainId={chainId}
        />
      )}
      {positions && positions.length > DEFAULT_PAGE_SIZE_POSITIONS && (
        <PaginationV2
          rowsPerPage={DEFAULT_PAGE_SIZE_POSITIONS}
          rows={positions.length}
          currentPage={page}
          setCurrentPage={setPage}
        />
      )}
    </div>
  );
};
export default ClosedPositionList;
